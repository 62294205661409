import {FC, useState, createContext, useContext} from 'react'

type SideBarContextType = {
  newSession: boolean
  setNewSession: (value: boolean) => void
  updateChatUiSize: boolean
  setUpdateChatUiSize: (updateChatUiSize: boolean) => void
}
const SideBarContext = createContext<SideBarContextType | undefined>(undefined)

const SideBarContextProvider = ({children}) => {
  const [newSession, setNewSession] = useState<boolean>(false)
  const [updateChatUiSize, setUpdateChatUiSize] = useState<boolean>(false)
  const value = {
    newSession,
    setNewSession,
    updateChatUiSize,
    setUpdateChatUiSize
  }
  return <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>
}

const useSideBarContext = () => {
  const sideBarContext = useContext(SideBarContext)
  if (!sideBarContext) {
    throw new Error(' useSideBarContext must be used within a Sidebarprovider')
  }
  return sideBarContext
}

export {useSideBarContext,SideBarContextProvider}