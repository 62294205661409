/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import axios from 'axios'
import {useProfileContext} from '../../../../app/context/profilecontext'
import {useFlowContext} from '../../../../app/context/FlowContext'
import selectedStyle from '../../../../app/styles/selectedprofiles.module.css'
import settingStyle from '../../../../app/styles/settingstyle.module.css'
import {Card} from 'react-bootstrap'
import {CSVLink} from 'react-csv'
import * as XLSX from 'xlsx'
type Props = {
  className: string
  flowProfiles: PersonList[]
}
interface PersonList {
  name: string[]
  id: string[]
  picUrl: string
  linkedinUrl: string[]
  git: boolean[]
  companyName: string[]
  companyUrl: string[]
  emailId: string[]
  role: string[]
  mobile: string[]
  listName: string
  date: string
}
interface Email {
  type: string | null
}
interface TableReportData {
  name: string
  email: string
  sent: string
  opened: string
  clicked: string
  replied: string
}
const sampleData: TableReportData[] = [
  {name: 'Demo', email: 'demo@company.com', sent: '20', opened: '15', clicked: '10', replied: '9'},
  {name: 'Demo', email: 'demo@company.com', sent: '20', opened: '15', clicked: '10', replied: '9'},
]
//  this the table which is rendered to display the selected profiles in tabular format
const FlowReportTable: React.FC<Props> = ({className, flowProfiles}) => {
  const [tableData, setTableData] = useState<TableReportData[]>(sampleData)
  return (
    <div style={{border: '0px', boxShadow: 'none'}} className={`card ${className}`}>
      <div style={{minHeight: 'auto'}} className='card-header border-0 pt-5 ps-3'>
        <h3 className='card-title align-items-center'>
          <span className='card-label fw-regular fs-3 mb-1'>Profiles Contacted</span>
          <span className={`text-muted mt-1 fw-semibold fs-6 ${selectedStyle.listInfo}`}>
            {tableData.length}
          </span>
        </h3>
        <div style={{position: 'relative'}} className='d-flex align-items-center'>
          <input
            style={{paddingTop: '20px', paddingBottom: '20px', paddingRight: '35px'}}
            type='text'
            className='form-control fw-light'
            id='formGroupExampleInput'
            placeholder='Search'
            // value={searchText}
            // onChange={(e) =>handleSearchInputChange(e)}
          />
          <i style={{position: 'absolute', left: '87%'}} className='bi bi-search fs-2'></i>
        </div>
      </div>
      <div className='card-body py-3 px-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='bg-light py-2 text-muted align-middle fw-semibold text-center'>
                <th className='ps-0 min-w-125px rounded-start  fs-6 '>Profile Name</th>
                <th className='min-w-225px fs-6 pt-0'>Email </th>
                <th className='min-w-125px fs-6 pt-0'>Sent</th>
                <th className='min-w-100px fs-6 pt-0'>Opened</th>
                <th className='min-w-100px fs-6 pt-0'>Clicked</th>
                <th className='min-w-100px fs-6 pt-0'>Replied</th>
                <th className='min-w-100px fs-6 pt-0'>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((profile, index) => (
                <tr className='text-center' key={index}>
                  <td>
                    <div>
                      <a style={{lineHeight: '13px'}} href='#' className='text-dark fw-semibold fs-7'>
                        {profile.name.charAt(0).toUpperCase() + profile.name.slice(1)}
                      </a>
                    </div>
                  </td>
                  <td>
                    <div>
                      {profile.email ? (
                        <span style={{lineHeight: '13px'}} className='d-block fs-7'>
                          {profile.email}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>

                  <td>
                    <div className=''>
                      <span>{profile.sent}</span>
                    </div>
                  </td>
                  <td>
                    <div className=''>
                      <span style={{lineHeight: '13px'}} className='text-dark fs-7'>
                        {profile.opened}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className=''>
                      <span style={{lineHeight: '13px'}} className='text-dark fs-7'>
                        {profile.clicked}
                      </span>
                    </div>
                  </td>
                  <div className=''>
                    <span style={{lineHeight: '13px'}} className='text-dark fs-7'>
                      {profile.replied}
                    </span>
                  </div>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {FlowReportTable}
