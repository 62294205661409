import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {Card, List, Popover} from 'antd'
import convstyle from '../styles/conversation.module.css'
import {useProfileContext} from '../context/profilecontext'
import msg1 from '../assets/msg1.svg'
import {Button, Empty, Flex, Avatar} from 'antd'
import {CircleUserRound} from 'lucide-react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {PlusOutlined} from '@ant-design/icons'
import EmailForm from './EmailForm'
import noConvo from '../assets/noconvo.png'
import beanbag from '../assets/BeanbagLogo.png'
import {CSSTransition} from 'react-transition-group'
import EmptyLogo from './Subcomponents/Empty'
import ConversationsEmail from './Subcomponents/ConversationsEmail'
/**
 * This component handles the rendering of conversation messages of that particular profile,
 * including message sorting, and provides the capability to view and interact with email content.
 * It also allows users to initiate new email conversations.
 */
const Conversation: FC = () => {
  const [selectedMessageIndex, setSelectedMessageIndex] = useState<number | null>(null)
  const [isConversation, setIsConversation] = useState<boolean>(false)
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
  const {selectProfile, profile, profilesData, index, localProfiles, candidateProfilePic} =
    useProfileContext()
  const [open, setOpen] = useState(false)

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  const [conversation, setConversation] = useState<any[]>([])

  useEffect(() => {
    if (profile && profile.conversations) setConversation(profile?.conversations)
  }, [profile])

  const handleShowEmail = () => {
    setShowEmailForm(true)
  }

  const sortedConversation = [...conversation].sort((a, b) => {
    const dateA = +new Date(a.time)
    const dateB = +new Date(b.time)
    return dateA - dateB
  })

  const currentDate = new Date()

  const formatDate = (date: Date) => {
    const timeDiff = currentDate.getTime() - date.getTime()
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

    if (daysDiff < 365) {
      return `${daysDiff} days ago`
    } else {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${day}-${month}-${year}`
    }
  }

  const handleToggleMessage = (index: number) => {
    setSelectedMessageIndex(index === selectedMessageIndex ? null : index)
  }

  return (
    <div className={convstyle.container}>
      {sortedConversation && sortedConversation.length > 0 ? (
        <List
          locale={{
            emptyText: <div><EmptyLogo  description={'No conversations'} /></div>,
          }}
          dataSource={sortedConversation}
          renderItem={(message, index) => (
            <List.Item key={`message${index}`}>
              {/* <Popover
                content={
                  index === selectedMessageIndex && (
                    <div>
                      <span className='fw-bold d-block fs-6'>{message.emailSubject}</span>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        {message.emailContent}
                      </span>
                    </div>
                  )
                }
                placement='bottom'
                trigger={'hover'}
                open={index === selectedMessageIndex}
                onOpenChange={handleOpenChange}
              > */}
              <Flex vertical gap={'small'}>
                <Flex align='center' gap={'small'}>
                  {message.type === 'received' ? (
                    profile ? (
                      <Avatar
                        shape='square'
                        size={'small'}
                        src={<img style={{objectFit: 'contain'}} src={profile['profilePic']} />}
                      />
                    ) : (
                      <AccountCircleIcon style={{width: '60px', height: '60px', color: 'grey'}} />
                    )
                  ) : (
                    <Avatar
                      shape='square'
                      size={'small'}
                      src={<img style={{objectFit: 'contain'}} src={beanbag} />}
                    />
                  )}
                  <span style={{fontSize: '12px'}} className='text-muted'>
                    {formatDate(new Date(message.time))}
                  </span>
                </Flex>
                <Card
                  style={{
                    width: '100%',
                    background: message.type === 'sent' ? '#ffefeb' : '#edfde3',
                  }}
                  onClick={() => handleToggleMessage(index)}
                >
                  <Card.Meta title={message.title} description={<span>{message.message}</span>} />
                </Card>
              </Flex>
              {/* </Popover> */}
            </List.Item>
          )}
        />
      ) : (
        <>
          {showEmailForm ? (
            <ConversationsEmail />
          ) : (
            <Flex vertical gap={'middle'} justify='center' align='center'>
              <img id={convstyle.noMsg} src={noConvo} alt='Message Icon' />
              <span className='text-muted fs-7 mt-3 mb-3'>
                There are no active conversations. To start a new conversation click here
              </span>
              <Button
                type='primary'
                icon={<PlusOutlined rev={undefined} />}
                onClick={handleShowEmail}
              >
                Email
              </Button>
            </Flex>
          )}
        </>
      )}
    </div>
  )
}

export default Conversation
