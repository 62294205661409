import React, {useState, createContext, useContext} from 'react'

type TaskContextData = {
  showCreateTask: boolean
  setShowCreateTask: (showCreateTask: boolean) => void
  tasksArr: TaskArray[]
  setTasksArr: (tasksArr: TaskArray[]) => void
  taskKeys: string[]
  setTaskKeys: (taskKeys: string[]) => void
}
interface TaskArray {
  taskType: string
  taskTitle: string
  due: string
  time: string
  isReminderSet: boolean
  createdBy: string
}
const TaskContext = createContext<TaskContextData | undefined>(undefined)

const TaskContextProvider = ({children}) => {
  const [showCreateTask, setShowCreateTask] = useState<boolean>(false)
  const [tasksArr, setTasksArr] = useState<TaskArray[]>([])
  const [taskKeys,setTaskKeys] = useState<string[]>([])
  let value = {
    showCreateTask,
    setShowCreateTask,
    tasksArr,
    setTasksArr,
    taskKeys,
    setTaskKeys
  }
  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
}

const useTaskContext = (): TaskContextData => {
  const taskContext = useContext(TaskContext)

  if (!taskContext) {
    throw new Error('Error occurred in task context')
  }

  return taskContext
}

export {useTaskContext, TaskContextProvider}
