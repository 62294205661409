// This function is displayed in the initial chat which gives the user th flexibilty to choose the
// roles,skills,company,industry and experience of the candidate in the form of dropdowns
import {useState, useEffect} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import queryTileStyle from '../styles/queryStyle.module.css'
import {FC} from 'react'
import {useChatContext} from '../context/chatcontext'
import {KTIcon, MessageModel} from '../../_metronic/helpers'
import {useSideBarContext} from '../context/sidebarcontext'
import {useValuesContext} from '../context/valuescontext'
import {useApiContext} from '../context/apicontext'
import {useSessionContext} from '../context/sessionContext'
import {v4 as uuid} from 'uuid'
import {ChatAiApi, SearchQueryButtonApi} from '../apicalls/ApiCall'
import {useEditQueryContext} from '../context/editquery'
import {Card, Button, Modal} from 'antd'
import {useProfileContext} from '../context/profilecontext'
import {ArrowRightOutlined, EditOutlined} from '@ant-design/icons'
import FilterPopUp, {FilterObject} from './FilterPopUp'
import FilterModal from './Reusablecomponents/FilterModal'
import QueryMessage from './QueryMessage'
import {QueryObject} from './DataTypes/QueryFilters'
import {userId} from './DataTypes/UserDataTypes'

const DropDownQuery: FC = () => {
  const {setShowchatUI, defaultQuery} = useChatContext()
  const {setSearchList, setTotalResultProfiles, setShowResultLoading} = useProfileContext()
  const {
    dashboardData,
    setApiSearchId,
    setQueryObj,
    setShowLoading,
    searchQuery,
    setTableUi,
    handleSearchReq,
    queryObj,
    setErr,
    setNewData,
    setScrollToken,
    setSqlQuery,
  } = useApiContext()
  const {setShowPopup, showPopup} = useEditQueryContext()
  const [selectedRole, setSelectedRole] = useState<string>('web developer')
  const [selectedCountry, setSelectedCountry] = useState<string>('India')
  const [selectedSkills, setSelectedSkills] = useState<string[]>(['Java'])
  const [selectedExperience, setSelectedExperience] = useState<number | number[]>(0)
  const [selectedCompanyType, setSelectedCompanyType] = useState<string>('StartUps')
  const {newSession} = useSideBarContext()
  const {setComponentInput, sendMessage} = useChatContext()
  const {
    pastJobTitles,
    jobTitles,
    timespent,
    university,
    skills,
    languages,
    excludedCompanies,
    minimumDegree,
    fieldOfStudy,
    industries,
    companySize,
    workedTimeOCompanies,
    companies,
    area,
    profiles,
    experience,
    reqInfo,
    excludedProfile,
    excludedGender,
    filterObject,
    setPastJobTitles,
    setProfiles,
    setArea,
    setExperience,
    setReqInfo,
    setExcludedGender,
    setExcludedProfile,
    setJobTitles,
    setTimeSpent,
    setUniversity,
    setSkills,
    setLanguages,
    setCompanies,
    setIndustries,
    setCompanySize,
    setExcludedCompanies,
    setMinimumDegree,
    setFieldOfStudy,
    setFilterObject,
  } = useValuesContext()
  const {showMessages, setShowMessages, isModalOpen, setIsModalOpen} = useChatContext()
  const {setSessionInteracted, setSearchId} = useSessionContext()
  const {searchId, selectedSearchKey} = useSessionContext()

  useEffect(() => {
    setSelectedCountry(defaultQuery?.Area[0] ? defaultQuery?.Area[0] : '')
    setSelectedExperience(
      defaultQuery?.Experience[0].minExp ? defaultQuery?.Experience[0].minExp : 0
    )
    setSelectedRole(defaultQuery?.JobTitles[0] ? defaultQuery?.JobTitles[0] : '')
    setSelectedSkills(defaultQuery?.Skills ? defaultQuery?.Skills : [])
    setSelectedCompanyType(defaultQuery?.Industries[0] ? defaultQuery.Industries[0] : '')
  }, [defaultQuery])

  useEffect(() => {
    if (filterObject) {
      const updateStateIfChanged = (stateSetter, newValue) => {
        if (stateSetter && newValue !== undefined && stateSetter instanceof Function) {
          stateSetter((prevState) =>
            JSON.stringify(prevState) !== JSON.stringify(newValue) ? newValue : prevState
          )
        }
      }
      updateStateIfChanged(setPastJobTitles, filterObject.PastJobTitles)
      updateStateIfChanged(setProfiles, filterObject.Profiles || 0)
      updateStateIfChanged(setArea, filterObject.Area)
      updateStateIfChanged(setExperience, filterObject.Experience || [{minExp: 0, maxExp: 0}])
      updateStateIfChanged(setReqInfo, filterObject.ReqInfo || [])
      updateStateIfChanged(setExcludedGender, filterObject.ExcludedGender || false)
      updateStateIfChanged(setExcludedProfile, filterObject.ExcludedProfile || false)
      updateStateIfChanged(setExcludedCompanies, filterObject.ExcludedCompanies)
      updateStateIfChanged(setJobTitles, filterObject.JobTitles)
      updateStateIfChanged(setTimeSpent, filterObject.TimeSpent || {years: 0, months: 0})
      updateStateIfChanged(setUniversity, filterObject.University)
      updateStateIfChanged(setSkills, filterObject.Skills || [])
      updateStateIfChanged(setLanguages, filterObject.Languages || [])
      updateStateIfChanged(setCompanies, filterObject.Companies)
      updateStateIfChanged(setIndustries, filterObject.Industries || [])
      updateStateIfChanged(setCompanySize, filterObject.CompanySize || '')
      updateStateIfChanged(setMinimumDegree, filterObject.MinimumDegree || '')
      updateStateIfChanged(setFieldOfStudy, filterObject.FieldOfStudy || [])
      updateStateIfChanged(setSqlQuery, filterObject.searchQuery)
    }
  }, [filterObject])

  const handleSearchCandidate = async (e) => {
    setShowResultLoading(true)
    e.stopPropagation()
    setSessionInteracted(true)
    let Id = uuid()
    setTimeout(() => {
      setShowLoading(true)
    }, 1000)
    setSearchId(Id)
    setApiSearchId(Id)
    sendMessage(searchQuery)
    setShowchatUI(true)
    setSearchList([])
    setTableUi(true)
    try {
      let data = await SearchQueryButtonApi(queryObj, Id, userId)
      if (
        data.status === 404 ||
        data.error ||
        !data.success ||
        data.status === 422 ||
        data.status === 500
      ) {
        setErr('Nodata')
        setShowResultLoading(false)
        setShowLoading(false)
        setNewData([])
      } else if (data.success && data.data) {
        setErr('')
        setShowResultLoading(false)
        setSearchList(data.data.profiles)
        setScrollToken(data.data.token)
        setSqlQuery(data.data.query)
        setTotalResultProfiles(data.data.total)
        setShowLoading(false)
      }
    } catch (e) {
      console.log('error in search ', e)
    }
    // setTimeout(() => {
    //   ;(async () => {
    //     try {
    //       if (Id) {
    //         let data = await SetFilterObjectApi(userId + Id, filterObject)
    //         console.log(data)
    //       }
    //     } catch (err) {
    //       throw new Error('Error while sending the filter Request')
    //     }
    //   })()
    // }, 5000)

    // setJobTitles(defaultQuery?.JobTitles || [])
    // sendMessage(defaultQuery?.searchQuery || '')
    // setShowchatUI(true)
    // setPastJobTitles(defaultQuery?.PastJobTitles || [])
    // setProfiles(defaultQuery?.Profiles || 0)
    // setArea(defaultQuery?.Area || [])
    // setReqInfo(defaultQuery?.ReqInfo || [])
    // setExcludedGender(defaultQuery?.ExcludedGender || false)
    // setExcludedProfile(defaultQuery?.ExcludedProfile || false)
    // setExperience([
    //   {minExp: defaultQuery?.Experience[0]?.minExp || 0},
    //   {maxExp: defaultQuery?.Experience[1]?.maxExp || 0},
    // ])

    // setTimeSpent({
    //   years: defaultQuery?.TimeSpent?.years || 0,
    //   months: defaultQuery?.TimeSpent?.months || 0,
    // })

    // setUniversity(defaultQuery?.University || [])
    // setSkills(defaultQuery?.Skills || [])
    // setLanguages(defaultQuery?.Languages || [])
    // setCompanies(defaultQuery?.Companies || [])
    // setIndustries(defaultQuery?.Industries || [])
    // setCompanySize(defaultQuery?.CompanySize || '')
    // setExcludedCompanies(defaultQuery?.ExcludedCompanies || [])
    // setMinimumDegree(defaultQuery?.MinimumDegree || '')
    // setFieldOfStudy(defaultQuery?.FieldOfStudy || [])
    // let Object: FilterObject = {
    //   Area: defaultQuery?.Area || [],
    //   Companies: defaultQuery?.Companies || [],
    //   CompanySize: defaultQuery?.CompanySize || '',
    //   ExcludedCompanies: defaultQuery?.ExcludedCompanies || [],
    //   ExcludedGender: defaultQuery?.ExcludedGender || false,
    //   ExcludedProfile: defaultQuery?.ExcludedProfile || false,
    //   FieldOfStudy: defaultQuery?.FieldOfStudy || [],
    //   Industries: defaultQuery?.Industries || [],
    //   JobTitles: defaultQuery?.JobTitles || [],
    //   Languages: defaultQuery?.Languages || [],
    //   Experience: defaultQuery?.Experience || [{minExp: 0, maxExp: 0}],
    //   MinimumDegree: defaultQuery?.MinimumDegree || '',
    //   PastJobTitles: defaultQuery?.PastJobTitles || [],
    //   Profiles: defaultQuery?.Profiles || 0,
    //   ReqInfo: defaultQuery?.ReqInfo || [],
    //   Skills: defaultQuery?.Skills || [],
    //   TimeSpent: defaultQuery?.TimeSpent || {years: 0, months: 0},
    //   University: defaultQuery?.University || [],
    //   icon: '',
    //   searchQuery: defaultQuery?.searchQuery || '',
    //   title: defaultQuery?.text || '',
    // }
    // setComponentInput(message)
    // console.log(Object)
    // setSessionInteracted(true)
    // let Id = uuid()
    // setSearchId(Id)
    // setApiSearchId(Id)
    // setFilterObject(Object)
    // setQueryObj(Object)
    // setShowchatUI(true)
    // sendMessage('Edit the below query to fetch refined results')
    // setShowchatUI(true)
    // setTimeout(() => {
    //   ;(async () => {
    //     try {
    //       if (Id) {
    //         let data = await SetFilterObjectApi(userId + Id, Object)
    //         console.log(data)
    //       }
    //     } catch (err) {
    //       throw new Error('Error while sending the filter Request')
    //     }
    //   })()
    // }, 9000)
  }

  console.log('Search query ', searchQuery)
  const handleShowDropDownEdit = () => {
    setShowPopup(true)
    setJobTitles(defaultQuery?.JobTitles || [])
    setPastJobTitles(defaultQuery?.PastJobTitles || [])
    setProfiles(defaultQuery?.Profiles || 0)
    setArea(defaultQuery?.Area || [])
    setReqInfo(defaultQuery?.ReqInfo || [])
    setExcludedGender(defaultQuery?.ExcludedGender || false)
    setExcludedProfile(defaultQuery?.ExcludedProfile || false)
    setExperience([
      {minExp: defaultQuery?.Experience[0]?.minExp || 0},
      {maxExp: defaultQuery?.Experience[1]?.maxExp || 0},
    ])

    setTimeSpent({
      years: defaultQuery?.TimeSpent?.years || 0,
      months: defaultQuery?.TimeSpent?.months || 0,
    })

    setUniversity(defaultQuery?.University || [])
    setSkills(defaultQuery?.Skills || [])
    setLanguages(defaultQuery?.Languages || [])
    setCompanies(defaultQuery?.Companies || [])
    setIndustries(defaultQuery?.Industries || [])
    setCompanySize(defaultQuery?.CompanySize || '')
    setExcludedCompanies(defaultQuery?.ExcludedCompanies || [])
    setMinimumDegree(defaultQuery?.MinimumDegree || '')
    setFieldOfStudy(defaultQuery?.FieldOfStudy || [])
  }
  const resetDropdownValues = () => {
    setJobTitles([])
    setSelectedRole('web developer')
    setArea([])
    setSelectedCountry('India')

    setSelectedSkills(['Java'])
    setExperience({})
    setSelectedExperience(0)
    setIndustries([])
    setSelectedCompanyType('StartUps')
  }

  // useEffect(() => {
  //   if (newSession) {
  //     resetDropdownValues()
  //   }
  // }, [newSession])
  let text = defaultQuery?.text
  let indexOfRole = text?.indexOf('role')
  let roleString = text?.substring(0, indexOfRole)
  let indexOfIn = text?.indexOf('location')
  let inString = text?.substring(Number(indexOfRole) + 6, indexOfIn)
  let indexOfSkills = text?.indexOf('skills')
  let skillsString = text?.substring(Number(indexOfIn), indexOfSkills).replace('location', '')
  let indexOfyearsOfExperience = text?.indexOf('yearsOfExperience  ')
  let yearsOfExperienceString = text
    ?.substring(Number(indexOfSkills), indexOfyearsOfExperience)
    .replace('skills', '')

  let indexOftypeOfCompany = text?.indexOf('typeOfCompany')
  let typeOfCompanyString = text
    ?.substring(Number(indexOfyearsOfExperience), indexOftypeOfCompany)
    .replace('yearsOfExperience', '')
  let lastString = text?.substring(Number(indexOftypeOfCompany)).replace('typeOfCompany', '')
  let [exp, setExp] = useState<number[]>([])
  // useEffect(() => {
  //   ;(() => {
  //     let length = defaultQuery?.Experience[1]?.maxExp
  //     let arr: number[] = []
  //     if (typeof length === 'number') {
  //       for (let i = 0; i <= length; i++) {
  //         arr.push(i)
  //       }
  //     } else {
  //       console.log('no number')
  //     }
  //     setExp(arr)
  //   })()
  // }, [defaultQuery])
  const handleShowPopUp = (event) => {
    event.stopPropagation()
    setShowPopup(true)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    let newObj: FilterObject = {
      Area: area,
      Companies: companies,
      CompanySize: companySize,
      ExcludedCompanies: excludedCompanies,
      ExcludedGender: excludedGender,
      ExcludedProfile: excludedProfile,
      Experience: experience,
      FieldOfStudy: fieldOfStudy,
      Industries: industries,
      JobTitles: jobTitles,
      Languages: languages,
      MinimumDegree: minimumDegree,
      PastJobTitles: pastJobTitles,
      Profiles: profiles,
      ReqInfo: reqInfo,
      Skills: skills,
      TimeSpent: timespent,
      University: university,
      icon: '',
      searchQuery: '',
      title: '',
    }
    let data = await ChatAiApi({}, showMessages, newObj)
    console.log('Updated data ', data)
    if (data.success) {
      const newMessage: MessageModel = {
        user: userId,
        type: 'out',
        text: 'Querying with updated filters',
        time: 'Just now',
      }
      if (showMessages.length > 0) {
        setShowMessages([...showMessages, newMessage])
      } else {
        setShowMessages([newMessage])
      }
      setFilterObject(data.data['resultJson'])
      const newClient: MessageModel = {
        user: userId,
        text: '',
        time: 'Just now',
        type: 'in',
        content: <QueryMessage componentInput={data.data['message']} />,
      }
      setShowMessages([...showMessages, newClient])
    }

    setFilterObject(newObj)
    let newQueryObj: QueryObject = {
      Area: area,
      Companies: companies,
      CompanySize: companySize,
      ExcludedCompanies: excludedCompanies,
      ExcludedGender: excludedGender,
      ExcludedProfile: excludedProfile,
      Experience: experience,
      FieldOfStudy: fieldOfStudy,
      Industries: industries,
      JobTitles: jobTitles,
      Languages: languages,
      MinimumDegree: minimumDegree,
      PastJobTitles: pastJobTitles,
      Profiles: profiles,
      ReqInfo: reqInfo,
      Skills: skills,
      TimeSpent: timespent,
      University: university,
    }
    setQueryObj(newQueryObj)
    // queryBuilder()
    setErr('')
    setShowPopup(false)
    if (searchId) {
      setApiSearchId(userId + searchId)
    } else if (selectedSearchKey) {
      setApiSearchId(selectedSearchKey)
    }
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Card
      className={`${queryTileStyle.titkeCustom} p-5`}
      style={{background: '#edfde3', border: '1px solid #ecefed'}}
    >
      <span className='fw-semibold fs-4'>{searchQuery}</span>
      <FilterModal modalOpen={isModalOpen} onOk={handleOk} onCancel={handleCancel} />
      <div style={{gap: '10px'}} className='d-flex justify-content-end'>
        <Button
          onClick={() => showModal()}
          // onClick={(e) => handleShowPopUp(e)}
          // disabled={disableEditQuery}
          icon={<EditOutlined rev={undefined} />}
        >
          Edit Query
        </Button>

        <Button
          style={{color: 'white'}}
          // disabled={disableSearch}
          type='primary'
          data-kt-element='send'
          onClick={handleSearchCandidate}
          icon={<ArrowRightOutlined rev={undefined} />}
        >
          Search
        </Button>
      </div>
    </Card>
  )
}

export default DropDownQuery
