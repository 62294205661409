import {FC, useState, useEffect, useContext, createContext} from 'react'
import { AccountType } from '../components/DataTypes/AccountsType'

type EmailContextType = {
  showEmailTemplate: boolean
  setShowEmailTemplate: (showEmailTemplate: boolean) => void
  sub: string
  setSub: (sub: string) => void
  content: string
  setContent: (content: string) => void
  templateValue: string
  setTemplateValue: (templateValue: string) => void
  showValues: boolean
  setShowValues: (showvalues: boolean) => void
  wordReplace: string
  setWordReplace: (wordReplace: string) => void
  emailSettingsObj: EmailSettingsData | undefined
  setEmailSettingsObj: (emailSettingsobj: EmailSettingsData) => void
  allTemplates: AllTemplatesState | undefined
  setAllTemplates: (alltemplates: AllTemplatesState) => void
  accountIds: string[]
  setAccountIds: (accountids: string[]) => void
  accountData: AccountType[]
  setAccountData: (accountdata: AccountType[]) => void
  showSmtpPopUp: boolean
  setShowSmtpPopUp: (showSmtpPopUp: boolean) => void
  selectedAccount: AccountType | undefined
  setSelectedAccount: (selectedAccount: AccountType | undefined) => void
  liAtToken: string
  setLiAtToken: (liAtToken: string) => void
}
interface EmailSettingsData {
  linkAnAccount: {
    accountSelected: any // You should define the type of accountSelect
  }
  campaignScheduler: {
    timeZone: {
      timezone: string
      time: string
    }
    fromTime: string
    toTime: string
    DaysInWeek: string[] // Array of strings
    startDate: string // Date format as mm/dd/yyyy
    endDate: string // Date format as mm/dd/yyyy
  }
  missingVariable: {
    missingVariableAction: {
      action: string // You should define the type of preventSending
      value: boolean
    }
  }
  flowTrackingSettings: {
    trackEmailOpen: boolean
    trackLinkClicks: boolean
    pauseCampaign: boolean
  }
  customFields: {
    customFieldsList: string[] // Array of strings
  }
  tags: {
    tagsList: string[] // Array of strings
  }
  dailyLimit: {
    dailyLimit: number
  }
  emailGap: {
    emailGap: number
  }
}
interface EmailTemplate {
  templateName: string
  subject: string
  content: string
}

interface AllTemplatesState {
  stockTemplates: EmailTemplate[]
  userTemplates: EmailTemplate[]
}

const EmailContext = createContext<EmailContextType | undefined>(undefined)

const EmailProvider = ({children}) => {
  const [showEmailTemplate, setShowEmailTemplate] = useState<boolean>(false)
  const [sub, setSub] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [templateValue, setTemplateValue] = useState<string>('')
  const [wordReplace, setWordReplace] = useState<string>('')
  const [showValues, setShowValues] = useState<boolean>(false)
  const [emailSettingsObj, setEmailSettingsObj] = useState<EmailSettingsData>()
  const [allTemplates, setAllTemplates] = useState<AllTemplatesState>()
  const [accountIds, setAccountIds] = useState<string[]>([])
  const [selectedAccount, setSelectedAccount] = useState<AccountType | undefined>()
  const [accountData, setAccountData] = useState<AccountType[]>([])
  const [showSmtpPopUp, setShowSmtpPopUp] = useState<boolean>(false)
  const [liAtToken, setLiAtToken] = useState<string>('')
  let value = {
    showEmailTemplate,
    setShowEmailTemplate,
    sub,
    setSub,
    content,
    setContent,
    setTemplateValue,
    templateValue,
    showValues,
    setShowValues,
    wordReplace,
    setWordReplace,
    emailSettingsObj,
    setEmailSettingsObj,
    allTemplates,
    setAllTemplates,
    accountIds,
    accountData,
    setAccountData,
    setAccountIds,
    showSmtpPopUp,
    setShowSmtpPopUp,
    selectedAccount,
    setSelectedAccount,
    liAtToken,
    setLiAtToken,
  }
  return <EmailContext.Provider value={value}>{children}</EmailContext.Provider>
}
const useEmailContext = () => {
  let useEmail = useContext(EmailContext)
  if (!useEmail) throw new Error('Error at Email context')
  return useEmail
}
export {useEmailContext, EmailProvider}
