import React from 'react'
import {Flex} from 'antd'
import {
  JobScrape,
  LinkedinSalesCompanies,
  LinkedinSalesProfiles,
  // LinkedinSalesProfiles,
  LinkedinSendConnection,
  LinkedinSendMessage,
  LinkedinVisitProfile,
} from '../Subcomponents/LinkedinAutomateUI'
import {GetPeople} from '../Subcomponents/GetPeopleAutomation'
const LinkedinScrape = () => {
  return (
    <Flex vertical style={{width: '100%'}}>
      <span style={{fontWeight: 'bold', fontSize: '1.5em'}}>LinkedIn </span>
      <Flex gap={'middle'} wrap='wrap'>
        <Flex gap={'middle'} wrap='wrap' style={{opacity: '0.5', pointerEvents: 'none'}}>
          <LinkedinVisitProfile />
          <LinkedinSendConnection />
          <LinkedinSendMessage />
        </Flex>
        <JobScrape />  
        <GetPeople />
        <LinkedinSalesProfiles />
        <LinkedinSalesCompanies />
      </Flex>
    </Flex>
  )
}

export default LinkedinScrape
