import React from 'react'
import {Empty} from 'antd'
import empty from '../../assets/empty.png'
const EmptyLogo = ({description}) => {
  return (
    <Empty
      imageStyle={{height: 300}}
      image={<img src={empty} />}
      description={<span>{description}</span>}
    />
  )
}

export default EmptyLogo 
