import queryTileStyle from '../styles/queryStyle.module.css'
import '../styles/queryStyle.module.css'
import {KTIcon, MessageModel} from '../../_metronic/helpers'
import {useEditQueryContext} from '../context/editquery'
import {useChatContext} from '../context/chatcontext'
import {useApiContext} from '../context/apicontext'
import {FC, useState, useEffect} from 'react'
import {useSessionContext} from '../context/sessionContext'
import {useProfileContext} from '../context/profilecontext'
import Dropdown from 'react-bootstrap/Dropdown'
import {useValuesContext} from '../context/valuescontext'
import {ClipboardEdit, UserRoundSearch} from 'lucide-react'
import {Card, Button, Flex} from 'antd'
import {ArrowRightOutlined, EditOutlined} from '@ant-design/icons'
import FilterModal from './Reusablecomponents/FilterModal'
import {FilterObject, QueryObject} from './DataTypes/QueryFilters'
import {userId} from './DataTypes/UserDataTypes'
import {ChatAiApi} from '../apicalls/ApiCall'
interface Props {
  componentInput: string
}
//  this is the component which comes as areply from the ai when a message is sent from the User
const QueryMessage: FC<Props> = ({componentInput}) => {
  const [input, setInput] = useState(componentInput)
  const {showPopup, setShowPopup} = useEditQueryContext()
  const {
    pastJobTitles,
    jobTitles,
    timespent,
    university,
    skills,
    languages,
    excludedCompanies,
    minimumDegree,
    fieldOfStudy,
    industries,
    companySize,
    workedTimeOCompanies,
    companies,
    area,
    profiles,
    experience,
    reqInfo,
    excludedProfile,
    excludedGender,
    filterObject,
    setPastJobTitles,
    setProfiles,
    setArea,
    setExperience,
    setReqInfo,
    setExcludedGender,
    setExcludedProfile,
    setJobTitles,
    setTimeSpent,
    setUniversity,
    setSkills,
    setLanguages,
    setCompanies,
    setIndustries,
    setCompanySize,
    setExcludedCompanies,
    setMinimumDegree,
    setFieldOfStudy,
    setFilterObject,
  } = useValuesContext()
  const {showMessages, setShowMessages, isModalOpen, setIsModalOpen} = useChatContext()
  const {setSearchList, setShowResultLoading} = useProfileContext()
  const {setComponentInput, setQueryOutput, sendMessage, setShowchatUI, defaultQuery} =
    useChatContext()
  const {setSessionInteracted, setSearchId, searchId, selectedSearchKey} = useSessionContext()
  const {
    dashboardData,
    setApiSearchId,
    setQueryObj,
    setShowLoading,
    searchQuery,
    setTableUi,
    handleSearchReq,
    queryObj,
    setErr,
    setNewData,
    setScrollToken,
    setSqlQuery,
  } = useApiContext()
  const [selectedRole, setSelectedRole] = useState<string>('web developer')
  const [selectedCountry, setSelectedCountry] = useState<string>('India')
  const [selectedSkills, setSelectedSkills] = useState<string[]>(['Java'])
  const [selectedExperience, setSelectedExperience] = useState<number>(0)
  const [selectedCompanyType, setSelectedCompanyType] = useState<string>('StartUps')

  const [showModal, setShowModal] = useState<boolean>(false)
  const handleSearchCandidate = (event) => {
    event.stopPropagation()
    setShowResultLoading(true)
    setTimeout(() => {
      setShowLoading(true)
    }, 1000)
    setSearchList([])
    setTableUi(true)
    setShowchatUI(true)
    // sendMessage(componentInput)
    // setComponentInput('Processing Your Input With Database')
    handleSearchReq()
  }
  useEffect(() => {
    setInput(componentInput)
  }, [componentInput])
  const handleShowPopUp = (event) => {
    event.stopPropagation()
    setShowPopup(true)
  }
  // useEffect(() => {
  //   setSelectedCountry(defaultQuery?.Area[0] ? defaultQuery?.Area[0] : '')
  //   setSelectedExperience(
  //     defaultQuery?.Experience[0].minExp ? defaultQuery?.Experience[0].minExp : 0
  //   )
  //   setSelectedRole(defaultQuery?.JobTitles[0] ? defaultQuery?.JobTitles[0] : '')
  //   setSelectedSkills(defaultQuery?.Skills ? defaultQuery?.Skills : [])
  //   setSelectedCompanyType(defaultQuery?.Industries[0] ? defaultQuery.Industries[0] : '')
  // }, [defaultQuery])
  const [message, setMessage] = useState(
    `I am looking for  ${selectedRole} in ${selectedCountry} who has worked with ${selectedSkills.join(
      ', '
    )} and has professional experience of about ${selectedExperience} and has worked in ${selectedCompanyType} environments.`
  )
  function updateMessage() {
    const newMessage = `I want to reach out to a ${selectedRole} in ${selectedCountry} who has worked with ${selectedSkills.join(
      ', '
    )} and has professional experience of about ${selectedExperience} and has worked in ${selectedCompanyType} environments.`
    setMessage(newMessage)
  }
  const handleSelectRole = (value: string) => {
    setSelectedRole(value)
    updateMessage()
  }

  const handleSelectCountry = (value: string) => {
    setSelectedCountry(value)
    // setArea([value])
    updateMessage()
  }

  const handleSelectSkills = (value: string) => {
    const updatedSkills = selectedSkills.includes(value)
      ? selectedSkills.filter((selectedSkill) => selectedSkill !== value)
      : [...selectedSkills, value]
    setSelectedSkills(updatedSkills)
    updateMessage()
  }

  const handleSelectExperience = (value: number) => {
    setSelectedExperience(value)
    // setExperience([{minExp: value}, {}])
    updateMessage()
  }

  const handleSelectCompanyType = (value: string) => {
    setSelectedCompanyType(value)
    // setIndustries([value])
    updateMessage()
  }
  const regex = /<span[^>]*>(.*?)<\/span>/g
  const handleSpanClick = () => {
    setShowPopup(true)
  }
  useEffect(() => {
    const queryFieldsElements = document.querySelectorAll('.queryFields')

    const handleClick = () => {
      handleSpanClick()
    }

    queryFieldsElements.forEach((element) => {
      element.addEventListener('click', handleClick)
    })

    return () => {
      queryFieldsElements.forEach((element) => {
        element.removeEventListener('click', handleClick)
      })
    }
  }, [handleSpanClick])

  const matches = componentInput.match(regex)
  if (matches && componentInput) {
    matches.forEach((match) => {
      const key = match.replace(/<span[^>]*>(.*?)<\/span>/, '$1')
      const value = getValueForKey(key, filterObject)
      if (value !== undefined) {
        const highlightedValue = `<span onclick="${handleSpanClick}" style="background:#d6c3fe;padding:5px;border-radius:5px;cursor:pointer" class='queryFields'>${value}</span>`
        componentInput = componentInput.replace(match, highlightedValue)
      }
    })
  }

  function getValueForKey(key, filterObject) {
    switch (key) {
      case 'JobTitles':
        return filterObject?.JobTitles?.[0]
      case 'PastJobTitles':
        return filterObject?.PastJobTitles?.[0]
      case 'Profiles':
        return filterObject?.Profiles
      case 'Area':
        return filterObject?.Area?.[0]
      case 'ReqInfo':
        return filterObject?.ReqInfo?.[0]
      case 'ExcludedGender':
        return filterObject?.ExcludedGender
      case 'ExcludedProfile':
        return filterObject?.ExcludedProfile
      case 'Experience':
        return filterObject?.Experience?.[1]?.maxExp
      case 'TimeSpent':
        return filterObject?.TimeSpent?.years
      case 'University':
        return filterObject?.University?.[0]
      case 'Languages':
        return filterObject?.Languages?.[0]
      case 'Companies':
        return filterObject?.Companies?.[0]
      case 'Industries':
        return filterObject?.Industries?.[0]
      case 'CompanySize':
        return filterObject?.CompanySize
      case 'ExcludedCompanies':
        return filterObject?.ExcludedCompanies?.[0]
      case 'MinimumDegree':
        return filterObject?.MinimumDegree
      case 'FieldOfStudy':
        return filterObject?.FieldOfStudy?.[0]
      case 'Skills':
        return filterObject?.Skills?.[0]
      default:
        return undefined
    }
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleOk = async () => {
    let newObj: FilterObject = {
      Area: area,
      Companies: companies,
      CompanySize: companySize,
      ExcludedCompanies: excludedCompanies,
      ExcludedGender: excludedGender,
      ExcludedProfile: excludedProfile,
      Experience: experience,
      FieldOfStudy: fieldOfStudy,
      Industries: industries,
      JobTitles: jobTitles,
      Languages: languages,
      MinimumDegree: minimumDegree,
      PastJobTitles: pastJobTitles,
      Profiles: profiles,
      ReqInfo: reqInfo,
      Skills: skills,
      TimeSpent: timespent,
      University: university,
      icon: '',
      searchQuery: '',
      title: '',
    }
    let data = await ChatAiApi({}, showMessages, newObj)
    if (data.success) {
      const newMessage: MessageModel = {
        user: userId,
        type: 'out',
        text: 'Querying with updated filters',
        time: 'Just now',
      }
      if (showMessages.length > 0) {
        setShowMessages([...showMessages, newMessage])
      } else {
        setShowMessages([newMessage])
      }
      setFilterObject(data.data['resultJson'])
      const newClient: MessageModel = {
        user: userId,
        text: '',
        time: 'Just now',
        type: 'in',
        content: <QueryMessage componentInput={data.data['message']} />,
      }
      setShowMessages([...showMessages, newClient])

      // toggleChatUpdateFlat((flag) => !flag)
      console.log(data)
    }

    setFilterObject(newObj)
    let newQueryObj: QueryObject = {
      Area: area,
      Companies: companies,
      CompanySize: companySize,
      ExcludedCompanies: excludedCompanies,
      ExcludedGender: excludedGender,
      ExcludedProfile: excludedProfile,
      Experience: experience,
      FieldOfStudy: fieldOfStudy,
      Industries: industries,
      JobTitles: jobTitles,
      Languages: languages,
      MinimumDegree: minimumDegree,
      PastJobTitles: pastJobTitles,
      Profiles: profiles,
      ReqInfo: reqInfo,
      Skills: skills,
      TimeSpent: timespent,
      University: university,
    }
    setQueryObj(newQueryObj)
    // queryBuilder()
    setErr('')
    if (searchId) {
      setApiSearchId(userId + searchId)
    } else if (selectedSearchKey) {
      setApiSearchId(selectedSearchKey)
    }
    setShowModal(false)
  }
  return (
    <Card className={queryTileStyle.queryBox}>
      <div>
        <div>
          {/* <span className=' fs-5 bg-light-dark p-2 rounded text-grey fw-bold'>Query generated:</span> */}
          <div
            style={{width: '100%', overflow: 'auto', lineHeight: '2.5'}}
            className='fs-6 mx-3'
            dangerouslySetInnerHTML={{
              __html: componentInput,
            }}
          />
        </div>
        <p className='fs-6 text-dark align-items-center flex-wrap'></p>
        <div className={queryTileStyle.footerBtn}>
          <FilterModal modalOpen={showModal} onOk={handleOk} onCancel={handleCloseModal} />
          <div className='d-flex justify-content-between'>
            {input !== 'Processing Your Input With Database' &&
            input !== 'Here are Your Results' &&
            input !== 'Something went Wrong please try again' ? (
              <Flex gap={'middle'}>
                <Button
                  onClick={() => setShowModal(true)}
                  // onClick={(e) => handleShowPopUp(e)}
                  // disabled={disableEditQuery}
                  icon={<EditOutlined rev={undefined} />}
                >
                  Edit Query
                </Button>
                <Button
                
                  type='primary'
                  data-kt-element='send'
                  onClick={(e) => handleSearchCandidate(e)}
                  icon={<ArrowRightOutlined rev={undefined} />}
                >
                  Search
                </Button>
              </Flex>
            ) : (
              <button
                onClick={(e) => handleShowPopUp(e)}
                type='button'
                className={`btn btn-info ${queryTileStyle.btnChat} h-30px d-flex justify-content-evenly align-items-center fs-7 pr-2 ps-3 btn-info`}
              >
                Edit Query
                <i className='bi bi-arrow-right text-white ps-1 fw-bold'></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default QueryMessage
