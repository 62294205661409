import {
  GetAccountsApi,
  GetConnectedLinkedInAccount,
  GetLinkedinAccountsApi,
} from '../../apicalls/ApiCall'
import {message} from 'antd'
import {userId} from '../DataTypes/UserDataTypes'
export async function GetLinkedinData(userId, setLiAtToken) {
  try {
    let response = await GetConnectedLinkedInAccount(userId)
    if (response) {
      if (response.data && response.success) {
        setLiAtToken(response.data)
      } else {
        console.error('Error while getting the linkedin token')
      }
    } else {
      message.error('Something went wrong')
    }
  } catch (err) {
    message.error('Error while getting the Li-at')
    console.error('Error while getting the Li-at', err)
  }
}

export const GetLinkedinAccounts = async (setLinkedinAccount, setLoading) => {
  try {
    setLoading(true)
    let res = await GetLinkedinAccountsApi(userId)
    setLoading(false)
    if (res && res.success && res.data) {
      setLinkedinAccount(res.data)
    } else {
      message.error('Something went wrong by getting the linkedin accounts')
    }
  } catch (err) {
    setLoading(false)
    console.error('Error while getting the linkedin accounts ' + err)
    message.error("Couldn't get LinkedIn Accounts")
  }
}

export const GetLinkedEmails = async ({selectedFlowId, startingFlowName, setAccData}) => {
  try {
    let data = await GetAccountsApi(userId)
    console.log(data)
    if (data && data.success && data.data) {
      setAccData(data.data)
    }
  } catch (err) {
    console.error('Error while getting the email accounts')
  }
}
