import {FC, useEffect, useState} from 'react'
import {GetLinkedinData} from './CommonApiCalls/AccountsApi'
import {useEmailContext} from '../context/emailContext'
import {GetAccountsApi, GetEmailSettingsApi} from '../apicalls/ApiCall'
import { Row, Tabs} from 'antd'
import {userId} from './DataTypes/UserDataTypes'
import ConnectEmail from './Reusablecomponents/ConnectEmail'
import LinkedinAccount from './Reusablecomponents/LinkedinAccount'
import AccountLimits from './Reusablecomponents/AccountLimits'
import PageLoader from './Subcomponents/PageLoader'
// This component manages email-related settings such as account selection, campaign scheduling,
// handling missing variables, and flow tracking preferences.

const EmailSetting: FC = () => {
  const {setEmailSettingsObj, setAccountData, setLiAtToken} = useEmailContext()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  // useEffect(() => {
  //   if (emailSettingsObj) {
  //     ;(async () => {
  //       try {
  //         let values = await GetAccountsApi(userId)
  //         if (values) {
  //           if (values.success && values.data) setAccountData(values.data)
  //           else console.error('Error while getting the accounts linked')
  //         } else console.error('Error while getting the accounts linked')
  //       } catch (err) {
  //         console.error('Error while getting the accounts linked')
  //       }
  //     })()
  //   }
  // }, [emailSettingsObj])

  useEffect(() => {
    ;(async () => {
      try {
        let data = await GetEmailSettingsApi(userId)
        if (data) {
          let settings = data
          if (data.success) setEmailSettingsObj(settings.data)
          else console.error('Error while getting the accounts linked')
        } else console.error('Error while getting the accounts linked')
      } catch (err) {
        console.error('Error while getting the accounts linked')
      }
    })()
    ;(async () => {
      try {
        setShowLoader(true)
        let values = await GetAccountsApi(userId)
        setShowLoader(false)
        if (values) {
          if (values.success && values.data) setAccountData(values.data)
          else console.error('Error while getting the accounts linked')
        } else console.error('Error while getting the accounts linked')
      } catch (err) {
        console.error('Error while getting the accounts linked')
      }
    })()
    GetLinkedinData(userId, setLiAtToken)
  }, [])



  let SettingsItem = [
    {label: `Connect Email Accounts`, key: 'email', children: <ConnectEmail />},
    {label: `Connect LinkedIn Account`, key: 'linkedin', children: <LinkedinAccount />},
    {label: `Limits`, key: 'limit', children: <AccountLimits />},
  ]
  return (
    <div className='w-100 h-100'>
      {showLoader ? (
        <PageLoader />
      ) : (
        <Row style={{width: '100%', height: '100%', background: 'white'}}>
          <Tabs style={{width: '100%', height: '100%'}} tabPosition={'left'} items={SettingsItem} />
        </Row>
      )}
    </div>
  )
}

export default EmailSetting
