import {useEffect, useState} from 'react'
import {Checkbox, Divider, Flex, message} from 'antd'
import {GetDataRequestSettingApi, UpdateDataRequestSettingsApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'

const SpillBeansSettings = () => {
  const [spillBeansOptions, setSpillBeansOptions] = useState([
    {label: 'Select All', value: 'selectAll'},
    {label: 'Work email', value: 'workEmail'},
    {label: 'Personal email', value: 'personalEmail'},
    {label: 'Mobile number', value: 'mobileNumber'},
    {label: 'Company number', value: 'companyNumber'},
  ])
  const [dataAccuracy, setDataAccuracy] = useState([
    {label: 'Accuracy', value: 'accuracy'},
    {label: 'Coverage', value: 'coverage'},
    {label: 'Only Verified data', value: 'verifiedData'},
  ])

  const [checkedValues, setCheckedValues] = useState(['selectAll'])

  useEffect(() => {
    getSpillBeansOptions()
  }, [])

  const getSpillBeansOptions = async () => {
    try {
      let response = await GetDataRequestSettingApi(userId)
      if (response && response.data && response.success) {
        const updatedCheckedValues: string[] = []
        if (response.data.HqNumber) updatedCheckedValues.push('companyNumber')
        if (response.data.workEmail) updatedCheckedValues.push('workEmail')
        if (response.data.personalEmail) updatedCheckedValues.push('personalEmail')
        if (response.data.personalMobile) updatedCheckedValues.push('mobileNumber')
        if (response.data.accuracy) updatedCheckedValues.push('accuracy')
        if (response.data.coverage) updatedCheckedValues.push('coverage')
        if (response.data.verifiedData) updatedCheckedValues.push('verifiedData')
        if (updatedCheckedValues.length === 7) {
          updatedCheckedValues.push('selectAll')
        }
        setCheckedValues(updatedCheckedValues)
      }
    } catch (err) {
      console.error('Error while getting the response data ' + err)
      message.error('An error occurred while fetching user details')
    }
  }

  const handleCheckboxChange = (checked, value) => {
    let updatedCheckedValues
    if (value === 'selectAll') {
      updatedCheckedValues = checked ? spillBeansOptions.map((option) => option.value) : []
    } else {
      updatedCheckedValues = checked
        ? [...checkedValues, value]
        : checkedValues.filter((val) => val !== value && val !== 'selectAll')
    }
    setCheckedValues(updatedCheckedValues)
    handleDataRequestSave(updatedCheckedValues)
  }

  const handleDataRequestSave = async (checkedValues) => {
    try {
      const requestData = {
        HqNumber: checkedValues.includes('companyNumber'),
        workEmail: checkedValues.includes('workEmail'),
        personalEmail: checkedValues.includes('personalEmail'),
        personalMobile: checkedValues.includes('mobileNumber'),
        accuracy: checkedValues.includes('accuracy'),
        coverage: checkedValues.includes('coverage'),
        verifiedData: checkedValues.includes('verifiedData'),
      }
      const response = await UpdateDataRequestSettingsApi(userId, requestData)
      if (response && response.success && response.data) {
        message.success('Updated the data request settings')
      } else {
        message.error('Error while updating the data request settings')
      }
    } catch (err) {
      console.error('Error while updating the request ' + err)
      message.error('Sorry unable to save the changes, Try again!')
    }
  }

  return (
    <div>
      <Flex vertical gap={'middle'}>
        <span className='heading fs-3' style={{fontWeight: '600'}}>
          Data Request type
        </span>
        {/* <div style={{display: 'flex', alignItems: 'center'}}>
          <Checkbox
            onChange={(e) => handleCheckboxChange(e.target.checked, 'selectAll')}
            checked={checkedValues.includes('selectAll')}
          >
            Select All
          </Checkbox>
          <Divider type='vertical' />
        </div> */}
        <div style={{flex: 1}}>
          {spillBeansOptions.slice(1).map((option) => (
            <Checkbox
              style={{marginLeft: '5px'}}
              key={option.value}
              onChange={(e) => handleCheckboxChange(e.target.checked, option.value)}
              checked={checkedValues.includes(option.value)}
            >
              {option.label}
            </Checkbox>
          ))}
        </div>
        <span className='heading fs-3' style={{fontWeight: '600'}}>
          Data Goal
        </span>
        <div style={{flex: 1}}>
          {dataAccuracy.map((option) => (
            <Checkbox
              style={{marginLeft: '5px'}}
              key={option.value}
              onChange={(e) => handleCheckboxChange(e.target.checked, option.value)}
              checked={checkedValues.includes(option.value)}
            >
              {option.label}
            </Checkbox>
          ))}
        </div>
      </Flex>
    </div>
  )
}

export default SpillBeansSettings
