import {GetExistingUserApi} from '../../apicalls/ApiCall'

import {userId} from '../DataTypes/UserDataTypes'

export const CheckContactInformation = async ({
  linkedin,
  setShowSpillBeans,
  setEmail,
  setPhone,
  setEmailError,
  setPhoneError,
}) => {
  let resData = await GetExistingUserApi(linkedin, userId)
  if (resData) {
    if (resData === 'Not Requested' || resData === null) {
      setShowSpillBeans(true)
      setEmail([])
      setPhone([])
      setEmailError('')
      setPhoneError('')
    } else if (resData.data === 'Not Requested') {
      setShowSpillBeans(true)
      setEmail([])
      setPhone([])
      setEmailError('')
      setPhoneError('')
    } else {
      if (resData) {
        setShowSpillBeans(false)
        let emailObjects = resData.work_email
        let mobilePhones = resData.mobile
        if (emailObjects) {
          if (emailObjects.every((obj) => obj.email === '' || obj.email === 'Not found')) {
            setEmailError("Sorry, Email doesn't exist")
          } else {
            let filteredEmails = emailObjects.filter(
              (obj) => obj.email !== 'Not found' && obj.email !== ''
            )
            setEmail(filteredEmails)
          }
        } else {
          setEmailError('Sorry, Something went wrong')
        }
        if (mobilePhones) {
          if (mobilePhones.every((phone) => phone.number === '' || phone.number === 'Not found')) {
            setPhoneError(
              "Uh oh! Number is currently unavailable. If you need it on priority, let us know. You'll be notified via email soon"
            )
          } else {
            setPhone(
              mobilePhones.filter(
                (phone) =>
                  phone.number !== 'Not found' && phone.number !== '' && phone.number !== null
              )
            )
          }
        } else {
          setPhoneError('Sorry, Something went wrong')
        }
      }
    }
  }
}

export const GetContactFromDB = async ({
  linkedin,
  setEmail,
  setEmailError,
  setPhone,
  setPhoneError,
}) => {
  try {
    let resData = await GetExistingUserApi(linkedin, userId)
    if (resData) {
      if (resData === 'Not Requested' || resData === null) {
        setEmail([])
        setPhone([])
        return false
      } else {
        if (resData) {
          let emailObjects = resData.work_email
          let mobilePhones = resData.mobile
          if (emailObjects) {
            if (emailObjects.every((obj) => obj.email === '' || obj.email === 'Not found')) {
              setEmailError("Sorry, Email doesn't exist")
            } else {
              let filteredEmails = emailObjects.filter(
                (obj) => obj.email !== 'Not found' && obj.email !== ''
              )
              setEmail(filteredEmails)
            }
          } else {
            setEmailError('Sorry, Something went wrong')
          }
          if (mobilePhones) {
            if (
              mobilePhones.every((phone) => phone.number === '' || phone.number === 'Not found')
            ) {
              setPhoneError(
                "Uh oh! Number is currently unavailable. If you need it on priority, let us know. You'll be notified via email soon"
              )
            } else {
              setPhone(
                mobilePhones.filter(
                  (phone) =>
                    phone.number !== 'Not found' && phone.number !== '' && phone.number !== null
                )
              )
            }
          } else {
            setPhoneError('Sorry, Something went wrong')
          }
          return true
        }
      }
    }
  } catch (err) {
    console.error('Error while getting the contact details for the user ', err)
  }
}
