export function getUTMParameters() {
  const utmParams = {}
  const cookieString = document.cookie
  const cookieArray = cookieString.split(';')

  cookieArray.forEach((cookie) => {
    const [key, value] = cookie.trim().split('=')
    if (key.startsWith('utm_')) {
      utmParams[key.trim()] = value.trim()
    }
  })
  return utmParams
}
