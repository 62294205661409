import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
const LinkedinIcon = ({width, height}) => {
  return (
    <div className=' text-primary'>
      <LinkedInIcon style={{width: width, height: height}} />
    </div>
  )
}

export default LinkedinIcon
