import React, {useState} from 'react'
import {
  Col,
  Divider,
  Row,
  Input,
  Radio,
  InputNumber,
  Button,
  Select,
  message,
  Modal,
  Flex,
  Spin,
} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import {v4 as uuidV4} from 'uuid'
import {UpdateSmtpObjectApi, GetAccountsApi} from '../apicalls/ApiCall'
import {useEmailContext} from '../context/emailContext'
import {userId} from './DataTypes/UserDataTypes'
import PageLoader from './Subcomponents/PageLoader'

const style: React.CSSProperties = {padding: '8px', gap: '5px'}
const SMTPCard = () => {
  const [formData, setFormData] = useState({
    senderEmail: '',
    senderName: '',
    smtpHost: '',
    imapHost: '',
    smtpPort: null,
    imapPort: null,
    smtpUsername: '',
    imapUsername: '',
    smtpPassword: '',
    imapPassword: '',
  })
  const [senderErrorEmail, setSenderErrorEmail] = useState(false)
  const [senderErrorName, setSenderErrorName] = useState(false)
  const [smtpHostError, setSmtpHostError] = useState(false)
  const [smtpPortError, setSmtpPortError] = useState(false)
  const [smtpUsernameError, setSmtpUsernameError] = useState(false)
  const [smtpPasswordError, setSmtpPasswordError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [imapHostError, setImapHostError] = useState(false)
  const [imapPortError, setImapPortError] = useState(false)
  const [imapUsernameError, setImapUsernameError] = useState(false)
  const [imapPasswordError, setImapPasswordError] = useState(false)
  const {setShowSmtpPopUp, setAccountData, showSmtpPopUp} = useEmailContext()
  const [isCustomSMTP, setIsCustomSMTP] = useState(false)
  const [otherSmtpHost, setOtherSmtpHost] = useState<string>('')
  const [otherSmtpHostError, setOtherSmtpHostError] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'senderEmail':
        setSenderErrorEmail(false)
        break
      case 'senderName':
        setSenderErrorName(false)
        break
      case 'smtpHost':
        setSmtpHostError(false)
        break
      case 'smtpPort':
        setSmtpPortError(false)
        break
      case 'smtpUsername':
        setSmtpUsernameError(false)
        break
      case 'smtpPassword':
        setSmtpPasswordError(false)
        break
      case 'imapHost':
        setImapHostError(false)
        break
      case 'imapPort':
        setImapPortError(false)
        break
      case 'imapUsername':
        setImapUsernameError(false)
        break
      case 'imapPassword':
        setImapPasswordError(false)
        break
      default:
        break
    }
    setFormData((prevData) => ({...prevData, [field]: value}))
  }

  const handleSmtpSubmit = async (e) => {
    e.stopPropagation()
    try {
      if (
        !formData.senderEmail &&
        !formData.senderName &&
        !formData.smtpHost &&
        !formData.smtpPort &&
        !formData.smtpUsername &&
        !formData.smtpPassword
      ) {
        console.log('Sample')
        setSenderErrorEmail(true)
        setSenderErrorName(true)
        setSmtpHostError(true)
        setSmtpPortError(true)
        setSmtpUsernameError(true)
        setSmtpPasswordError(true)
      } else if (!formData.senderEmail) {
        setSenderErrorEmail(true)
      } else if (!formData.senderName) {
        setSenderErrorName(true)
      } else if (!formData.smtpHost) {
        setSmtpHostError(true)
      } else if (!formData.smtpPort) {
        setSmtpPortError(true)
      } else if (!formData.smtpUsername) {
        setSmtpUsernameError(true)
      } else if (!formData.smtpPassword) {
        setSmtpPasswordError(true)
      } else if (isCustomSMTP) {
        console.log('sample2')
        if (!formData.imapHost) setImapHostError(false)
        if (!formData.imapPort) setImapPortError(false)
        if (!formData.imapUsername) setImapUsernameError(false)
        if (!formData.imapPassword) setImapPasswordError(false)
        if (!otherSmtpHost) setOtherSmtpHostError(true)
      } else {
        console.log('sample3')
        if (!formData.imapHost) setImapHostError(true)
        if (!formData.imapPort) setImapPortError(true)
        if (!formData.imapUsername) setImapUsernameError(true)
        if (!formData.imapPassword) setImapPasswordError(true)
      }
      setShowLoader(true)
      let smtpId = uuidV4()
      let data = await UpdateSmtpObjectApi(userId, 'smtp' + smtpId, formData)
      setShowLoader(false)
      console.log(data)
      if (data.success) {
        setShowSmtpPopUp(false)
        let values = await GetAccountsApi(userId)
        if (values) {
          if (values.success && values.data) {
            setAccountData(values.data)
            message.success('Email added successfully')
          } else {
            message.error('Something went wrong')
          }
        }
      } else {
        message.error(<span style={{textTransform: 'capitalize'}}>{data.data}</span>)
      }
    } catch (err) {
      console.log('Error while updating the smtp object ', err)
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const onSMTPHostChange = (value: string) => {
    if (value !== 'others') {
      handleInputChange('smtpHost', value)
      setIsCustomSMTP(false)
    } else setIsCustomSMTP(true)
  }
  const handleOtherHost = (value) => {
    handleInputChange('smtpHost', value)
    setOtherSmtpHost(value)
  }
  const onSMTPHostSearch = (value: string) => {
    console.log('search:', value)
  }
  const onChange = (value: string) => {
    handleInputChange('imapHost', value)
  }

  const onSearch = (value: string) => {
    console.log('search:', value)
  }

  const filterOption = (input: string, option?: {label: string; value: string}) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const filterSMPTPOption = (input: string, option?: {label: string; value: string}) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  return (
    <Modal
      open={showSmtpPopUp}
      width={1000}
      style={{position:'relative'}}
      centered
      onCancel={() => setShowSmtpPopUp(false)}
      onOk={(e) => handleSmtpSubmit(e)}
      footer={[
        <Button key='cancel' onClick={() => setShowSmtpPopUp(false)}>
          Cancel
        </Button>,
        <Button key='save' type='primary' onClick={(e) => handleSmtpSubmit(e)}>
          Save
        </Button>,
      ]}
    >
      <div>
        {showLoader && (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 50,
                  color: '#204f58',
                  position: 'absolute',
                  left: '46%',
                  top: '50%',
                  zIndex: '1000',
                }}
                spin
                rev={undefined}
              />
            }
          />
        )}
        <Divider orientation='center' className='fs-2'>
          Connect your SMTP Account
        </Divider>
        <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}} gutter={[20, 10]}>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <span className='fw-semibold fs-7 text-grey'>Sender Email*</span>
              <Input
                status={senderErrorEmail ? 'error' : undefined}
                className='fs-6'
                size='large'
                placeholder='Sender Email'
                value={formData.senderEmail}
                onChange={(e) => handleInputChange('senderEmail', e.target.value)}
              />
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <span className='fw-semibold fs-7 text-grey'>Sender Name*</span>
              <Input
                status={senderErrorName ? 'error' : undefined}
                size='large'
                className='fs-6'
                placeholder='Sender Name'
                value={formData.senderName}
                onChange={(e) => handleInputChange('senderName', e.target.value)}
              />
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <h2 className='fs-4'>SMTP (Sending mails)</h2>
              <span className='fw-semibold fs-7 text-grey'>SMTP Host*</span>
              <Flex vertical gap={'small'}>
                <Select
                  status={smtpHostError ? 'error' : undefined}
                  size='large'
                  showSearch
                  className='fs-6'
                  placeholder='Select SMTP Host'
                  optionFilterProp='children'
                  value={isCustomSMTP ? 'Others' : formData.smtpHost}
                  onChange={onSMTPHostChange}
                  onSearch={onSMTPHostSearch}
                  filterOption={filterSMPTPOption}
                  options={[
                    {
                      value: 'smtp.gmail.com',
                      label: 'smtp.gmail.com',
                    },
                    {
                      value: 'smtp.office365.com',
                      label: 'smtp.office365.com',
                    },
                    {
                      value: 'smtp-mail.outlook.com',
                      label: 'smtp-mail.outlook.com',
                    },
                    {
                      value: 'smtp.mail.yahoo.com',
                      label: 'smtp.mail.yahoo.com',
                    },
                    {
                      value: 'smtp.zoho.com',
                      label: 'smtp.zoho.com',
                    },
                    {value: 'others', label: 'Others'},
                  ]}
                />
                {isCustomSMTP && (
                  <Input
                    size='large'
                    className='fs-6'
                    placeholder='Enter the other SMTP host'
                    value={otherSmtpHost}
                    onChange={(e) => handleOtherHost(e.target.value)}
                  />
                )}
              </Flex>
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <h2 className='fs-4'>IMAP (Required fields)</h2>
              <span className='fw-semibold fs-7 text-grey'>IMAP Host*</span>
              <Select
                status={imapHostError ? 'error' : undefined}
                size='large'
                showSearch
                className='fs-6'
                placeholder='Select IMAP Host'
                optionFilterProp='children'
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: 'imap.gmail.com',
                    label: 'imap.gmail.com',
                  },
                  {
                    value: 'outlook.office365.com',
                    label: 'outlook.office365.com',
                  },
                  {
                    value: 'imap.mail.yahoo.com',
                    label: 'imap.mail.yahoo.com',
                  },
                  {
                    value: 'imap.zoho.com',
                    label: 'imap.zoho.com',
                  },
                ]}
              />
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <span className='fw-semibold fs-7 text-grey'>SMTP port*</span>
              <div style={{gap: '5px'}} className='d-flex align-items-center'>
                <InputNumber
                  status={smtpPortError ? 'error' : undefined}
                  size='large'
                  className='w-100 fs-6'
                  placeholder='Enter SMTP port number'
                  value={formData.smtpPort}
                  onChange={(value) => handleInputChange('smtpPort', value)}
                />
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <span className='fw-semibold fs-7 text-grey'>IMAP port*</span>
              <div style={{gap: '5px'}} className='d-flex align-items-center'>
                <InputNumber
                  status={imapPortError ? 'error' : undefined}
                  size='large'
                  className='w-100 fs-6'
                  placeholder='Enter IMAP port number'
                  value={formData.imapPort}
                  onChange={(value) => handleInputChange('imapPort', value)}
                />
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <span className='fw-semibold fs-7 text-grey'>SMTP Username*</span>
              <div style={{gap: '5px'}} className='d-flex align-items-center'>
                <Input
                  status={smtpUsernameError ? 'error' : undefined}
                  size='large'
                  className='fs-6'
                  placeholder='SMTP Username'
                  value={formData.smtpUsername}
                  onChange={(e) => handleInputChange('smtpUsername', e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <span className='fw-semibold fs-7 text-grey'>IMAP Username*</span>
              <div style={{gap: '5px'}} className='d-flex align-items-center'>
                <Input
                  status={imapUsernameError ? 'error' : undefined}
                  size='large'
                  className='fs-6'
                  placeholder='IMAP Username'
                  value={formData.imapUsername}
                  onChange={(e) => handleInputChange('imapUsername', e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <span className='fw-semibold fs-7 text-grey'>SMTP password*</span>
              <div style={{gap: '5px'}} className='d-flex align-items-center'>
                <Input
                  status={smtpPasswordError ? 'error' : undefined}
                  size='large'
                  className='fs-6'
                  type={showPassword ? 'text' : 'password'}
                  placeholder='SMTP password'
                  value={formData.smtpPassword}
                  suffix={
                    <i
                      onClick={() => handleShowPassword()}
                      className='bi bi-eye cursor-pointer text-hover-primary fs-4'
                    ></i>
                  }
                  onChange={(e) => handleInputChange('smtpPassword', e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={10}>
            <div className='d-flex flex-column' style={style}>
              <span className='fw-semibold fs-7 text-grey'>IMAP password*</span>
              <div style={{gap: '5px'}} className='d-flex align-items-center'>
                <Input
                  status={imapPasswordError ? 'error' : undefined}
                  size='large'
                  className='fs-6'
                  placeholder='IMAP password'
                  type={showPassword ? 'text' : 'password'}
                  value={formData.imapPassword}
                  suffix={
                    <i
                      onClick={() => handleShowPassword()}
                      className='bi bi-eye cursor-pointer text-hover-primary fs-4'
                    ></i>
                  }
                  onChange={(e) => handleInputChange('imapPassword', e.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default SMTPCard
