import React, {FC, useState} from 'react'
import dashstyle from '../styles/newdash.module.css'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {useValuesContext} from '../context/valuescontext'
import {Select, Card, Flex, Tooltip} from 'antd'
import FilterTags from './Reusablecomponents/FilterTags'
import FineTuneModal from './Subcomponents/FineTuneModal'
// This function renders the card for selecting the languages
const LanguageCard: FC = () => {
  const {languages, setLanguages} = useValuesContext()
  const [showFineTuneLanguages, setShowFineTuneLanguages] = useState<boolean>(false)
  const [fineTuneObject, setFineTuneObject] = useState<{type: string; prompt: string}>({
    type: '',
    prompt: '',
  })
  const languagesList = [
    {
      value: 'English',
      label: 'English',
    },
    {
      value: 'Spanish',
      label: 'Spanish',
    },
    {
      value: 'Mandarin Chinese',
      label: 'Mandarin Chinese',
    },
    {
      value: 'Hindi',
      label: 'Hindi',
    },
    {
      value: 'Arabic',
      label: 'Arabic',
    },
    {
      value: 'Portuguese',
      label: 'Portuguese',
    },
    {
      value: 'Bengali',
      label: 'Bengali',
    },
    {
      value: 'Russian',
      label: 'Russian',
    },
    {
      value: 'Japanese',
      label: 'Japanese',
    },
    {
      value: 'Punjabi',
      label: 'Punjabi',
    },
    {
      value: 'German',
      label: 'German',
    },
    {
      value: 'French',
      label: 'French',
    },
    {
      value: 'Urdu',
      label: 'Urdu',
    },
    {
      value: 'Indonesian',
      label: 'Indonesian',
    },
    {
      value: 'Italian',
      label: 'Italian',
    },
    {
      value: 'Turkish',
      label: 'Turkish',
    },
    {
      value: 'Dutch',
      label: 'Dutch',
    },
    {
      value: 'Vietnamese',
      label: 'Vietnamese',
    },
    {
      value: 'Korean',
      label: 'Korean',
    },
    {
      value: 'Tamil',
      label: 'Tamil',
    },
  ]
  const [selectLang, setSelectLang] = useState<string[]>([])
  const handleSelectLang = (value: string) => {
    if (!languages.includes(value)) {
      setLanguages([...languages, value])
    }
  }
  const handleRemoveLanguage = (value: string) => {
    const updatedLanguages = [...languages]
    const indexToRemove = updatedLanguages.indexOf(value)
    if (indexToRemove !== -1) {
      updatedLanguages.splice(indexToRemove, 1)
      setLanguages(updatedLanguages)
    }
  }
  const onChange = (value: string) => {
    if (!languages.includes(value)) {
      setLanguages([...languages, value])
    }
  }
  const handleSaveCompanyFineTune = () => {
    console.log(fineTuneObject)
  }

  const handlePromptInputChange = (e) => {
    setFineTuneObject({type: 'language', prompt: e.target.value})
  }
  const onSearch = (value: string) => {}

  const filterOption = (input: string, option?: {label: string; value: string}) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  return (
    <div>
      <Card>
        <div>
          <div className='d-flex flex-column justify-content-evenly h-75px mt-0'>
            <div className='d-flex flex-column w-100 justify-content-between'>
              <Flex align='center ' style={{justifyContent: 'space-between'}}>
                <span style={{fontFamily: "'Lato',sans-serif"}} className=' fs-6'>
                  Languages
                </span>
                <Tooltip title='Fine tune languages'>
                  <Flex vertical align='center' onClick={() => setShowFineTuneLanguages(true)}>
                    <AutoAwesomeIcon style={{color: '#87255b', cursor: 'pointer'}} />
                  </Flex>
                </Tooltip>
              </Flex>
              <Select
                size='large'
                style={{width: '100%', marginBottom: '5px', marginTop: '5px'}}
                showSearch
                placeholder='Select a language'
                optionFilterProp='children'
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={languagesList}
              />
            </div>
          </div>
          <FineTuneModal
            showModal={showFineTuneLanguages}
            setShowModal={setShowFineTuneLanguages}
            handleFineTune={handleSaveCompanyFineTune}
            handleInputChange={handlePromptInputChange}
          />
          <div className={dashstyle.languageContainer}>
            {languages.map((lang, index) => (
              <FilterTags removeFunction={handleRemoveLanguage} value={lang} />
              // <div key={index} className={dashstyle.languagebox}>
              //   <span className={`btn-label ${dashstyle.countrynames} `}>{lang.toString()}</span>{' '}
              //   <i
              //     style={{color: '#6f36e8'}}
              //     className={`bi bi-x fs-2 ps-2 ${dashstyle.close}`}
              //     onClick={() => handleRemoveLanguage(lang)}
              //   ></i>
              // </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default LanguageCard
