import React, {useEffect, useState} from 'react'
import {Select, Input, Flex, Button, message, notification} from 'antd'
import {useEmailContext} from '../../context/emailContext'
import {useFlowContext} from '../../context/FlowContext'
import {GetLinkedEmails} from '../CommonApiCalls/AccountsApi'
import EditorComponent from './Editor'
import {GetFullProfile, StartConversationMail} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import {useProfileContext} from '../../context/profilecontext'
import EmailForm from '../EmailForm'
import type {NotificationArgsProps} from 'antd'

type NotificationPlacement = NotificationArgsProps['placement']
type NotificationType = 'success' | 'info' | 'warning' | 'error'
const ConversationsEmail = () => {
  const {startingFlowName, selectedFlowId} =
    useFlowContext()
  const {sub, content, setSub, setTemplateValue, setContent} = useEmailContext()
  const [api, contextHolder] = notification.useNotification()

  const openNotification = (
    placement: NotificationPlacement,
    type: NotificationType,
    mssg: string
  ) => {
    api[type]({
      message: mssg,
      placement,
    })
  }
  const {accountData, selectedAccount, setSelectedAccount} = useEmailContext()
  const {profile, setProfile} = useProfileContext()
  const [selectedValue, setSelectedValue] = useState({})
  const [subject, setSubject] = useState<string>('')
  // const [content, setContent] = useState<string>('')
  const [accData, setAccData] = useState([])
  useEffect(() => {
    for (let acc of accData) {
      if (acc['id'] === selectedValue) {
        setSelectedAccount(acc)
        break
      }
    }
  }, [selectedValue])

  const filterOption = (input: string, option?: {label: string; value: string}) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const onSearch = (value: string) => {}

  const handleSendEmail = async () => {
    if (profile) {
      const emailData = {
        account: selectedAccount,
        subject: sub,
        content: content,
        linkedinUrl: profile['key'],
      }
      try {
        const response = await StartConversationMail(userId, emailData)
        if (response && response.data && response.success) {
          // message.success('Email will be sent to the current user')
          openNotification('topRight', 'success', 'Email will be sent to the current user')
          setSub('')
          setContent('')
          setTemplateValue('')

          setSelectedValue({})
          let data = await GetFullProfile(userId, profile['key'])
          if (data && data.success && data.data) {
            setProfile(data.data)
          }
        } else {
          openNotification('topRight', 'error', 'Please try again later')
        }
      } catch (err) {
        console.error('Error while updating the conversation')
        openNotification('topRight', 'error', " COuldn't send the Email, Please try again later")
      }
    }
  }

  useEffect(() => {
    GetLinkedEmails({selectedFlowId, startingFlowName, setAccData})
  }, [])

  const isSendDisabled = !selectedAccount || !sub || !content

  return (
    <Flex gap={'small'} vertical style={{width: '100%', marginTop: '10px'}}>
      {contextHolder}
      <Flex
        vertical
        gap={'small'}
        align='start'
        style={{width: '100%', paddingLeft: '20px', paddingRight: '20px'}}
      >
        <span className=' heading fs-6'>Select the email</span>
        <Select
          showSearch
          value={accData && accData['name']}
          className='w-100'
          placeholder='Select account'
          optionFilterProp='children'
          onChange={(value) => setSelectedValue(value)}
          onSearch={onSearch}
          filterOption={filterOption}
          options={
            accData
              ? accData.map((account, index) => ({
                  label: account['name'],
                  value: account['id'],
                }))
              : []
          }
        />
      </Flex>
      <Flex vertical gap={'small'}>
        <EmailForm />
        {/* <span className=' heading fs-6'>Subject</span>
        <Input
          placeholder='Enter your subject'
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        /> */}
      </Flex>
      {/* <Flex vertical gap={'small'}> */}
      {/* <span className=' heading fs-6'>Content</span>
        <EditorComponent editorRef={undefined} content={content} handleEditorChange={setContent} /> */}
      {/* </Flex> */}
      <Flex justify='end' style={{width: '100%', paddingLeft: '20px', paddingRight: '20px'}}>
        <Button type='primary' onClick={handleSendEmail} disabled={isSendDisabled}>
          Send Email
        </Button>
      </Flex>
    </Flex>
  )
}

export default ConversationsEmail
