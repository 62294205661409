import {useEffect, useState} from 'react'
import {Card, Flex, InputNumber, Tooltip, message} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'
import {GetUserLimitsApi, SaveLimitsApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'

const AccountLimits = () => {
  const [dailyLimit, setDailyLimit] = useState<number>(40)
  const [profileVisitLimit, setProfileVisitLimit] = useState<number>(70)
  const [connectionRequestLimit, setConnectionRequestLimit] = useState<number>(10)
  const [messageLimit, setMessageLimit] = useState<number>(60)

  useEffect(() => {
    getLimits()
  }, [])
  const getLimits = async () => {
    try {
      const res = await GetUserLimitsApi(userId)
      if (res && res.success && Object.keys(res.data).length > 0) {
        const {dailyLimit, profileLimit, connectionLimit, messageLimit} = res.data
        setDailyLimit(dailyLimit)
        setProfileVisitLimit(profileLimit)
        setConnectionRequestLimit(connectionLimit)
        setMessageLimit(messageLimit)
      }
    } catch (err) {
      console.error('Error while getting the limits in component ' + err)
    }
  }
  const handleDailyLimitChange = async (value: number | null) => {
    if (value !== null) {
      setDailyLimit(value)
      const limits = {
        dailyLimit: value,
        profileLimit: value,
        connectionLimit: connectionRequestLimit,
        messageLimit: messageLimit,
      }
      await handleSave(limits)
    }
  }

  const handleProfileVisitLimitChange = async (value: number | null) => {
    if (value !== null) {
      const limits = {
        dailyLimit: dailyLimit,
        profileLimit: value,
        connectionLimit: connectionRequestLimit,
        messageLimit: messageLimit,
      }
      setProfileVisitLimit(value)
      await handleSave(limits)
    }
  }

  const handleConnectionRequestLimitChange = async (value: number | null) => {
    if (value !== null) {
      const limits = {
        dailyLimit: dailyLimit,
        profileLimit: value,
        connectionLimit: value,
        messageLimit: messageLimit,
      }
      setConnectionRequestLimit(value)
      await handleSave(limits)
    }
  }

  const handleMessageLimitChange = async (value: number | null) => {
    if (value !== null) {
      const limits = {
        dailyLimit: dailyLimit,
        profileLimit: value,
        connectionLimit: connectionRequestLimit,
        messageLimit: value,
      }
      setMessageLimit(value)
      await handleSave(limits)
    }
  }

  const handleSave = async (limits) => {
    try {
      const res = await SaveLimitsApi(userId, limits)
      if (res && res.success && res.data) {
        message.success('Limits saved success fully')
      } else {
        message.error('Error while saving the limits')
      }
    } catch (err) {
      message.error('Error while saving the limits')
      console.error('Error while saving  user limit data ', err)
    }
  }
  return (
    <Flex vertical gap={'middle'} style={{width: '100%', padding: '10px'}}>
      <Card style={{width: '100%'}}>
        <Flex align='center' style={{justifyContent: 'space-between'}}>
          <Flex align='center ' gap={'small'}>
            <span className='heading fs-5'>Daily Limit</span>
            <Tooltip title='Maximum number of Emails per day is limited to 500'>
              <InfoCircleOutlined
                style={{cursor: 'pointer'}}
                className='text-muted'
                rev={undefined}
              />
            </Tooltip>
          </Flex>
          <InputNumber min={1} max={500} value={dailyLimit} onChange={handleDailyLimitChange} />
        </Flex>
      </Card>
      <Card style={{width: '100%'}}>
        <Flex align='center' style={{justifyContent: 'space-between'}}>
          <Flex align='center ' gap={'small'}>
            <span className='heading fs-5'>LinkedIn profile visit</span>
            <Tooltip title='Number of LinkedIn profile visits per day'>
              <InfoCircleOutlined
                style={{cursor: 'pointer'}}
                className='text-muted'
                rev={undefined}
              />
            </Tooltip>
          </Flex>

          <InputNumber
            min={1}
            max={80}
            value={profileVisitLimit}
            onChange={handleProfileVisitLimitChange}
          />
        </Flex>
      </Card>
      <Card style={{width: '100%'}}>
        <Flex align='center' style={{justifyContent: 'space-between'}}>
          <Flex align='center ' gap={'small'}>
            <span className='heading fs-5'>LinkedIn Connection request</span>
            <Tooltip title='Number of LinkedIn Connection visits per day'>
              <InfoCircleOutlined
                style={{cursor: 'pointer'}}
                className='text-muted'
                rev={undefined}
              />
            </Tooltip>
          </Flex>
          <InputNumber
            min={1}
            max={50}
            value={connectionRequestLimit}
            onChange={handleConnectionRequestLimitChange}
          />
        </Flex>
      </Card>
      <Card style={{width: '100%'}}>
        <Flex align='center' style={{justifyContent: 'space-between'}}>
          <Flex align='center ' gap={'small'}>
            <span className='heading fs-5'>LinkedIn Message</span>
            <Tooltip title='Number of LinkedIn Message visits per day'>
              <InfoCircleOutlined
                style={{cursor: 'pointer'}}
                className='text-muted'
                rev={undefined}
              />
            </Tooltip>
          </Flex>
          <InputNumber min={1} max={100} value={messageLimit} onChange={handleMessageLimitChange} />
        </Flex>
      </Card>
    </Flex>
  )
}

export default AccountLimits
