import {GetTeamMembersApi} from '../../apicalls/ApiCall'
import {message} from 'antd'
import {TeamDataProps} from '../DataTypes/TeamSettings'
import {userId} from '../DataTypes/UserDataTypes'

type GetMembersProps = {
  setShowLoader: (value: boolean) => void
  setTeamData: (teamData: TeamDataProps[]) => void
}

export const getTeamMembers = async ({setShowLoader, setTeamData}: GetMembersProps) => {
  try {
    setShowLoader(true)
    const resData = await GetTeamMembersApi(userId)
    setShowLoader(false)
    if (resData && resData.success && resData.data) {
      setTeamData(resData.data)
    } else {
      message.error('Error while getting the team details ')
    }
  } catch (err) {
    message.error('Error while getting the team details ')
    console.error('error while getting the team members data ', err)
  }
}
