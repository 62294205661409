import React from 'react'
import {Editor} from '@tinymce/tinymce-react'
const EditorComponent = ({editorRef, content, handleEditorChange}) => {
  return (
    <Editor
      ref={editorRef}
      apiKey='g14jn8vnond5tzv8dvoa63aevk6awxgq14bi77nsx8xrr25n'
      id='Content'
      init={{
        height: 400,
        width: '100%',
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
          'image',
          'table',
          'file',
          'link',
          'code',
        ],
        toolbar:
          'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        font_formats:
          'Arial=arial,helvetica,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino; Lucida=Lucida Sans Unicode, Lucida Grande, sans-serif; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif; Trebuchet MS=trebuchet ms,geneva,sans-serif; Verdana=verdana,geneva,sans-serif',
        init_instance_callback: function (editor) {
          var rightContainers = document.querySelectorAll(
            '.tox-statusbar__right-container'
          ) as NodeListOf<HTMLElement>
          console.log(rightContainers)
          if (rightContainers) {
            for (var i = 0; i < rightContainers.length; i++) {
              if (rightContainers[i]) {
                rightContainers[i].style.display = 'none'
              }
            }
          }
        },
        images_upload_url: 'https://api.beanbag.ai/uploadPicture',
        file_browser_callback_types: 'file image media',
      }}
      value={content}
      onEditorChange={handleEditorChange}
    />
  )
}

export default EditorComponent
