import {FC, createContext, useContext, useState} from 'react'

type UpgradeContextType = {
  showUpgradePopUp: boolean
  setShowUpgradePopUp: (showUpgradePopUp: boolean) => void
}
const UpgradeContext = createContext<UpgradeContextType | undefined>(undefined)

const UpgradeContextProvider = ({children}) => {
  const [showUpgradePopUp, setShowUpgradePopUp] = useState<boolean>(false)
  let value = {
    showUpgradePopUp,
    setShowUpgradePopUp,
  }
  return <UpgradeContext.Provider value={value}>{children}</UpgradeContext.Provider>
}

const useUpgradeContext = ()=>{
    const upgrade = useContext(UpgradeContext)
    if(!upgrade) throw new Error('error in UpgradeContext')
    return upgrade
}
export {useUpgradeContext,UpgradeContextProvider}