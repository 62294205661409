import React, {useEffect, useState} from 'react'
import {
  Card,
  Flex,
  Modal,
  Button,
  Input,
  Spin,
  message,
  Tooltip,
  Checkbox,
  Switch,
  Select,
} from 'antd'
import {
  VisitProfileApi,
  SendConnectionApi,
  SendMessageApi,
  ScrapeJobsPostingApi,
  ScrapeEmployeesData,
  SalesPeopleSearchExportApi,
  SalesAccountSearchExportApi,
} from '../../apicalls/AutomationAPI'
import Papa from 'papaparse'
import {InfoCircleOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons'
import linkedinLogo from '../../assets/linkedin logg.png'
import {GetFlowListApi, GetListApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import {v4 as uuidV4} from 'uuid'

export const LinkedinVisitProfile = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [liAt, setLiAt] = useState('')
  const [profileUrl, setProfileUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<'success' | 'failure' | null>(null)

  const handleVisitProfile = async () => {
    setLoading(true)
    setModalVisible(false)
    setResult(null)
    try {
      const res = await VisitProfileApi(liAt, profileUrl)
      if (res['message'] === 'Success: Successfully visited profile') {
        setResult('success')
      } else {
        setResult('failure')
      }
    } catch (err) {
      console.error('Error visiting profile:', err)
      setResult('failure')
    } finally {
      setLoading(false)
      setLiAt('')
      setProfileUrl('')
      setTimeout(() => {
        setResult(null)
      }, 5000)
    }
  }

  return (
    <Flex vertical>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='linkedin'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={linkedinLogo}
            />
          </div>
        }
        onClick={() => setModalVisible(true)}
        style={{width: '220px', height: '220px', background: '#0077b5', cursor: 'pointer'}}
      >
        <Flex vertical gap={'middle'}>
          <span
            style={{fontWeight: '500', fontSize: '1.5em', color: 'white', lineHeight: '1.2'}}
            className='heading'
          >
            Visit Profiles on LinkedIn
          </span>
          <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
            Automate LinkedIn profile visits
          </span>
          {loading && <Spin size='small' style={{marginLeft: '5px'}} />}
          {result === 'success' && <span style={{color: 'green', marginLeft: '5px'}}>✔</span>}
          {result === 'failure' && <span style={{color: 'red', marginLeft: '5px'}}>✘</span>}
        </Flex>
      </Card>
      <Modal
        title='Enter LinkedIn Credentials and Profile URL'
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='visit-profile'
            type='primary'
            disabled={!liAt || !profileUrl}
            onClick={handleVisitProfile}
          >
            Visit Profile
          </Button>,
        ]}
      >
        <Flex vertical gap={10}>
          <div>
            <Flex gap={'4px'}>
              <label htmlFor='liAtInput'>LI_AT</label>
              <Tooltip title='Your LinkedIn authentication token'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              size='large'
              id='liAtInput'
              placeholder='Enter li_at'
              value={liAt}
              onChange={(e) => setLiAt(e.target.value)}
            />
          </div>
          <div>
            <Flex gap={'4px'}>
              <label htmlFor='profileUrlInput'>Spreadsheet Link</label>
              <Tooltip title='The URL of the LinkedIn profile you want to visit'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              size='large'
              id='profileUrlInput'
              placeholder='Enter Profile URL'
              value={profileUrl}
              onChange={(e) => setProfileUrl(e.target.value)}
            />
          </div>
        </Flex>
      </Modal>
    </Flex>
  )
}

export const LinkedinSendConnection = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [liAt, setLiAt] = useState('')
  const [profileUrl, setProfileUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<'success' | 'failure' | null>(null)
  const [linkedinMessage, setLinkedinMessage] = useState('')

  const handleVisitProfile = async () => {
    setLoading(true)
    setModalVisible(false)
    setResult(null)
    try {
      const res = await SendConnectionApi(liAt, profileUrl, linkedinMessage)
      if (res) {
        message.info(res['message'])
      }
    } catch (err) {
      console.error('Error visiting profile:', err)
      setResult('failure')
    } finally {
      setLoading(false)
      setLiAt('')
      setProfileUrl('')
      setLinkedinMessage('')
      setTimeout(() => {
        setResult(null)
      }, 5000)
    }
  }

  return (
    <Flex vertical>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='linkedin'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={linkedinLogo}
            />
          </div>
        }
        onClick={() => setModalVisible(true)}
        style={{width: '220px', height: '220px', background: '#0077b5', cursor: 'pointer'}}
      >
        <Flex vertical gap={'middle'}>
          <span
            style={{fontWeight: '500', fontSize: '1.5em', color: 'white', lineHeight: '1.2'}}
            className='heading'
          >
            Send Connection Request
          </span>
          <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
            Auto connect with a list of users by sending invitations with a personalized note.
          </span>
        </Flex>
      </Card>
      <Modal
        title='Enter LinkedIn details for connection request'
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='visit-profile'
            type='primary'
            disabled={!liAt || !profileUrl}
            onClick={handleVisitProfile}
          >
            Send Connection request
          </Button>,
        ]}
      >
        <Flex vertical gap={10}>
          <div>
            <Flex gap={'4px'}>
              <label htmlFor='liAtInput'>li_at:</label>
              <Tooltip title='Your LinkedIn authentication token'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              size='large'
              id='liAtInput'
              placeholder='Enter li_at'
              value={liAt}
              onChange={(e) => setLiAt(e.target.value)}
            />
          </div>
          <div>
            <Flex gap={'4px'}>
              <label htmlFor='profileUrlInput'>Spreadsheet Link</label>
              <Tooltip title='The URL of the LinkedIn profile you want to visit'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              size='large'
              id='profileUrlInput'
              placeholder='Enter Profile URL'
              value={profileUrl}
              onChange={(e) => setProfileUrl(e.target.value)}
            />
          </div>
          <div>
            <Flex gap={'4px'}>
              <label htmlFor='liAtInput'>Message:</label>
              <Tooltip title='Message to send with the connection request'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              size='large'
              id='profileMesg'
              placeholder='Enter Message '
              value={linkedinMessage}
              onChange={(e) => setLinkedinMessage(e.target.value)}
            />
          </div>
        </Flex>
      </Modal>
    </Flex>
  )
}
export const LinkedinSendMessage = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [liAt, setLiAt] = useState('')
  const [profileUrl, setProfileUrl] = useState('')
  const [linkedinMessage, setLinkedinMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<'success' | 'failure' | null>(null)

  const handleVisitProfile = async () => {
    setLoading(true)
    setModalVisible(false)
    setResult(null)
    try {
      const res = await SendMessageApi(liAt, profileUrl, linkedinMessage)
      console.log(res)
      if (res) {
        message.info(res['message'])
      }
      if (res['message'] === 'Success: Message sent successfully') {
        setResult('success')
      } else {
        setResult('failure')
      }
    } catch (err) {
      console.error('Error visiting profile:', err)
      setResult('failure')
    } finally {
      setLoading(false)
      setLiAt('')
      setProfileUrl('')
      setLinkedinMessage('')
      setTimeout(() => {
        setResult(null)
      }, 5000)
    }
  }

  return (
    <Flex vertical>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='linkedin'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={linkedinLogo}
            />
          </div>
        }
        onClick={() => setModalVisible(true)}
        style={{width: '220px', height: '220px', background: '#0077b5', cursor: 'pointer'}}
      >
        <Flex vertical gap={'middle'}>
          <span style={{color: 'white', fontWeight: '500', fontSize: '1.5em', lineHeight: '1.2'}}>
            Send Automatic Messages
          </span>
          <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
            Send personalized messages to your LinkedIn connections automatically
          </span>
          {loading && <Spin size='small' style={{marginLeft: '5px'}} />}
          {result === 'success' && <span style={{color: 'green', marginLeft: '5px'}}>✔</span>}
          {result === 'failure' && <span style={{color: 'red', marginLeft: '5px'}}>✘</span>}
        </Flex>
      </Card>
      <Modal
        title='Enter LinkedIn details for message request'
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='visit-profile'
            type='primary'
            disabled={!liAt || !profileUrl}
            onClick={handleVisitProfile}
          >
            Send message
          </Button>,
        ]}
      >
        <Flex vertical gap={10}>
          <div>
            <Flex gap={'4px'}>
              <label htmlFor='liAtInput'>li_at:</label>
              <Tooltip title='Your LinkedIn authentication token'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              size='large'
              id='liAtInput'
              placeholder='Enter li_at'
              value={liAt}
              onChange={(e) => setLiAt(e.target.value)}
            />
          </div>
          <div>
            <Flex gap={'4px'}>
              <label htmlFor='profileUrlInput'>Spreadsheet Link</label>
              <Tooltip title='The URL of the LinkedIn profile you want to visit'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              size='large'
              id='profileUrlInput'
              placeholder='Enter Profile URL'
              value={profileUrl}
              onChange={(e) => setProfileUrl(e.target.value)}
            />
          </div>
          <div>
            <Flex gap={'4px'}>
              <label htmlFor='profileMesg'>Message:</label>
              <Tooltip title='Message to send'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              size='large'
              id='profileMesg'
              placeholder='Enter Message '
              value={linkedinMessage}
              onChange={(e) => setLinkedinMessage(e.target.value)}
            />
          </div>
        </Flex>
      </Modal>
    </Flex>
  )
}

export const JobScrape = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [liAt, setLiAt] = useState('')
  const [jobsPostingUrl, setJobsPostingUrl] = useState('')
  const [linkedinMessage, setLinkedinMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [recurring, setRecurring] = useState<boolean>(false)
  const [oneTime, setOneTime] = useState<boolean>(false)
  const [time, setTime] = useState<string[]>(['00:00'])
  const [scheduleTime, setScheduleTime] = useState<string>('')
  const [result, setResult] = useState<'success' | 'failure' | null>(null)
  const [liAtList, setLiAtList] = useState([''])
  const [getJobPoster, setGetJobPoster] = useState<boolean>(false)
  const [mode, setMode] = useState<'lists' | 'flows'>('lists')
  const [flowTitle, setFlowTitle] = useState<string>('')
  const [selectedList, setSelectedList] = useState<string | null>(null)
  const [selectedFlow, setSelectedFlow] = useState<string | null>(null)
  const [options, setOptions] = useState<{label: string; value: string}[]>([
    {label: 'Head of Talent', value: 'Head of Talent'},
    {label: 'Lead Recruiter', value: 'Lead Recruiter'},
    {label: 'Head of Recruiting', value: 'Head of Recruiting'},
    {label: 'Chief Talent Officer', value: 'Chief Talent Officer'},
    {label: 'Talent Acquisition Partner', value: 'Talent Acquisition Partner'},
    {label: 'Talent Acquisition coordinator', value: 'Talent Acquisition coordinator'},
    {label: 'Talent Acquisition Specialist', value: 'Talent Acquisition Specialist'},
    {label: 'Recruiter', value: 'Recruiter'},
    {label: 'Recruitment Coordinator', value: 'Recruitment Coordinator'},
    {label: 'Head of Recruitment', value: 'Head of Recruitment'},
    {label: 'Talent Director', value: 'Talent Director'},
    {label: 'Corporate Recruiter', value: 'Corporate Recruiter'},
    {label: 'Recruiting Manager', value: 'Recruiting Manager'},
    {label: 'VP of Talent Acquisition', value: 'VP of Talent Acquisition'},
    {label: 'Cofounder', value: 'Cofounder'},
    {label: 'CEO', value: 'CEO'},
    {label: 'CTO', value: 'CTO'},
    {label: 'Founder', value: 'Founder'},
    {label: 'Chief Executive Officer', value: 'Chief Executive Officer'},
    {label: 'Chief Technology Officer', value: 'Chief Technology Officer'},
    {label: 'Marketing Manager', value: 'Marketing Manager'},
    {label: 'Sales Manager', value: 'Sales Manager'},
    {label: 'VP of Marketing', value: 'VP of Marketing'},
    {label: 'VP of Sales', value: 'VP of Sales'},
    {label: 'Head of Marketing', value: 'Head of Marketing'},
    {label: 'Head of Sales', value: 'Head of Sales'},
    {label: 'Inside sales manager', value: 'Inside sales manager'},
    {label: 'Software Development', value: 'Software Development'},
    {label: 'Software Engineer', value: 'Software Engineer'},
    {label: 'Technical Program Manager', value: 'Technical Program Manager'},
    {label: 'Senior Software Engineer', value: 'Senior Software Engineer'},
    {label: 'Site Reliability Engineer', value: 'Site Reliability Engineer'},
    {label: 'Quality Assurance Engineer', value: 'Quality Assurance Engineer'},
    {label: 'Software Test Engineer', value: 'Software Test Engineer'},
    {label: 'Software Engineer Intern', value: 'Software Engineer Intern'},
    {label: 'Data Engineer', value: 'Data Engineer'},
    {label: 'Senior Product Manager', value: 'Senior Product Manager'},
    {label: 'Senior Developer', value: 'Senior Developer'},
    {label: 'Automation Engineer', value: 'Automation Engineer'},
    {label: 'Linux Engineer', value: 'Linux Engineer'},
    {label: 'Front End Developer', value: 'Front End Developer'},
    {label: 'Sales', value: 'Sales'},
    {label: 'Account Executive', value: 'Account Executive'},
    {label: 'Relationship Manager', value: 'Relationship Manager'},
    {label: 'Account Manager', value: 'Account Manager'},
    {label: 'Enterprise Account Executive', value: 'Enterprise Account Executive'},
    {label: 'Account Director', value: 'Account Director'},
    {label: 'Enterprise Account Manager', value: 'Enterprise Account Manager'},
    {label: 'Sales Consultant', value: 'Sales Consultant'},
    {label: 'Sales Director', value: 'Sales Director'},
    {label: 'Sales Specialist', value: 'Sales Specialist'},
    {label: 'Senior Representative', value: 'Senior Representative'},
    {label: 'Sales Manager', value: 'Sales Manager'},
    {label: 'Collection Agent', value: 'Collection Agent'},
    {label: 'Market Manager', value: 'Market Manager'},
    {label: 'Account Representative', value: 'Account Representative'},
    {label: 'Senior Sales Leader', value: 'Senior Sales Leader'},
    {label: 'Sales Associate', value: 'Sales Associate'},
    {label: 'Regional Sales Manager', value: 'Regional Sales Manager'},
    {label: 'Partnership Manager', value: 'Partnership Manager'},
    {label: 'Comercial de ventas', value: 'Comercial de ventas'},
    {label: 'Solution Specialist', value: 'Solution Specialist'},
    {label: 'Sales and Lettings Negotiator', value: 'Sales and Lettings Negotiator'},
    {label: 'Senior Relationship Manager', value: 'Senior Relationship Manager'},
    {label: 'Marketing Manager', value: 'Marketing Manager'},
    {label: 'Marketing Specialist', value: 'Marketing Specialist'},
    {label: 'Campaign Manager', value: 'Campaign Manager'},
    {label: 'Senior Marketing Manager', value: 'Senior Marketing Manager'},
    {label: 'Senior Digital Marketer', value: 'Senior Digital Marketer'},
    {label: 'Brand Manager', value: 'Brand Manager'},
    {label: 'Marketing Executive', value: 'Marketing Executive'},
    {label: 'Senior Market Researcher', value: 'Senior Market Researcher'},
    {label: 'Web Manager', value: 'Web Manager'},
    {label: 'Brand Ambassador', value: 'Brand Ambassador'},
    {label: 'Manager', value: 'Manager'},
    {label: 'Consultant', value: 'Consultant'},
    {label: 'Solutions Manager', value: 'Solutions Manager'},
    {label: 'Operations Manager', value: 'Operations Manager'},
    {label: 'Site Manager', value: 'Site Manager'},
    {label: 'Management Trainee', value: 'Management Trainee'},
    {label: 'Event Manager', value: 'Event Manager'},
    {label: 'Site Lead', value: 'Site Lead'},
    {label: 'Senior Director', value: 'Senior Director'},
    {label: 'Contract Manager', value: 'Contract Manager'},
    {label: 'Deployment Manager', value: 'Deployment Manager'},
    {label: 'Learning and Development Manager', value: 'Learning and Development Manager'},
    {label: 'Senior Manager', value: 'Senior Manager'},
    {
      label: 'Vice President of Business Development',
      value: 'Vice President of Business Development',
    },
    {label: 'Business Development Manager', value: 'Business Development Manager'},
    {label: 'Facilities Manager', value: 'Facilities Manager'},
    {label: 'Director of Engineering', value: 'Director of Engineering'},
    {label: 'Vice President', value: 'Vice President'},
    {label: 'Sourcing Manager', value: 'Sourcing Manager'},
    {label: 'Senior Operation Manager', value: 'Senior Operation Manager'},
    {label: 'Business Intelligence Manager', value: 'Business Intelligence Manager'},
    {label: 'Team Leader', value: 'Team Leader'},
    {label: 'Recruiter', value: 'Recruiter'},
    {label: 'Technical Recruiter', value: 'Technical Recruiter'},
    {label: 'Recruiting Coordinator', value: 'Recruiting Coordinator'},
    {label: 'Recruitment Resourcer', value: 'Recruitment Resourcer'},
    {label: 'Senior Compensation Analyst', value: 'Senior Compensation Analyst'},
    {label: 'Director of Security', value: 'Director of Security'},
    {label: 'Human Resources Specialist', value: 'Human Resources Specialist'},
    {label: 'Regional Recruiter', value: 'Regional Recruiter'},
    {label: 'Safety Specialist', value: 'Safety Specialist'},
    {label: 'Sourcing Specialist', value: 'Sourcing Specialist'},
    {label: 'Senior Technical Recruiter', value: 'Senior Technical Recruiter'},
    {label: 'Receptionist', value: 'Receptionist'},
    {label: 'Operations Associate', value: 'Operations Associate'},
    {label: 'Billing Specialist', value: 'Billing Specialist'},
    {label: 'Office Administrator', value: 'Office Administrator'},
    {label: 'Center Manager', value: 'Center Manager'},
    {label: 'Executive Assistant', value: 'Executive Assistant'},
    {label: 'Student Assistant', value: 'Student Assistant'},
    {label: 'Government Executive', value: 'Government Executive'},
    {label: 'Operations Intern', value: 'Operations Intern'},
    {label: 'Administrative Assistant', value: 'Administrative Assistant'},
    {label: 'Assistant Secretary', value: 'Assistant Secretary'},
    {label: 'Project Intern', value: 'Project Intern'},
    {label: 'Personal Assistant', value: 'Personal Assistant'},
    {label: 'Systems Analyst', value: 'Systems Analyst'},
    {label: 'Data Analyst', value: 'Data Analyst'},
    {label: 'Operations Analyst', value: 'Operations Analyst'},
    {label: 'Technology Analyst', value: 'Technology Analyst'},
    {label: 'Technical Consultant', value: 'Technical Consultant'},
    {label: 'User Experience Designer', value: 'User Experience Designer'},
    {label: 'Customer Insights Analyst', value: 'Customer Insights Analyst'},
    {label: 'Senior Business Process Analyst', value: 'Senior Business Process Analyst'},
    {label: 'Instructional Designer', value: 'Instructional Designer'},
    {label: 'Senior User Experience Designer', value: 'Senior User Experience Designer'},
    {label: 'Business Analyst', value: 'Business Analyst'},
    {label: 'Support Specialist', value: 'Support Specialist'},
    {label: 'Customer Service Manager', value: 'Customer Service Manager'},
    {label: 'Customer Success Manager', value: 'Customer Success Manager'},
    {
      label: 'Senior Customer Service Representative',
      value: 'Senior Customer Service Representative',
    },
    {label: 'Customer Specialist', value: 'Customer Specialist'},
    {label: 'Customer Service Representative', value: 'Customer Service Representative'},
    {label: 'Support Associate', value: 'Support Associate'},
    {label: 'Customer Support Representative', value: 'Customer Support Representative'},
    {label: 'Program Manager', value: 'Program Manager'},
    {label: 'Project Manager', value: 'Project Manager'},
    {label: 'Strategic Project Manager', value: 'Strategic Project Manager'},
    {label: 'Project Manager/Business Analyst', value: 'Project Manager/Business Analyst'},
    {label: 'Project Coordinator', value: 'Project Coordinator'},
    {label: 'Analyst', value: 'Analyst'},
    {label: 'Senior Data Scientist', value: 'Senior Data Scientist'},
    {label: 'Senior Analyst', value: 'Senior Analyst'},
    {label: 'Data Science Intern', value: 'Data Science Intern'},
    {label: 'Technician', value: 'Technician'},
    {label: 'Operator', value: 'Operator'},
    {label: 'Equipment Technician', value: 'Equipment Technician'},
    {label: 'Editor', value: 'Editor'},
    {label: 'Audio Visual Engineer', value: 'Audio Visual Engineer'},
    {label: 'Communications Associate', value: 'Communications Associate'},
    {label: 'Video Editor', value: 'Video Editor'},
    {label: 'Writer/Editor', value: 'Writer/Editor'},
    {label: 'Freelance Writer', value: 'Freelance Writer'},
    {label: 'Accounts Payable Clerk', value: 'Accounts Payable Clerk'},
    {label: 'Senior Billing Analyst', value: 'Senior Billing Analyst'},
    {
      label: 'Director of Financial Planning and Analysis',
      value: 'Director of Financial Planning and Analysis',
    },
    {label: 'Finance Manager', value: 'Finance Manager'},
    {label: 'Accountant', value: 'Accountant'},
    {label: 'Billing Analyst', value: 'Billing Analyst'},
    {label: 'Procurement Analyst', value: 'Procurement Analyst'},
    {label: 'Senior Quality Specialist', value: 'Senior Quality Specialist'},
    {label: 'Production Manager', value: 'Production Manager'},
    {label: 'Security Officer', value: 'Security Officer'},
    {label: 'Test Engineer', value: 'Test Engineer'},
    {label: 'Senior System Engineer', value: 'Senior System Engineer'},
    {label: 'Senior Engineering Manager', value: 'Senior Engineering Manager'},
    {label: 'System Engineer', value: 'System Engineer'},
    {label: 'Trainer', value: 'Trainer'},
    {label: 'Training Coordinator', value: 'Training Coordinator'},
    {label: 'Director of Admissions', value: 'Director of Admissions'},
    {label: 'Training Developer', value: 'Training Developer'},
    {label: 'Dance Instructor', value: 'Dance Instructor'},
    {label: 'Server', value: 'Server'},
    {label: 'Barista', value: 'Barista'},
    {label: 'Cook', value: 'Cook'},
    {label: 'IT Support', value: 'IT Support'},
    {label: 'Data Center Technician', value: 'Data Center Technician'},
    {label: 'Senior Network Engineer', value: 'Senior Network Engineer'},
    {label: 'Helpdesk Technician', value: 'Helpdesk Technician'},
    {label: 'Life Science Consultant', value: 'Life Science Consultant'},
    {label: 'Laboratory Supervisor', value: 'Laboratory Supervisor'},
    {label: 'Content Strategist', value: 'Content Strategist'},
    {label: 'Cinematographer', value: 'Cinematographer'},
    {label: 'Social Worker', value: 'Social Worker'},
    {label: 'Wellness Coordinator', value: 'Wellness Coordinator'},
    {label: 'Mentor', value: 'Mentor'},
    {label: 'Quarry Manager', value: 'Quarry Manager'},
    {label: 'Hardware Engineer', value: 'Hardware Engineer'},
    {label: 'Independent Contractor', value: 'Independent Contractor'},
    {label: 'Lawyer', value: 'Lawyer'},
    {label: 'Compliance Officer', value: 'Compliance Officer'},
    {label: 'Behavior Technician', value: 'Behavior Technician'},
    {label: 'Logistic Coordinator', value: 'Logistic Coordinator'},
    {label: 'Equipment Operator', value: 'Equipment Operator'},
  ])
  const [selectedRole, setSelectedRoles] = useState<string[]>([])
  const [flows, setFlows] = useState([])
  const [lists, setLists] = useState([])
  const [jobsPostingData, setJobsPostingData] = useState<
    {
      jobPostUrl: string
      flowId: string
      listId?: string
    }[]
  >([
    {
      jobPostUrl: '',
      flowId: '',
      listId: '',
    },
  ])
  const onChange = (checked: boolean) => {
    setMode(checked ? 'flows' : 'lists')
  }

  const listOptions = lists.map((list) => ({label: list['listName'], value: list['listId']}))
  const flowOptions = flows.map((flow) => ({label: flow['flowName'], value: flow['flowId']}))

  const handleSelectionChange = (value: string, index) => {
    if (mode === 'lists') {
      setSelectedList(value)
      const updatedJobsPostingData = [...jobsPostingData]
      updatedJobsPostingData[index].flowId = ''
      updatedJobsPostingData[index].listId = value
    } else {
      setSelectedFlow(value)
      const updatedJobsPostingData = [...jobsPostingData]
      updatedJobsPostingData[index].flowId = value
      updatedJobsPostingData[index].listId = ''
    }
  }
  const generateTimeOptions = () => {
    const options: string[] = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        options.push(`${formattedHour}:${formattedMinute}`)
      }
    }
    return options
  }

  useEffect(() => {
    getFlows()
    getLists()
    setTime(generateTimeOptions())
  }, [])

  async function getLists() {
    try {
      const response = await GetListApi(userId)
      if (response && response.success && response.data) {
        setLists(response.data)
      }
    } catch (err) {
      console.error('Error while getting the lists of user ' + err)
    }
  }

  const handleJobsPostingUrlChange = (value, index) => {
    const updatedJobsPostingData = [...jobsPostingData]
    updatedJobsPostingData[index].jobPostUrl = value
    setJobsPostingData(updatedJobsPostingData)
  }

  const getFlows = async () => {
    try {
      let data = await GetFlowListApi(userId)
      if (data.success) {
        setFlows(data.data)
      }
    } catch (err) {
      message.error('error while getting the accounts data')
      console.error('error while getting the accounts data')
    }
  }
  const addLiAtInput = () => {
    setLiAtList([...liAtList, ''])
  }
  const addJobPostingUrl = () => {
    setJobsPostingData([...jobsPostingData, {jobPostUrl: '', flowId: ''}])
  }
  const removeJobPostingData = (index) => {
    const updatedJobsPostingData = [...jobsPostingData]
    updatedJobsPostingData.splice(index, 1)
    setJobsPostingData(updatedJobsPostingData)
  }
  const handleRoleSelect = (value: string[]) => {
    setSelectedRoles(value)
  }
  const removeLiAtInput = (index) => {
    const updatedLiAtList = [...liAtList]
    updatedLiAtList.splice(index, 1)
    setLiAtList(updatedLiAtList)
  }
  const handleScrapeJobsData = async () => {
    const userData = {
      recurring,
      oneTime,
      scheduleTime,
      liAtList,
      getJobPoster,
      selectedRole,
      jobsPostingData,
      userId,
      flowTitle,
      id: uuidV4(),
    }
    setLoading(true)
    setModalVisible(false)
    setResult(null)
    try {
      const res = await ScrapeJobsPostingApi(liAt, userData)
      if (res.length > 0) {
        downloadCSV(res)
        setResult('success')
      } else {
        setResult('failure')
      }
    } catch (err) {
      console.error('Error visiting profile:', err)
      setResult('failure')
    } finally {
      setLoading(false)
      setLiAt('')
      setJobsPostingUrl('')
      setLinkedinMessage('')
      setTimeout(() => {
        setResult(null)
      }, 5000)
    }
  }

  const downloadCSV = (data) => {
    const csv = Papa.unparse(data)
    const blob = new Blob([csv], {type: 'text/csv'})
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'saasBoomiData.csv')
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
  }

  return (
    <Flex vertical>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='linkedin'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={linkedinLogo}
            />
          </div>
        }
        onClick={() => setModalVisible(true)}
        style={{width: '220px', height: '220px', background: '#0077b5', cursor: 'pointer'}}
      >
        <Flex vertical gap={'middle'}>
          <span style={{color: 'white', fontWeight: '500', fontSize: '1.5em', lineHeight: '1.2'}}>
            Automate Linkedin Prospecting
          </span>
          
          <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
            Collect the Prospects data in few simple steps
          </span>
          {loading && <Spin size='small' style={{marginLeft: '5px'}} />}
          {result === 'success' && <span style={{color: 'green', marginLeft: '5px'}}>✔</span>}
          {result === 'failure' && <span style={{color: 'red', marginLeft: '5px'}}>✘</span>}
        </Flex>
      </Card>
      <Modal
        centered
        title='Enter LinkedIn details getting Jobs posting'
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='visit-profile'
            type='primary'
            disabled={
              !(liAtList.length > 0 && liAtList.some((li) => li !== '')) ||
              !((selectedRole && selectedRole.length > 0) || getJobPoster) ||
              !jobsPostingData.every(
                (data) => (data.jobPostUrl !== '' && data.flowId !== '') || data.listId !== ''
              ) ||
              !(oneTime || recurring) ||
              (recurring && scheduleTime === '') ||
              !flowTitle
            }
            onClick={handleScrapeJobsData}
          >
            Scrape Jobs
          </Button>,
        ]}
      >
        <Flex vertical gap={10}>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <span>Automation title</span>
            <Input
              placeholder='Enter the automation title'
              value={flowTitle}
              onChange={(e) => setFlowTitle(e.target.value)}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Flex gap={'4px'} align='center' justify='space-between'>
              <Flex gap={'4px'}>
                <label htmlFor={`liAtInput`}>Linkedin cookie:</label>
                <Tooltip title='Your LinkedIn authentication token'>
                  <InfoCircleOutlined
                    style={{color: '#848484', fontSize: '12px'}}
                    rev={undefined}
                  />
                </Tooltip>
              </Flex>
              <Button
                type='primary'
                onClick={addLiAtInput}
                icon={<PlusOutlined rev={undefined} />}
                size={'small'}
              ></Button>
            </Flex>
            {liAtList.map((liAt, index) => (
              <Flex key={index} justify='space-between' align='center' gap={'small'}>
                <Input
                  id={`liAtInput${index}`}
                  placeholder='Enter LinkedIn cookie'
                  value={liAt}
                  onChange={(e) => {
                    const updatedLiAtList = [...liAtList]
                    updatedLiAtList[index] = e.target.value
                    setLiAtList(updatedLiAtList)
                  }}
                />
                {index > 0 && (
                  <Button
                    onClick={() => removeLiAtInput(index)}
                    icon={<MinusOutlined rev={undefined} />}
                  ></Button>
                )}
              </Flex>
            ))}
          </div>
          <div>
            <Checkbox value={getJobPoster} onChange={() => setGetJobPoster(!getJobPoster)}>
              Job poster
            </Checkbox>
          </div>
          <div>
            <Flex align='center' gap={'middle'}>
              <span style={{fontWeight: '500', fontSize: '14px'}}>Role</span>
              <Select
                mode='tags'
                allowClear
                style={{width: '100%'}}
                placeholder='Please select a role'
                defaultValue={[]}
                onChange={handleRoleSelect}
                tokenSeparators={[',']} 
                options={options}
              />
            </Flex>
          </div>
          <div>
            <Flex vertical gap={'middle'}>
              <Flex align='center' justify='space-between'>
                <Flex gap={'4px'}>
                  <label htmlFor='JobpostingUrl'>Input Search query</label>
                  <Tooltip title='Search query URL Ex: https://www.linkedin.com/jobs/search/?currentJobId=3815640408'>
                    <InfoCircleOutlined
                      rev={undefined}
                      style={{color: '#848484', fontSize: '12px'}}
                    />
                  </Tooltip>
                </Flex>
                <Button
                  type='primary'
                  onClick={addJobPostingUrl}
                  icon={<PlusOutlined rev={undefined} />}
                  size={'small'}
                ></Button>
              </Flex>
              {jobsPostingData.map((jobData, index) => (
                <div
                  key={index}
                  style={{display: 'flex', gap: '10px', justifyContent: 'space-between'}}
                >
                  <Flex gap={'middle'} style={{width: '100%'}} align='center'>
                    <Input
                      id={`JobpostingUrl${index}`}
                      placeholder='Enter Job search URL'
                      value={jobData.jobPostUrl}
                      onChange={(e) => handleJobsPostingUrlChange(e.target.value, index)}
                    />
                    <Select
                      allowClear
                      style={{width: '100%'}}
                      placeholder={
                        mode === 'lists' ? 'Please select a list' : 'Please select a flow'
                      }
                      value={mode === 'lists' ? selectedList : selectedFlow}
                      onChange={(value) => handleSelectionChange(value, index)}
                      options={mode === 'lists' ? listOptions : flowOptions}
                    />
                    <Switch onChange={onChange} />
                  </Flex>
                  {index > 0 && (
                    <Button
                      onClick={() => removeJobPostingData(index)}
                      icon={<MinusOutlined rev={undefined} />}
                    />
                  )}
                </div>
              ))}
            </Flex>
          </div>
          <div>
            <Flex>
              <Checkbox
                checked={recurring}
                onChange={() => {
                  setOneTime(false)
                  setRecurring(true)
                }}
              >
                Recurring
              </Checkbox>
              <Checkbox
                checked={oneTime}
                onChange={() => {
                  setOneTime(true)
                  setRecurring(false)
                  setScheduleTime('')
                }}
              >
                One-Time
              </Checkbox>
            </Flex>
          </div>
          {recurring && (
            <div>
              <Select
                allowClear
                style={{width: '100%'}}
                placeholder='Select Time'
                onChange={(value) => setScheduleTime(value)}
                options={time.map((t) => ({label: t, value: t}))}
              />
            </div>
          )}
        </Flex>
      </Modal>
    </Flex>
  )
}
export const LinkedinSalesProfiles = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [liAt, setLiAt] = useState('')
  const [jobsPostingUrl, setJobsPostingUrl] = useState('')
  const [linkedinMessage, setLinkedinMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [recurring, setRecurring] = useState<boolean>(false)
  const [oneTime, setOneTime] = useState<boolean>(false)
  const [time, setTime] = useState<string[]>(['00:00'])
  const [scheduleTime, setScheduleTime] = useState<string>('')
  const [result, setResult] = useState<'success' | 'failure' | null>(null)
  const [liAtList, setLiAtList] = useState([''])
  const [getJobPoster, setGetJobPoster] = useState<boolean>(false)
  const [mode, setMode] = useState<'lists' | 'flows'>('lists')
  const [flowTitle, setFlowTitle] = useState<string>('')
  const [selectedList, setSelectedList] = useState<string | null>(null)
  const [selectedFlow, setSelectedFlow] = useState<string | null>(null)
  const [options, setOptions] = useState<{label: string; value: string}[]>([
    {label: 'Head of Talent', value: 'Head of Talent'},
    {label: 'Lead Recruiter', value: 'Lead Recruiter'},
    {label: 'Head of Recruiting', value: 'Head of Recruiting'},
    {label: 'Chief Talent Officer', value: 'Chief Talent Officer'},
    {label: 'Talent Acquisition Partner', value: 'Talent Acquisition Partner'},
    {label: 'Talent Acquisition coordinator', value: 'Talent Acquisition coordinator'},
    {label: 'Talent Acquisition Specialist', value: 'Talent Acquisition Specialist'},
    {label: 'Recruiter', value: 'Recruiter'},
    {label: 'Recruitment Coordinator', value: 'Recruitment Coordinator'},
    {label: 'Head of Recruitment', value: 'Head of Recruitment'},
    {label: 'Talent Director', value: 'Talent Director'},
    {label: 'Corporate Recruiter', value: 'Corporate Recruiter'},
    {label: 'Recruiting Manager', value: 'Recruiting Manager'},
    {label: 'VP of Talent Acquisition', value: 'VP of Talent Acquisition'},
    {label: 'Cofounder', value: 'Cofounder'},
    {label: 'CEO', value: 'CEO'},
    {label: 'CTO', value: 'CTO'},
    {label: 'Founder', value: 'Founder'},
    {label: 'Chief Executive Officer', value: 'Chief Executive Officer'},
    {label: 'Chief Technology Officer', value: 'Chief Technology Officer'},
    {label: 'Marketing Manager', value: 'Marketing Manager'},
    {label: 'Sales Manager', value: 'Sales Manager'},
    {label: 'VP of Marketing', value: 'VP of Marketing'},
    {label: 'VP of Sales', value: 'VP of Sales'},
    {label: 'Head of Marketing', value: 'Head of Marketing'},
    {label: 'Head of Sales', value: 'Head of Sales'},
    {label: 'Inside sales manager', value: 'Inside sales manager'},
  ])
  const [selectedRole, setSelectedRoles] = useState<string[]>([])
  const [flows, setFlows] = useState([])
  const [lists, setLists] = useState([])
  const [jobsPostingData, setJobsPostingData] = useState<
    {
      searchUrl: string
      flowId: string
      listId?: string
    }[]
  >([
    {
      searchUrl: '',
      flowId: '',
      listId: '',
    },
  ])
  const onChange = (checked: boolean) => {
    setMode(checked ? 'flows' : 'lists')
  }

  const listOptions = lists.map((list) => ({label: list['listName'], value: list['listId']}))
  const flowOptions = flows.map((flow) => ({label: flow['flowName'], value: flow['flowId']}))

  const handleSelectionChange = (value: string, index) => {
    if (mode === 'lists') {
      setSelectedList(value)
      const updatedJobsPostingData = [...jobsPostingData]
      updatedJobsPostingData[index].flowId = ''
      updatedJobsPostingData[index].listId = value
    } else {
      setSelectedFlow(value)
      const updatedJobsPostingData = [...jobsPostingData]
      updatedJobsPostingData[index].flowId = value
      updatedJobsPostingData[index].listId = ''
    }
  }
  const generateTimeOptions = () => {
    const options: string[] = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        options.push(`${formattedHour}:${formattedMinute}`)
      }
    }
    return options
  }

  useEffect(() => {
    getFlows()
    getLists()
    setTime(generateTimeOptions())
  }, [])

  async function getLists() {
    try {
      const response = await GetListApi(userId)
      if (response && response.success && response.data) {
        setLists(response.data)
      }
    } catch (err) {
      console.error('Error while getting the lists of user ' + err)
    }
  }

  const handleJobsPostingUrlChange = (value, index) => {
    const updatedJobsPostingData = [...jobsPostingData]
    updatedJobsPostingData[index].searchUrl = value
    setJobsPostingData(updatedJobsPostingData)
  }

  const handleFlowSelect = (value, index) => {
    const updatedJobsPostingData = [...jobsPostingData]
    updatedJobsPostingData[index].flowId = value
    setJobsPostingData(updatedJobsPostingData)
  }
  const getFlows = async () => {
    try {
      let data = await GetFlowListApi(userId)
      if (data.success) {
        setFlows(data.data)
      }
    } catch (err) {
      message.error('error while getting the accounts data')
      console.error('error while getting the accounts data')
    }
  }
  const addLiAtInput = () => {
    setLiAtList([...liAtList, ''])
  }
  const addJobPostingUrl = () => {
    setJobsPostingData([...jobsPostingData, {searchUrl: '', flowId: '', listId: ''}])
  }
  const removeJobPostingData = (index) => {
    const updatedJobsPostingData = [...jobsPostingData]
    updatedJobsPostingData.splice(index, 1)
    setJobsPostingData(updatedJobsPostingData)
  }
  const handleRoleSelect = (value: string[]) => {
    setSelectedRoles(value)
  }
  const removeLiAtInput = (index) => {
    const updatedLiAtList = [...liAtList]
    updatedLiAtList.splice(index, 1)
    setLiAtList(updatedLiAtList)
  }
  const handleScrapeJobsData = async () => {
    const userData = {
      liAt: liAtList[0],
      searchData: jobsPostingData,
      userId,
      title: flowTitle,
    }
    setModalVisible(false)
    console.log(userData)
    message.info('Automation has started, you will receive an email shortly')
    try {
      const res = await SalesPeopleSearchExportApi(userData)
    } catch (err) {
      console.error('Error visiting profile:', err)
      message.error('Please try again')
    } finally {
    }
  }

  return (
    <Flex vertical>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='linkedin'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={linkedinLogo}
            />
          </div>
        }
        onClick={() => setModalVisible(true)}
        style={{width: '220px', height: '220px', background: '#0077b5', cursor: 'pointer'}}
      >
        <Flex vertical gap={'middle'}>
          <span style={{color: 'white', fontWeight: '500', fontSize: '1.5em', lineHeight: '1.2'}}>
            Sales Navigator Leads Export
          </span>
          <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
            Collect the Leads data in few simple steps
          </span>
        </Flex>
      </Card>
      <Modal
        centered
        title='Enter LinkedIn Sales navigator details to get the Prospects information'
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='visit-profile'
            type='primary'
            disabled={
              !(liAtList.length > 0 && liAtList.some((li) => li !== '')) ||
              !jobsPostingData.every(
                (data) => data.searchUrl !== '' && (data.flowId || data.listId)
              ) ||
              !flowTitle
            }
            onClick={handleScrapeJobsData}
          >
            Get Leads
          </Button>,
        ]}
      >
        <Flex vertical gap={10}>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <span>Automation title</span>
            <Input
              placeholder='Enter the automation title'
              value={flowTitle}
              onChange={(e) => setFlowTitle(e.target.value)}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Flex gap={'4px'} align='center' justify='space-between'>
              <Flex gap={'4px'}>
                <label htmlFor={`liAtInput`}>Linkedin cookie</label>
                <Tooltip title='Your LinkedIn authentication token'>
                  <InfoCircleOutlined
                    style={{color: '#848484', fontSize: '12px'}}
                    rev={undefined}
                  />
                </Tooltip>
              </Flex>
            </Flex>
            {liAtList.map((liAt, index) => (
              <Flex key={index} justify='space-between' align='center' gap={'small'}>
                <Input
                  id={`liAtInput${index}`}
                  placeholder='Enter LinkedIn cookie'
                  value={liAt}
                  onChange={(e) => {
                    const updatedLiAtList = [...liAtList]
                    updatedLiAtList[index] = e.target.value
                    setLiAtList(updatedLiAtList)
                  }}
                />
              </Flex>
            ))}
          </div>
          <div>
            <Flex vertical gap={'middle'}>
              <Flex align='center' justify='space-between'>
                <Flex gap={'4px'}>
                  <label htmlFor='JobpostingUrl'>Input Search query</label>
                  <Tooltip title='Search query URL Ex: https://www.linkedin.com/sales/search/people?query=(recentSearchParam%3A(doLogHistory%3Atrue)%2Cfilters%3AList((type%3ACURRENT_COMPANY%2Cvalues%3AList((id%3Aurn%253Ali%253Aorganization%253A28744513%2Ctext%3ACrewscale%2CselectionType%3AINCLUDED%2Cparent%3A(id%3A0))))))&sessionId=xXBf7NC8QOytaq%2BKHc%2FBHg%3D%3D'>
                    <InfoCircleOutlined
                      rev={undefined}
                      style={{color: '#848484', fontSize: '12px'}}
                    />
                  </Tooltip>
                </Flex>
                <Button
                  type='primary'
                  onClick={addJobPostingUrl}
                  icon={<PlusOutlined rev={undefined} />}
                  size={'small'}
                ></Button>
              </Flex>
              {jobsPostingData.map((jobData, index) => (
                <div
                  key={index}
                  style={{display: 'flex', gap: '10px', justifyContent: 'space-between'}}
                >
                  <Flex gap={'middle'} style={{width: '100%'}} align='center'>
                    <Input
                      id={`JobpostingUrl${index}`}
                      placeholder='Enter Job search URL'
                      value={jobData.searchUrl}
                      onChange={(e) => handleJobsPostingUrlChange(e.target.value, index)}
                    />
                    <Select
                      allowClear
                      style={{width: '100%'}}
                      placeholder={
                        mode === 'lists' ? 'Please select a list' : 'Please select a flow'
                      }
                      value={mode === 'lists' ? selectedList : selectedFlow}
                      onChange={(value) => handleSelectionChange(value, index)}
                      options={mode === 'lists' ? listOptions : flowOptions}
                    />
                    <Switch onChange={onChange} />
                  </Flex>
                  {index > 0 && (
                    <Button
                      onClick={() => removeJobPostingData(index)}
                      icon={<MinusOutlined rev={undefined} />}
                    />
                  )}
                </div>
              ))}
            </Flex>
          </div>
        </Flex>
      </Modal>
    </Flex>
  )
}
export const LinkedinSalesCompanies = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [liAt, setLiAt] = useState('')
  const [jobsPostingUrl, setJobsPostingUrl] = useState('')
  const [linkedinMessage, setLinkedinMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [recurring, setRecurring] = useState<boolean>(false)
  const [oneTime, setOneTime] = useState<boolean>(false)
  const [time, setTime] = useState<string[]>(['00:00'])
  const [scheduleTime, setScheduleTime] = useState<string>('')
  const [result, setResult] = useState<'success' | 'failure' | null>(null)
  const [liAtList, setLiAtList] = useState([''])
  const [getJobPoster, setGetJobPoster] = useState<boolean>(false)
  const [mode, setMode] = useState<'lists' | 'flows'>('lists')
  const [flowTitle, setFlowTitle] = useState<string>('')
  const [selectedList, setSelectedList] = useState<string | null>(null)
  const [selectedFlow, setSelectedFlow] = useState<string | null>(null)
  const [options, setOptions] = useState<{label: string; value: string}[]>([
    {label: 'Head of Talent', value: 'Head of Talent'},
    {label: 'Lead Recruiter', value: 'Lead Recruiter'},
    {label: 'Head of Recruiting', value: 'Head of Recruiting'},
    {label: 'Chief Talent Officer', value: 'Chief Talent Officer'},
    {label: 'Talent Acquisition Partner', value: 'Talent Acquisition Partner'},
    {label: 'Talent Acquisition coordinator', value: 'Talent Acquisition coordinator'},
    {label: 'Talent Acquisition Specialist', value: 'Talent Acquisition Specialist'},
    {label: 'Recruiter', value: 'Recruiter'},
    {label: 'Recruitment Coordinator', value: 'Recruitment Coordinator'},
    {label: 'Head of Recruitment', value: 'Head of Recruitment'},
    {label: 'Talent Director', value: 'Talent Director'},
    {label: 'Corporate Recruiter', value: 'Corporate Recruiter'},
    {label: 'Recruiting Manager', value: 'Recruiting Manager'},
    {label: 'VP of Talent Acquisition', value: 'VP of Talent Acquisition'},
    {label: 'Cofounder', value: 'Cofounder'},
    {label: 'CEO', value: 'CEO'},
    {label: 'CTO', value: 'CTO'},
    {label: 'Founder', value: 'Founder'},
    {label: 'Chief Executive Officer', value: 'Chief Executive Officer'},
    {label: 'Chief Technology Officer', value: 'Chief Technology Officer'},
    {label: 'Marketing Manager', value: 'Marketing Manager'},
    {label: 'Sales Manager', value: 'Sales Manager'},
    {label: 'VP of Marketing', value: 'VP of Marketing'},
    {label: 'VP of Sales', value: 'VP of Sales'},
    {label: 'Head of Marketing', value: 'Head of Marketing'},
    {label: 'Head of Sales', value: 'Head of Sales'},
    {label: 'Inside sales manager', value: 'Inside sales manager'},
  ])
  const [selectedRole, setSelectedRoles] = useState<string[]>([])
  const [flows, setFlows] = useState([])
  const [lists, setLists] = useState([])
  const [jobsPostingData, setJobsPostingData] = useState<
    {
      searchUrl: string
      flowId: string
      listId?: string
    }[]
  >([
    {
      searchUrl: '',
      flowId: '',
      listId: '',
    },
  ])
  const onChange = (checked: boolean) => {
    setMode(checked ? 'flows' : 'lists')
  }

  const listOptions = lists.map((list) => ({label: list['listName'], value: list['listId']}))
  const flowOptions = flows.map((flow) => ({label: flow['flowName'], value: flow['flowId']}))

  const handleSelectionChange = (value: string, index) => {
    if (mode === 'lists') {
      setSelectedList(value)
      const updatedJobsPostingData = [...jobsPostingData]
      updatedJobsPostingData[index].flowId = ''
      updatedJobsPostingData[index].listId = value
    } else {
      setSelectedFlow(value)
      const updatedJobsPostingData = [...jobsPostingData]
      updatedJobsPostingData[index].flowId = value
      updatedJobsPostingData[index].listId = ''
    }
  }
  const generateTimeOptions = () => {
    const options: string[] = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        options.push(`${formattedHour}:${formattedMinute}`)
      }
    }
    return options
  }

  useEffect(() => {
    getFlows()
    getLists()
    setTime(generateTimeOptions())
  }, [])

  async function getLists() {
    try {
      const response = await GetListApi(userId)
      if (response && response.success && response.data) {
        setLists(response.data)
      }
    } catch (err) {
      console.error('Error while getting the lists of user ' + err)
    }
  }

  const handleJobsPostingUrlChange = (value, index) => {
    const updatedJobsPostingData = [...jobsPostingData]
    updatedJobsPostingData[index].searchUrl = value
    setJobsPostingData(updatedJobsPostingData)
  }

  const handleFlowSelect = (value, index) => {
    const updatedJobsPostingData = [...jobsPostingData]
    updatedJobsPostingData[index].flowId = value
    setJobsPostingData(updatedJobsPostingData)
  }
  const getFlows = async () => {
    try {
      let data = await GetFlowListApi(userId)
      if (data.success) {
        setFlows(data.data)
      }
    } catch (err) {
      message.error('error while getting the accounts data')
      console.error('error while getting the accounts data')
    }
  }
  const addLiAtInput = () => {
    setLiAtList([...liAtList, ''])
  }
  const addJobPostingUrl = () => {
    setJobsPostingData([...jobsPostingData, {searchUrl: '', flowId: '', listId: ''}])
  }
  const removeJobPostingData = (index) => {
    const updatedJobsPostingData = [...jobsPostingData]
    updatedJobsPostingData.splice(index, 1)
    setJobsPostingData(updatedJobsPostingData)
  }
  const handleRoleSelect = (value: string[]) => {
    setSelectedRoles(value)
  }
  const removeLiAtInput = (index) => {
    const updatedLiAtList = [...liAtList]
    updatedLiAtList.splice(index, 1)
    setLiAtList(updatedLiAtList)
  }
  const handleScrapeCompaniesData = async () => {
    const userData = {
      liAt: liAtList[0],
      searchData: jobsPostingData,
      userId,
      title: flowTitle,
    }
    setModalVisible(false)
    message.info('Automation has started, you will receive an email shortly')
    try {
      const res = await SalesAccountSearchExportApi(userData)
    } catch (err) {
      console.error('Error visiting profile:', err)
      message.error('Please try again')
    } finally {
    }
  }

  return (
    <Flex vertical>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='linkedin'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={linkedinLogo}
            />
          </div>
        }
        onClick={() => setModalVisible(true)}
        style={{width: '220px', height: '220px', background: '#0077b5', cursor: 'pointer'}}
      >
        <Flex vertical gap={'middle'}>
          <span style={{color: 'white', fontWeight: '500', fontSize: '1.5em', lineHeight: '1.2'}}>
            Sales Navigator Account Export
          </span>
          <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
            Collect the Accounts data in few simple steps
          </span>
        </Flex>
      </Card>
      <Modal
        centered
        title='Enter LinkedIn Sales navigator details to get the Account information'
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='visit-profile'
            type='primary'
            disabled={
              !(liAtList.length > 0 && liAtList.some((li) => li !== '')) ||
              !jobsPostingData.every((data) => data.searchUrl !== '') ||
              !flowTitle
            }
            onClick={handleScrapeCompaniesData}
          >
            Get Companies
          </Button>,
        ]}
      >
        <Flex vertical gap={10}>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <span>Automation title</span>
            <Input
              placeholder='Enter the automation title'
              value={flowTitle}
              onChange={(e) => setFlowTitle(e.target.value)}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Flex gap={'4px'} align='center' justify='space-between'>
              <Flex gap={'4px'}>
                <label htmlFor={`liAtInput`}>Linkedin Sales navigator cookie</label>
                <Tooltip title='Your LinkedIn authentication token which has a sales navigator account'>
                  <InfoCircleOutlined
                    style={{color: '#848484', fontSize: '12px'}}
                    rev={undefined}
                  />
                </Tooltip>
              </Flex>
            </Flex>
            {liAtList.map((liAt, index) => (
              <Flex key={index} justify='space-between' align='center' gap={'small'}>
                <Input
                  id={`liAtInput${index}`}
                  placeholder='Enter LinkedIn cookie '
                  value={liAt}
                  onChange={(e) => {
                    const updatedLiAtList = [...liAtList]
                    updatedLiAtList[index] = e.target.value
                    setLiAtList(updatedLiAtList)
                  }}
                />
              </Flex>
            ))}
          </div>
          <div>
            <Flex vertical gap={'middle'}>
              <Flex align='center' justify='space-between'>
                <Flex gap={'4px'}>
                  <label htmlFor='JobpostingUrl'>Input Search query</label>
                  <Tooltip title='Search query URL Ex: https://www.linkedin.com/sales/search/company?query=(filters%3AList((type%3ACOMPANY_HEADCOUNT%2Cvalues%3AList((id%3AC%2Ctext%3A11-50%2CselectionType%3AINCLUDED)))))&sessionId=gHUDnz1dSIa29lScERcBtg%3D%3D'>
                    <InfoCircleOutlined
                      rev={undefined}
                      style={{color: '#848484', fontSize: '12px'}}
                    />
                  </Tooltip>
                </Flex>
                <Button
                  type='primary'
                  onClick={addJobPostingUrl}
                  icon={<PlusOutlined rev={undefined} />}
                  size={'small'}
                ></Button>
              </Flex>
              {jobsPostingData.map((jobData, index) => (
                <div
                  key={index}
                  style={{display: 'flex', gap: '10px', justifyContent: 'space-between'}}
                >
                  <Flex gap={'middle'} style={{width: '100%'}} align='center'>
                    <Input
                      id={`JobpostingUrl${index}`}
                      placeholder='Enter Job search URL'
                      value={jobData.searchUrl}
                      onChange={(e) => handleJobsPostingUrlChange(e.target.value, index)}
                    />
                  </Flex>
                  {index > 0 && (
                    <Button
                      onClick={() => removeJobPostingData(index)}
                      icon={<MinusOutlined rev={undefined} />}
                    />
                  )}
                </div>
              ))}
            </Flex>
          </div>
        </Flex>
      </Modal>
    </Flex>
  )
}

// export const GetEmployees = () => {
//   const [modalVisible, setModalVisible] = useState(false)
//   const [liAt, setLiAt] = useState('')
//   const [companyUrl, setcompanyUrl] = useState('')
//   const [linkedinMessage, setLinkedinMessage] = useState('')
//   const [loading, setLoading] = useState(false)
//   const [result, setResult] = useState<'success' | 'failure' | null>(null)

//   const handleScrapeJobsData = async () => {
//     setLoading(true)
//     setModalVisible(false)
//     setResult(null)
//     try {
//       message.info('Started getting the data')
//       const res = await ScrapeEmployeesData(liAt, companyUrl)
//       if (res.length > 0) {
//         downloadCSV(res)
//         setResult('success')
//         message.success('Got the list and downloaded')
//       } else {
//         message.error('Error while getting the employees')
//         setResult('failure')
//       }
//     } catch (err) {
//       message.error('Error while getting the employees')
//       console.error('Error visiting profile:', err)
//       setResult('failure')
//     } finally {
//       setLoading(false)
//       setLiAt('')
//       setcompanyUrl('')
//       setLinkedinMessage('')
//       setTimeout(() => {
//         setResult(null)
//       }, 5000)
//     }
//   }
//   const downloadCSV = (data) => {
//     const csv = Papa.unparse(data)
//     const blob = new Blob([csv], {type: 'text/csv'})
//     const url = window.URL.createObjectURL(blob)
//     const link = document.createElement('a')
//     link.href = url
//     link.setAttribute('download', 'CompanyEmployees.csv')
//     document.body.appendChild(link)
//     link.click()
//     window.URL.revokeObjectURL(url)
//   }
//   return (
//     <Flex vertical>
//       <Card
//         hoverable
//         cover={
//           <div
//             style={{
//               width: '40px',
//               height: '40px',
//               background: 'white',
//               borderBottomRightRadius: '25px',
//             }}
//           >
//             <img
//               alt='linkedin'
//               style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
//               src={linkedinLogo}
//             />
//           </div>
//         }
//         onClick={() => setModalVisible(true)}
//         style={{width: '220px', height: '220px', background: '#0077b5', cursor: 'pointer'}}
//       >
//         <Flex vertical gap={'middle'}>
//           <span style={{color: 'white', fontWeight: '500', fontSize: '1.5em', lineHeight: '1.2'}}>
//             Scrape Company Employees
//           </span>
//           <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
//             Fetch all the Employees data into a csv in one click
//           </span>
//           {/* {loading && <Spin size='small' style={{marginLeft: '5px'}} />}
//           {result === 'success' && <span style={{color: 'green', marginLeft: '5px'}}>✔</span>}
//           {result === 'failure' && <span style={{color: 'red', marginLeft: '5px'}}>✘</span>} */}
//         </Flex>
//       </Card>
//       <Modal
//         title='Enter LinkedIn details getting Jobs posting'
//         open={modalVisible}
//         onCancel={() => setModalVisible(false)}
//         footer={[
//           <Button key='cancel' onClick={() => setModalVisible(false)}>
//             Cancel
//           </Button>,
//           <Button
//             key='visit-profile'
//             type='primary'
//             disabled={!liAt || !companyUrl}
//             onClick={handleScrapeJobsData}
//           >
//             Scrape Employees
//           </Button>,
//         ]}
//       >
//         <Flex vertical gap={10}>
//           <div>
//             <Flex gap={'4px'}>
//               <label htmlFor='liAtInput'>li_at</label>
//               <Tooltip title='Your LinkedIn authentication token'>
//                 <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
//               </Tooltip>
//             </Flex>
//             <Input
//               size='large'
//               id='liAtInput'
//               placeholder='Enter li_at'
//               value={liAt}
//               onChange={(e) => setLiAt(e.target.value)}
//             />
//           </div>
//           <div>
//             <Flex gap={'4px'}>
//               <label htmlFor='JobpostingUrl'>Company URL</label>
//               <Tooltip title='Company URL whose employee data to be scraped'>
//                 <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
//               </Tooltip>
//             </Flex>
//             <Input
//               size='large'
//               id='JobpostingUrl'
//               placeholder='Enter Company URL'
//               value={companyUrl}
//               onChange={(e) => setcompanyUrl(e.target.value)}
//             />
//           </div>
//         </Flex>
//       </Modal>
//     </Flex>
//   )
// }
