import {GetUserSettingsApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'

export const handleGetUserSettings = async ({message, setProfileObj}) => {
  try {
    let data = await GetUserSettingsApi(userId)
    if (data && data.success && data.data) {
      let profile = data.data
      if (profile) setProfileObj(profile)
    } else {
      message.error('Something went wrong ')
    }
  } catch (err) {
    message.error('Something went wrong ')
    console.error('Error while making the request ' + err)
  }
}
