import React from 'react'
import {Flex} from 'antd'
import LinkedinScrape from '../Reusablecomponents/LinkedinScrape'
import FacebookGroupmessage from '../Subcomponents/FacebookGroup'
import SaasBoomiScrape from '../Subcomponents/SaasBoomiScrape'
import UpworkScrape from '../Subcomponents/UpworkScrape'
import TwitterCards from '../Reusablecomponents/TwitterCards'

const AutomationPage = () => {
  return (
    <div style={{padding: '10px', overflowY: 'scroll', height: '100vh'}}>
      <Flex vertical gap={'middle'}>
        <LinkedinScrape />
        <FacebookGroupmessage />
        <TwitterCards />
        <div>
          <span style={{fontWeight: 'bold', fontSize: '1.6em'}}>Coming Soon</span>
          <Flex align='center' gap={'middle'} style={{opacity: '0.5', pointerEvents: 'none'}}>
            <SaasBoomiScrape />
            <UpworkScrape />
          </Flex>
        </div>
      </Flex>
    </div>
  )
}

export default AutomationPage
