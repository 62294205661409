import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {useAuthContext} from '../../../context/AuthContext'
import {sendPasswordResetEmail} from 'firebase/auth'
import {auth} from '../../../../firebase'
import {useNavigate} from 'react-router-dom'
import {Card} from 'react-bootstrap'
import {notification, Input, Button, Flex} from 'antd'
import {ForgotPasswordApi} from '../../../apicalls/Authentication'
import {getUTMParameters} from '../../../components/UTM/utm'
const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [showMessage, setShowMessage] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {passwordReset} = useAuthContext()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (placement, type, message, description) => {
    api[type]({
      message: message,
      description: description,
      placement,
    })
  }
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let sendForgotToken = await ForgotPasswordApi(values.email)

        if (sendForgotToken && sendForgotToken.success) {
          localStorage.setItem('userId', JSON.stringify(values.email))
          openNotification(
            'topRight',
            'info',
            `Reset code has been sent to your email `,
            `Use that to reset the password`
          )
          navigate('/confirmforgotpassword')
        } else {
          openNotification(
            'topRight',
            'error',
            'Error',
            sendForgotToken.data.message || 'Something went wrong!'
          )
        }
      } catch (err) {
        openNotification('topRight', 'error', 'Error', 'Something went wrong!')
        console.error('Error while sending the code confirm password ', err)
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      {contextHolder}
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div>
      </div>
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your email</div>
        </div>
      )}
      <div className='fv-row mb-8'>
        <Input
          size='large'
          type='email'
          status={formik.errors.email ? 'error' : ''}
          placeholder='Enter your email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <Flex gap={'middle'} justify='center'>
        <Link to='/'>
          <Button id='kt_login_password_reset_form_cancel_button'>Cancel</Button>
        </Link>{' '}
        <Button
          disabled={
            formik.isSubmitting ||
            !formik.isValid ||
            Object.values(formik.values).some((value) => value === '')
          }
          type='primary'
          htmlType='submit'
          loading={loading}
          id='kt_password_reset_submit'
        >
          <span className='indicator-label'>Send reset code</span>
        </Button>
      </Flex>
      {showMessage && (
        <div
          style={{
            width: '100%',
            backgroundColor: '#acacac57',
            height: '100%',
            position: 'fixed',
            left: '0',
            top: '0',
            zIndex: '5',
          }}
        ></div>
      )}
      {showMessage && (
        <Card
          className='d-flex align-items-center justify-content-center'
          style={{
            position: 'absolute',
            zIndex: '10',
            width: '28%',
            top: '40%',
            left: '40%',
            height: '20vh',
            padding: '2%',
          }}
        >
          <span className='fw-bold fs-2'>We have sent a reset link to your mail</span>
          <span className='fw-semibold text-muted text-align-end w-100'>
            This pop will be closed automatically
          </span>
        </Card>
      )}
      {/* end::Form group */}
    </form>
  )
}
