import React, {createContext, useContext, useState} from 'react'

interface EnrichContextProps {
  enrichTableData: any[]
  setEnrichTableData: (data: any[]) => void
}

const EnrichContext = createContext<EnrichContextProps | undefined>(undefined)

const EnrichContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [enrichTableData, setEnrichTableData] = useState<any[]>([])
  let value = {
    enrichTableData,
    setEnrichTableData,
  }

  return <EnrichContext.Provider value={value}>{children}</EnrichContext.Provider>
}

const useEnrichContext = () => {
  const context = useContext(EnrichContext)
  if (!context) {
    throw new Error('useEnrichContext must be used within an EnrichContextProvider')
  }
  return context
}

export {useEnrichContext, EnrichContextProvider, EnrichContext}
