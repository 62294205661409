import React, {useContext, createContext, FC, useEffect, useState} from 'react'
import {
  signInWithPopup,
  sendEmailVerification,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth'
import {auth, provider} from '../../firebase'
import {login} from '../modules/auth/core/_requests'
import {useAuth} from '../modules/auth'
import {setUserLoginApi} from '../apicalls/ApiCall'
import {LoginUserData, SignInUserWithGoogle, SignUpUserData} from '../apicalls/Authentication'

// import {useNavigate} from 'react-router-dom'
const UserAuthContext = createContext()

const AuthContextProvider = ({children}) => {
  const [user, setUser] = useState({})
  const {saveAuth, setCurrentUser} = useAuth()
  const [toast, setToast] = useState(false)
  const [error, setError] = useState('')
  // const navigate = useNavigate()
  // Set the flag to trigger a page reload
  localStorage.setItem('reloadPage', 'true')

  const googleSignIn = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const currentUser = result.user
        console.log(result)
        if (currentUser) {
          let obj = {
            userId: currentUser.uid,
            email: currentUser.email,
            photoUrl: currentUser.photoURL,
            userName: currentUser.displayName,
            password: '',
            planName: 'Free Plan',
          }

          try {
            let data = await SignInUserWithGoogle(currentUser.uid)
            console.log('data for google auth ', data)
            if (data.success) {
              if (data.data === null) {
                let newUser = await SignUpUserData(obj)
                if (newUser.data) {
                  // setUser(currentUser)
                  // localStorage.setItem('userDefaultData', JSON.stringify(obj))
                  // localStorage.setItem('kt_theme_mode_value', 'light')
                  // localStorage.setItem('userLogin', JSON.stringify(newUser.data))
                  // saveAuth({
                  //   api_token: 'd6226e1f989dff0d46243dafc82903447b75a55147749d807d0c3590ff8a2a7d',
                  // })
                  // setTimeout(() => {
                  //   window.location.reload()
                  // }, 1000)
                }
              } else {
                if (data.data) {
                  
                  // setUser(currentUser)
                  // localStorage.setItem('userDefaultData', JSON.stringify(obj))
                  // localStorage.setItem('kt_theme_mode_value', 'light')
                  // saveAuth({
                  //   api_token: 'd6226e1f989dff0d46243dafc82903447b75a55147749d807d0c3590ff8a2a7d',
                  // })
                  // setTimeout(() => {
                  //   window.location.reload()
                  // }, 1000)
                }
              }
            }
          } catch (err) {
            console.log('Error at Google sign in with db', err)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const actionCodeSettings = {
    url: 'http://localhost:3011/auth',
    handleCodeInApp: true,
  }
  const passwordReset = (email) => {
    sendPasswordResetEmail(auth, email)
      .then((val) => {
        console.log(val)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const emailLogin = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        setToast(false)
        setError('')
        if (userCredential.user) {
          setUser(userCredential.user)
          let obj = {
            userId: userCredential.user.uid,
            email: userCredential.user.email,
            userName: userCredential.user.displayName,
            photoUrl: userCredential.user.photoURL ? userCredential.user.photoURL : '',
            password: '',
            planName: 'Free Plan',
          }
          try {
            let data = await LoginUserData(userCredential.user.uid)
            if (data.success) {
              // {succes:true,data:null}
              if (data.data === null) {
                let newUser = await SignUpUserData(userCredential.user.uid, obj)
                if (newUser) {
                  // make the api call to set the userID loginstatus as true
                  localStorage.setItem('userDefaultData', JSON.stringify(obj))
                  localStorage.setItem('kt_theme_mode_value', 'light')

                  saveAuth({
                    api_token: 'd6226e1f989dff0d46243dafc82903447b75a55147749d807d0c3590ff8a2a7d',
                  })
                  window.location.reload()
                }
              }
              if (data.data) {
                // make the api call to set the userID loginstatus as true
                localStorage.setItem('userDefaultData', JSON.stringify(obj))
                localStorage.setItem('kt_theme_mode_value', 'light')
                saveAuth({
                  api_token: 'd6226e1f989dff0d46243dafc82903447b75a55147749d807d0c3590ff8a2a7d',
                })
                window.location.reload()
              }
            }
          } catch (err) {
            console.log('Error from api.beanbag ' + err)
          }
        }
      })
      .catch((error) => {
        console.log('Error from backend ' + error)
        setToast(true)
        setError('Invalid credentials')
        saveAuth(undefined)
      })
  }

  const emailsignUp = async (email, password, firstname, lastname) => {
    const payLoad = {
      email,
      password,
      firstname,
      lastname,
    }
    const signUpRes = await SignUpUserData(payLoad)
    if (signUpRes.success) {
      localStorage.setItem('loginToken', signUpRes.data.token)
      // navigate('/dashboard')
    } else {
    }
    // let first = firstname
    // let pass = password
    // createUserWithEmailAndPassword(auth, email, password)
    //   .then(async (userCredential) => {
    //     const user = userCredential.user
    //     console.log(user)
    //     if (user) {
    //       setUser(user)
    //       let obj = {
    //         userId: user.uid,
    //         email: user.email,
    //         photoUrl: user.photoURL,
    //         userName: first,
    //         password: pass,
    //         planName: 'Free Plan',
    //       }
    //       let data = await SignUpUserData(user.uid, obj)
    //       console.log(data)
    //       if (data.success) {
    //         if (data.data) {
    //           // make the api call to set the userID loginstatus as true
    //           localStorage.setItem('userDefaultData', JSON.stringify(obj))
    //           localStorage.setItem('kt_theme_mode_value', 'light')
    //           saveAuth({
    //             api_token: 'd6226e1f989dff0d46243dafc82903447b75a55147749d807d0c3590ff8a2a7d',
    //           })
    //           window.location.reload()
    //         }
    //       }
    //     } else if (user) {
    //       sendEmailVerification(user)
    //       console.log('Email verification sent. Please verify your email.')
    //       saveAuth(undefined)
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     saveAuth(undefined)
    //   })
  }
  function ResetPassword(oobCode, newPassword) {
    confirmPasswordReset(auth, oobCode, newPassword)
  }

  const signOutFromGoogle = () => {
    signOut(auth)
  }

  const values = {
    googleSignIn,
    user,
    signOutFromGoogle,
    emailLogin,
    emailsignUp,
    passwordReset,
    ResetPassword,
    error,
    setError,
    toast,
    setToast,
  }

  return <UserAuthContext.Provider value={values}>{children}</UserAuthContext.Provider>
}

const useAuthContext = () => {
  return useContext(UserAuthContext)
}
export {useAuthContext, AuthContextProvider}
