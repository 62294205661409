import React, {FC, useEffect, useState} from 'react'
import {Card} from 'react-bootstrap'
import templateStyle from '../styles/newTemplate.module.css'
import {useEmailContext} from '../context/emailContext'
import {Input, Tabs, Empty, Button} from 'antd'
import {PlusCircleOutlined} from '@ant-design/icons'
type Template = {
  templateName: string
  subject: string
  content: string
}

// EmailTemplate component for displaying and selecting email templates.
const EmailTemplate = ({setShowTemplates}) => {
  const {setContent, setSub, setTemplateValue, setShowEmailTemplate, allTemplates} =
    useEmailContext()
  const [selectedKey, setSelectedKey] = useState('stockTemplates')
  const [templates, setTemplates] = useState<Template[]>([])
  const [key, setKey] = useState<string>('')
  useEffect(() => {
    if (allTemplates) setTemplates(allTemplates['stockTemplates'])
  }, [allTemplates])
  const handleTemplateChange = (key: string) => {
    if (allTemplates) {
      setSelectedKey(key)
      setTemplates(allTemplates[key])
      console.log(allTemplates[key])
    }
  }
  console.log(allTemplates ? allTemplates[selectedKey] : "")
  const [searchText, setSearchText] = useState('')

  const handleTemplateSelect = (index: number) => {
    setTemplateValue(templates[index].templateName)
    setSub(templates[index].subject)
    setContent(templates[index].content)
    setShowTemplates(false)
  }

  const filteredTemplates = templates.filter((template) =>
    template.templateName?.toLowerCase().includes(searchText.toLowerCase())
  )
  const onChange = (key: string) => {
    if (allTemplates) {
      console.log(key)
      setSelectedKey(key)
      setTemplates(allTemplates[key])
      setKey(key)
    }
  }
  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase()
    setSearchText(inputValue)
    if (allTemplates)
      if (inputValue.length === 0) {
        setTemplates(allTemplates[selectedKey])
      } else {
        const filteredTemplates = allTemplates[selectedKey].filter((template) =>
          template.templateName?.toLowerCase().includes(inputValue)
        )
        setTemplates(filteredTemplates)
      }
  }

  return (
    <div style={{gap: '10px'}} className='d-flex flex-column mt-5 p-2'>
      <div
        style={{position: 'relative'}}
        className='d-flex align-items-center justify-content-between'
      >
        <Input
          size='large'
          type='text'
          className='w-50'
          placeholder='Search templates'
          value={searchText}
          suffix={<i className='bi bi-search'></i>}
          onChange={(e) => handleSearch(e)}
        />
        <div>
          <Tabs
            defaultActiveKey='stockTemplates'
            items={
              allTemplates &&
              Object.keys(allTemplates).map((templateName, index) => ({
                key: templateName,
                label: templateName.charAt(0).toUpperCase() + templateName.slice(1),
              }))
            }
            onChange={onChange}
          />
        </div>
      </div>

      {templates && templates.length > 0 ? (
        templates.map((template, index) => (
          <Card key={index}>
            <div
              className={`${templateStyle.tempName} rounded d-flex align-items-center w-100 justify-content-between`}
            >
              <div>
                <span className='fw-bold fs-5'>Template Name:</span>
                <span className='mx-2 fw-semibold'>{template.templateName}</span>
              </div>
              <Button
                onClick={() => handleTemplateSelect(index)}
                type='primary'
                icon={<PlusCircleOutlined rev={undefined} />}
              ></Button>
            </div>
            <div className={`${templateStyle.subject}`}>
              <span className='fw-bold fs-5'>Subject:</span>
              <span className='mx-2'>{template.subject}</span>
            </div>
            <div className={templateStyle.content}>
              <span className='fw-bold fs-5'>Content:</span>
              <pre className=' fs-7' style={{fontFamily: 'Work Sans, sans-serif'}}>
                {template.content}
              </pre>
            </div>
          </Card>
        ))
      ) : (
        <Empty />
      )}
    </div>
  )
}

export default EmailTemplate
