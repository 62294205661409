import React from 'react'
import {Tabs, List, Flex} from 'antd'
import type {TabsProps} from 'antd'
import {FundingType} from '../DataTypes/CompanyDatatype'

interface ListComponentProps {
  listElements: string[]
  categories: {[key: string]: number}
  funding: FundingType[]
}

const ListComponent: React.FC<ListComponentProps> = ({listElements, categories, funding}) => {
  console.log(categories)
  const categoriesArray = Object.entries(categories).map(([key, value]) => ({key, value}))
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span className='heading'>{'Technologies'}</span>,
      children: (
        <div style={{maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}>
          <List
            dataSource={listElements}
            renderItem={(item: string) => (
              <List.Item className='w-100' style={{fontFamily: "'Karla',sans-serif"}}>
                {item}
              </List.Item>
            )}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: <span className='heading'>Departments</span>,
      children: (
        <div style={{maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}>
          <List
            dataSource={categoriesArray}
            renderItem={(item: {key: string; value: number}) => (
              <List.Item
                className='w-100'
                style={{fontFamily: "'Karla',sans-serif", textDecoration: ''}}
              >
                {`${item.key.charAt(0).toUpperCase() + item.key.slice(1)}: ${item.value}`}
              </List.Item>
            )}
          />
        </div>
      ),
    },
    {
      key: '3',
      label: <span className='heading'>Funding</span>,
      children: (
        <div style={{maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}>
          <List
            dataSource={funding}
            renderItem={(item: FundingType) => (
              <List.Item
                className='w-100'
                style={{fontFamily: "'Karla',sans-serif", textDecoration: ''}}
              >
                <Flex vertical gap={'small'}>
                  <div>News link: {item.news_url}</div>
                  <div>Type: {item.type}</div>
                  <div>Investors: {item.investors}</div>
                  <div>
                    Amount: {item.amount} {item.currency}
                  </div>
                </Flex>
              </List.Item>
            )}
          />
        </div>
      ),
    },
  ]

  return (
    <div className='px-3 w-100' style={{height: '100%', overflowY: 'hidden'}}>
      <Tabs
        defaultActiveKey='1'
        items={items}
        style={{position: 'sticky', top: 0, zIndex: 1, background: '#fff'}}
      />
    </div>
  )
}

export default ListComponent
