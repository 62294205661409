import {FC, useState, createContext, useContext} from 'react'
interface TimeZone {
  timezone: string
  time: string
}
type SettingContextProp = {
  timeZone: TimeZone[]
  setTimeZone: (timeZone: TimeZone[]) => void
  selectedDays: string[]
  setSelectedDays: (selectedDays: string[]) => void
  daysInWeek: string[]
  setDaysInWeek: (daysInWeek: string[]) => void
}
const SettingContext = createContext<SettingContextProp | undefined>(undefined)

const SettingProvider = ({children}) => {
  const [timeZone, setTimeZone] = useState<TimeZone[]>([
    {timezone: 'America/New_York', time: '10:00 AM'},
    {timezone: 'America/Los_Angeles', time: '7:00 AM'},
    {timezone: 'Europe/London', time: '3:00 PM'},
  ])
  const [selectedDays, setSelectedDays] = useState<string[]>(['Mon', 'Tue', 'Wed', 'Thu', 'Fri'])
  const [daysInWeek, setDaysInWeek] = useState<string[]>([
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
  ])
  let value = {timeZone, setTimeZone, selectedDays, setSelectedDays, daysInWeek, setDaysInWeek}
  return <SettingContext.Provider value={value}>{children}</SettingContext.Provider>
}
const useSettingContext = () => {
  const useSetting = useContext(SettingContext)
  if (!useSetting) throw new Error('Error in setting context')
  return useSetting
}
export {useSettingContext, SettingProvider}
