import {userId} from '../DataTypes/UserDataTypes'
import {GetCreditsApi} from '../../apicalls/ApiCall'
export const GetCredits = async (
  setEmailCredits,
  setEmailTotalCredits,
  setPhoneCredits,
  setPhoneTotalCredits
) => {
  try {
    let creditsres = await GetCreditsApi(userId)
    if (creditsres && creditsres.success && creditsres.data) {
      setEmailCredits(creditsres.data.usedEmailCredit)
      setPhoneCredits(creditsres.data.usedMobileCredit)
      setEmailTotalCredits(creditsres.data.emailTotalCredit)
      setPhoneTotalCredits(creditsres.data.mobileTotalCredit)
    }
  } catch (err) {
    console.error('Error while getting the credits of user')
  }
}
