import axios from 'axios'
import {MessageModel} from '../../_metronic/helpers'
const APILINK = 'https://api.beanbag.ai'
const CompanyAPi = 'https://staging.beanbag.ai'
// https://api.beanbag.ai
// http://127.0.0.1:8000
// const APILINK = 'https://phm58bxr-8000.inc1.devtunnels.ms'

async function NewChatApi(sessionid) {
  try {
    const response = await axios.post(`${APILINK}/newChat`, {id: sessionid})
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

export const VerifyEmailAPi = async (userId, obj) => {
  try {
    const response = await axios.post(`${APILINK}/emailVerification/${userId}`, obj)
    return response.data
  } catch (err) {
    console.error('Error while verifying the email ' + err)
  }
}
export const SingleEmailHistoryAPi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/emailVerificationList/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while verifying the email ' + err)
  }
}
export const VerifyBulkEmailAPi = async (userId, obj) => {
  try {
    const response = await axios.post(`${APILINK}/emailVerificationListCsv/${userId}`, obj)
    return response.data
  } catch (err) {
    console.error('Error while verifying the email ' + err)
  }
}
export const BulkEmailHistoryAPi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/emailVerificationListCsv/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while verifying the email ' + err)
  }
}
export const BulkEmailDownloadAPi = async (userId, excelId) => {
  try {
    const response = await axios.get(`${APILINK}/emailVerificationCsv/${userId}/${excelId}`)
    return response.data
  } catch (err) {
    console.error('Error while verifying the email ' + err)
  }
}
export const SaveLimitsApi = async (userId, limitsData) => {
  try {
    const response = await axios.post(`${APILINK}/user/updateLimits/${userId}`, limitsData)
    return response.data
  } catch (err) {
    console.error('Error while saving the limits ' + err)
  }
}
export const GetUserLimitsApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/getLimits/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the limits ' + err)
  }
}
export const SaveUserInformationApi = async (userId, Obj) => {
  try {
    const response = await axios.post(`${APILINK}/user/moreInfo/${userId}`, Obj)
    return response.data
  } catch (err) {
    console.error('Error while saving the personal user information ', err)
  }
}
export const GetUserInfoApi = async (linkedInurl, userId, companyName, firstName, lastName) => {
  try {
    let response = await axios.post(`${APILINK}/user/getContactInfo/${userId}`, {
      linkedInurl: linkedInurl,
      companyName: companyName,
      firstName: firstName,
      lastName: lastName,
    })
    return response.data
  } catch (Err) {
    console.error('Error while getting the userinfo' + Err)
  }
}
export const GetAccountsLimitApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/${userId}/getAccountLimits`)
    return response.data
  } catch (err) {
    console.error('Error while getting account limits ', err)
  }
}

export const UpdateAccountsLimitApi = async (userId, limitObj) => {
  try {
    let response = await axios.post(`${APILINK}/user/${userId}/updateAccountLimits`, limitObj)
    return response.data
  } catch (err) {
    console.error('Error while getting account limits ', err)
  }
}
export const StartConversationMail = async (userId, obj) => {
  try {
    const response = await axios.post(`${APILINK}/conversations/mail/${userId}`, obj)
    return response.data
  } catch (err) {
    console.error('Error while starting the conversation mail ', err)
  }
}
export const GetCompanyWebsiteApi = async (linkedinUrl, companyName, userId) => {
  try {
    let response = await axios.post(`${APILINK}/profile/newCompanyData/${userId}`, {
      linkedinUrl: linkedinUrl,
      companyName: companyName,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the company responsee ', err)
  }
}
export const UpdateProfileObject = async (userId, Obj) => {
  try {
    let response = await axios.post(`${APILINK}/`, Obj)
    return response.data
  } catch (err) {
    console.error('Error while updating the profile object')
  }
}
export const GetLinkedinAnalyticsApi = async (userId, flowId) => {
  try {
    const response = await axios.post(`${APILINK}/getLinkedinAnalytics/${userId}/${flowId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the linkedin analytics ' + err)
  }
}
// Validate API
export const GetDataWithApikey = async (userId, apikey) => {
  try {
    const response = await axios.get(`${APILINK}/api/validate/${userId}/${apikey}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the validation api key ', err)
  }
}
export const UpdateValidApikey = async (userId, obj) => {
  try {
    const response = await axios.post(`${APILINK}/api/validate/update/${userId}`, obj)
    return response.data
  } catch (err) {
    console.error('Error while getting the validation api key ', err)
  }
}
export const GetExistingUserApi = async (linkedinUrl, userId) => {
  try {
    let response = await axios.post(`${APILINK}/user/getContactDetails/${userId}`, {
      linkedInurl: linkedinUrl,
    })
    return response.data
  } catch (err) {
    console.log('Error while getting the existing user data ' + err)
  }
}
export const DownloadCsvApi = async (userId, enrichId) => {
  try {
    let response = await axios.get(`${APILINK}/user/getEnrichData/${userId}/${enrichId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the csv data for enrich ment ', err)
  }
}

// Team settings API calls
export const GetTeamMembersApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/team/getMembers/${userId}`)
    return response.data
  } catch (err) {
    console.error('error while getting the team members API ', err)
  }
}
export const UpdateTeamMateRoleApi = async (userId, Obj) => {
  try {
    const response = await axios.post(`${APILINK}/user/team/updateRole/${userId}`, {team: Obj})
    return response.data
  } catch (err) {
    console.error('Error while updating the role of team member ', err)
  }
}
export const DeleteTeamMemberApi = async (userId, teamMemId) => {
  try {
    const response = await axios.delete(`${APILINK}/user/team/deleteMember/${userId}/${teamMemId}`)
    return response.data
  } catch (err) {
    console.error('Error while deleting the team member ', err)
  }
}
export const SendInvitationApi = async (userId, obj) => {
  try {
    const response = await axios.post(`${APILINK}/user/inviteTeamMember/${userId}`, obj)
    return response.data
  } catch (err) {
    console.error('Error while inviting the team member', err)
  }
}
export const UpdateCreditsApi = async (userId, teamMateId, Obj) => {
  try {
    const response = await axios.post(
      `${APILINK}/user/team/updateCredits/${userId}/${teamMateId}`,
      Obj
    )
    return response.data
  } catch (err) {
    console.error('Error while updating the credits ', err)
  }
}
export const GetUsersSpilledDataApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/getSpilledData/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the spilled information ', err)
  }
}
export const setUserLoginApi = async (userId, loginData) => {
  try {
    const response = await axios.post(`${APILINK}/setLogin/${userId}`, loginData)
    return response.data
  } catch (err) {
    console.log('Error while checking the user login status ' + err)
  }
}
export const GetUserSearchKeyApi = async (keyword: string) => {
  try {
    let response = await axios.get(`${APILINK}/keywordsearch/${keyword}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the user data from db ' + err)
  }
}
export const GetTimeZoneApi = async () => {
  try {
    let response = await axios.get(`${APILINK}/timeZones`)
    return response.data
  } catch (err) {
    console.error('Error while getting the user data from db ' + err)
  }
}
export const GetFlowSettings = async (userId, flowId) => {
  try {
    let response = await axios.get(`${APILINK}/user/getflowsettings/${userId}/${flowId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the flow settings' + err)
  }
}
export const UpdateFlowSettings = async (userId, flowId, settingsObj) => {
  try {
    let response = await axios.post(
      `${APILINK}/user/updateflowsettings/${userId}/${flowId}`,
      settingsObj
    )
    return response.data
  } catch (err) {
    console.error('error while updating the flow settings ' + err)
  }
}

export const LogoutUser = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/logout/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while logging out ', err)
  }
}
export const SigninUserWithLinkedinAuthApi = async (data) => {
  try {
    let response = await axios.post(`${APILINK}/auth/linkedinLogin`, data)
    return response.data
  } catch (err) {
    console.error('Error while signing with linkedinAuth' + err)
  }
}
export const LoginDefaultUser = async (userId, userObj) => {
  try {
    let response = await axios.post(`${APILINK}/login/${userId}`, userObj)
    return response.data
  } catch (err) {
    console.error('Error while validating the user' + err)
  }
}
export const GetCollegeLogo = async (collegeName) => {
  try {
    let response = await axios.get(`${APILINK}/profile/collegeLogo/${collegeName}`)
    return response.data
  } catch (err) {
    console.error('Error while getting college logo' + err)
  }
}
async function InteractionWithChatApi(chatid, chats: MessageModel[]) {
  try {
    const response = await axios.post(`${APILINK}newChat/chats`, {id: chatid, chats})
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const UpdateDataRequestSettingsApi = async (userId, settingsObj) => {
  try {
    const response = await axios.post(`${APILINK}/user/dataRequestType/${userId}`, settingsObj)
    return response.data
  } catch (err) {
    console.error('Error while updating the data request settings ' + err)
  }
}
export const GetDataRequestSettingApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/dataRequestType/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while  fetching Data Request Settings ' + err)
  }
}

// Search query API
export const SearchWithFiltersApi = async (userId, filterObj) => {
  try {
    const response = await axios.post(`${APILINK}/search/filters/${userId}`, filterObj)
    return response.data
  } catch (err) {
    console.error('Error while getting the profiles')
  }
}
//Generate API keys
export const GetUserApiKeys = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/apiKeys/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while  fetching api key details', err)
  }
}
export const GenerateNewApiKey = async (userId, ApiObj) => {
  try {
    const response = await axios.post(`${APILINK}/user/generateApiKey/${userId}`, ApiObj)
    return response.data
  } catch (err) {
    console.error('Error while generating api key', err)
  }
}
export const UpdateApiName = async (userId, ApiObj) => {
  try {
    const response = await axios.post(`${APILINK}/user/updateApiObj/${userId}`, ApiObj)
    return response.data
  } catch (err) {
    console.error('Error while generating api key', err)
  }
}
export const DeleteApiKey = async (userId, apiId) => {
  try {
    const response = await axios.delete(`${APILINK}/user/deleteApikey/${userId}/${apiId}`)
    return response.data
  } catch (err) {
    console.error('Error while generating api key', err)
  }
}

//Upload multiple profiles through csv
export const UploadProfilesThroughcsv = async (userId, obj) => {
  try {
    const response = await axios.post(`${APILINK}/user/uploadlinkedin/csvData/${userId}`, obj)
    return response.data
  } catch (err) {
    console.error('Error while uploading csv profiles by admin ', err)
  }
}

export const UploadCSVProfilesToListApi = async (userId, listId, csvProfiles, mappingObject) => {
  try {
    const response = await axios.post(`${APILINK}/list/csvUpload/${userId}/${listId}`, {
      csvProfiles,
      mappingObject,
    })
    return response.data
  } catch (err) {
    console.error('Error while uploading csv profiles by admin ', err)
  }
}
async function SearchQueryButtonApi(queryObj, searchId, userId) {
  try {
    const response = await axios.post(
      `${APILINK}/user/searchHistory/searchId/searchQuery/${searchId}/${userId}`,
      queryObj
    )
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const UpdateEmailAccountsLinkedApi = async (loginObj, userId) => {
  try {
    const response = await axios.post(`${APILINK}/user/updateEmailAccounts/${userId}`, loginObj)
    return response.data
  } catch (err) {
    console.error('Error while adding new account Link' + err)
  }
}
export const GetAllFlowReports = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/allFlowReport/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting all flow reports' + err)
  }
}
//Detailed Analytics
export const AddProfileToFlowFromAnalyticsApi = async (userId, flowId, profileKeys) => {
  try {
    const response = await axios.post(
      `${APILINK}/flow/analytics/addProfiles/${userId}/${flowId}`,
      profileKeys
    )
    return response.data
  } catch (err) {
    console.error('Error while adding the profiles to flow')
  }
}
export const GetMoreAnalyticsDetailsApi = async (userId, flowId) => {
  try {
    const response = await axios.get(`${APILINK}/flow/getMoreAnalytics/${userId}/${flowId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the more analytics api ', err)
  }
}
export const PromptApi = async (userId, prompt: string) => {
  try {
    const response = await axios.post(`${APILINK}/prompt/getAiresult/${userId}`, {prompt: prompt})
    return response.data
  } catch (err) {
    console.error('Error while getting the prompt data' + err)
  }
}
// Linkedin use prompt
export const LinkedinPromptApi = async (userId, prompt: string) => {
  try {
    const response = await axios.post(`${APILINK}/linkedin/prompt/getAiresult/${userId}`, {
      prompt: prompt,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the prompt data' + err)
  }
}
// Delete multiple
export const DeleteMultipleProfilesApi = async (userId, profiles, listId) => {
  try {
    const response = await axios.post(`${APILINK}/user/lists/profiles/deleteMultiple/${userId}`, {
      listId: listId,
      profiles: profiles,
    })
    return response.data
  } catch (err) {
    console.error('Error while deleting multiple profiles')
  }
}
export const DeleteMultipleCompaniesApi = async (userId, companies, listId) => {
  try {
    const response = await axios.post(`${APILINK}/user/lists/companies/deleteMultiple/${userId}`, {
      listId: listId,
      companies: companies,
    })
    return response.data
  } catch (err) {
    console.error('Error while deleting multiple profiles')
  }
}
export const DeleteMultipleProfilesFlowApi = async (userId, profiles, flowId) => {
  try {
    const response = await axios.post(`${APILINK}/user/flows/profiles/deleteMultiple/${userId}`, {
      flowId: flowId,
      profiles: profiles,
    })
    return response.data
  } catch (err) {
    console.error('Error while deleting multiple profiles')
  }
}
export const StartFlowAPi = async (userId, flowId) => {
  try {
    const response = await axios.post(`${APILINK}/flow/startFlow/${flowId}/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while running the flow' + err)
  }
}
export const stopFLowApi = async (userId, flowId) => {
  try {
    let resposne = await axios.get(`${APILINK}/flow/stopFlow/${flowId}/${userId}`)
    return resposne.data
  } catch (Err) {
    console.error('Error while stopping the flow ' + Err)
  }
}
export const GetFlowCsvProfiles = async (userId, flowId) => {
  try {
    let response = await axios.get(`${APILINK}/csvProfiles/${flowId}/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the csv profiles ' + err)
  }
}
export const GetPreviewApi = async (userId, flowId, profileId) => {
  try {
    const response = await axios.post(`${APILINK}/flow/emailPreview/${userId}/${flowId}`, {
      profileId,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the preview' + err)
  }
}
export const GetAiPromptApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/getprompts/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the prompts' + err)
  }
}
// Linkedin ai prompts
export const GetLinkedinPrompts = async (userId: string) => {
  try {
    const response = await axios.get(`${APILINK}/linkedInPrompts/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the linkedin prompts ', err)
  }
}
export const GetFlowReportOfAccountApi = async (userId, accountName) => {
  try {
    const response = await axios.get(`${APILINK}/user/userFlowReport/${userId}/${accountName}`)
    return response.data
  } catch (err) {
    console.error('Error while geting individual Account reports' + err)
  }
}
export const GetAccountsLinkedReports = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/accountsLinked/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error in fetching accounts linked' + err)
  }
}

export const GetListApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/getLists/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the list ' + err)
  }
}
export const GetCompanyListApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/companyLists/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the list ' + err)
  }
}
export const CreateNewListApi = async (userId, listObj) => {
  try {
    let response = await axios.post(`${APILINK}/user/createList/${userId}`, listObj)

    return response.data
  } catch (err) {
    console.error('Error while creating the list name ' + err)
  }
}
export const CreateNewCompanyListApi = async (userId, listObj) => {
  try {
    const response = await axios.post(`${APILINK}/user/companyList/createList/${userId}`, listObj)
    return response.data
  } catch (err) {
    console.error('Error while making the api for new company list ', err)
  }
}
export const GetProfilesInListApi = async (userId, listId) => {
  try {
    let response = await axios.get(`${APILINK}/user/list/${userId}/${listId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the profiles list ' + err)
  }
}
export const GetCompaniesInListApi = async (userId, listId) => {
  try {
    let response = await axios.get(`${APILINK}/user/lists/companies/${userId}/${listId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the profiles list ' + err)
  }
}

export const GetUserAnalyticsDataApi = async (userId, dict) => {
  try {
    let response = await axios.post(`${APILINK}/getAnalyticsData/${userId}`, dict)
    return response.data
  } catch (err) {
    console.error('Error while getting the analytics ', err)
  }
}

export const GetTotalUserCreditsApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/getUserTotalCredits/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the total credits' + err)
  }
}
export const DeleteListApi = async (userId, listObj) => {
  try {
    let response = await axios.post(`${APILINK}/user/deleteList/${userId}`, listObj)
    return response.data
  } catch (e) {
    console.error('error while deleting the list item ' + e)
  }
}
export const DeleteCompanyListApi = async (userId, listObj) => {
  try {
    let response = await axios.post(`${APILINK}/user/delete/companyList/${userId}`, listObj)
    return response.data
  } catch (e) {
    console.error('error while deleting the list item ' + e)
  }
}

export const DeleteCompanyFromListApi = async (userId, listId, companyId) => {
  try {
    const response = await axios.post(`${APILINK}/user/list/deleteCompany/${userId}/${listId}`, {
      linkedinUrl: companyId,
    })
    return response.data
  } catch (err) {
    console.error('Error while deleting the company from list ', err)
  }
}
export const UpdateListNameApi = async (userId, fullList) => {
  try {
    let response = await axios.post(`${APILINK}/user/updateSelectedList/${userId}`, fullList)
    return response.data
  } catch (e) {
    console.log('Error in updating selected List', e)
  }
}

export const UpdateCompanyListNameApi = async (userId, fullList) => {
  try {
    let response = await axios.post(`${APILINK}/user/company/updateList/${userId}`, fullList)
    return response.data
  } catch (e) {
    console.log('Error in updating selected List', e)
  }
}
export const UpdateFlowContentApi = async (userId, nodeId, contentObj, flowId) => {
  try {
    const response = await axios.post(
      `${APILINK}/flow/updateFlowContent/${userId}/${nodeId}/${flowId}`,
      contentObj
    )
    return response.data
  } catch (err) {
    console.error('Error while updating the object' + err)
  }
}
export const GetReplaceValuesApi = async () => {
  try {
    let response = await axios.get(`${APILINK}/getReplaceValues`)
    return response.data
  } catch (err) {
    console.log('Error while getting the curly braces list ' + err)
  }
}
export const UpdateFlowName = async (nameObj, userId, flowId) => {
  try {
    const response = await axios.post(
      `${APILINK}/flow/updatedFlowName/${userId}/${flowId}`,
      nameObj
    )
    return response.data
  } catch (err) {
    console.error('Error while updating the Flows Name in db' + err)
  }
}

export const AddNewSearchAPi = async (userId, searchId, searchObj) => {
  try {
    let response = await axios.post(`${APILINK}/user/newSearch/${userId}/${searchId}`, searchObj)
    return response.data
  } catch (err) {
    console.error('Error while Adding new session' + err)
  }
}

async function AddToFlowApi(flowObj, userId, flowId) {
  try {
    const response = await axios.post(`${APILINK}/flow/addFlowData/${userId}/${flowId}`, flowObj)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const UpdateDelayTimeApi = async (nodeList, userId: string, flowId) => {
  try {
    const response = await axios.post(
      `${APILINK}/flow/updatedDelayNode/${userId}/${flowId}`,
      nodeList
    )
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
async function SessionHistoryApi() {
  try {
    const response = await axios.get(`${APILINK}sessionHistory`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function SessionSelectApi(sessionId) {
  try {
    const response = await axios.get(`${APILINK}/user/searchHistory/searchObj/${sessionId}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const SetFilterObjectApi = async (searchId, filterObj) => {
  try {
    let response = await axios.post(`${APILINK}/user/newSearch/filterObj/${searchId}`, filterObj)
    return response.data
  } catch (err) {
    console.error('Error in setting filter object' + err)
  }
}

async function SessionDeleteApi(userId, searchId) {
  try {
    const response = await axios.delete(`${APILINK}/user/searchHistory/${userId}/${searchId}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const UpdateQueryApi = async (dic) => {
  try {
    const response = await axios.post(`${APILINK}/getNewProfiles`, dic)
    return response.data
  } catch (err) {
    console.error('Error while getting the updated query data' + err)
  }
}
export const AddAccountApi = async (obj, userId) => {
  try {
    const response = await axios.post(`${APILINK}/user/addEmailAccount/${userId}`, obj)
    return response.data
  } catch (err) {
    console.error('Error while adding the account to DB' + err)
  }
}
export const GetAccountsApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/getEmailAccount/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the accounts data' + err)
  }
}
export const DeleteAccountApi = async (userId, accountId) => {
  try {
    const response = await axios.delete(`${APILINK}/user/deleteEmailAccount/${userId}/${accountId}`)
    return response.data
  } catch (err) {
    console.error('Error while deleting the account' + err)
  }
}
export const UpdateSmtpObjectApi = async (userId, smtpId, smtpObj) => {
  try {
    let response = await axios.post(`${APILINK}/updateSMTPServer/${userId}/${smtpId}`, smtpObj)
    return response.data
  } catch (err) {
    console.error('Error while updating smtp object ' + err)
  }
}

export const GetListsApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/getLists/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the lists data ' + err)
  }
}
export const GetFlowListApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/flow/getFlowList/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the list:', err)
  }
}

export const ConnectLinkedinAccount = async (liAt, userId) => {
  try {
    let response = await axios.post(`${APILINK}/connectLinkedin/${userId}`, {li_at: liAt})
    return response.data
  } catch (err) {
    console.error('error while saving the linkedin account ', err)
  }
}
export const GetConnectedLinkedInAccount = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/getConnectedLinkedinAccount/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the connected account')
  }
}
export const RemoveLinkedInAccount = async (userId) => {
  try {
    let response = await axios.delete(`${APILINK}/deleteLinkedInAccount/${userId}`)
    return response.data
  } catch (Err) {
    console.error('Error while removing the linkedin account ', Err)
  }
}
async function AllProfilesApi(userId) {
  try {
    const response = await axios.get(`${APILINK}/allPreviewProfiles/?userId=${userId}`)
    let data = {
      ...response.data,
      data: response.data.data?.filter((item) => !(item.success === false)),
    }
    return data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const TaskListApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/taskList/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the task list' + err)
  }
}
export const GetEmailTemplatesApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/templates/getTemplates/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error in fetching email templates' + err)
  }
}
export const AddTaskApi = async (userId, taskId, taskObj) => {
  try {
    const response = await axios.post(
      `${APILINK}/taskList/addTask/${userId}?taskId=${taskId}`,
      taskObj
    )
    return response.data
  } catch (err) {
    console.error('Err while posting the task object' + err)
  }
}
export const AddProfileToExistingFlowApi = async (userId, flowId, newProfiles) => {
  try {
    let response = await axios.post(`${APILINK}/flow/running/profiles/${userId}/${flowId}`, {
      newProfiles,
    })
    return response.data
  } catch (err) {
    console.error('Error while adding the profile to flow ' + err)
  }
}
export const AddProfileToFlowApi = async (userId, flowId, newProfiles) => {
  try {
    let response = await axios.post(`${APILINK}/addListProfileFlow/${userId}/${flowId}`, {
      newProfiles,
    })
    return response.data
  } catch (err) {
    console.error('Error while adding the profile to flow ' + err)
  }
}
export const AddProfileToListApi = async (userId, listId, profileObj) => {
  try {
    let response = await axios.post(`${APILINK}/user/list/addProfile/${userId}/${listId}`, {
      profileList: profileObj,
    })
    return response.data
  } catch (err) {
    console.error('Error while add the profile ' + err)
  }
}
export const AddFullProfileToListApi = async (userId, listId, profileObj) => {
  try {
    let response = await axios.post(`${APILINK}/user/list/addFullProfile/${userId}/${listId}`, {
      profileList: profileObj,
    })
    return response.data
  } catch (err) {
    console.error('Error while add the profile ' + err)
  }
}

export const DeleteFlowProfileApi = async (userId, flowId, profileObj) => {
  try {
    let response = await axios.post(
      `${APILINK}/deleteFlowUserProfile/${userId}/${flowId}`,
      profileObj
    )
    return response.data
  } catch (e) {
    console.error('Error while deleting the profile from flow ' + e)
  }
}

export const DeleteListProfileApi = async (userId, listId, profileObj) => {
  try {
    let response = await axios.post(
      `${APILINK}/user/list/deleteProfile/${userId}/${listId}`,
      profileObj
    )
    return response.data
  } catch (e) {
    console.error('Error while deleting the profile from flow ' + e)
  }
}
export const DeleteTaskApi = async (userId, taskId) => {
  try {
    const response = await axios.delete(
      `${APILINK}/taskList/deletetask/?userId=${userId}&taskId=${taskId}`,
      {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3011', // Replace with your client's origin
          'Access-Control-Allow-Methods': 'DELETE', // Specify the allowed method
          'Access-Control-Allow-Headers': 'Content-Type', // Specify allowed headers
        },
      }
    )
    return response.data
  } catch (err) {
    console.error('Error while deleting the task' + err)
  }
}

export const DeleteFlowApi = async (flowId, userId) => {
  try {
    const response = await axios.delete(`${APILINK}/flow/deleteFlow/${flowId}?userId=${userId}`)
    return response.data
  } catch (err) {
    console.error('Error in Deleting Flow' + err)
  }
}
// Linkedin accounts
export const GetLinkedinAccountsApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/linkedinAccounts/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the linkedin accounts ' + err)
  }
}
export const ConnectLinkedinApi = async (userId, liAt) => {
  try {
    const response = await axios.post(`${APILINK}/user/linkedin/connect/${userId}`, {liAt})
    return response.data
  } catch (err) {
    console.error('Error while connecting the linkedin account ' + err)
  }
}
export const DeleteLinkedinAccountApi = async (userId, accountId) => {
  try {
    const response = await axios.delete(`${APILINK}/user/linkedin/account/${userId}/${accountId}`)
    return response.data
  } catch (err) {
    console.error('Error while deleting the linkedin account ' + err)
  }
}
export const SelectLinkedinAccountApi = async (userId, obj) => {
  try {
    const response = await axios.post(`${APILINK}/user/linkedin/selectAccount`, obj)
    return response.data
  } catch (Err) {
    console.error('Error while  selecting the linkedin account ' + Err)
  }
}
export const GetUserSettingsApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/settings/userSettings/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the user settings' + err)
  }
}
export const UpdateUserSettingsApi = async (userId, userObj) => {
  try {
    let response = await axios.post(`${APILINK}/settings/userSettings/${userId}`, userObj)
    return response.data
  } catch (err) {
    console.error('Error while getting the user settings' + err)
  }
}
export const GetUserPlandetailsApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/planDetails/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the user plandetails' + err)
  }
}
export const GetEmailSettingsApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/settings/emailsettings/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the email settings ' + err)
  }
}
export const GetUserIdDataApi = async (userId, candidateId) => {
  try {
    let response = await axios.get(`${APILINK}/userProfile/${userId}/${candidateId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the candidate data ', err)
  }
}
export const GetDataActivityByDateApi = async (userId, dateObj) => {
  try {
    const response = await axios.post(`${APILINK}/activity/byDate/${userId}`, dateObj)
    return response.data
  } catch (err) {
    console.error('Error while getting the date activity ', err)
  }
}
export const GetDataWithLinkedinApi = async (userId, linkedinUrl) => {
  try {
    let response = await axios.post(`${APILINK}/userProfile/linkedinData/${userId}`, {
      url: linkedinUrl,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the candidate data ', err)
  }
}

export const GetUserActivityDataApi = async (userId, searchUserId) => {
  try {
    const response = await axios.get(
      `${APILINK}/user/getLinkedinActivity/${userId}/${searchUserId}`
    )
    return response.data
  } catch (err) {
    console.error('Error while getting the user activity ', err)
  }
}
export const GetUserActivityApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/getActivity/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the user activity ', err)
  }
}
export const GetUserProfileCredits = async (userId, profileId) => {
  try {
    let res = await axios.get(`${APILINK}/user/getUserCredit/${userId}/${profileId}`)
    return res.data
  } catch (err) {
    console.error('Error while  fetching profile credits ', err)
  }
}
export const GetEnrichTableApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/getEnrichDataTable/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the enrich data ', err)
  }
}
export const SendEmailConfirmApi = async (userId, url) => {
  try {
    const response = await axios.post(`${APILINK}/user/sendUpdateEmail/${userId}`, {
      linkedinurl: url,
    })
    return response.data
  } catch (err) {
    console.error('Error while sending the confirmation email ', err)
  }
}
export const GetCreditsApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/user/getCredit/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the data of user credits ', err)
  }
}
export const UpdateCreditApi = async (userId, creditObj, AdminuserId) => {
  try {
    const response = await axios.post(
      `${APILINK}/user/updateUserCredit/${AdminuserId}/${userId}`,
      creditObj
    )

    return response.data
  } catch (err) {
    console.error('Error while updating the credits')
  }
}
export const AddCreditApi = async (userId, creditObj, AdminuserId) => {
  try {
    const response = await axios.post(
      `${APILINK}/admin/addCredits/${AdminuserId}/${userId}`,
      creditObj
    )

    return response.data
  } catch (err) {
    console.error('Error while updating the credits')
  }
}
export const UploadCsvEnrichDataApi = async (userId, dataObj) => {
  try {
    const response = await axios.post(`${APILINK}/user/uploadCsv/enrichData/${userId}`, dataObj)
    return response.data
  } catch (err) {
    console.error('Error while getting the data ', err)
  }
}
export const UpdateUserProfileCredits = async (userId, profileId, creditsObj) => {
  try {
    let response = await axios.post(
      `${APILINK}/updateUserProfileCredits/${userId}/${profileId}`,
      creditsObj
    )
    return response.data
  } catch (err) {
    console.error('Error while  fetching profile credits ', err)
  }
}
export const UpdateUserDataWithLinkedinAPi = async (userId, data) => {
  try {
    let response = await axios.post(`${APILINK}/userProfile/updateLinkedinData/${userId}`, data)
    return response.data
  } catch (err) {}
}
export const AddUserDataWithLinkedinAPi = async (userId, data) => {
  try {
    let response = await axios.post(`${APILINK}/user/addLinkedinData/${userId}`, data)
    return response.data
  } catch (err) {}
}
export const UpdateUserIdDataApi = async (userId, candidateId, updatedObj) => {
  try {
    let response = await axios.post(
      `${APILINK}/updateUserProfile/${userId}/${candidateId}`,
      updatedObj
    )
    return response.data
  } catch (err) {
    console.error('Error while getting the candidate data ', err)
  }
}
export const GetusersListApi = async (userId) => {
  try {
    const response = await axios.get(`${APILINK}/getUserList/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the user list ', err)
  }
}

export const UpdateEMailSettingsApi = async (userId, object) => {
  try {
    let response = await axios.post(`${APILINK}/settings/emailSettings/${userId}`, {object})
    return response.data
  } catch (err) {
    console.error('Error While Updating Email Settings ' + err)
  }
}
export const GetTeamSettingsApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/settings/getTeamSettings?userId=${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting team settings' + err)
  }
}
export const UpdateFlowObjApi = async (userId, flowId, flowObj) => {
  try {
    let response = await axios.post(`${APILINK}/flow/updateFlow/${flowId}/${userId}`, flowObj)
    return response.data
  } catch (err) {
    console.error('Error while updating the flow data' + err)
  }
}
export const UpdateFlowDetailsApi = async (userId, flowId, flowObj) => {
  try {
    let response = await axios.post(
      `${APILINK}/flow/updateFlowDetails/${userId}/${flowId}`,
      flowObj
    )
    return response.data
  } catch (err) {
    console.error('Error in update Flow Details ' + err)
  }
}
export const ChatAiApi = async (value, chats, queryObject) => {
  try {
    let response = await axios.post(`${APILINK}/chatAi/updateChats`, {
      newMessage: value,
      prevChats: chats,
      queryObject: queryObject,
    })
    return response.data
  } catch (err) {
    console.error('Error while posting the chats' + err)
  }
}
export const SearchWithJDApi = async (file) => {
  try {
    let response = await axios.post(
      `${APILINK}/uploadfile`,
      {file},
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    console.error('Error while searching with JD ' + err)
  }
}
export const SearchJDTextApi = async (jdText) => {
  try {
    let response = await axios.post(`${APILINK}/uploadjson`, {jdText})
    return response.data
  } catch (err) {
    console.error('Error while sending the text jd ' + err)
  }
}
export const SuggestionsApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/getChatSuggestions/${userId}`)
    return response.data
  } catch (Err) {
    console.error('Error while getting the suggestions')
  }
}
export const GetFlowByIdApi = async (flowId, userId) => {
  try {
    let response = await axios.get(`${APILINK}/flow/getFlow/${userId}/${flowId}`)
    return response.data
  } catch (err) {
    console.error('Error in fetching Flow by Id' + err)
  }
}
export const GetFlowKeyIdsApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/flow/getFlowListIds/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error in fetching Flow Key Ids ' + err)
  }
}
export const GetFlowProfilesApi = async (flowId, userId) => {
  try {
    let response = await axios.get(`${APILINK}/flowProfiles/${flowId}/${userId}`)
    return response.data
  } catch (err) {
    console.error('error while getting the flow profiles' + err)
  }
}
export const UpdateTeamSettingsApi = async (userId, TeamObj) => {
  try {
    let response = await axios.post(`${APILINK}/settings/updateTeamSettings/${userId}`, TeamObj)
    return response.data
  } catch (err) {
    console.error('Error while updating the TeamSettings' + err)
  }
}
export const UploadCsvProfilesApi = async (flowId, userId, newProfiles) => {
  try {
    let response = await axios.post(`${APILINK}/csvProfiles/${flowId}/${userId}`, newProfiles)
    return response.data
  } catch (err) {
    console.error('Error while Uploading new Profiles' + err)
  }
}
export const SaveUserTemplateApi = async (userId, templateId, template) => {
  try {
    let response = await axios.post(
      `${APILINK}/templates/setUserEmailTemplates/${userId}/${templateId}`,
      template
    )
    return response.data
  } catch (err) {
    console.error('Error while upating user template' + err)
  }
}

export const UploadCsvApi = async (userId, flowId, csvProfiles, mappingObject) => {
  try {
    let response = await axios.post(`${APILINK}/uploadCsvProfiles/${userId}/${flowId}`, {
      csvProfiles,
      mappingObject,
    })
    return response.data
  } catch (err) {
    console.error('Error while posting new csv profiles ' + err)
  }
}

export const getTimeLineApi = async (userId, profileId) => {
  try {
    let response = await axios.post(`${APILINK}/getUserProfileTimeLine/${userId}`, {
      profileKey: profileId,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting user Time line data ' + err)
  }
}
export const GetIndivdualProfileApi = async (userId, profileId, signal) => {
  try {
    const response = await axios.get(`${APILINK}/profiles/${userId}/${profileId}`, {signal})
    return response.data
  } catch (err) {
    console.error('Error in fetching individual profile ' + err)
  }
}
export const UpdateTaskObject = async (taskObj, taskId, userId) => {
  try {
    const response = await axios.post(`${APILINK}/taskList/updateTask/${userId}/${taskId}`, taskObj)
    return response.data
  } catch (err) {
    console.error('Error in updating the Task' + err)
  }
}
export const GetFullProfile = async (userId, key) => {
  try {
    let response = await axios.post(`${APILINK}/getFullProfile/${userId}`, {key})
    return response.data
  } catch (err) {
    console.error('Error while getting the full profile ', err)
  }
}
export const GetprofilePicApi = async (linkedin, key) => {
  try {
    const response = await axios.post(`${APILINK}/profile/getProfilePic`, {
      linkedin: linkedin,
      key: key,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the profilePic' + err)
  }
}

export const GetNextSetProfilesApi = async (searchKey, userId, scrollToken, sqlQuery) => {
  try {
    let response = await axios.post(
      `${APILINK}/user/searchHistory/searchId/nextProfiles/${searchKey}/${userId}/${scrollToken}`,
      {query: sqlQuery}
    )
    return response.data
  } catch (err) {
    console.error('error while getting the nest set of profiles ' + err)
  }
}
export const GetCompanyLogoApi = async (companyName) => {
  try {
    const response = await axios.get(`${CompanyAPi}/profile/companyLogo/${companyName}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the company logo: ' + err)
  }
}
export const GetIndividualCompanyLogoApi = async (companyName) => {
  try {
    const response = await axios.get(`${CompanyAPi}/profile/companyLogo/${companyName}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the company logo' + err)
  }
}
async function ProfileSelectApi(profileId) {
  try {
    const response = await axios.post(`${APILINK}profileSelect`, {id: profileId})
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function NextProfileApi(profileId) {
  try {
    const response = await axios.post(`${APILINK}nextprofile`, {id: profileId})
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function PrevProfileApi(profileId) {
  try {
    const response = await axios.post(`${APILINK}prevProfile`, {id: profileId})
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function ChatMessageUpdateApi(sessionId, messages) {
  try {
    const response = await axios.post(`${APILINK}updatemessage`, {sessionId, messages})
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function DropdownQueryApi(endpoint) {
  try {
    const response = await axios.get(`${APILINK}dropdownquery/${endpoint}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function EasyQueryApi(endpoint) {
  try {
    const response = await axios.get(`${APILINK}easyQuery/${endpoint}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
// Auto fill
export const GetCountriesApi = async (country: string) => {
  try {
    const response = await axios.get(`${APILINK}/pdl/getCountries/?country=${country}`)
    return response.data
  } catch (err) {
    console.error('Error while  fetching countries ', err)
  }
}
export const GetSkillsApi = async (skill: string) => {
  try {
    const response = await axios.get(`${APILINK}/pdl/getSkillslist/?skill=${skill}`)
    return response.data
  } catch (err) {
    console.error('Error while  fetching countries ', err)
  }
}
export const GetMajorsApi = async (major: string) => {
  try {
    const response = await axios.get(`${APILINK}/pdl/getMajors/?major=${major}`)
    return response.data
  } catch (err) {
    console.error('Error while  fetching education majors ', err)
  }
}
async function FilterPopupRegionsApi(location: string) {
  try {
    const response = await axios.get(`${APILINK}/pdl/getLocation/?locationName=${location}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const FilterByStateApi = async (state: string) => {
  try {
    const response = await axios.get(`${APILINK}/pdl/getRegions/?region=${state}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the states ' + err)
  }
}
async function FilterminExp() {
  try {
    const response = await axios.get(`${APILINK}filter/minExp`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FiltermaxExp() {
  try {
    const response = await axios.get(`${APILINK}filter/maxExp`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function filterReqInfo() {
  try {
    const response = await axios.get(`${APILINK}filter/reqinfo`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const GetCompanyDataApi = async (companyUrl) => {
  try {
    let response = await axios.post(`${APILINK}/profile/companyData`, {companyUrl: companyUrl})
    return response.data
  } catch (err) {
    console.error('Error while getting the companyData' + err)
  }
}
async function FilterCompanies(companyName) {
  try {
    const response = await axios.get(`${APILINK}/pdl/getCompany/?companyName=${companyName}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function CompanyDuration() {
  try {
    const response = await axios.get(`${APILINK}filter/companytime`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function Filterjobtitles(title) {
  try {
    const response = await axios.get(`${APILINK}/pdl/getJobTitles/?jobTitle=${title}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterTimeSpentYears() {
  try {
    const response = await axios.get(`${APILINK}filter/timespent/years`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterTimeSpentMonths() {
  try {
    const response = await axios.get(`${APILINK}filter/timespent/months`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterPastJobtitles() {
  try {
    const response = await axios.get(`${APILINK}filter/pastjobtitles`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterUniversities(universityName) {
  try {
    const response = await axios.get(`${APILINK}/pdl/getSchoolNames/?schoolName=${universityName}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterSkills(skills) {
  try {
    const response = await axios.get(`${APILINK}/pdl/getSkills/?skillsName=${skills}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterLanguages() {
  try {
    const response = await axios.get(`${APILINK}filter/languages`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterIndustries(industry) {
  try {
    const response = await axios.get(`${APILINK}/pdl/getIndustries/?industryName=${industry}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterCompanySize() {
  try {
    const response = await axios.get(`${APILINK}filter/companysize`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function FilterExcludedCompanies(companyName: string) {
  try {
    const response = await axios.get(`${APILINK}/pdl/getCompany/?companyName=${companyName}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

export const DashBoardApi = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/dashboard/${userId}`)
    return response.data
  } catch (err) {
    throw err
  }
}

async function FilterMinimumDegree() {
  try {
    const response = await axios.get(`${APILINK}filter/mindegree`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}
export const UpdateChatApi = async (userId, sessionId, chats) => {
  try {
    let response = await axios.post(
      `${APILINK}/searchHistory/searchObj/${userId}/${sessionId}`,
      chats
    )
    return response.data
  } catch (err) {
    console.error('Error while Updating chats for session' + err)
  }
}
export const UpdateProfilesApi = async (userId, sessionId, profiles) => {
  try {
    let response = await axios.post(
      `${APILINK}/searchHistory/searchObj/updateProfiles/${userId}/${sessionId}`,
      profiles
    )
    return response.data
  } catch (err) {
    console.error('Error while Updating chats for session' + err)
  }
}
export const GetSearchHistory = async (userId) => {
  try {
    let response = await axios.get(`${APILINK}/user/getSearchHistory/${userId}`)
    return response.data
  } catch (err) {
    console.error('Error while getting the data' + err)
  }
}
export const EditSearchNameApi = async (userId, searchId, name) => {
  try {
    let response = await axios.post(
      `${APILINK}/user/searchHistory/updateSearchObj/${userId}/${searchId}/${name}`
    )
    return response.data
  } catch (err) {
    console.error('Error while updating the Name' + err)
  }
}
async function FilterFieldOfStudy(fieldofStudy) {
  try {
    const response = await axios.get(`${APILINK}/pdl/getFieldOfStudy/?fieldOfStudy=${fieldofStudy}`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

async function GetTimezones() {
  try {
    const response = await axios.get(`${APILINK}timezones`)
    return response.data
  } catch (error) {
    console.error('Error while getting the data fromm backend ', error)
  }
}

export {
  NewChatApi,
  InteractionWithChatApi,
  SearchQueryButtonApi,
  AddToFlowApi,
  SessionHistoryApi,
  SessionSelectApi,
  SessionDeleteApi,
  AllProfilesApi,
  ProfileSelectApi,
  NextProfileApi,
  PrevProfileApi,
  ChatMessageUpdateApi,
  DropdownQueryApi,
  EasyQueryApi,
  FilterPopupRegionsApi,
  FilterminExp,
  FiltermaxExp,
  filterReqInfo,
  FilterCompanies,
  CompanyDuration,
  Filterjobtitles,
  FilterTimeSpentYears,
  FilterTimeSpentMonths,
  FilterPastJobtitles,
  FilterUniversities,
  FilterSkills,
  FilterLanguages,
  FilterIndustries,
  FilterCompanySize,
  FilterExcludedCompanies,
  FilterMinimumDegree,
  FilterFieldOfStudy,
  GetTimezones,
}
