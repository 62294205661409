import React, {useState, useEffect} from 'react'
import {Button, Col, Form, Input, Row, Flex, Tooltip, message, Select} from 'antd'
import {useUserSettingContext} from '../../context/UserSettings'
import LockResetIcon from '@mui/icons-material/LockReset'
import {UpdateUserSettingsApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
const formItemLayout = {
  labelCol: {span: 5},
  wrapperCol: {span: 20},
}

const UserForm: React.FC = () => {
  const {profileObj} = useUserSettingContext()
  const [formData, setFormData] = useState({
    Companyname: '',
    Title: '',
    Email: '',
    Password: '',
    Role: '',
  })
  const [formModified, setFormModified] = useState(false)
  const [listOfRoles, setListOfRoles] = useState<{label: string; value: string}[]>([
    {label: 'Marketing', value: 'Marketing'},
    {label: 'Recruting', value: 'Recruting'},
    {label: 'Normal', value: 'Normal'},
    {label: 'Admin', value: 'Admin'},
  ])
  useEffect(() => {
    if (profileObj) {
      setFormData({
        Title: profileObj['title'] ?? '',
        Email: profileObj.email,
        Companyname: profileObj.companyName,
        Password: '',
        Role: profileObj.role,
      })
    }
  }, [profileObj])

  const handleChange = (fieldName: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }))
    setFormModified(true)
  }

  const handleSubmit = async () => {
    const updatedProfileObj = {...profileObj}
    updatedProfileObj.title = formData.Title.trim()
    updatedProfileObj['companyName'] = formData.Companyname.trim()
    updatedProfileObj['role'] = formData.Role.trim()
    try {
      let data = await UpdateUserSettingsApi(userId, updatedProfileObj)
      if (data && data.success && data.data) {
        message.success('User settings saved')
      } else {
        message.error('Error while saving user settings')
      }
    } catch (err) {
      message.error('Error while saving user settings')
      console.error('Error while updating profile settings ' + err)
    }
  }
  const handleResetPassword = () => {
    console.log('Reset password clicked')
  }
  return (
    <Form {...formItemLayout} onFinish={handleSubmit} className='w-100'>
      <Row gutter={[16, 16]} className='w-100'>
        <Col span={12}>
          <Form.Item label='Company ' name='Company '>
            <div>
              <Input
                placeholder='Enter your Company name'
                size='large'
                className='w-100'
                value={formData.Companyname}
                onChange={(e) => handleChange('Companyname', e.target.value)}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Title' name='Title'>
            <div>
              <Input
                placeholder='Enter your title'
                size='large'
                value={formData.Title}
                onChange={(e) => handleChange('Title', e.target.value)}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Role' name='role'>
            <Flex align='center' gap={'small'}>
              <Select
                options={listOfRoles}
                value={formData.Role}
                onChange={(value) => {
                  setFormModified(true)
                  setFormData({...formData, Role: value})
                }}
              />
            </Flex>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Email' name='Email'>
            <div>
              <Input
                placeholder='Your Email'
                size='large'
                disabled
                value={formData.Email}
                onChange={(e) => handleChange('Email', e.target.value)}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Password' name='Password'>
            <Flex align='center' gap={'small'}>
              <Input.Password
                placeholder={
                  formData.Password ? 'Enter new password' : 'Cannot change social login password'
                }
                disabled={formData.Password ? false : true}
                size='large'
                value={formData.Password}
                onChange={(e) => handleChange('Password', e.target.value)}
              />
              {formData.Password ? (
                <Tooltip title='Reset password'>
                  <LockResetIcon
                    style={{fontSize: '24px', color: '#204f58', cursor: 'pointer'}}
                    onClick={handleResetPassword}
                  />
                </Tooltip>
              ) : null}
            </Flex>
          </Form.Item>
        </Col>
      </Row>

      <Row className='w-100'>
        <Flex align='center' justify='end' className='w-100'>
          <Form.Item wrapperCol={{span: 24}}>
            <Button type='primary' htmlType='submit' disabled={!formModified}>
              Submit
            </Button>
          </Form.Item>
        </Flex>
      </Row>
    </Form>
  )
}

export default UserForm
