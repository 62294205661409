import {FC, createContext, useContext, useState, useEffect} from 'react'
import axios from 'axios'
import {useProfileContext} from './profilecontext'
import {DashBoardApi} from '../apicalls/ApiCall'
import {SearchQueryButtonApi} from '../apicalls/ApiCall'
import {UserObject} from '../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuContent'
import {useChatContext} from './chatcontext'
import {userId} from '../components/DataTypes/UserDataTypes'

type Profile = {
  id: string
  profileName: string
  profilePic: string
  phone: number
  email: string
  github: string
  linkedin: string
  latestCompany: {
    companyName: string
    companyLogo: string
  }
  role: string
  skills: string[]
  experience: {
    companyName: string
    companyLogo: string
    duration: string
    jobdescription: string
    website: string
  }[]
  education: {
    name: string
    type: string
    duration: string
    logo: string
  }[]
  conversations?: {
    recruiterMail: {
      subject: string
      content: string
      sendAt: string
    }
    userMail: {
      subject: string
      content: string
      sendAt: string
    }
  }[]
}

interface newProfiles {
  id: string
  profileName: string
  profilePic: string
  latestCompany: {
    companyName: string
    companyLogo: string
  }
  role: string
  phone: boolean
  email: boolean
  github: boolean
  linkedin: string
}
interface DefaultQuery {
  text: string
  searchQuery: string
  JobTitles: string[]
  PastJobTitles: string[]
  Profiles: number
  Area: string[]
  ReqInfo: string[]
  ExcludedGender: boolean
  ExcludedProfile: boolean
  Experience: {minExp: number; maxExp: number}[]
  TimeSpent: {years: number; months: number}
  University: string[]
  Skills: string[]
  Languages: string[]
  Companies: string[]
  Industries: string[]
  CompanySize: string
  ExcludedCompanies: string[]
  MinimumDegree: string
  FieldOfStudy: string[]
}
interface FilterObject {
  Area: string[]
  Companies: string[]
  CompanySize: string
  ExcludedCompanies: string[]
  ExcludedGender: boolean
  ExcludedProfile: boolean
  Experience: {minExp: number; maxExp: number}[]
  FieldOfStudy: string[]
  Industries: string[]
  JobTitles: string[]
  Languages: string[]
  MinimumDegree: string
  PastJobTitles: string[]
  Profiles: number
  ReqInfo: string[]
  Skills: string[]
  TimeSpent: {years: number; months: number}
  University: string[]
  icon: string
  searchQuery: string
  title: string
}

interface FlowListData {
  flowId: string
  flowName: string
}
interface SearchHistoryProps {
  searchId: string
  searchName: string
}
interface DashboardData {
  defaultThumbnails: {
    thumbnail1: FilterObject
    thumbnail2: FilterObject
    thumbnail3: FilterObject
  }
  defaultquery: DefaultQuery | undefined
  flowList: FlowListData[]
  searchHistory: SearchHistoryProps[]
  userName: null
  userPic: string
}
interface reportsData {
  total: number
  sent: number
  opened: number
  clicked: number
}
interface AccountReports {
  name: string
  accountType: string
  flowName: string
  flowId: string
  total: number
  sent: number
  opened: number
  clicked: number
}
interface QueryObject {
  Area: string[]
  Companies: string[]
  CompanySize: string
  ExcludedCompanies: string[]
  ExcludedGender: boolean
  ExcludedProfile: boolean
  Experience: {minExp: number; maxExp: number}[] | null
  FieldOfStudy: string[] | null
  Industries: string[]
  JobTitles: string[]
  Languages: string[]
  MinimumDegree: string | null
  PastJobTitles: string[]
  Profiles: number | number[]
  ReqInfo: string[]
  Skills: string[] | null
  TimeSpent: {years: number; months: number}
  University: string[]
}
type ApiContextType = {
  handleSearchReq: () => Promise<void>
  showLoading: boolean
  setShowLoading: (value: boolean) => void
  err: string
  tableUi: boolean
  setTableUi: (value: boolean) => void
  setErr: (err: string) => void
  dashboardData: DashboardData | undefined
  reportsData: reportsData | undefined
  setReportsData: (reportsData: reportsData) => void
  setDashBoardData: (dashboardData: DashboardData) => void
  accountsReport: AccountReports[] | undefined
  setAccountsReport: (accountsReport: AccountReports[]) => void
  apiSearchId: string
  setApiSearchId: (apiSearchId: string) => void
  queryBuilder
  sqlQuery: string
  setSqlQuery: (sqlQuery: string) => void
  queryObj: QueryObject | undefined
  setQueryObj: (queryObj: QueryObject) => void
  scrollToken: string
  setScrollToken: (scrollToken: string) => void
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
  newData: Profile[]
  setNewData: (newData: Profile[]) => void
}
const initialData: Profile[] = []
const ApiContext = createContext<ApiContextType | undefined>(undefined)

const ApiContextProvider = ({children}) => {
  const [newData, setNewData] = useState(initialData)
  const {
    profilesData,
    setProfilesData,
    profileList,
    setProfileList,
    setSearchList,
    setTotalResultProfiles,
    setShowResultLoading,
  } = useProfileContext()

  const {setComponentInput, sendMessage, setDefaultQuery} = useChatContext()
  const [apiSearchId, setApiSearchId] = useState<string>('')
  const [scrollToken, setScrollToken] = useState<string>('')
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [err, setErr] = useState<string>('')
  const [tableUi, setTableUi] = useState<boolean>(false)
  const [arrayOfProfiles, setArrayOfProfiles] = useState<newProfiles[]>([])
  const [reportsData, setReportsData] = useState<reportsData>()
  const [accountsReport, setAccountsReport] = useState<AccountReports[]>()
  const [queryObj, setQueryObj] = useState<QueryObject | undefined>()
  const [sqlQuery, setSqlQuery] = useState<string>('')
  const [createdQuery, setCreatedQuery] = useState<string>('')
  const [dashboardData, setDashBoardData] = useState<DashboardData>()
  const [searchQuery, setSearchQuery] = useState<string>('')

  useEffect(() => {
    const isDataNew = arrayOfProfiles.every((newProfile) => {
      return !profileList.some(
        (existingProfile) => existingProfile.profileName === newProfile.profileName
      )
    })
    if (isDataNew) {
      setProfileList(arrayOfProfiles)
    }
  }, [arrayOfProfiles, profileList])
  useEffect(() => {
    setDefaultQuery(dashboardData?.defaultquery)
  }, [dashboardData])

  useEffect(() => {
    const isDataNew = newData.every((newProfile) => {
      return !profilesData.some(
        (existingProfile) => existingProfile.profileName === newProfile.profileName
      )
    })

    if (isDataNew) {
      setProfilesData(newData)
    }
  }, [newData, profilesData])
  useEffect(() => {
    ;(async () => {
      try {
        if (userId) {
          let data = await DashBoardApi(userId)
          if (data && data.success && data.data) {
            if (data.data['userName']) {
              localStorage.setItem('userName', data.data['userName'])
            }
            setDashBoardData(data.data)
          } else {
            console.error('Error while fetching the dashboard data')
          }
        }
      } catch (e) {
        console.error('Error while fetching the dashboard data' + e)
      }
    })()
  }, [])
  useEffect(() => {
    setSqlQuery(createdQuery)
  }, [createdQuery])
  // const handleProfilesFetch = async () => {
  //   let headers = {
  //     'X-api-key': ' 608c2bf393d9ba4f55411a0419c446f4ae5768c21d2bf9b92252281683962f03',

  //     // 0b782d403680bc814b17cef6a2efeddb06bc0a5dfe50c60e6845efb5d5d0eec1
  //     // 0b782d403680bc814b17cef6a2efeddb06bc0a5dfe50c60e6845efb5d5d0eec1
  //   }
  //   let response = await axios.get(
  //     "https://api.peopledatalabs.com/v5/person/search?sql=SELECT count (*) FROM person WHERE (mobile_phone is not null and industry = 'computer software' and job_company_name = 'google' and education.majors = 'computer science' and location_country = 'united states' and (skills = 'data structures' and (skills = 'java' or skills = 'javascript')) and education.school.name = 'university of wisconsin - madison')&size=10",
  //     {headers}
  //   )
  //   let data = response.data.data
  //   let newArr: newProfiles[] = []
  //   for (let profileObj of data) {
  //     let newProfile: newProfiles = {
  //       id: profileObj.id,
  //       profileName: profileObj.full_name,
  //       profilePic: '',
  //       latestCompany: {
  //         companyName: profileObj.job_company_name,
  //         companyLogo: '',
  //       },
  //       role: profileObj.job_title,
  //       phone: profileObj.mobile_phone,
  //       email: profileObj.emails,
  //       github: profileObj.github_url,
  //       linkedin: profileObj.linkedin_url,
  //     }
  //     newArr.push(newProfile)
  //   }
  //   setArrayOfProfiles(newArr)
  // }
  const queryBuilder = () => {
    sendMessage('Querying with the saved filters')
    setComponentInput('Fetching the data')
  }
  const handleSearchReq = async () => {
    try {
      let data = await SearchQueryButtonApi(queryObj, apiSearchId, userId)
      if (data.error || !data.success) {
        setShowResultLoading(false)
        setErr('Nodata')
        setShowLoading(false)
        setNewData([])
      } else if (data && data.success && data.data) {
        setShowResultLoading(false)
        setSearchList(data.data.profiles)
        setScrollToken(data.data.token)
        setSqlQuery(data.data.query)
        setTotalResultProfiles(data.data.total)
        setShowLoading(false)
      }
    } catch (err) {
      setShowResultLoading(false)
      setErr('failed')
      setShowLoading(false)
      setNewData([])
      console.log(err)
    }
  }
  let value = {
    handleSearchReq,
    showLoading,
    setShowLoading,
    newData,
    setNewData,
    err,
    setTableUi,
    tableUi,
    setErr,
    dashboardData,
    setDashBoardData,
    reportsData,
    setReportsData,
    accountsReport,
    setAccountsReport,
    apiSearchId,
    setApiSearchId,
    queryBuilder,
    sqlQuery,
    setSqlQuery,
    queryObj,
    setQueryObj,
    scrollToken,
    setScrollToken,
    searchQuery,
    setSearchQuery,
  }
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}
const useApiContext = () => {
  const apiContext = useContext(ApiContext)

  if (!apiContext) {
    throw new Error('useapiContext must be used within a apiContextProvider')
  }

  return apiContext
}
export {useApiContext, ApiContextProvider}
