import React, {useEffect, useState} from 'react'
import {Flex, Button, Table, Input, Space, Tooltip, message, Popconfirm} from 'antd'
import {
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import copy from 'clipboard-copy'
import {
  DeleteApiKey,
  GenerateNewApiKey,
  GetUserApiKeys,
  UpdateApiName,
} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import {v4 as uuidV4} from 'uuid'
import EmptyLogo from '../Subcomponents/Empty'
import PageLoader from '../Subcomponents/PageLoader'
import Tags from '../Subcomponents/Tags'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const APIkeyGeneration = () => {
  const [apiData, setApiData] = useState<any[]>([])
  const [editingKey, setEditingKey] = useState('')
  const [visibleKeys, setVisibleKeys] = useState<any[]>([])
  const [editedName, setEditedName] = useState('')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  useEffect(() => {
    handleGetApiKeys()
  }, [])
  const handleViewKey = (record) => {
    if (!visibleKeys.includes(record.key)) {
      setVisibleKeys([...visibleKeys, record.key])
    } else {
      setVisibleKeys(visibleKeys.filter((key) => key !== record.key))
    }
  }

  const handleCopyKey = (key) => {
    copy(key)
    message.success('Key copied to clipboard')
  }

  const handleSave = async (record) => {
    const updatedData = apiData.map((item) => {
      if (item.key === record.key) {
        return {...item, name: editedName}
      }
      return item
    })
    try {
      const response = await UpdateApiName(userId, {updatedList: updatedData})
      if (response && response.data && response.success) {
        message.success('Updated sucessfully')
        setApiData(updatedData)
        setEditingKey('')
      } else {
        message.error('Error while updating')
      }
    } catch (err) {
      message.error('Error while updating')
      console.error('Error while updating ', err)
    }
  }

  const handleCancel = () => {
    setEditingKey('')
  }

  const handleGetApiKeys = async () => {
    try {
      setShowLoader(true)
      const response = await GetUserApiKeys(userId)
      setShowLoader(false)
      if (response && response.success && response.data) {
        setApiData(response.data)
      } else {
        message.error('Error while getting the API keys')
      }
    } catch (err) {
      message.error('Error while getting the API keys')
      console.error('Error while getting API keys')
    }
  }
  const handleNewApiKey = async () => {
    try {
      const ApiObj = {
        name: 'API_key',
        key: uuidV4(),
        secretKey: '',
        created: '',
        active: true,
      }
      setShowLoader(true)
      const res = await GenerateNewApiKey(userId, ApiObj)
      setShowLoader(false)
      if (res && res.success && res.data) {
        message.success('API Key Created Successfully')
        await handleGetApiKeys()
      } else {
        message.error('Something went wrong while geenrating')
      }
    } catch (err) {
      message.error('Error Occured While Genenrting API Key')
      console.error('Error while generating the API key ', err)
    }
  }
  const handleDelete = async (key) => {
    try {
      const res = await DeleteApiKey(userId, key)
      if (res && res.success && res.data) {
        message.success('Successfully removed the API')
        await handleGetApiKeys()
      } else {
        message.error('Failed to delete the API')
      }
    } catch (err) {
      message.error('Error while deleting the api key ')
      console.error('Error while deleting the api key ', err)
    }
  }

  const handleOpenApiDocumentation = () => {
    window.open(
      'https://documenter.getpostman.com/view/17157000/2sA2r6XQCR#9d737810-4cfa-4fe6-a24f-d943fb04823f',
      '_blank'
    )
  }
  const columns = [
    {
      width: 300,
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        const isEditing = record.key === editingKey
        return isEditing ? (
          <Input defaultValue={text} onChange={(e) => setEditedName(e.target.value)} />
        ) : (
          <span>{text}</span>
        )
      },
    },
    {
      title: 'Secret Key',
      dataIndex: 'secretKey',
      key: 'secretKey',
      render: (text, record) => {
        const isVisible = visibleKeys.includes(record.key)
        return (
          <Space>
            <span>{isVisible ? text : '*********'}</span>
          </Space>
        )
      },
    },
    {
      width: 120,
      title: 'Created Date',
      dataIndex: 'created',
      key: 'createdDate',
    },
    {
      width: 120,
      title: 'status',
      dataIndex: 'active',
      render: (val) => (
        <Tags color={val ? ' #73ba9b' : '#8d86c9'} tagsContent={val ? 'Active' : 'Inactive'} />
      ),
      key: 'status',
    },
    {
      width: 100,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        const isEditing = record.key === editingKey
        return isEditing ? (
          <Flex gap={'1px'} align='center'>
            <Tooltip title='Save'>
              <Button
                type='text'
                icon={<CheckOutlined rev={undefined} />}
                onClick={() => handleSave(record)}
              />
            </Tooltip>
            <Tooltip title='Cancel'>
              <Button type='text' icon={<CloseOutlined rev={undefined} />} onClick={handleCancel} />
            </Tooltip>
          </Flex>
        ) : (
          <Flex gap={'1px'} align='center'>
            <Tooltip title='Edit'>
              <Button
                type='text'
                icon={<EditOutlined rev={undefined} />}
                onClick={() => setEditingKey(record.key)}
              />
            </Tooltip>
            <Popconfirm
              title='Are you sure you want to delete this key'
              placement='left'
              okText='Yes'
              cancelText='No'
              onConfirm={() => handleDelete(record.secretKey)}
            >
              <Tooltip title='Delete'>
                <Button type='text' icon={<DeleteOutlined rev={undefined} />} />
              </Tooltip>
            </Popconfirm>
            <Tooltip title={visibleKeys.includes(record.key) ? 'Hide Key' : 'Show Key'}>
              <Button
                type='text'
                icon={<EyeOutlined rev={undefined} />}
                onClick={() => handleViewKey(record)}
              />
            </Tooltip>
            <Tooltip title='Copy Key'>
              <Button
                type='text'
                icon={<CopyOutlined rev={undefined} />}
                onClick={() => handleCopyKey(record.secretKey)}
              />
            </Tooltip>
          </Flex>
        )
      },
    },
  ]
  //
  return (
    <div>
      {showLoader && <PageLoader />}
      <Flex align='center' justify='space-between'>
        <Flex align='center' gap={'small'}>
          <span style={{fontSize: '25px', fontWeight: '600'}} className='heading'>
            API keys
          </span>
          <div
            onClick={() => handleOpenApiDocumentation()}
            style={{marginTop: '4px', cursor: 'pointer'}}
          >
            <OpenInNewIcon className='text-hover-primary' />
          </div>
        </Flex>
        <Button
          onClick={() => handleNewApiKey()}
          type='primary'
          icon={<PlusOutlined rev={undefined} />}
        >
          Generate new key
        </Button>
      </Flex>
      <span>Your keys are listed below</span>
      <Table
        locale={{
          emptyText: <EmptyLogo description={'Generate new key'} />,
        }}
        columns={columns}
        dataSource={apiData}
      />
    </div>
  )
}

export default APIkeyGeneration
