import React, {FC, useState, useEffect} from 'react'
import dashstyle from '../styles/newdash.module.css'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {KTIcon} from '../../_metronic/helpers'
import {useValuesContext} from '../context/valuescontext'
import {Filterjobtitles} from '../apicalls/ApiCall'
import Search from './Reusablecomponents/Select'
import {Select, Card, Flex, Tooltip} from 'antd'
import FilterTags from './Reusablecomponents/FilterTags'
import FineTuneModal from './Subcomponents/FineTuneModal'

interface TimeSpent {
  years: number
  months: number
}

const JobTitlesCard: FC = () => {
  const {jobTitles, setJobTitles, timespent, setTimeSpent, showJobTitles, setShowJobTitles} =
    useValuesContext()
  const [exp, setExp] = useState<number[]>(Array.from({length: 20}, (_, i) => i))
  const [storeYearExp, setStoreYearExp] = useState<number>(timespent.years)
  const [storeMonthExp, setStoreMonthExp] = useState<number>(timespent.months)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [jobTitleList, setJobTitleList] = useState<string[]>([])
  const [showFineTuneJobTitles, setShowFineTuneJobTitles] = useState<boolean>(false)
  const [fineTuneObject, setFineTuneObject] = useState<{type: string; prompt: string}>({
    type: '',
    prompt: '',
  })
  useEffect(() => {
    const timeSpent: TimeSpent = {
      years: storeYearExp,
      months: storeMonthExp,
    }
    setTimeSpent(timeSpent)
  }, [storeYearExp, storeMonthExp])
  useEffect(() => {
    setStoreYearExp(timespent.years)
    setStoreMonthExp(timespent.months)
  }, [timespent])
  console.log(timespent)
  const handleSelectJobTitle = (value: string) => {
    if (!jobTitles.includes(value)) {
      setJobTitles([...jobTitles, value])
    }
  }
  const handleInputChange = async (e) => {
    try {
      setSearchTerm(e.target.value)

      let jobs = await Filterjobtitles(e.target.value)
      console.log(jobs)
      if (!jobs.error) {
        setJobTitleList(jobs)
      }
      if (e.target.value.length === 0) setJobTitleList([])
    } catch (err) {
      console.log('Error while getting the job titles ', err)
    }
  }
  const handleRemoveJobTitles = (value: string) => {
    const updatedJobTitles = jobTitles.filter((job) => job !== value)
    setJobTitles(updatedJobTitles)
  }
  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }
  const handleYearChange = (value) => {
    setStoreYearExp(value)
  }
  const handleMonthChange = (value) => {
    setStoreMonthExp(value)
  }
  const handleSaveCompanyFineTune = () => {
    console.log(fineTuneObject)
  }

  const handlePromptInputChange = (e) => {
    setFineTuneObject({type: 'jobTitles', prompt: e.target.value})
  }
  return (
    <Card>
      <div>
        <Flex align='center ' style={{justifyContent: 'space-between'}}>
          <span style={{fontFamily: "'Lato',sans-serif"}} className=' fs-6'>
            Job titles
          </span>
          <Tooltip title='Fine tune job titles'>
            <Flex vertical align='center' onClick={() => setShowFineTuneJobTitles(true)}>
              <AutoAwesomeIcon style={{color: '#87255b', cursor: 'pointer'}} />
            </Flex>
          </Tooltip>
        </Flex>
        <Search
          apiCallFunction={Filterjobtitles}
          statevariable={jobTitleList}
          updateFunction={setJobTitleList}
          updateUiFunction={setJobTitles}
        />
        <FineTuneModal
          showModal={showFineTuneJobTitles}
          setShowModal={setShowFineTuneJobTitles}
          handleFineTune={handleSaveCompanyFineTune}
          handleInputChange={handlePromptInputChange}
        />
        <div className={dashstyle.jobtitlecontainer}>
          {jobTitles?.map((job, index) => (
            <FilterTags removeFunction={handleRemoveJobTitles} value={job} />
          ))}
        </div>
        <div className='d-flex flex-column justify-content-evenly h-75px mt-2'>
          <div className='d-flex flex-column w-100 justify-content-between'>
            <label
              style={{fontFamily: "'Lato',sans-serif"}}
              className='btn-label d-flex align-items-center mr-2 mb-2 fs-6'
            >
              Time Spent at Current Role
            </label>
            <div className='d-flex align-items-center'>
              <Select
                style={{width: 120}}
                onChange={handleYearChange}
                value={storeYearExp}
                options={exp.map((number) => {
                  return {label: number, value: number}
                })}
              />
              <label style={{fontFamily: "'Lato',sans-serif"}} className='btn-label px-md-3'>
                Years
              </label>
              <Select
                style={{width: 120}}
                onChange={handleMonthChange}
                value={storeMonthExp}
                options={exp.map((number) => {
                  return {label: number, value: number}
                })}
              />

              <label style={{fontFamily: "'Lato',sans-serif"}} className='btn-label px-md-2'>
                Months
              </label>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default JobTitlesCard
