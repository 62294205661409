import {createContext, useContext, useState} from 'react'

const DataSearchContext = createContext<DataContextInterface | undefined>(undefined)
type DataContextInterface = {
  jsonObjects
  setJsonObjects
  showLoading: boolean
  setShowLoading: (showLoading: boolean) => void
}
const DataSearchContextProvider = ({children}) => {
  const [jsonObjects, setJsonObjects] = useState({})
  const [showLoading, setShowLoading] = useState<boolean>(false)
  let values = {
    jsonObjects,
    setJsonObjects,
    showLoading,
    setShowLoading,
  }
  return <DataSearchContext.Provider value={values}>{children}</DataSearchContext.Provider>
}

const useDataSearchContext = () => {
  const context = useContext(DataSearchContext)
  if (context) {
    return context
  }
  throw new Error('Error at key word search context')
}
export {useDataSearchContext, DataSearchContextProvider}
