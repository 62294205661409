import {FC, useState} from 'react'
import {useProfileContext} from '../context/profilecontext'
import btnStyle from '../styles/btn.module.css'
import {GetFullProfile, GetIndivdualProfileApi} from '../apicalls/ApiCall'
import {CheckContactInformation} from './CommonApiCalls/CheckSpillBeans'
import {userId} from './DataTypes/UserDataTypes'
import {CaretDoubleLeft, CaretDoubleRight} from '@phosphor-icons/react'

// This contains next prev and close button of profile which is used in single profile display
const NextBtn: FC<{showLoading}> = ({showLoading}) => {
  const [abortController, setAbortController] = useState(new AbortController())
  const {
    index,
    setIndex,
    searchList,
    setProfile,
    setShowSpillBeans,
    setEmail,
    setPhone,
    setEmailError,
    setPhoneError,
    setShowDefaultImage,
    newProfilesList,
  } = useProfileContext()

  const handleNext = async () => {
    if (
      window.location.pathname === '/allProfiles' ||
      window.location.pathname === '/flowInterface' ||
      window.location.pathname === '/lists'
    ) {
      if (newProfilesList && index < newProfilesList.length - 1) {
        const newIndex = index + 1
        let key = newProfilesList[newIndex]['key']
        try {
          setShowDefaultImage(true)
          showLoading(true)
          let res = await GetFullProfile(userId, key)
          console.log(res)
          showLoading(false)
          if (res && res.success && res.data) {
            await CheckContactInformation({
              linkedin: res.data['linkedin'],
              setShowSpillBeans,
              setEmail,
              setPhone,
              setEmailError,
              setPhoneError,
            })

            showLoading(false)
            setProfile(res.data)
            setIndex(newIndex)
            setShowDefaultImage(false)
          }
        } catch (err) {
          showLoading(false)
          console.error('Error while getting next Profile ' + err)
        }
      }
    } else {
      if (searchList && index < searchList.length - 1) {
        const newIndex = index + 1
        let profileId = searchList[newIndex]['pdlId']
        const signal = abortController.signal
        try {
          setShowDefaultImage(true)
          showLoading(true)
          let res = await GetIndivdualProfileApi(userId, profileId, signal)
          console.log(res)
          showLoading(false)
          if (res && res.success && res.data) {
            await CheckContactInformation({
              linkedin: res.data['linkedin'],
              setShowSpillBeans,
              setEmail,
              setPhone,
              setEmailError,
              setPhoneError,
            })

            showLoading(false)
            setProfile(res.data)
            setIndex(newIndex)
            setShowDefaultImage(false)
          }
        } catch (err) {
          showLoading(false)
          console.error('Error while getting next Profile ' + err)
        }
      }
    }
  }

  return (
    <div>
      <button
        style={{
          padding: '0px',
          color: index === newProfilesList.length - 1 ? 'grey' : 'black',
          cursor: index === newProfilesList.length - 1 ? 'not-allowed' : 'pointer',
        }}
        onClick={handleNext}
        disabled={index === newProfilesList.length - 1 || newProfilesList.length === 1}
        className={`${btnStyle.nextBtn} ${
          index === searchList.length ? btnStyle.disabledBtn : ''
        }}`}
      >
        Next Profile <i className='bi bi-arrow-right'> </i>
        {/* <CaretDoubleRight
          size={22}
          style={{
            color: index === newProfilesList.length - 1 ? 'grey' : 'black',
            cursor: index === newProfilesList.length - 1 ? 'not-allowed' : 'pointer',
          }}
        /> */}
      </button>
    </div>
  )
}

const PrevBtn: FC<{showLoading}> = ({showLoading}) => {
  const [abortController, setAbortController] = useState(new AbortController())
  const signal = abortController.signal
  const {
    index,
    setIndex,
    searchList,
    setProfile,
    setShowSpillBeans,
    setEmail,
    setPhone,
    setEmailError,
    setPhoneError,
    setShowDefaultImage,
    newProfilesList,
  } = useProfileContext()

  const handlePrev = async () => {
    if (
      window.location.pathname === '/allProfiles' ||
      window.location.pathname === '/flowInterface' ||
      window.location.pathname === '/lists'
    ) {
      if (index >= 1) {
        const newIndex = index - 1
        let key = newProfilesList[newIndex]['key']
        const signal = abortController.signal
        try {
          setShowDefaultImage(true)
          showLoading(true)
          let res = await GetFullProfile(userId, key)
          showLoading(false)
          if (res && res.success && res.data) {
            await CheckContactInformation({
              linkedin: res['linkedin'],
              setShowSpillBeans,
              setEmail,
              setPhone,
              setEmailError,
              setPhoneError,
            })

            showLoading(false)
            setProfile(res.data)
            setIndex(newIndex)
            setShowDefaultImage(false)
          }
        } catch (err) {
          showLoading(false)
          console.error('Error while getting next Profile ' + err)
        }
      }
    } else {
      if (index >= 1) {
        const newIndex = index - 1
        setIndex(newIndex)
        let profileId = searchList[newIndex]['pdlId']
        try {
          showLoading(true)
          setShowDefaultImage(true)
          let res = await GetIndivdualProfileApi(userId, profileId, signal)
          showLoading(false)
          console.log(res)
          if (res && res.success && res.data) {
            await CheckContactInformation({
              linkedin: res['linkedin'],
              setShowSpillBeans,
              setEmail,
              setPhone,
              setEmailError,
              setPhoneError,
            })
            showLoading(false)
            setProfile(res.data)
            setIndex(newIndex)
            setShowDefaultImage(false)
          }
        } catch (err) {
          showLoading(false)
          console.error('Error while getting next Profile ' + err)
        }
      }
    }
  }

  return (
    <div>
      <button
        style={{
          padding: '0px',
          color: index <= 0 ? 'grey' : 'black',
          cursor: index <= 0 ? 'not-allowed' : 'pointer',
        }}
        onClick={handlePrev}
        className={`${btnStyle.prevBtn} ${index <= 0 ? btnStyle.disabledBtn : ''}`}
        disabled={index <= 0}
      >
        <i className='bi bi-arrow-left'></i> Prev Profile
        {/* <CaretDoubleLeft
          size={22}
          style={{
            color: index <= 0 ? 'grey' : 'black',
            cursor: index <= 0 ? 'not-allowed' : 'pointer',
          }}
        /> */}
      </button>
    </div>
  )
}

const CloseBtn: FC = () => {
  const {setShowProfile} = useProfileContext()

  const handleClose = () => {
    setShowProfile(false)
  }

  return (
    <div>
      <button onClick={handleClose} className={btnStyle.prevBtn}>
        <i id={btnStyle.closeIcon} className='bi bi-x-lg'></i>
      </button>
    </div>
  )
}

export {NextBtn, PrevBtn, CloseBtn}
