import React, {useState} from 'react'
import {Row, Col, Card, Flex, Divider} from 'antd'
import {SupportAgent, HelpOutline} from '@mui/icons-material'
import CampaignIcon from '@mui/icons-material/Campaign'
import InsightsIcon from '@mui/icons-material/Insights'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import CodeIcon from '@mui/icons-material/Code'
const teamIcons = {
  Marketing: <CampaignIcon style={{width: '36px', height: '36px', color: 'black'}} />,
  Sales: <InsightsIcon style={{width: '36px', height: '36px', color: 'black'}} />,
  Operations: <ManageAccountsIcon style={{width: '36px', height: '36px', color: 'black'}} />,
  Tech: <CodeIcon style={{width: '36px', height: '36px', color: 'black'}} />,
  Support: <SupportAgent style={{width: '36px', height: '36px', color: 'black'}} />,
  Others: <HelpOutline style={{width: '36px', height: '36px', color: 'black'}} />,
}

const TeamInfo = ({typeOfTeam, teamType}) => {
  const [teamSelected, setTeamSelected] = useState<boolean>(false)
  const [type, setType] = useState<string>('')
  const [listOfTeams, setListOfTeams] = useState([
    {type: 'Marketing', icon: teamIcons.Marketing, selected: false},
    {type: 'Sales', icon: teamIcons.Sales, selected: false},
    {type: 'Operations', icon: teamIcons.Operations, selected: false},
    {type: 'Tech', icon: teamIcons.Tech, selected: false},
    {type: 'Support', icon: teamIcons.Support, selected: false},
    {type: 'Others', icon: teamIcons.Others, selected: false},
  ])
  const handleTeamSelection = (index) => {
    setTeamSelected(true)
    let selectedTeam = listOfTeams[index].type
    typeOfTeam({type: selectedTeam})
    setType(selectedTeam)
    setListOfTeams(
      listOfTeams.map((team, i) =>
        i === index ? {...team, selected: true} : {...team, selected: false}
      )
    )
  }

  return (
    <Flex vertical gap={'middle'}>
      <Divider>
        <span className='heading fs-3'>Which team are you on?</span>
      </Divider>
      <Row gutter={[16, 16]}>
        {listOfTeams.map((list, index) => (
          <Col span={8} key={index}>
            <Card
              style={{
                width: '90%',
                padding: '20px',
                cursor: 'pointer',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 2px 10px 0px',
                background:
                  list.selected || teamType.type == listOfTeams[index].type ? '#f3f3f3' : 'white',
              }}
              hoverable
              onClick={() => handleTeamSelection(index)}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                }}
              >
                {list.icon}
                <span>{list.type}</span>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Flex>
  )
}

export default TeamInfo
