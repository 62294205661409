import React, {useEffect, useState} from 'react'
import {Card, Flex, Button, Avatar, Input, message, List, Divider, Spin, Popconfirm} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'
import {useEmailContext} from '../../context/emailContext'
import {
  ConnectLinkedinAccount,
  ConnectLinkedinApi,
  DeleteLinkedinAccountApi,
} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import {LinkedinAccountType} from '../DataTypes/AccountsType'
import {CheckLinkedinLoginApi, GetUserInformation} from '../../apicalls/AutomationAPI'
import DeleteIcon from '@mui/icons-material/Delete'
import {LoadingOutlined} from '@ant-design/icons'
import {GetLinkedinAccounts} from '../CommonApiCalls/AccountsApi'
const LinkedinAccount = () => {
  const {liAtToken, setLiAtToken} = useEmailContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [connectLoading, setConnectLoading] = useState<boolean>(false)
  const [liAt, setLiAt] = useState<string>('')
  const [linkedinAccount, setLinkedinAccount] = useState<LinkedinAccountType[]>([])

  useEffect(() => {
    GetLinkedinAccounts(setLinkedinAccount, setLoading)
  }, [])
  const handleLiAtChange = (value) => {
    setLiAtToken(value)
  }

  const getUserDetails = async (liAt: string) => {
    try {
      setConnectLoading(true)
      const response = await ConnectLinkedinApi(userId, liAt)
      setConnectLoading(false)
      if (response && response.data && response.success) {
        await GetLinkedinAccounts(setLinkedinAccount, setLoading)
      } else {
        message.info(response.data)
      }
    } catch (err) {
      console.error('Error while getting the user details ' + err)
    }
  }
  const deleteLinkedinAccount = async (id) => {
    try {
      const response = await DeleteLinkedinAccountApi(userId, id)
      if (response && response.success && response.data) {
        await GetLinkedinAccounts(setLinkedinAccount, setLoading)
      } else {
        message.error('Failed to remove linkedIn account')
      }
    } catch (err) {
      console.error('Error while getting the linkedin  account to be deleted ' + err)
    }
  }

  return (
    <Card className='card mb-5 mb-xl-10'>
      <Flex vertical gap={'small'}>
        <Spin
          spinning={connectLoading}
          indicator={<LoadingOutlined style={{fontSize: 24}} spin rev={undefined} />}
        >
          <Flex vertical gap={'small'}>
            <Flex align='center' gap={''}>
              <span className='heading fw-bold'>Your LinkedIn session token</span>
              <Avatar
                size={'small'}
                src={<InfoCircleOutlined rev={undefined} style={{color: 'grey'}} />}
              />
            </Flex>
            <span className='fs-7 text-muted'>
              Every time you log into LinkedIn on your browser, a new cookie is created for that
              "session". If you log out or disconnected, the cookie expires.
            </span>
            <Flex align='center' gap={'small'}>
              <Input
                onChange={(e) => handleLiAtChange(e.target.value)}
                value={liAtToken}
                placeholder='Place your li_at'
                size='large'
              />
              <Button
                disabled={liAtToken === ''}
                onClick={() => getUserDetails(liAtToken)}
                size='large'
                type='text'
              >
                Connect
              </Button>
            </Flex>
          </Flex>
        </Spin>
        <Divider />
        <span style={{fontWeight: '600', fontSize: '16px'}}>Linkedin Accounts</span>
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined style={{fontSize: 24}} spin rev={undefined} />}
        >
          <List
            dataSource={linkedinAccount}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Popconfirm
                    title='Are you sure you want to remove this account?'
                    onConfirm={() => deleteLinkedinAccount(item.id)}
                  >
                    <DeleteIcon
                      sx={{marginBottom: '4px', marginLeft: '1px', cursor: 'pointer'}}
                      className='text-hover-danger'
                    />
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      shape='square'
                      size={'large'}
                      style={{marginTop: '5px'}}
                      src={item.linkedinProfilePic}
                    />
                  }
                  title={
                    <Flex align='center' gap={'middle'}>
                      <span className='heading fs-5'>{item.linkedinName}</span>
                      {liAt === item.liAt && <Spin size='small' />}
                    </Flex>
                  }
                  description={item.status ? 'Connected' : 'Disconnected'}
                />
              </List.Item>
            )}
          />
        </Spin>
      </Flex>
    </Card>
  )
}

export default LinkedinAccount
