import React, {FC, useEffect, useState} from 'react'
import dashstyle from '../styles/newdash.module.css'
import TextField from '@mui/material/TextField/TextField'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import {KTIcon} from '../../_metronic/helpers'
import {useValuesContext} from '../context/valuescontext'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {FilterPopupRegionsApi} from '../apicalls/ApiCall'
import {Select, Card, Slider, Row, Flex, Col, Tooltip, Tag} from 'antd'
import Search from './Reusablecomponents/Select'
import FilterTags from './Reusablecomponents/FilterTags'
import FineTuneModal from './Subcomponents/FineTuneModal'

// This function consist of the personal details filtering like the country, exp,No of profiles at search etc
const PersonalChoice: FC = () => {
  const {reqInfo, profiles, setArea, setProfiles, area, setReqInfo} = useValuesContext()
  const [showFineTuneLocation, setShowFineTuneLocation] = useState<boolean>(false)
  const [fineTuneObject, setFineTuneObject] = useState<{type: string; prompt: string}>({
    type: '',
    prompt: '',
  })
  // console.log(displayCard)
  // const handleAreaClick = (a) => {
  //   setDisplayCard(false)
  //   if (!area.includes(a)) {
  //     setArea([...area, a])
  //   }
  // }
  const handleSaveCompanyFineTune = () => {
    console.log(fineTuneObject)
  }

  const handlePromptInputChange = (e) => {
    setFineTuneObject({type: 'location', prompt: e.target.value})
  }
  const handleRemoveSearch = (val) => {
    const indexToRemove = area.indexOf(val)
    if (indexToRemove !== -1) {
      const updatedSelectedCountries = [...area]
      updatedSelectedCountries.splice(indexToRemove, 1)
      setArea(updatedSelectedCountries)
    }
  }

  // const handleReqInfoChose = (value) => {
  //   if (!reqInfo.includes(value)) {
  //     setReqInfo([...reqInfo, value])
  //   }
  // }

  const handleRemoveReqInfo = (value: string) => {
    const indexToRemove = reqInfo.indexOf(value)
    if (indexToRemove !== -1) {
      const updatedReqInfo = [...reqInfo]
      updatedReqInfo.splice(indexToRemove, 1)
      setReqInfo(updatedReqInfo)
    }
  }

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [areas, setAreas] = useState<string[]>([])
  // const handleAreaSearch = async (e) => {
  //   let searchText = e.target.value
  //   setSearchTerm(searchText)
  //   try {
  //     let data = await FilterPopupRegionsApi(searchText)
  //     if (!data.error) setAreas(data)
  //     else {
  //       setAreas([])
  //     }
  //     if (e.target.value.length === 0) setAreas([])
  //   } catch (err) {
  //     console.error('Error at location Api ', err)
  //   }
  // }
  const onChange = (value: string) => {
    if (!reqInfo.includes(value)) {
      setReqInfo([...reqInfo, value])
    }
  }
  const handleClose = (removedTag: string) => {
    // const newTags = tags.filter((tag) => tag !== removedTag);
    // console.log(newTags);
    // setTags(newTags);
  }
  const onSearch = (value: string) => {}

  const filterOption = (input: string, option?: {label: string; value: string}) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  return (
    <div>
      <Card>
        <div>
          <span style={{fontFamily: "'Lato',sans-serif"}} className='fs-6'>
            Number of Prospects
          </span>
          <Flex justify='center' gap='small' align='center'>
            <Col span={21}>
              <Slider
                styles={{
                  track: {
                    background: 'transparent',
                  },
                  tracks: {
                    background: 'teal',
                  },
                }}
                onChange={(newValue) => setProfiles(newValue)}
                defaultValue={30}
                value={Array.isArray(profiles) ? profiles[0] : profiles}
              />
            </Col>
            <Col span={3}>
              <span
                style={{fontFamily: "'Karla',sans-serif"}}
                className='btn-label pl-1'
              >{`(${profiles})`}</span>
            </Col>
          </Flex>
          <div>
            <span style={{fontFamily: "'Lato',sans-serif"}} className='fs-6 mb-2'>
              Requried contact information
            </span>
            <Select
              size='large'
              style={{width: '100%', marginTop: '8px'}}
              showSearch
              placeholder='Select required contact info'
              optionFilterProp='children'
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: 'Phone',
                  label: 'Phone',
                },
                {
                  value: 'Github',
                  label: 'Github',
                },
                {
                  value: 'Gmail',
                  label: 'Gmail',
                },
                {
                  value: 'LinkedIn',
                  label: 'LinkedIn',
                },
              ]}
            />
          </div>
          <div className={` mt-3 mb-3`}>
            {reqInfo?.map((info, index) => (
              <FilterTags removeFunction={handleRemoveReqInfo} value={info} />
            ))}
          </div>
          <div style={{position: 'relative'}} className={`${dashstyle.country}, `}>
            <Flex align='center ' style={{justifyContent: 'space-between'}}>
              <span style={{fontFamily: "'Lato',sans-serif"}} className=' fs-6'>
                Location
              </span>
              <Tooltip title='Fine tune locations'>
                <Flex vertical align='center' onClick={() => setShowFineTuneLocation(true)}>
                  <AutoAwesomeIcon style={{color: '#87255b', cursor: 'pointer'}} />
                </Flex>
              </Tooltip>
            </Flex>
            <Search
              apiCallFunction={FilterPopupRegionsApi}
              statevariable={areas}
              updateFunction={setAreas}
              updateUiFunction={setArea}
            />
          </div>
          <FineTuneModal
            showModal={showFineTuneLocation}
            setShowModal={setShowFineTuneLocation}
            handleFineTune={handleSaveCompanyFineTune}
            handleInputChange={handlePromptInputChange}
          />

          <div className={`${dashstyle.countrynamescont}`}>
            {area?.map((country, index) => (
              <FilterTags removeFunction={handleRemoveSearch} value={country} />
              // <div key={index} className={dashstyle.countrybox}>
              //   <span className={`btn-label ${dashstyle.countrynames} `}>{country}</span>{' '}
              //   <i
              //     style={{color: '#6f36e8'}}
              //     className={`bi bi-x fs-2 ps-2 ${dashstyle.close}`}
              //     onClick={() => handleRemoveSearch(country)}
              //   ></i>
              // </div>
            ))}
          </div>

          {/* <div className='d-flex flex-column justify-content-evenly h-75px mt-2'>
            <div className='d-flex w-100 justify-content-between'>
              <label
                style={{marginTop: '-3px'}}
                className='btn-label d-flex align-items-center ps-8'
              >
                Excludes Profiles from past searches
                <KTIcon iconName='information-2' className='btn-icon fs-3 mx-1' />
              </label>
              <input
                style={{border: '1px solid #7239ea', width: '1.3rem', height: '1.3rem'}}
                className={`form-check-input ml-0 ${dashstyle.checkOne}`}
                type='checkbox'
                id='checkboxNoLabel'
                value=''
                checked={excludedProfile}
                onClick={() => setExcludedProfile(!excludedProfile)}
              />
            </div>
            <div className='d-flex w-100 justify-content-between'>
              <label
                style={{marginTop: '-3px'}}
                className='btn-label d-flex align-items-center ps-8'
              >
                Priortise gender diversity
                <KTIcon iconName='information-2' className='btn-icon fs-3 mx-1' />
              </label>
              <input
                style={{border: '1px solid #7239ea', width: '1.3rem', height: '1.3rem'}}
                className={`form-check-input ml-0 ${dashstyle.checkOne}`}
                type='checkbox'
                id='checkboxNoLabel'
                value=''
                checked={excludedGender}
                onClick={() => setExcludedGender(!excludedGender)}
              />
            </div>
          </div> */}
        </div>
      </Card>
    </div>
  )
}

export default PersonalChoice
