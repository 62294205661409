/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import queryTileStyle from '../../../app/styles/queryStyle.module.css'
import dashstyle from '../../../app/styles/newdash.module.css'
import {toAbsoluteUrl, defaultUserInfos, UserInfoModel} from '../../helpers'
import {useEditQueryContext} from '../../../app/context/editquery'
import DropDownQuery from '../../../app/components/DropDownQuery'
import {useChatContext} from '../../../app/context/chatcontext'
import chatStyle from '../../../app/styles/chatstyle.module.css'
import EasyQuery from '../../../app/components/EasyQuery'
import QueryMessage from '../../../app/components/QueryMessage'
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition'
import {useSideBarContext} from '../../../app/context/sidebarcontext'
import type {MenuProps} from 'antd'
import {
  AddNewSearchAPi,
  GetSearchHistory,
  SearchWithJDApi,
  SuggestionsApi,
  UpdateChatApi,
  SearchJDTextApi,
} from '../../../app/apicalls/ApiCall'
import {useSessionContext} from '../../../app/context/sessionContext'
import {v4 as uuid} from 'uuid'
import {UserObject} from '../../layout/components/sidebar/sidebar-menu/SidebarMenuContent'
import {useApiContext} from '../../../app/context/apicontext'
import {useValuesContext} from '../../../app/context/valuescontext'
import {Newspaper, MoveRight, Bot} from 'lucide-react'
import {userId} from '../../../app/components/DataTypes/UserDataTypes'
import {styled} from '@mui/material/styles'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {TextField} from '@mui/material'
import axios from 'axios'
import {Layout, Space, Flex, Input, Button, Card, Dropdown, Menu} from 'antd'
import beanBagLogo from '../../../app/assets/BeanbagLogo.png'
import {SendOutlined} from '@ant-design/icons'
type Props = {
  isDrawer?: boolean
}

// This is the ChatUI where the user can chat with the AI, initally we define the querytiles for easy query and change the UI accordingly
const Chat: FC<Props> = ({isDrawer = false}) => {
  const {Header, Content, Footer} = Layout
  const [message, setMessage] = useState<string>('')
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const [browserErr, setBrowserErr] = useState<string>('')
  const [disableVoice, setDisbleVoice] = useState<boolean>(false)
  const {transcript, resetTranscript, browserSupportsSpeechRecognition} = useSpeechRecognition()
  const {setShowPopup} = useEditQueryContext()
  const {newSession} = useSideBarContext()
  const [searchKeys, setSearchKeys] = useState<string[]>([])

  const {setApiSearchId} = useApiContext()
  const {filterObject} = useValuesContext()
  const {
    setSearchHistory,
    sessionInteracted,
    setSessionInteracted,
    selectedSearchKey,
    searchHistory,
    searchId,
    setSearchId,
  } = useSessionContext()
  const {
    showchatUI,
    setShowchatUI,
    sendMessage,
    messages,
    setComponentInput,
    localChat,
    setIsListen,
    isListen,
    showMessages,
  } = useChatContext()
  const {dashboardData, sqlQuery} = useApiContext()
  const handlesend = () => {
    if (message !== '') {
      setShowchatUI(true)
      sendMessage(message)
      setComponentInput(message)
      setMessage('')
      resetTranscript()
      setSessionInteracted(true)
      let Id = uuid()
      setSearchId(Id)
      setApiSearchId(Id)
    }
  }
  const [showJobDesUi, setShowJobDesUi] = useState<boolean>(false)
  const [showFileUplopadSuccess, setShowFileUploadSuccess] = useState<boolean>(false)
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false)
  const [listOfSuggestions, setListOfSuggestions] = useState<string[]>([])
  const [jdText, setJdText] = useState<string>('')

  useEffect(() => {
    let arr: string[] = []
    searchHistory.map((object) => {
      return arr.push(object.searchId)
    })
    console.log(arr)
  }, [])
  useEffect(() => {
    setMessage(transcript)
  }, [transcript])
  const handleSelectSuggestion = (index: number, e) => {
    e.stopPropagation()
    setMessage(`${listOfSuggestions[index]}`)
    setShowSuggestion(false)
  }
  useEffect(() => {
    if (browserErr.length > 0) setDisbleVoice(true)
  }, [browserErr])

  const handleJDSearch = async () => {
    try {
      if (file) {
        const formData = new FormData()
        formData.append('file', file)

        const response = await axios.post('https://api.beanbag.ai/uploadfile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      } else if (jdText) {
        let data = await SearchJDTextApi(jdText)
        console.log(data)
      }
    } catch (err) {
      console.log('Error while sending the Jd' + err)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        if (sessionInteracted) {
          let searchObj = {
            searchName: 'New Search1',
            chats: messages,
            filterObj: {},
            profiles: [],
          }
          let data = await AddNewSearchAPi(userId, userId + searchId, searchObj)
          console.log(data)
          if (data) {
            setSessionInteracted(false)
            setSearchKeys(data)
            let history = await GetSearchHistory(userId)
            setSearchHistory(history)
          }
        }
      } catch (err) {
        setSearchId('')
        console.error('Error while sending the New search reuest' + err)
      }
    })()
  }, [sessionInteracted, searchId])
  useEffect(() => {
    ;(async () => {
      try {
        let targetKey = newSession ? searchId : selectedSearchKey
        if (searchId) {
          if (showMessages.length > 0) {
            setTimeout(async () => {
              let data = await UpdateChatApi(userId, userId + searchId, showMessages)
            }, 2000)
          }
        }
        if (selectedSearchKey) {
          if (showMessages.length > 0) {
            let data = await UpdateChatApi(userId, selectedSearchKey, showMessages)
          }
        }
      } catch (err) {
        console.error('Error while sending the New search request' + err)
      }
    })()
  }, [showMessages, searchId, selectedSearchKey])

  const [file, setFile] = useState<File | null>(null)
  const [err, setErr] = useState<string>('')
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0]
      setFile(selectedFile)
      setErr('')
      setJdText('')
      setShowFileUploadSuccess(true)
      setTimeout(() => {
        setShowFileUploadSuccess(false)
      }, 1500)
    } else {
      setErr('Upload a file')
    }
  }

  const handleShowSuggestions = async () => {
    try {
      let data = await SuggestionsApi(userId)

      if (data && data.success && data.data) {
        setListOfSuggestions(data.data)
      }
    } catch (err) {
      console.log('error while getting the list ' + err)
    }
    setShowSuggestion(true)
  }

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      let ok = 'error'
      setBrowserErr(ok)
    }
  }, [])

  const startListening = () => {
    setIsListen(!isListen)
    if (!isListen) {
      SpeechRecognition.startListening({continuous: true})
    } else {
      SpeechRecognition.stopListening()
    }
  }
  const localArrayJSON = localStorage.getItem('sessionarray')
  let localArray = []

  if (localArrayJSON) {
    try {
      localArray = JSON.parse(localArrayJSON)
    } catch (error) {
      console.error('Error parsing localArray JSON:', error)
    }
  }

  const items: MenuProps['items'] = listOfSuggestions.map((suggestion, index) => ({
    key: index.toString(),
    label: (
      <div
        onClick={(e) => {
          handleSelectSuggestion(index, e)
        }}
      >
        {suggestion}
      </div>
    ),
  }))

  return (
    <div
      className='card-body'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      style={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0px auto',
      }}
      onClick={() => setShowSuggestion(false)}
    >
      {showchatUI ? (
        <Layout>
          <Content style={{maxHeight: '100%', overflowY: 'scroll'}}>
            {' '}
            <div
              style={{padding: '50px'}}
              className={clsx('scroll-y   ', {' h-lg-auto': !isDrawer})}
              data-kt-element='messages'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies={
                isDrawer
                  ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                  : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
              }
              data-kt-scroll-wrappers={
                isDrawer
                  ? '#kt_drawer_chat_messenger_body'
                  : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
              }
              data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
            >
              {showMessages.map((message, index) => {
                const userInfo = userInfos[message.user]
                const state = message.type === 'in' ? 'info' : 'primary'
                const templateAttr = {}
                if (message.template) {
                  Object.defineProperty(templateAttr, 'data-kt-element', {
                    value: `template-${message.type}`,
                  })
                }
                const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                  message.type === 'in' ? 'start' : 'end'
                } `
                return (
                  <div
                    key={`message${index}`}
                    className={clsx('d-flex', contentClass, 'mb-2', {'d-none': message.template})}
                    {...templateAttr}
                  >
                    <div
                      className={clsx(
                        'd-flex flex-column align-items',
                        `align-items-${message.type === 'in' ? 'start' : 'end'}`
                      )}
                    >
                      <div
                        style={{gap: '10px'}}
                        className={clsx(
                          'd-flex',
                          `${message.type === 'in' ? 'align-items-start' : 'align-items-start'}`
                        )}
                      >
                        {message.type === 'in' ? (
                          <>
                            <img src={beanBagLogo} style={{width: '24px', height: '26px'}} />
                            <div
                              style={{
                                wordWrap: 'break-word',
                                width: message.type === 'in' ? '75%' : 'fit-content',
                              }}
                              className={clsx(
                                'rounded chat-bg',
                                'text-dark fs-6 mg-lg-50',

                                `text-${message.type === 'in' ? 'start' : 'justify'} chat-${
                                  message.type === 'in' ? 'left' : 'right'
                                }`
                              )}
                              data-kt-element='message-text'
                              onClick={(event) => {
                                if (event.target instanceof HTMLButtonElement) {
                                  setShowPopup(true)
                                }
                              }}
                            >
                              {message.content ? (
                                typeof message.content === 'string' ? (
                                  <>
                                    <Card
                                      className={queryTileStyle.queryBox}
                                      dangerouslySetInnerHTML={{__html: message.content}}
                                    ></Card>
                                  </>
                                ) : (
                                  <QueryMessage {...message.content.props} />
                                )
                              ) : (
                                <>
                                  <Card
                                    className={queryTileStyle.queryBox}
                                    dangerouslySetInnerHTML={{__html: message.text}}
                                  ></Card>{' '}
                                </>
                              )}
                            </div>{' '}
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                wordWrap: 'break-word',
                                width: message.type === 'out' ? 'fit-content' : '100%',
                                maxWidth: message.type === 'out' ? '500px' : '100%',
                                background: '#fbfbfb',
                              }}
                              className={clsx(
                                'rounded',
                                'text-dark fs-6 mg-lg-50 ',

                                `text-${message.type === 'out' ? 'start' : 'justify'} chat-${
                                  message.type === 'out' ? 'right' : 'left'
                                }`
                              )}
                              data-kt-element='message-text'
                              onClick={(event) => {
                                if (event.target instanceof HTMLButtonElement) {
                                  setShowPopup(true)
                                }
                              }}
                            >
                              {message.content ? (
                                typeof message.content === 'string' ? (
                                  <>
                                    <div dangerouslySetInnerHTML={{__html: message.content}} />{' '}
                                  </>
                                ) : (
                                  <QueryMessage {...message.content.props} />
                                )
                              ) : (
                                <>
                                  <Card
                                    className={queryTileStyle.queryBox}
                                    style={{wordWrap: 'break-word', width: '100%'}}
                                  >
                                    {message.text}
                                  </Card>
                                </>
                              )}
                            </div>
                            <img
                              src={dashboardData?.userPic}
                              className='rounded'
                              style={{width: '24px', height: '25px'}}
                              alt=''
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Content>
          <Footer>
            <div
              className={` ${chatStyle.beforeChatStart} card-footer  ps-0`}
              id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
              style={{position: 'relative', bottom: '0', height: '7vh'}}
            >
              <div style={{width: '100%'}}>
                <div className='position-relative'>
                  <Input.TextArea
                    autoSize={{minRows: 2, maxRows: 6}}
                    size='large'
                    data-kt-element='input'
                    placeholder='Enter your query here or Use your microphone'
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      handleShowSuggestions()
                    }}
                    value={message}
                    onChange={(e) => {
                      if (e.target.value === '') resetTranscript()
                      setShowSuggestion(false)
                      setMessage(e.target.value)
                    }}
                    style={{resize: 'none', paddingRight: '100px'}}
                    // onKeyDown={onEnterPress}
                  ></Input.TextArea>
                  {showSuggestion && (
                    <Card
                      style={{
                        width: '100%',
                        minHeight: 'fit-content',
                        maxHeight: '30vh',
                        position: 'absolute',
                        bottom: '70px',
                      }}
                    >
                      {listOfSuggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          onClick={(e) => {
                            handleSelectSuggestion(index, e)
                          }}
                          className='p-3 bg-hover-light-info'
                        >
                          <span className='fw-semibold'>{suggestion}</span>
                        </div>
                      ))}
                    </Card>
                  )}
                  <div
                    style={{gap: '10px', position: 'absolute', right: '10px', top: '20%'}}
                    className='d-flex'
                  >
                    <Button
                      size='large'
                      disabled={disableVoice}
                      title={isListen ? 'Stop listening' : 'Start listening '}
                      onClick={startListening}
                      icon={
                        <i
                          style={{
                            fontSize: '1em',
                          }}
                          className={`bi ${isListen ? 'bi-mic-fill text-danger' : 'bi-mic-mute'}  `}
                        ></i>
                      }
                    ></Button>
                    <Button
                      size='large'
                      type='primary'
                      disabled={disableVoice}
                      title={isListen ? 'Stop listening' : 'Start listening '}
                      onClick={handlesend}
                      icon={<SendOutlined rev={undefined} />}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </Footer>
        </Layout>
      ) : (
        <Layout>
          <Header
            style={{background: 'inherit'}}
            className='d-flex align-items-center justify-content-center'
          >
            <h1
              style={{color: '#f0795b', fontSize: '2.5em', fontFamily: "'Lato', sans-serif"}}
              className='fw-bold'
            >
              Welcome to Beanbag AI
            </h1>
          </Header>
          <Content className=' d-flex justify-content-center align-items-center '>
            <Space direction='vertical' size='middle' className='w-100'>
              <Flex gap='large' vertical align='center' justify='center'>
                <EasyQuery />
                <DropDownQuery />
              </Flex>
            </Space>

            {showJobDesUi && (
              <Card
                className='p-5'
                style={{
                  position: 'absolute',
                  height: '80vh',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  width: '50vw',
                  top: '10%',
                  left: '25%',
                  zIndex: '50',
                }}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <div
                    style={{gap: '5px'}}
                    className='d-flex align-items-center justify-content-center'
                  >
                    <Newspaper style={{width: '1.2rem', color: '#7239ea'}} />
                    <span className='fs-5'>Search by Job Description</span>
                  </div>
                  <Button type='primary' style={{gap: '5px'}} onClick={() => handleJDSearch()}>
                    Save & search
                  </Button>
                </div>
                <div
                  className='d-flex flex-column py-3'
                  style={{gap: '10px', borderBottom: '1px solid #cccccc'}}
                >
                  <div className='d-flex justify-content-start'>
                    <span
                      style={{gap: '10px'}}
                      className='d-flex fs-6 align-items-center justify-content-center'
                    >
                      Job Description{' '}
                      <Button icon={<CloudUploadIcon />}>
                        Upload file
                        <input type='file' onChange={handleFile} style={{display: 'none'}} />
                      </Button>
                      {showFileUplopadSuccess ? (
                        <span className='fs-8 text-success fw-semibold'>Uploaded</span>
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  <span className='fw-regular text-muted fs-7'>
                    You can upload PDF or text-based documents like .docx, plain text, or formatted
                    text.
                  </span>
                </div>
                <div className='d-flex flex-column' style={{gap: '10px'}}>
                  <span className='fw-regular fs-6'>
                    Alternatively, paste in your job description
                  </span>
                  <span className='fs-7 text-muted'>
                    Don't worry about the formatting, we'll take care of that for you.
                  </span>
                  <TextField
                    id='outlined-basic'
                    value={jdText}
                    onChange={(e) => setJdText(e.target.value)}
                    onFocus={() => setFile(null)}
                    multiline
                    rows={15}
                    variant='outlined'
                  />
                </div>
              </Card>
            )}
            {showJobDesUi && (
              <div onClick={() => setShowJobDesUi(false)} className={dashstyle.backdrop}></div>
            )}
          </Content>
          <Footer>
            <div
              className={` ${chatStyle.beforeChatStart} card-footer  ps-0`}
              id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
              style={{position: 'relative', bottom: '0', height: '7vh'}}
            >
              <div style={{width: '80%'}}>
                <div className='position-relative'>
                  <Dropdown
                    menu={{items}}
                    placement='topLeft'
                    open={showSuggestion}
                    trigger={['click']}
                  >
                    <Input.TextArea
                      autoSize={{minRows: 2, maxRows: 6}}
                      size='large'
                      data-kt-element='input'
                      placeholder='Enter your query here or Use your microphone'
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleShowSuggestions()
                      }}
                      value={message}
                      onChange={(e) => {
                        if (e.target.value === '') resetTranscript()
                        setShowSuggestion(false)
                        setMessage(e.target.value)
                      }}
                      style={{resize: 'none', paddingRight: '100px'}}
                    ></Input.TextArea>
                  </Dropdown>
                  {/* {showSuggestion && (
                    <Card
                      style={{
                        width: '100%',
                        minHeight: 'fit-content',
                        maxHeight: '30vh',
                        position: 'absolute',
                        bottom: '60px',
                      }}
                    >
                      {listOfSuggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          onClick={(e) => {
                            handleSelectSuggestion(index, e)
                          }}
                          className='p-3 bg-hover-light-info'
                        >
                          <span className='fw-semibold'>{suggestion}</span>
                        </div>
                      ))}
                    </Card>
                  )} */}
                  <div
                    style={{gap: '10px', position: 'absolute', right: '10px', top: '20%'}}
                    className='d-flex'
                  >
                    <Button
                      size='large'
                      disabled={disableVoice}
                      title={isListen ? 'Stop listening' : 'Start listening '}
                      onClick={startListening}
                      icon={
                        <i
                          style={{
                            fontSize: '1em',
                          }}
                          className={`bi ${isListen ? 'bi-mic-fill text-danger' : 'bi-mic-mute'}  `}
                        ></i>
                      }
                    ></Button>
                    <Button
                      size='large'
                      type='primary'
                      disabled={disableVoice}
                      title={isListen ? 'Stop listening' : 'Start listening '}
                      onClick={handlesend}
                      icon={<SendOutlined rev={undefined} />}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </Footer>
        </Layout>
      )}
      {showchatUI && (
        <>
          {showJobDesUi && (
            <Card
              className='p-5'
              style={{
                position: 'absolute',
                height: '80vh',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                width: '50vw',
                top: '10%',
                left: '25%',
                zIndex: '50',
              }}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <div
                  style={{gap: '5px'}}
                  className='d-flex align-items-center justify-content-center'
                >
                  <Newspaper style={{width: '1.2rem', color: '#7239ea'}} />
                  <span className='fs-5'>Search by Job Description</span>
                </div>
                <button
                  style={{gap: '5px'}}
                  onClick={() => handleJDSearch()}
                  className='d-flex align-items-center justify-content-center btn btn-light-success h-30px text-hover-light bg-hover-success'
                >
                  Save & search <MoveRight style={{width: '20px'}} />
                </button>
              </div>
              <div
                className='d-flex flex-column py-3'
                style={{gap: '10px', borderBottom: '1px solid #cccccc'}}
              >
                <div className='d-flex justify-content-start'>
                  <span
                    style={{gap: '10px'}}
                    className='d-flex fs-6 align-items-center justify-content-center'
                  >
                    Job Description{' '}
                    <Button
                      className='bg-light-info text-info fw-bold bg-hover-info text-hover-light'
                      icon={<CloudUploadIcon />}
                    >
                      Upload file
                      <input type='file' onChange={handleFile} style={{display: 'none'}} />
                    </Button>
                    {showFileUplopadSuccess ? (
                      <span className='fs-8 text-success fw-semibold'>Uploaded</span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
                <span className='fw-regular text-muted fs-7'>
                  You can upload PDF or text-based documents like .docx, plain text, or formatted
                  text.
                </span>
              </div>
              <div className='d-flex flex-column' style={{gap: '10px'}}>
                <span className='fw-regular fs-6'>
                  Alternatively, paste in your job description
                </span>
                <span className='fs-7 text-muted'>
                  Don't worry about the formatting, we'll take care of that for you.
                </span>
                <TextField
                  id='outlined-basic'
                  value={jdText}
                  onChange={(e) => setJdText(e.target.value)}
                  onFocus={() => setFile(null)}
                  multiline
                  rows={15}
                  variant='outlined'
                />
              </div>
            </Card>
          )}
          {showJobDesUi && (
            <div onClick={() => setShowJobDesUi(false)} className={dashstyle.backdrop}></div>
          )}
          {/* <div
            className={` ${chatStyle.chatFooter} card-footer  ps-0`}
            id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
            style={{position: 'relative', bottom: '0', height: '7vh'}}
          >
            <div style={{width: '100%'}} className='position-relative '>
              <div className='position-relative'>
                <textarea
                  className='form-control form-control-flush mb-3 '
                  id={chatStyle.chatborder}
                  data-kt-element='input'
                  placeholder='Enter your query here or Use your microphone'
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleShowSuggestions()
                  }}
                  // onClick={() => setShowSuggestion(false)}
                  value={message}
                  onChange={(e) => {
                    if (e.target.value === '') resetTranscript()
                    setShowSuggestion(false)
                    setMessage(e.target.value)
                  }}
                  onKeyDown={onEnterPress}
                ></textarea>
                {showSuggestion && (
                  <Card
                    style={{
                      width: '100%',
                      minHeight: 'fit-content',
                      maxHeight: '30vh',
                      position: 'absolute',
                      bottom: '60px',
                    }}
                  >
                    {listOfSuggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        onClick={(e) => {
                          handleSelectSuggestion(index, e)
                        }}
                        className='p-3 bg-hover-light-info'
                      >
                        <span className='fw-semibold'>{suggestion}</span>
                      </div>
                    ))}
                  </Card>
                )}
              </div>
              <button
                disabled={disableVoice}
                className={`btn btn-sm btn-icon hover:btn-active-light-primary`}
                type='button'
                data-bs-toggle='tooltip'
                title={isListen ? 'Stop listening' : 'Start listening '}
                onClick={startListening}
                style={{
                  position: 'absolute',
                  top: '13px',
                  right: '62px',
                }}
              >
                <i
                  style={{
                    fontSize: '2em',
                  }}
                  className={`bi ${isListen ? 'bi-mic-fill text-danger' : 'bi-mic-mute'}  `}
                ></i>
              </button>

              <i
                onClick={() => {
                  handlesend()
                }}
                id={chatStyle.sendBtn}
                className='bi bi-send-fill text-info'
              ></i>
            </div>

            <div className=' d-flex justify-content-end align-items-center'></div>
          </div> */}
        </>
      )}
    </div>
  )
}
export {Chat}
