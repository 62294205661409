import React, {useEffect, useState} from 'react'
import {Button, Input, Flex, message, Modal, Divider, Tag} from 'antd'
import {UsergroupAddOutlined} from '@ant-design/icons'
import {GetUserSettingsApi, SendInvitationApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import PageLoader from '../Subcomponents/PageLoader'
import {getTeamMembers} from '../CommonApiCalls/TeamsettingsApi'
import {useTeamSettingContext} from '../../context/TeamSettings'

const InviteTeamMem = () => {
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false)
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const {setTeamData} = useTeamSettingContext()
  const [availableSeats, setAvailableSeats] = useState(0)
  const [totalSeats, setTotalSeats] = useState(0)

  console.log(availableSeats)
  const GetUserSettings = async () => {
    try {
      let data = await GetUserSettingsApi(userId)
      if (data && data.success && data.data) {
        if (data.data['availableSeats']) setAvailableSeats(data.data['availableSeats'])
        if (data.data['totalSeats']) setTotalSeats(data.data['totalSeats'])
      } else {
        message.error('Error while getting the available seats ')
      }
    } catch (err) {
      message.error('Error while getting the available seats ')
      console.error('Error while getting the available seats ', err)
    }
  }
  useEffect(() => {
    GetUserSettings()
  }, [])
  const handleInvite = async () => {
    try {
      let obj = {
        userId: emailAddress,
        status: 'Pending',
        emailCredits: 0,
        emailTotalCredits: 0,
        mobileCredits: 0,
        mobileTotalCredits: 0,
        teamMemRole: 'User',
      }
      setShowLoader(true)
      const response = await SendInvitationApi(userId, obj)
      setShowLoader(false)
      if (response && response.success && response.data) {
        if (response.data === 'No seats') {
          message.warning('Reached maximum seats, Upgrade to add more')
        } else {
          message.success(`${emailAddress} has been invited`)
          setShowInviteModal(false)
          await getTeamMembers({setShowLoader, setTeamData})
          await GetUserSettings()
        }
      } else {
        message.error(<span style={{textTransform: 'capitalize'}}>{response.data}</span>)
      }
    } catch (err) {
      message.error('Error while inviting team members ')
      console.error('Error while submitting the email', err)
    }
  }

  return (
    <div>
      {showLoader && <PageLoader />}
      <Flex justify='space-between' align='center'>
        <span className='heading fs-4'>Invite Team Members to team</span>
        <Flex gap='middle' align='center'>
          <div>
            <Tag color={'#8d86c9'} style={{padding: '4px 8px 5px 8px', fontSize: '16px'}}>
              {availableSeats}/{totalSeats}
            </Tag>
          </div>

          <Button
            disabled={availableSeats === 0}
            type='primary'
            icon={<UsergroupAddOutlined rev={undefined} />}
            onClick={() => {
              setShowInviteModal(true)
              setEmailAddress('')
            }}
          >
            Invite
          </Button>
        </Flex>
      </Flex>
      <Modal
        open={showInviteModal}
        closable={false}
        centered
        footer={[
          <Button onClick={() => setShowInviteModal(false)}>Close</Button>,
          <Button type='primary' disabled={!emailAddress} onClick={() => handleInvite()}>
            Send invite
          </Button>,
        ]}
      >
        {showLoader && <PageLoader />}
        <Flex vertical className='mt-3' gap={'small'}>
          <span className='fs-3 fw-bold'>User Information</span>
          <Divider style={{margin: '5px 0'}}> </Divider>
          <span className='fs-4  heading'>Enter Email Address</span>
          <Input
            required
            size='large'
            placeholder='Enter Team member Email'
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <span className='fs-7 text-grey'>The invitation will be sent to this Email address</span>
        </Flex>
      </Modal>
    </div>
  )
}

export default InviteTeamMem
