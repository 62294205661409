import React from 'react'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
const EmailIcons = ({width, height}) => {
  return (
    <MailOutlineIcon
      style={{color: '#f5222d', padding: 'none', width: width, height: height, cursor: 'pointer'}}
    />
  )
}

export default EmailIcons
