import React, {useState} from 'react'
import {Flex} from 'antd'

const CompanyDescriptionBlock = ({companyAddress, companyDes}) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const truncatedDescription = () => {
    const words = companyDes.split(' ')
    return words.slice(0, 50).join(' ')
  }

  return (
    <Flex vertical>
      <span className='heading fw-bold fs-4'>About company</span>
      <div style={{maxHeight: expanded ? 'none' : '150px', overflowY: 'auto'}}>
        <span style={{fontSize:'0.9em'}}>{expanded ? companyDes : truncatedDescription()}</span>
      </div>
      {companyDes.split(' ').length > 50 && (
        <span style={{color: 'blue', cursor: 'pointer',fontSize:'0.9em'}} onClick={toggleExpanded}>
          {expanded ? 'Show Less' : `Show  more`}
        </span>
      )}
    </Flex>
  )
}

export default CompanyDescriptionBlock
