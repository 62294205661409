import React, {Suspense} from 'react'
import {Table, Row, Col, Flex, Avatar, Empty} from 'antd'
import {CircleUserRound} from 'lucide-react'
const LazyCompanyLogo = React.lazy(() => import('../CommonApiCalls/CompanyLogo'))

const EduTable = ({educationDetails}) => {
  const columns = [
    educationDetails &&
      educationDetails.length > 0 &&
      (educationDetails[0].name
        ? {
            title: '',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
              <Flex justify='middle'>
                <Col span={5}>
                  <Suspense
                    fallback={
                      <Avatar
                        src={<CircleUserRound />}
                        style={{objectFit: 'contain', width: '20px', height: '20px'}}
                      />
                    }
                  >
                    <Avatar
                      shape='square'
                      style={{objectFit: 'contain', width: '30px', height: '30px'}}
                      src={<LazyCompanyLogo companyName={name} />}
                    />
                  </Suspense>
                </Col>
                <Col>
                  <Flex vertical>
                    <span style={{fontFamily: "'Lato',sans-serif"}} className='fs-6'>
                      {name ? name.charAt(0).toUpperCase() + name.slice(1) : ''}
                    </span>
                    <span className='text-grey fs-7'>{record.duration}</span>
                  </Flex>
                </Col>
              </Flex>
            ),
          }
        : {
            title: '',
            dataIndex: 'companyName',
            key: 'name',
            render: (name, record) => (
              <Flex justify='middle'>
                <Col span={2}>
                  <Suspense
                    fallback={
                      <Avatar
                        src={<CircleUserRound />}
                        style={{objectFit: 'contain', width: '20px', height: '20px'}}
                      />
                    }
                  >
                    <Avatar
                      shape='square'
                      style={{objectFit: 'contain', width: '30px', height: '30px'}}
                      src={<LazyCompanyLogo companyName={name} />}
                    />
                  </Suspense>
                </Col>
                <Col>
                  <Flex vertical>
                    <span style={{fontFamily: "'Lato',sans-serif"}} className='fs-6'>
                      {name ? name.charAt(0).toUpperCase() + name.slice(1) : ''}
                    </span>
                    <span className='text-grey fs-7'>{record.duration}</span>
                  </Flex>
                </Col>
              </Flex>
            ),
          }),
  ]

  return educationDetails.length > 0 ? (
    <Table
      showHeader={false}
      style={{ padding: '5px' }}
      size='small'
      columns={columns}
      dataSource={educationDetails}
      pagination={false}
    />
  ) : (
    <Empty />
  )
}

export default EduTable
