import React, {createContext, useContext, useState} from 'react'

interface ActivityDashContextProps {
  // Define your context properties here
  spilledData: {message: string; key: string}[]
  setSpilledData: (spilledData: {message: string; key: string}[]) => void
}

const ActivityDashContext = createContext<ActivityDashContextProps | undefined>(undefined)

const ActivityDashContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [spilledData, setSpilledData] = useState<{message: string; key: string}[]>([])
  let value = {
    spilledData,
    setSpilledData,
  }

  return <ActivityDashContext.Provider value={value}>{children}</ActivityDashContext.Provider>
}

const useActivityDashContext = () => {
  const context = useContext(ActivityDashContext)
  if (!context) {
    throw new Error('useActivityDashContext must be used within an ActivityDashContextProvider')
  }
  return context
}

export {useActivityDashContext, ActivityDashContextProvider, ActivityDashContext}
