import React, {useEffect, useState} from 'react'
import {useUserSettingContext} from '../../context/UserSettings'
import {Upload, Flex, Input, message} from 'antd'
import type {UploadFile, UploadProps} from 'antd'
import {EditOutlined, CheckOutlined, CloseOutlined, PlusOutlined} from '@ant-design/icons'
import {userId} from '../../components/DataTypes/UserDataTypes'
import {UpdateUserSettingsApi} from '../../apicalls/ApiCall'
interface CreditDetail {
  creditType: string
  amount: number
}

interface CreditDetail {
  creditType: string
  amount: number
}

interface Communication {
  Email: string
  Phone: string
}

interface SignInObject {
  userName: string
  password: string
}

interface PlanDetails {
  planName: string
  creditDetails: CreditDetail[]
  expiry: string
}

interface UserProfile {
  profilePic: string
  fullName: string
  company: string
  contactPhone: string
  companySite: string
  country: string
  Language: string
  TimeZone: string
  Currency: string
  Communication: Communication
  SignInObject: SignInObject
  PlanDetails: PlanDetails
}

const AccountHeader: React.FC = () => {
  const {profileObj} = useUserSettingContext()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [dataProfileObj, setDataProfileObj] = useState<UserProfile>()
  const [editName, setEditName] = useState(false)
  const [newName, setNewName] = useState('')

  useEffect(() => {
    if (profileObj) {
      setDataProfileObj(profileObj)
      console.log(profileObj)
      if (profileObj['userName']) setNewName(profileObj['userName'])
    }
  }, [profileObj])

  const uploadButton = (
    <button style={{border: 0, background: 'none'}} type='button'>
      <PlusOutlined rev={undefined} />
      <div>Upload</div>
    </button>
  )

  const handleChange: UploadProps['onChange'] = async ({fileList: newFileList}) => {
    setFileList(newFileList)
  }

  const handleEditName = () => {
    setEditName(true)
    setNewName(profileObj['userName'] || '')
  }

  const handleSaveName = async () => {
    const updatedProfileObj = {...profileObj}
    updatedProfileObj.userName = newName.trim()
    try {
      let data = await UpdateUserSettingsApi(userId, updatedProfileObj)
      setEditName(false)
      if (data && data.success && data.data) {
        message.success('User name updated')
      } else {
        message.error('Error while saving user name')
      }
    } catch (err) {
      message.error('Error while saving user name')
      console.error('Error while updating profile name ' + err)
    }
  }

  const handleCancelEdit = () => {
    setEditName(false)
    setNewName(profileObj['userName'] || '')
  }

  return (
    <Flex align='center' gap={'small'}>
      <Upload
        action={`https://api.beanbag.ai/uploadProfilePicture/${userId}`}
        listType='picture-circle'
        fileList={fileList}
        onChange={handleChange}
        style={{width: '50px', height: '50px'}}
      >
        {fileList.length >= 1 ? null : profileObj?.photoUrl ? (
          <img
            src={profileObj.photoUrl}
            alt='Profile pic'
            style={{width: '70px', height: '70px', objectFit: 'cover', borderRadius: '50%'}}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      <Flex align='center' gap={'middle'}>
        {editName ? (
          <>
            <Input value={newName} size='large' onChange={(e) => setNewName(e.target.value)} />
            <CheckOutlined
              onClick={handleSaveName}
              style={{cursor: 'pointer', fontSize: '15px'}}
              rev={undefined}
            />
            <CloseOutlined
              onClick={handleCancelEdit}
              style={{cursor: 'pointer', fontSize: '15px'}}
              rev={undefined}
            />
          </>
        ) : (
          <>
            <span style={{fontSize: '2.5em'}} className='heading fw-bold'>
              {newName}
            </span>
            <EditOutlined
              onClick={handleEditName}
              style={{cursor: 'pointer', fontSize: '20px', marginTop: '7px'}}
              rev={undefined}
            />
          </>
        )}
      </Flex>
    </Flex>
  )
}

export {AccountHeader}
