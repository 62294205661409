import React, {useState} from 'react'
import {
  Empty,
  Input,
  List,
  Dropdown,
  Flex,
  Button,
  Spin,
  message,
  Row,
  Col,
  Badge,
  Popconfirm,
  notification,
} from 'antd'
import {v4 as uuidv4} from 'uuid'
import {LoadingOutlined} from '@ant-design/icons'
import type {MenuProps} from 'antd'
import {FlowsArray} from '../../context/FlowContext'
import {
  AddProfileToExistingFlowApi,
  AddProfileToFlowApi,
  AddProfileToListApi,
  CreateNewListApi,
  GetFlowListApi,
  GetListsApi,
  UpdateFlowDetailsApi,
} from '../../apicalls/ApiCall'
import {userId, userName} from '../DataTypes/UserDataTypes'
import {SearchOutlined} from '@ant-design/icons'
import {useProfileContext} from '../../context/profilecontext'
const AddListFlowBtn = ({profile}) => {
  const {profileCount} = useProfileContext()
  const [listName, setListName] = useState<string>('')
  const [createFlowLoading, setCreateFlowLoading] = useState<boolean>(false)
  const [showGettingData, setShowGettingData] = useState<boolean>(false)
  const [showAddToListMsg, setShowAddToListMsg] = useState<boolean>(false)
  const [showCreateList, setShowCreateList] = useState<boolean>(false)
  const [userLists, setUserLists] = useState<{listId: string; listName: string}[]>([])
  const [listItems, setListItems] = useState<MenuProps['items']>([])
  const [flowItems, setFlowItems] = useState<MenuProps['items']>([])
  const [disableLists, setDisableLists] = useState<boolean>(true)
  const [disableFlows, setDisableFlows] = useState<boolean>(true)
  const [userFlows, setUserFlows] = useState<FlowsArray[]>([])
  const [userFilteredLists, setUserFilteredLists] =
    useState<{listId: string; listName: string}[]>(userLists)
  const [flowName, setFlowName] = useState<string>('')
  const [showCreateFlow, setShowCreateFlow] = useState<boolean>(false)
  const [showAddToFlowMsg, setShowAddToFlowMsg] = useState<boolean>(false)
  const [createListLoading, setCreateListLoading] = useState<boolean>(false)
  const [userFilteredFlows, setUserFilteredFlows] = useState<FlowsArray[]>([])
  const [addingToFlow, setAddingToFlow] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()
  const handleListSearchChange = (e) => {
    let value: string = e.target.value
    setListName(value)
    let filteredLists = userLists.filter((list) => {
      return list.listName.toLowerCase().includes(value.toLowerCase())
    })
    if (filteredLists.length > 0) {
      setUserFilteredLists(filteredLists)
      setShowCreateList(false)
      setShowAddToListMsg(false)
    } else {
      setShowCreateList(true)
      setShowAddToListMsg(true)
    }
    if (value.length === 0) {
      setUserFilteredLists(userLists)
    }
  }

  const handleCreateList = async (e) => {
    e.stopPropagation()
    setCreateListLoading(true)
    try {
      let listObj = {
        listId: uuidv4(),
        listName: listName,
      }
      let data = await CreateNewListApi(userId, listObj)
      if (data.success && data.data) {
        message.success('List created successfully')
        setUserLists(data.data)
        setUserFilteredLists(data.data)
        setShowCreateList(false)
        setListName('')
        setShowAddToListMsg(false)
        setCreateListLoading(false)
      }
    } catch (err) {
      message.error('Error while creating list ')
      console.log('Error in creating new List : ', err)
    }
  }

  const handleListDropDown = async (e) => {
    setShowGettingData(true)

    try {
      let data = await GetListsApi(userId)
      if (data.success && data.data) {
        setListItems(data.data)
        setUserLists(data.data)
        setUserFilteredLists(data.data)
        setShowAddToListMsg(false)
        setShowGettingData(false)
      } else {
        setShowAddToListMsg(true)
        setShowGettingData(false)
      }
    } catch (err) {
      setUserLists([])
      setUserFilteredLists([])
      setShowAddToListMsg(true)
      setShowGettingData(false)
      message.error('Something went wrong while creating please try later')
      console.log('Error while getting lists ', err)
    }
  }
  const handleShowFlowDropDown = async (e) => {
    setShowGettingData(true)
    try {
      let data = await GetFlowListApi(userId)
      if (data.success && data.data) {
        setShowGettingData(false)
        setShowAddToFlowMsg(false)
        setFlowItems(data.data)
        setUserFilteredFlows(data.data)
        setUserFlows(data.data)
      } else {
        setShowCreateList(true)
        message.error('Error while getting the flows ')
        setShowGettingData(false)
      }
    } catch (err) {
      message.error('Error while getting the flows ')
      setShowGettingData(false)
      console.log('Error in getting User Flow List')
    }
  }
  const handleAddProfileToList = async (listId) => {
    try {
      let sendProfile = [profile]
      if (Array.isArray(profile)) {
        sendProfile = profile
      }
      let data = await AddProfileToListApi(userId, listId, sendProfile)
      if (data.success && data.data) {
        setFlowName('')
        message.success('Added to list successfully')
      } else {
        message.error('Error while adding to list')
      }
    } catch (e) {
      message.error('Error while adding to list')
      console.log('error', e)
    }
  }

  const AddProfileToExistingFlow = async (flowId) => {
    try {
      setAddingToFlow(true)
      let sendProfile = [profile]
      if (Array.isArray(profile)) {
        sendProfile = profile
      }
      let data = await AddProfileToExistingFlowApi(userId, flowId, sendProfile)
      setAddingToFlow(false)
      if (data.success) {
        const {noEmail, updateEmail} = data['data']
        const openNotification = () => {
          api.success({
            message: `${updateEmail} profiles were updated, ${noEmail} profiles were skipped`,
            placement: 'topRight',
          })
        }
        openNotification()
      } else {
        message.error('Error while adding to flow')
      }
    } catch (err) {
      message.error('Error while adding to flow')
      console.error('Error while adding to started flow')
    }
  }
  const handleAddProfileToNewFlow = async (flowId) => {
    try {
      setAddingToFlow(true)
      let sendProfile = [profile]
      if (Array.isArray(profile)) {
        sendProfile = profile
      }
      let data = await AddProfileToFlowApi(userId, flowId, sendProfile)
      setAddingToFlow(false)
      if (data.success) {
        const {noEmail, updateEmail} = data['data']
        const openNotification = () => {
          api.success({
            message: `${updateEmail} profiles were updated, ${noEmail} profiles were skipped`,
            placement: 'topRight',
          })
        }
        openNotification()
      } else {
        message.error('Error while adding to flow')
      }
    } catch (e) {
      message.error('Error while adding to flow')
      console.log('Error at adding profile to flow ', e)
    }
  }
  const handleAddProfileToFlow = async (record) => {
    if (record['flowStarted']) {
      AddProfileToExistingFlow(record.flowId)
    } else {
      handleAddProfileToNewFlow(record.flowId)
    }
  }

  const handleFlowsSearchChange = (e) => {
    let value: string = e.target.value
    setFlowName(value)
    let filteredFlows = userFlows.filter((Flows) => {
      return Flows.flowName.toLowerCase().includes(value.toLowerCase())
    })
    if (filteredFlows.length > 0) {
      setUserFilteredFlows(filteredFlows)
      setShowCreateFlow(false)

      setShowAddToFlowMsg(false)
    } else {
      setShowAddToFlowMsg(true)
    }
    if (value.length === 0) {
      setUserFilteredLists(userLists)
    }
  }

  const handleCreateFlow = async (e) => {
    setCreateFlowLoading(true)
    try {
      const currentDate = new Date()
      const mm = String(currentDate.getMonth() + 1).padStart(2, '0')
      const dd = String(currentDate.getDate()).padStart(2, '0')
      const yyyy = currentDate.getFullYear()
      const formattedDate = `${mm}/${dd}/${yyyy}`
      let i = uuidv4()
      let flowObj = {
        flowName: flowName,
        flowDescription: '',
        node: [],
        edges: [],
        createdAt: formattedDate,
        creator: userName,
        flowStarted: false,
        content: {},
      }
      let flowId = `newFlow${i}`
      let data = await UpdateFlowDetailsApi(userId, flowId, flowObj)
      if (data.success) {
        let updatedList = await GetFlowListApi(userId)
        if (updatedList.success && updatedList.data) {
          setUserFlows(updatedList.data)
          message.success('Created the flow successfully')
          setUserFilteredFlows(updatedList.data)
          setFlowItems(updatedList.data)
          setShowCreateFlow(false)
          setFlowName('')
          setShowAddToFlowMsg(false)
          setCreateFlowLoading(false)
        } else {
          message.error('Something went wrong while creating please try later')
        }
      }
    } catch (err) {
      message.error('Something went wrong while creating please try later')
      console.log('Error while creating the new flow ' + err)
    }
  }
  return (
    <Row gutter={10} className='d-flex align-items-center'>
      {contextHolder}
      {showGettingData && <Spin />}
      <Col>
        <Dropdown
          menu={{items: listItems}}
          dropdownRender={(menu) => (
            <div
              style={{minHeight: '20vh', maxHeight: '30vh', overflowY: 'scroll', padding: '10px'}}
              className='bg-white'
            >
              <Input
                value={listName}
                onChange={(e) => handleListSearchChange(e)}
                placeholder='Search/Create list'
                suffix={<SearchOutlined rev={undefined} />}
              />
              {listItems?.length === 0 || showAddToListMsg ? (
                <div
                  style={{gap: '10px'}}
                  className='d-flex mt-5 justify-content-center flex-column align-items-center h-75'
                >
                  <span className='fs-8 text-grey'>Create list with this name?</span>
                  <Button
                    onClick={(e) => handleCreateList(e)}
                    type='primary'
                    size='small'
                    loading={createListLoading}
                  >
                    Create List
                  </Button>
                </div>
              ) : (
                <List
                  dataSource={userFilteredLists}
                  renderItem={(item, index) => (
                    <List.Item
                      onClick={() => handleAddProfileToList(item.listId)}
                      className='bg-hover-light px-3'
                    >
                      <span>{item.listName}</span>
                    </List.Item>
                  )}
                ></List>
              )}
            </div>
          )}
          trigger={['click']}
        >
          <div>
            <Button
              disabled={!profile && profile.length === 0}
              onClick={(e) => {
                e.preventDefault()
                handleListDropDown(e)
              }}
              type='primary'
              icon={<i className='bi bi-clipboard2-data text-white'></i>}
            >
              Lists
            </Button>
          </div>
        </Dropdown>
      </Col>

      <Col>
        <Dropdown
          menu={{items: flowItems}}
          dropdownRender={(menu) => (
            <div
              style={{minHeight: '20vh', maxHeight: '30vh', overflowY: 'scroll', padding: '10px'}}
              className='bg-white'
            >
              <Input
                value={flowName}
                onChange={(e) => handleFlowsSearchChange(e)}
                placeholder='Search/Create flow'
                suffix={<SearchOutlined rev={undefined} />}
              />
              {flowItems?.length === 0 && !showAddToFlowMsg ? (
                <Empty
                  className='mt-3'
                  description={<span>No flows, Type to create a flow</span>}
                />
              ) : showAddToFlowMsg ? (
                <div
                  style={{gap: '10px'}}
                  className='d-flex mt-5 justify-content-center flex-column align-items-center h-75'
                >
                  <span className='fs-8 text-grey'>Create Flow with this name?</span>
                  <Button
                    onClick={(e) => handleCreateFlow(e)}
                    type='primary'
                    size='small'
                    loading={createListLoading}
                  >
                    Create flow
                  </Button>
                </div>
              ) : (
                <List
                  dataSource={userFilteredFlows}
                  renderItem={(item, index) =>
                    item.flowStarted ? (
                      <Badge.Ribbon color='#73ba9b' style={{fontSize: '10px'}} text='Started'>
                        <Popconfirm
                          title={`${profileCount} Email credits will be deducted`}
                          onConfirm={() => handleAddProfileToFlow(item)}
                        >
                          <List.Item className='bg-hover-light px-3 w-100'>
                            <Flex justify='space-between' style={{width: '100%'}}>
                              <span>{item.flowName}</span>
                            </Flex>
                          </List.Item>
                        </Popconfirm>
                      </Badge.Ribbon>
                    ) : (
                      <Popconfirm
                        title={`${profileCount} Email credits will be deducted`}
                        onConfirm={() => handleAddProfileToFlow(item)}
                      >
                        <List.Item className='bg-hover-light px-3 w-100'>
                          <Flex justify='space-between' style={{width: '100%'}}>
                            <span>{item.flowName}</span>
                          </Flex>
                        </List.Item>
                      </Popconfirm>
                    )
                  }
                />
              )}
            </div>
          )}
          trigger={['click']}
        >
          <div>
            <Button
              disabled={!profile && profile.length === 0}
              onClick={(e) => {
                e.preventDefault()
                handleShowFlowDropDown(e)
              }}
              type='primary'
              icon={<i className='bi bi-send-fill text-white'></i>}
            >
              Flows
            </Button>
          </div>
        </Dropdown>
      </Col>
      <Spin
        indicator={<LoadingOutlined style={{fontSize: 30, color: 'white'}} rev={undefined} />}
        spinning={addingToFlow}
        size='large'
        fullscreen
        style={{fontWeight: '600'}}
        tip='Hold tight! Adding your profiles to the flow...'
      />
    </Row>
  )
}

export default AddListFlowBtn
