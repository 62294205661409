import React, {FC, useEffect, useState} from 'react'
import dashstyle from '../styles/newdash.module.css'
import {Card, Flex, Tooltip} from 'antd'
import {useValuesContext} from '../context/valuescontext'
import {FilterIndustries} from '../apicalls/ApiCall'
import Search from './Reusablecomponents/Select'
import FilterTags from './Reusablecomponents/FilterTags'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import FineTuneModal from './Subcomponents/FineTuneModal'
interface Industry {
  [key: string]: string
}

const initialSize = ['0-100', '100-1000', '1000-10000']

const CompanyIndustryCard: FC = () => {
  const {industries, setIndustries} = useValuesContext()
  const [searchTerm, setSearchTerm] = useState('')
  const [industriesList, setIndustriesList] = useState<string[]>([])
  const [showFineTuneIndustry, setShowFineTuneIndustry] = useState<boolean>(false)
  const [fineTuneObject, setFineTuneObject] = useState<{type: string; prompt: string}>({
    type: '',
    prompt: '',
  })
  
  const handleSaveCompanyFineTune = () => {
    console.log(fineTuneObject)
  }

  const handlePromptInputChange = (e) => {
    setFineTuneObject({type: 'industry', prompt: e.target.value})
  }
  const handleRemoveIndustry = (industry) => {
    const updatedIndustryChose = [...industries]
    const indexToRemove = updatedIndustryChose.indexOf(industry)

    if (indexToRemove !== -1) {
      updatedIndustryChose.splice(indexToRemove, 1)
      setIndustries(updatedIndustryChose)
    }
  }
  
  return (
    <Card>
      <Flex align='center ' style={{justifyContent: 'space-between'}}>
        <span style={{fontFamily: "'Lato',sans-serif"}} className=' fs-6'>
         Company Industry
        </span>
        <Tooltip title='Fine tune industries'>
          <Flex vertical align='center' onClick={() => setShowFineTuneIndustry(true)}>
            <AutoAwesomeIcon style={{color: '#87255b', cursor: 'pointer'}} />
          </Flex>
        </Tooltip>
      </Flex>
      <FineTuneModal
        showModal={showFineTuneIndustry}
        setShowModal={setShowFineTuneIndustry}
        handleFineTune={handleSaveCompanyFineTune}
        handleInputChange={handlePromptInputChange}
      />
      <div>
        <Search
          apiCallFunction={FilterIndustries}
          statevariable={industriesList}
          updateFunction={setIndustriesList}
          updateUiFunction={setIndustries}
        />
        {/* <input
            value={searchTerm}
            onChange={(event) => handleInputChange(event)}
            onClick={() => setShowIndustries(!showIndustry)}
            placeholder='Industries'
            className={`w-100 mt-3 rounded ${dashstyle.searchcompanies}`}
          />
          {showIndustry && (
            <Card
              className='mt-1'
              style={{zIndex: '5', position: 'absolute'}}
              id={dashstyle.searchIndustryCard}
            >
              {industriesList ? (
                industriesList?.length > 0 &&
                industriesList?.map((filteredIndustry, index) => {
                  return (
                    <div key={index} className='d-flex flex-column'>
                      <span
                        onClick={() => {
                          setShowIndustries(false)
                          handleIndustryChose(filteredIndustry)
                          setSearchTerm('')
                        }}
                        className={`btn-label ${dashstyle.companySelect}`}
                      >
                        {filteredIndustry}
                      </span>
                    </div>
                  )
                })
              ) : (
                <div className='d-flex flex-column'>
                  <span>No data</span>
                </div>
              )}
            </Card>
          )} */}
      </div>
      <div className={dashstyle.IndustryContainer}>
        {industries?.map((Industry, index) => (
          <FilterTags removeFunction={handleRemoveIndustry} value={Industry} />
          // <div key={index} className={`mr-2 ${dashstyle.industrybox}`}>
          //   <span className={`btn-label ${dashstyle.countrynames}`}>{Industry.toString()}</span>{' '}
          //   <i
          //     style={{color: '#6f36e8'}}
          //     className={`bi bi-x fs-2 ps-2 ${dashstyle.close}`}
          //     onClick={() => handleRemoveIndustry(Industry)}
          //   ></i>
          // </div>
        ))}
      </div>
      {/* <label className='btn-label fs-6 mt-3'>Company Size</label>
        <select
          className='form-select form-select-sm w-100'
          aria-label='.form-select-sm example'
          value={companySize}
          onChange={(e) => setCompanySize(e.target.value)}
        >
          {initialSize.map((option, index) => (
            <option key={index}>{option.toString()}</option>
          ))}
        </select> */}
    </Card>
  )
}

export default CompanyIndustryCard
