import React, {useState} from 'react'
import {
  Card,
  Flex,
  Modal,
  Button,
  Input,
  Spin,
  Switch,
  message,
  InputNumber,
  Tooltip,
  Popconfirm,
} from 'antd'
import {SendFacebookGroupMessagesApi} from '../../apicalls/AutomationAPI'
import {InfoCircleOutlined} from '@ant-design/icons'
import facebookLogo from '../../assets/facebook.png'
import {userId} from '../DataTypes/UserDataTypes'
const FacebookGroupmessage = () => {
  const [faceBookModal, setFaceBookModal] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [result, setResult] = useState<'success' | 'failure' | null>(null)
  const [userName, setUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [facebookMessage, setFacebookMessage] = useState<string>('')
  const [rephraseData, setRephraseData] = useState<boolean>(true)
  const [groupLink, setGroupLink] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [minTime, setMinTime] = useState<number>(10)
  const [maxTime, setMaxTime] = useState<number>(15)
  const handleSendFacebookMessages = async () => {
    try {
      message.info('Started sending messages ')
      setFaceBookModal(false)
      let obj = {
        userName: userName,
        groupLink: groupLink,
        password: password,
        flowTitle: title,
        userId,
        message: facebookMessage,
        rephraseData,
        min: minTime,
        max: maxTime,
      }
      await sendMessages(obj)
    } catch (err) {
      console.error('Error while sending the messages in group')
      message.error("Couldn't start the automation, please try again")
    }
  }

  const sendMessages = async (obj) => {
    try {
      await SendFacebookGroupMessagesApi(obj)
    } catch (error) {
      message.error("Couldn't start the automation, please try again")
    }
  }
  return (
    <Flex style={{width: '100%'}} vertical gap={'4px'}>
      <span style={{fontWeight: 'bold', fontSize: '1.5em'}}>Facebook</span>
      <Flex style={{width: '100%'}} gap={'large'}>
        <Card
          hoverable
          cover={
            <div
              style={{
                width: '40px',
                height: '40px',
                background: 'white',
                borderBottomRightRadius: '25px',
              }}
            >
              <img
                alt='linkedin'
                style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
                src={facebookLogo}
              />
            </div>
          }
          style={{
            width: '220px',
            height: '220px',
            background: '#1877f2',
            cursor: loadingData ? 'not-allowed' : 'pointer',
          }}
          onClick={() => {
            loadingData ? setFaceBookModal(false) : setFaceBookModal(true)
          }}
        >
          <Flex vertical>
            <Flex gap={'small'} align='center' justify='space-between'>
              <Flex align='center' vertical gap={'middle'}>
                <span
                  style={{fontWeight: '500', fontSize: '1.5em', color: 'white', lineHeight: '1.2'}}
                  className='heading'
                >
                  Auto message Group Members
                </span>{' '}
                <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
                  Automatically send messages to any Facebook group members
                </span>
              </Flex>
              {loadingData && <Spin size='small' style={{marginLeft: '5px'}} />}
              {result === 'success' && <span style={{color: 'green', marginLeft: '5px'}}>✔</span>}
              {result === 'failure' && <span style={{color: 'red', marginLeft: '5px'}}>✘</span>}
            </Flex>
          </Flex>
        </Card>
        <Modal
          centered
          closable={false}
          title='Facebook details to continue the automation'
          footer={[
            <Button key='cancel' onClick={() => setFaceBookModal(false)}>
              Cancel
            </Button>,
            <Button
              key='get-data'
              type='primary'
              disabled={!userName || !password || !groupLink || !title || !facebookMessage}
              onClick={handleSendFacebookMessages}
            >
              Send messages
            </Button>,
          ]}
          open={faceBookModal}
          onCancel={() => setFaceBookModal(false)}
        >
          <Flex vertical gap={'middle'}>
            <Flex vertical>
              <span>Automation title</span>
              <Input
                placeholder='Automation title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Flex>
            <Flex vertical>
              <Flex gap={'small'}>
                <span>User cookie</span>
                <Tooltip title='In Facebook you will find a cookie name "c_user"'>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                  />{' '}
                </Tooltip>
              </Flex>
              <Input
                placeholder='C_user'
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Flex>
            <Flex vertical>
              <Flex gap={'small'}>
                <span>XS Cookie</span>
                <Tooltip title='In Facebook you will find a cookie name "xs"'>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                  />{' '}
                </Tooltip>
              </Flex>
              <Input
                placeholder='XS cookie'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Flex>
            <Flex vertical>
              <Flex gap={'small'}>
                <span>Facebook group URL</span>
                <Tooltip title='Facebook group url to send messages EX: "https://www.facebook.com/groups/579390307427086/members" '>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                  />{' '}
                </Tooltip>
              </Flex>
              <Input
                placeholder='Group URL'
                value={groupLink}
                onChange={(e) => setGroupLink(e.target.value)}
              />
            </Flex>
            <Flex vertical>
              <Flex gap={'small'}>
                <span>Message</span>
                <Tooltip title='Type the message to be sent'>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                  />{' '}
                </Tooltip>
              </Flex>
              <Input.TextArea
                placeholder='Message to be sent to users'
                value={facebookMessage}
                onChange={(e) => setFacebookMessage(e.target.value)}
              />
            </Flex>
            <Flex align='center' justify='space-between'>
              <Flex gap={'small'}>
                <span>Rephrase message with Mr.Bean </span>
                <Tooltip title='Our Mr.Bean will rephrase each message for safer automation.'>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                  />{' '}
                </Tooltip>
              </Flex>
              {rephraseData ? (
                <Popconfirm
                  title='We recommend rephrasing the text for each message.'
                  onConfirm={() => setRephraseData(false)}
                  onCancel={() => setRephraseData(true)}
                >
                  <Switch
                    value={rephraseData}
                    // onChange={(value) => {
                    //   setRephraseData(value)
                    // }}
                    size='small'
                  />
                </Popconfirm>
              ) : (
                <Switch
                  value={rephraseData}
                  onChange={(value) => {
                    setRephraseData(value)
                  }}
                  size='small'
                />
              )}
            </Flex>
            <Flex vertical gap={'small'}>
              <Flex gap={'small'}>
                <span>Add time delay between messages in minutes </span>
                <Tooltip title='The time delay will be random between min and max for each message to be sent'>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                  />{' '}
                </Tooltip>
              </Flex>
              <Flex gap={'small'}>
                <InputNumber
                  placeholder='Select Minimum Time '
                  style={{width: '40%'}}
                  min={10}
                  value={minTime}
                  onChange={(value) => {
                    if (value) {
                      setMinTime(value)
                    }
                  }}
                ></InputNumber>
                <InputNumber
                  placeholder='Select Maximum Time '
                  style={{width: '40%'}}
                  min={13}
                  value={maxTime}
                  onChange={(value) => {
                    if (value) {
                      setMaxTime(value)
                    }
                  }}
                ></InputNumber>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      </Flex>
    </Flex>
  )
}

export default FacebookGroupmessage
