import {useState} from 'react'
import TwitterCard from '../Subcomponents/TwitterCard'
import {Flex, Modal, Button, Input, Select, message, Tooltip, Switch, InputNumber} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'
import {SendTwitterMessages} from '../../apicalls/AutomationAPI'
import {userId} from '../DataTypes/UserDataTypes'

const TwitterMessage = () => {
  const [messagemodal, setMessageModal] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [cookie, setCookie] = useState<string>('')
  const [spreadsheetLink, setSpreadsheetLink] = useState<string>('')
  const [twitterMessage, setTwitterMessage] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [rephraseData, setRephraseData] = useState<boolean>(false)
  const [minTime, setMinTime] = useState<number>(1)
  const [maxTime, setMaxTime] = useState<number>(2)
  const [replaceoptions, setReplaceOptions] = useState<{label: string; value: string}[]>([
    {label: '{{name}}', value: '{{name}}'},
  ])
  const [addReplaceValue, setAddReplaceValue] = useState<boolean>(false)
  const handleTwitterSendMessages = async () => {
    try {
      message.info('Started sending messages ')
      setMessageModal(false)
      // console.log({
      //   userId,
      //   auth: cookie,
      //   spreadsheet: spreadsheetLink,
      //   title,
      //   message: twitterMessage,
      //   replaceValues: selectedOptions,
      //   minTime: minTime,
      //   maxTime: maxTime,
      //   rephrase: rephraseData,
      // })
      await SendTwitterMessages({
        userId,
        auth: cookie,
        spreadsheet: spreadsheetLink,
        title,
        message: twitterMessage,
        replaceValues: selectedOptions,
        minTime: minTime,
        maxTime: maxTime,
        rephrase: rephraseData,
      })
    } catch (err) {
      console.error('Error while sending the messages in group')
    }
  }

  const handleSelectedOptions = (value: string[]) => {
    setSelectedOptions(value)
  }
  const handleTwitterMessageReplace = (item) => {
    const input = document.getElementById('TwitterMessage') as HTMLInputElement
    const startPos = input.selectionStart
    const endPos = input.selectionEnd

    if (startPos !== null && endPos !== null) {
      const newValue =
        input.value.substring(0, startPos) +
        item +
        input.value.substring(endPos, input.value.length)
      input.value = newValue
      const newCursorPosition = startPos + item.length
      input.setSelectionRange(newCursorPosition, newCursorPosition)
      const event = new Event('input', {bubbles: true})
      input.dispatchEvent(event)
      setTwitterMessage(input.value)
    }
  }
  return (
    <div>
      {' '}
      <TwitterCard
        loadingData={loadingData}
        setModal={setMessageModal}
        title={'Send Automatic Messages '}
        description={'Send Personalised messages to Twitter users'}
      />
      <Modal
        width={600}
        centered
        title='Connect Twitter Automation with required details to start sending messages'
        footer={[
          <Button key='cancel' onClick={() => setMessageModal(false)}>
            Cancel
          </Button>,
          <Button
            key='get-data'
            type='primary'
            disabled={
              !cookie ||
              !spreadsheetLink ||
              !title ||
              !twitterMessage ||
              (addReplaceValue && selectedOptions.length === 0)
            }
            onClick={handleTwitterSendMessages}
          >
            Send messages
          </Button>,
        ]}
        closable={false}
        maskClosable={false}
        open={messagemodal}
        onCancel={() => setMessageModal(false)}
      >
        <Flex vertical gap={'middle'}>
          <Flex vertical>
            <span>Automation title</span>
            <Input
              placeholder='Automation title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Flex>
          <Flex vertical>
            <Flex gap={'small'}>
              <span>User cookie</span>
              <Tooltip title='In Twitter you will find a cookie name "auth_token"'>
                <InfoCircleOutlined
                  rev={undefined}
                  style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            </Flex>
            <Input
              placeholder='auth_token'
              value={cookie}
              onChange={(e) => setCookie(e.target.value)}
            />
          </Flex>
          <Flex vertical>
            <Flex gap={'small'}>
              <span>Spreadsheet Link</span>
              <Tooltip title="Provide the Spreadsheet which has all the Twitter userId's in the first column and should allow editor access Ex: https://docs.google.com/spreadsheets/d/1fNaIKObHBcfwzye1E5dxI5NRGu1kP-WBTaThcxz1r4k/edit#gid=0">
                <InfoCircleOutlined
                  rev={undefined}
                  style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            </Flex>
            <Input
              placeholder='Group URL'
              value={spreadsheetLink}
              onChange={(e) => setSpreadsheetLink(e.target.value)}
            />
          </Flex>
          <Flex vertical gap={'small'}>
            <Flex align='center' justify='space-between'>
              <Flex gap={'4px'}>
                <span>Message</span>
                <Tooltip title='Provide the Message to be sent to different users'>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                  />{' '}
                </Tooltip>
              </Flex>
              <Flex align='center' gap={'small'}>
                {/* <div style={{marginTop: '3px'}}>
                  <ReplaceDropdown handleReplace={handleTwitterMessageReplace} />
                </div> */}
                <Tooltip
                  title={
                    addReplaceValue
                      ? 'Toggle to remove the replace value'
                      : 'Toggle to add the replace value'
                  }
                >
                  <Switch
                    value={addReplaceValue}
                    onChange={(value) => {
                      setAddReplaceValue(value)
                      !addReplaceValue && setSelectedOptions([])
                    }}
                    size='small'
                  />
                </Tooltip>
              </Flex>
            </Flex>
            <Input.TextArea
              rows={5}
              id='TwitterMessage'
              placeholder='Message to be sent'
              value={twitterMessage}
              onChange={(e) => setTwitterMessage(e.target.value)}
            />
            {addReplaceValue && (
              <Select
                mode='multiple'
                allowClear
                value={selectedOptions}
                style={{width: '100%'}}
                placeholder='Please select the replace values used'
                onChange={handleSelectedOptions}
                options={replaceoptions}
              />
            )}
          </Flex>
          <Flex align='center' justify='space-between'>
            <Flex gap={'small'}>
              <span>Rephrase message with Mr.Bean </span>
              <Tooltip title='Our Mr.Bean will rephrase each message for safer automation.'>
                <InfoCircleOutlined
                  rev={undefined}
                  style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            </Flex>
            <Switch
              value={rephraseData}
              onChange={(value) => {
                setRephraseData(value)
              }}
              size='small'
            />
          </Flex>
          <Flex vertical gap={'small'}>
            <Flex gap={'small'}>
              <span>Add time delay between messages in minutes </span>
              <Tooltip title='The time delay will be random between min and max for each message to be sent'>
                <InfoCircleOutlined
                  rev={undefined}
                  style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            </Flex>
            <Flex gap={'small'}>
              <InputNumber
                placeholder='Select Minimum Time '
                style={{width: '40%'}}
                min={1}
                value={minTime}
                onChange={(value) => {
                  if (value) {
                    setMinTime(value)
                  }
                }}
              ></InputNumber>
              <InputNumber
                placeholder='Select Maximum Time '
                style={{width: '40%'}}
                min={2}
                value={maxTime}
                onChange={(value) => {
                  if (value) {
                    setMaxTime(value)
                  }
                }}
              ></InputNumber>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </div>
  )
}

export default TwitterMessage
