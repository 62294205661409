import {FC} from 'react'
import {Tile1, Tile2, Tile3} from './Tile1'

// This is the main parent component which renders three cards for easy querying
const EasyQuery: FC = () => {
  return (
    <div className='d-flex w-75 justify-content-between '>
      <Tile1 />
      <Tile2 />
      <Tile3 />
    </div>
  )
}
export default EasyQuery
