import {Select} from 'antd'
import {useState} from 'react'

const Search = ({apiCallFunction, statevariable, updateFunction, updateUiFunction}) => {
  const [selectedSearchTerm, setSelectSearchterm] = useState<string>('')
  const onChange = (value: string) => {
    updateUiFunction((prev) => {
      if (!prev.includes(value)) {
        setSelectSearchterm('')
        return [...prev, value]
      }
      return prev
    })
  }

  const onSearch = async (value: string) => {
    try {
      let data = await apiCallFunction(value)
      console.log(data)
      if (data && data.success) {
        updateFunction(data.data)
      } else {
        updateFunction([])
      }
      if (value.length === 0) updateFunction([])
    } catch (err) {
      console.error('Error at location Api ', err)
    }
  }
  const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
  console.log(statevariable)
  return (
    <Select
      size='large'
      style={{width: '100%', marginBottom: '5px', marginTop: '10px'}}
      showSearch
      value={selectedSearchTerm}
      placeholder='Type to search'
      optionFilterProp='children'
      onChange={onChange}
      onSearch={onSearch}
      filterOption={filterOption}
      options={statevariable?.map((area) => {
        return {
          value: area,
          label: area.charAt(0).toUpperCase() + area.slice(1),
        }
      })}
    />
  )
}

export default Search
