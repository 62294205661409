import React, {FC, useEffect, useState} from 'react'
import {TeamDataProps} from '../DataTypes/TeamSettings'
import {
  Modal,
  Select,
  Flex,
  Row,
  Col,
  message,
  InputNumber,
  Progress,
  Button,
  Segmented,
} from 'antd'
import Tags from './Tags'
import {userId} from '../DataTypes/UserDataTypes'
import {GetCredits} from '../CommonApiCalls/Credits'
import {UpdateCreditsApi} from '../../apicalls/ApiCall'

type CreditsEditProps = {
  listOfMembers: TeamDataProps[]
  showModal: boolean
  member
  setShowModal: (showModal: boolean) => void
}

const CreditsEdit: FC<CreditsEditProps> = ({listOfMembers, showModal, member, setShowModal}) => {
  const [options, setOptions] = useState<{value: string; label: string}[]>([])
  const [selectedvalue, setSelectedValue] = useState<TeamDataProps | null>(null)
  const [emailCredits, setEmailCredits] = useState<number>(0)
  const [phoneCredits, setPhoneCredits] = useState<number>(0)
  const [emailTotalCredits, setEmailTotalCredits] = useState<number>(0)
  const [phoneTotalCredits, setPhoneTotalCredits] = useState<number>(0)
  const [emailInput, setEmailInput] = useState<number>(0)
  const [phoneInput, setPhoneInput] = useState<number>(0)
  const [action, setAction] = useState<string>('Increment')

  useEffect(() => {
    setOptions(
      listOfMembers.map((item) => {
        return {value: item.userId, label: item.userId}
      })
    )
  }, [listOfMembers])

  useEffect(() => {
    setSelectedValue(member)
    GetCredits(setEmailCredits, setEmailTotalCredits, setPhoneCredits, setPhoneTotalCredits)
  }, [member])

  const handleMemberChange = (userId: string) => {
    const selectedMember = listOfMembers.find((item) => item.userId === userId)
    if (selectedMember) {
      setSelectedValue(selectedMember)
      setEmailInput(0)
      setPhoneInput(0)
    }
  }

  const handleEmailInputChange = (value) => {
    setEmailInput(value)
  }

  const handlePhoneInputChange = (value) => {
    setPhoneInput(value)
  }

  const handleUpdateCredits = async () => {
    try {
      const Obj = {
        emailCredits: emailInput,
        mobileCredits: phoneInput,
        action: action,
      }
      const response = await UpdateCreditsApi(userId, member['userId'], Obj)
      if (response && response.success && response.data) {
        message.success('Successfully updated credits')
        setShowModal(false)
      } else {
        message.warning(response.data)
      }
    } catch (err) {
      message.error('Error while updating the credits ')
      console.error('Error while updating the Credits ', err)
    }
  }

  return (
    <Modal
      centered
      closable={false}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={[
        <Flex justify='end' key='footer-buttons'>
          <Button key='cancel' onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button key='sendInvite' type='primary' onClick={() => handleUpdateCredits()}>
            Update Credits
          </Button>
        </Flex>,
      ]}
    >
      <Flex vertical gap={'middle'} style={{padding: '15px'}}>
        <Flex vertical align='start' className='w-100'>
          <Flex align='center' justify='space-between' className='w-100'>
            <span className=''>Total Email Credits </span>
            <Tags color={'blue'} tagsContent={emailCredits} />
          </Flex>
        </Flex>
        <Flex vertical align='start' className='w-100'>
          <Flex align='center' justify='space-between' className='w-100'>
            <span className=''>Total Phone Credits </span>
            <Tags color={'blue'} tagsContent={phoneCredits} />
          </Flex>
        </Flex>
        <Flex justify='space-between' align='center'>
          <span className=' '> Select User to update Credits </span>
          <Select value={selectedvalue?.userId} onChange={handleMemberChange} options={options} />
        </Flex>
        <Flex justify='space-between' align='center'>
          <span className=' '> User Email Credits </span>
          <Tags color={'blue'} tagsContent={selectedvalue ? selectedvalue['emailCredits'] : 0} />
        </Flex>
        <Flex justify='space-between' align='center'>
          <span className=' '> User Phone Credits </span>
          <Tags color={'blue'} tagsContent={selectedvalue ? selectedvalue['mobileCredits'] : 0} />
        </Flex>
        <Flex vertical style={{width: '100%'}}>
          <span>Email credits</span>
          <InputNumber
            className='w-100'
            max={emailCredits}
            onChange={handleEmailInputChange}
            value={emailInput}
            placeholder='Enter the Email credits'
            type='number'
          />
        </Flex>
        <Flex vertical style={{width: '100%'}}>
          <span>Mobile credits</span>
          <InputNumber
            className='w-100'
            max={phoneCredits}
            onChange={handlePhoneInputChange}
            value={phoneInput}
            placeholder='Enter the Mobile credits'
          />
        </Flex>
      </Flex>
    </Modal>
  )
}

export default CreditsEdit
