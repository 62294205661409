import React from 'react'
import {Tag} from 'antd'
const Tags = ({tagsContent, color}) => {
  return (
    <Tag color={color} style={{fontFamily: "'Lato',sans-serif", whiteSpace: 'break-spaces'}}>
      <span style={{textTransform: 'capitalize'}}>{tagsContent}</span>
    </Tag>
  )
}

export default Tags
