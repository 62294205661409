import React, {useEffect, useRef, useState} from 'react'
import {Card} from 'react-bootstrap'
import upgradeStyle from '../styles/upgradeStyle.module.css'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

const Upgrade = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    document.body.appendChild(script)

    const checkIframeLoaded = () => {
      const iframe = iframeRef.current
      if (iframe && !iframeLoaded) {
        setIframeLoaded(true)
      }
    }

    const interval = setInterval(checkIframeLoaded, 100)

    return () => {
      clearInterval(interval)
      document.body.removeChild(script)
    }
  }, [iframeLoaded])

  useEffect(() => {
    if (iframeLoaded) {
      const iframe = iframeRef.current

      if (iframe) {
        const observer = new MutationObserver(() => {
          const priceColumns = iframe.contentWindow?.document.querySelectorAll(
            '.PriceColumn'
          ) as NodeListOf<HTMLElement>
          if (priceColumns) {
            console.log('price columns ', priceColumns)
            priceColumns.forEach((column) => {
              column.style.backgroundColor = 'red'
            })
            observer.disconnect()
          }
        })

        observer.observe(iframe.contentWindow?.document.body as Node, {
          childList: true,
          subtree: true,
        })
      }
    }
  }, [iframeLoaded])

  const stripePricingTableHTML = `
    <stripe-pricing-table pricing-table-id="prctbl_1OaccTIr4VelKIbvE6U1hIV7"
      publishable-key="pk_live_51Nf7abIr4VelKIbvXJduREO8cLQmPlPbMs26QGTDHWVH00wlXhR2zRCSQPK8DExpuvIPmD9ZnVM9iB4W57oQTeyI00c5SxByoC"
      ref={iframeRef}>
    </stripe-pricing-table>
  `

  return (
    <div style={{maxHeight: '92vh', overflowY: 'scroll'}}>
      <div dangerouslySetInnerHTML={{__html: stripePricingTableHTML}} />
    </div>
  )
}

export default Upgrade
