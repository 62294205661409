import {FC} from 'react'
import timeLineStyle from '../styles/timeline.module.css'
import {useProfileContext} from '../context/profilecontext'
import {Empty, Timeline, Flex} from 'antd'
import {ScheduleOutlined} from '@ant-design/icons'
import LinkedinIcon from './Subcomponents/LinkedinIcon'
import EmailIcons from './Subcomponents/EmailIcons'
import PhoneIcons from './Subcomponents/PhoneIcon'
import empty from '../assets/empty.png'

// This is the timeline component which is displayed for individual profile and gives the info about the interaction between them
const TimeLine: FC = () => {
  const {profileTimeline} = useProfileContext()
  const sortedInteraction = [...profileTimeline].sort((a, b) => {
    const dateA = +new Date(a.date)
    const dateB = +new Date(b.date)
    return dateB - dateA
  })

  const getTypeIcon = (type) => {
    switch (type) {
      case 'LinkedIn':
        return <LinkedinIcon width={'15px'} height={'15px'} />
      case 'Email':
        return <EmailIcons width={'15px'} height={'15px'} />
      case 'Call':
        return <PhoneIcons />
      case 'Task':
        return <ScheduleOutlined rev={undefined} style={{fontSize: '15px', height: '15px'}} />
      case 'Flow':
        return (
          <i className='bi bi-send-fill text-info' style={{fontSize: '15px', height: '15px'}}></i>
        )
      case 'List':
        return (
          <i
            className='bi bi-clipboard2-data text-warning '
            style={{fontSize: '15px', height: '15px'}}
          ></i>
        )
      default:
        return ''
    }
  }

  const reversedMappedItems = sortedInteraction.map((item) => ({
    children: (
      <Flex vertical align='start' gap={'5px'}>
        <span
          className='fs-4'
          style={{
            width: '100%',
            textTransform: 'capitalize',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {item.content}
        </span>
        {item['message'] && (
          <div
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
              textTransform: 'capitalize',
              fontSize: '14px',
              color: 'grey',
            }}
            dangerouslySetInnerHTML={{__html: item['message']}}
          ></div>
        )}

        <span className='fs-8 text-grey'>{item.date}</span>
      </Flex>
    ),
    dot: getTypeIcon(item.type),
  }))
  return (
    <div className={timeLineStyle.timelineContainer}>
      <div>
        {profileTimeline && profileTimeline.length > 0 ? (
          <Timeline items={reversedMappedItems} />
        ) : (
          <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
            <Empty
              imageStyle={{height: 300}}
              image={<img src={empty} />}
              description={<span>No timeline</span>}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TimeLine
