import {createContext, useContext, useEffect, useState} from 'react'
import {ProfileEmails, ProfilePhoneNumbers} from '../components/DataTypes/ProfileTypes'
type ProfileContext = {
  showProfile: boolean
  setShowProfile: (value: boolean) => void
  profilesData: any[]
  index: number
  setIndex: (value: number) => void
  selectProfile: Profile | null
  setProfilesData: (value: Profile[]) => void
  selectedProfiles: PersonList[]
  setSelectedProfiles: (selectedProfiles: PersonList[]) => void
  localProfiles: Profile[]
  setLocalProfiles: (localProfiles: Profile[]) => void
  profileList: newProfiles[]
  setProfileList: (profileList: newProfiles[]) => void
  profiles: Profile[]
  setProfiles: (profiles: Profile[]) => void
  searchList: PersonList[]
  setSearchList: (searchList: PersonList[]) => void
  profile: Profile | undefined
  setProfile: (profile: Profile) => void
  allSelectedProfiles: PersonList[]
  setAllSelectedProfiles: (allSelectedProfiles: PersonList[]) => void
  showProfileLoading: boolean
  setShowProfileLoading: (showProfileLoading: boolean) => void
  showNextProfileLoading: boolean
  setShowNextProfileLoading: (showNextProfileLoading: boolean) => void
  listNames: ListNameType[]
  setListNames: (listNames: ListNameType[]) => void
  profileTimeline: TimeLine[]
  setProfileTimeLine: (profileTimeline: TimeLine[]) => void
  totalResultProfiles: string
  setTotalResultProfiles: (totalResultProfiles: string) => void
  showSpillBeans: boolean
  setShowSpillBeans: (showSpillBeans: boolean) => void
  email: ProfileEmails[]
  setEmail: (email: ProfileEmails[]) => void
  phone: ProfilePhoneNumbers[]
  setPhone: (phone: ProfilePhoneNumbers[]) => void
  phoneError: string
  emailError: string
  setEmailError: (emailError: string) => void
  setPhoneError: (phoneError: string) => void
  showDefaultImage: boolean
  setShowDefaultImage: (showDefaultImage: boolean) => void
  newProfilesList
  setNewProfilesList: (newProfileList) => void
  showProfilesTableLoading: boolean
  setShowProfilesTableLoading: (showProfilesTableLoading: boolean) => void
  showResultLoading: boolean
  setShowResultLoading: (showResultLoading: boolean) => void
  companyData
  setCompanyData
  startTour: boolean
  setStartTour: (startTour: boolean) => void
  candidateProfilePic: string
  setCandidateProfilePic: (candidateProfilePic: string) => void
  companies
  setCompanies
  profileCount: number
  setProfileCount: (profileCount: number) => void
  signupComplete: boolean
  setSignupComplete: (signupComplete: boolean) => void
}

type Profile = {
  id: string
  profileName: string
  profilePic: string
  phone: number
  email: string
  github: string
  linkedin: string
  latestCompany: {
    companyName: string
    companyLogo: string
  }
  role: string
  skills: string[]
  experience: {
    companyName: string
    companyLogo: string
    duration: string
    jobdescription: string
    website: string
  }[]
  education: {
    name: string
    type: string
    duration: string
    logo: string
  }[]
  conversations?
}
type newProfiles = {
  id: string
  profileName: string
  profilePic: string
  latestCompany: {
    companyName: string
    companyLogo: string
  }
  role: string
  phone: boolean
  email: boolean
  github: boolean
  linkedin: string
}
interface PersonList {
  name: string[]
  id: string[]
  picUrl: string
  linkedinUrl: string[]
  git: boolean[]
  companyName: string[]
  companyUrl: string[]
  emailId: string[]
  role: string[]
  mobile: string[]
  listName: string
  date: string
}
interface ListNameType {
  listId: string
  listName: string
}

interface TimeLine {
  type: string
  content: string
  date: string
  message?: string
}

const ProfileContext = createContext<ProfileContext | undefined>(undefined)

const ProfileProvider = ({children}) => {
  const [showProfile, setShowProfile] = useState<boolean>(false)
  const [email, setEmail] = useState<ProfileEmails[]>([])
  const [phone, setPhone] = useState<ProfilePhoneNumbers[]>([])
  const [index, setIndex] = useState<number>(0)
  const [profilesData, setProfilesData] = useState<Profile[]>([])
  const [profile, setProfile] = useState<Profile | undefined>()
  const [selectProfile, setSelectProfile] = useState<Profile | null>(null)
  const [allProfiles, setAllProfiles] = useState<Profile[]>([])
  const [allSelectedProfiles, setAllSelectedProfiles] = useState<PersonList[]>([])
  const [localProfiles, setLocalProfiles] = useState<Profile[]>([])
  const [profileList, setProfileList] = useState<newProfiles[]>([])
  const [selectedProfiles, setSelectedProfiles] = useState<PersonList[]>([])
  const [searchList, setSearchList] = useState<PersonList[]>([])
  const [listNames, setListNames] = useState<ListNameType[]>([])
  const [profiles, setProfiles] = useState<Profile[]>()
  const [showProfileLoading, setShowProfileLoading] = useState<boolean>(false)
  const [showNextProfileLoading, setShowNextProfileLoading] = useState<boolean>(false)
  const [profileTimeline, setProfileTimeLine] = useState<TimeLine[]>([])
  const [totalResultProfiles, setTotalResultProfiles] = useState<string>('')
  const [showSpillBeans, setShowSpillBeans] = useState<boolean>(false)
  const [phoneError, setPhoneError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [newProfilesList, setNewProfilesList] = useState([])
  const [showDefaultImage, setShowDefaultImage] = useState<boolean>(false)
  const [showProfilesTableLoading, setShowProfilesTableLoading] = useState<boolean>(false)
  const [showResultLoading, setShowResultLoading] = useState<boolean>(false)
  const [companyData, setCompanyData] = useState()
  const [startTour, setStartTour] = useState<boolean>(false)
  const [candidateProfilePic, setCandidateProfilePic] = useState<string>('')
  const [companies, setCompanies] = useState([])
  const [profileCount, setProfileCount] = useState<number>(1)
  const [signupComplete, setSignupComplete] = useState<boolean>(false)

  useEffect(() => {
    if (index !== undefined) {
      setSelectProfile(profilesData[index])
    } else {
      setSelectProfile(null)
    }
  }, [index, profilesData])

  useEffect(() => {
    setAllProfiles([...allProfiles, ...profilesData])
  }, [profilesData])

  const value = {
    showSpillBeans,
    setShowSpillBeans,
    showProfile,
    setShowProfile,
    profilesData,
    index,
    setIndex,
    selectProfile,
    setProfilesData,
    setSelectedProfiles,
    selectedProfiles,
    localProfiles,
    setLocalProfiles,
    profileList,
    setProfileList,
    profiles: profiles || [],
    setProfiles,
    searchList,
    setSearchList,
    profile,
    setProfile,
    allSelectedProfiles,
    setAllSelectedProfiles,
    showProfileLoading,
    setShowProfileLoading,
    showNextProfileLoading,
    setShowNextProfileLoading,
    listNames,
    setListNames,
    profileTimeline,
    signupComplete,
    setSignupComplete,
    setProfileTimeLine,
    totalResultProfiles,
    setTotalResultProfiles,
    email,
    setEmail,
    phone,
    setPhone,
    emailError,
    setEmailError,
    phoneError,
    setPhoneError,
    showDefaultImage,
    setShowDefaultImage,
    setNewProfilesList,
    newProfilesList,
    showProfilesTableLoading,
    setShowProfilesTableLoading,
    showResultLoading,
    setShowResultLoading,
    companyData,
    setCompanyData,
    startTour,
    setStartTour,
    candidateProfilePic,
    setCandidateProfilePic,
    companies,
    setCompanies,
    profileCount,
    setProfileCount,
  }
  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}
const useProfileContext = () => {
  const profile = useContext(ProfileContext)
  if (!profile) {
    throw new Error('useProfileContext must be used within a ProfileProvider')
  }
  return profile
}

export {useProfileContext, ProfileProvider}
