import React, {useEffect, useRef, useState} from 'react'
import {
  Layout,
  Tour,
  Input,
  Avatar,
  Popconfirm,
  Divider,
  Menu,
  message,
  Button,
  Drawer,
  Popover,
  Flex,
  Modal,
  Steps,
} from 'antd'
import {CloseOutlined, EditOutlined} from '@ant-design/icons'
import BeanbagLogo from '../../assets/BeanbagLogo.png'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretDown,
  ChartPieSlice,
  Chats,
  CurrencyDollar,
  Database,
  FlowArrow,
  Gear,
  MagicWand,
  Pulse,
  SealCheck,
  SignOut,
  UserCircle,
  UserList,
  Users,
} from '@phosphor-icons/react'
import {
  AllProfilesApi,
  EditSearchNameApi,
  GetFlowListApi,
  GetListApi,
  GetSearchHistory,
  LogoutUser,
  SaveUserInformationApi,
  SessionDeleteApi,
  SessionSelectApi,
} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import {useProfileContext} from '../../context/profilecontext'
import {useFlowContext} from '../../context/FlowContext'
import {useSessionContext} from '../../context/sessionContext'
import {useNavigate} from 'react-router-dom'
import {useApiContext} from '../../context/apicontext'
import {useAuth} from '../../modules/auth'
import {useAuthContext} from '../../context/AuthContext'
import {useLocation} from 'react-router-dom'
import {useSideBarContext} from '../../context/sidebarcontext'
import {useReportContext} from '../../context/reportscontext'
import {SearchItem} from '../DataTypes/SearchHistory'
import {useChatContext} from '../../context/chatcontext'
import {useValuesContext} from '../../context/valuescontext'
import {handleGetAnalytics} from '../CommonApiCalls/PageRouteApicall'
import type {TourProps} from 'antd'
import PageLoader from '../Subcomponents/PageLoader'
import TeamInfo from '../Reusablecomponents/TeamInfo'
import PersonalInfo from '../Reusablecomponents/PersonalInfo'

const Sidebar = ({toggleSider, collapsed}) => {
  const pathname = useLocation().pathname
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()
  const {logout, setToken} = useAuth()
  const {
    setAnalyticsData,
    setFlowAnalyticsId,
    setSelectedValue,
    setAccountsList,
    setAnalyticsFlowName,
    setShowMoreAnalyticsUI,
  } = useReportContext()
  const {signOutFromGoogle} = useAuthContext()
  const {
    setSearchList,
    setProfileList,
    setNewProfilesList,
    setProfilesData,
    setListNames,
    setShowProfilesTableLoading,
    setStartTour,
    startTour
  } = useProfileContext()
  const {setFlowsArr} = useFlowContext()
  const {setShowchatUI, setShowMessages} = useChatContext()
  const {Header, Content} = Layout
  const {setSqlQuery, dashboardData, setTableUi} = useApiContext()
  const [selectedKey, setSelectedKey] = useState('')
  const {setNewSession} = useSideBarContext()
  const {setSearchId, setSelectedSearchKey, searchHistory, setSearchHistory} = useSessionContext()
  const [open, setOpen] = useState(false)
  const [placement, setPlacement] = useState('right')
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [sessionArr, setSessionArr] = useState<SearchItem[]>([])
  const [showOptions, setShowOptions] = useState<number | null>(null)
  const [editedName, setEditedName] = useState<string>('')
  const chatsRef = useRef<HTMLElement | null>(null)
  const allprofilesRef = useRef<HTMLElement | null>(null)
  const listsRef = useRef<HTMLElement | null>(null)
  const flowsRef = useRef<HTMLElement | null>(null)
  const analyticsRef = useRef<HTMLElement | null>(null)
  const settingsRef = useRef<HTMLElement | null>(null)
  const activityRef = useRef<HTMLElement | null>(null)
  const dataEnrichRef = useRef<HTMLElement | null>(null)
  const steps: TourProps['steps'] = [
    {
      title: 'Mr. Bean at your service 😜',
      description: 'Build your outreach campigns here',
      target: flowsRef.current?.parentElement,
    },
    {
      title: 'All Prospects ',
      description: 'View all your prospected profiles here',
      target: allprofilesRef.current?.parentElement,
    },
    {
      title: 'Lists',
      description: 'Create lists to organise profiles here',
      target: listsRef.current?.parentElement,
    },
    // {
    //   title: 'Flows',
    //   description: 'Build your outreach campigns here',
    //   target: flowsRef.current?.parentElement,
    // },
    {
      title: 'Analytics',
      description: 'Track all your campagin metrics here',
      target: analyticsRef.current?.parentElement,
    },
    {
      title: 'Spill beans activity board',
      description: 'Get your spill beans activity and data here',
      target: activityRef.current?.parentElement,
    },
    {
      title: 'Data enrichment',
      description: 'Enrich the data by uploading CSV',
      target: dataEnrichRef.current?.parentElement,
    },
    // {
    //   title: 'Settings',
    //   description: 'Settings to connect your account',
    //   target: settingsRef.current?.parentElement,
    // },
  ]

  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  const {
    setEmailContact,
    setShowTableFlow,
    setEmail,
    setLinVal,
    setLinkedinmessage,
    setCall,
    setShowTask,
    setTask,
    setShowFlow,
    setLinkedInmessage,
    setCallContact,
    setSelectedFlowId,
    setStartingFlowName,
    setLinkedIn,
    setShowLoadingAtFlowTable,
    setLinConnect,
    setShowLinConnect,
  } = useFlowContext()

  const {setFilterObject} = useValuesContext()
  const {setShowChatsCard} = useSessionContext()
  const [selectedSessionKeys, setSelectedSessionKeys] = useState<string[]>([])

  const handleAllProfilesApi = async () => {
    try {
      setShowProfilesTableLoading(true)
      navigate('/allProfiles')
      setShowChatsCard(false)
      let data = await AllProfilesApi(userId)
      if (data.success) {
        if (data.data) {
          setShowProfilesTableLoading(false)
          setNewProfilesList(data.data)
        } else {
          setShowProfilesTableLoading(false)
          setNewProfilesList([])
        }
      } else {
        messageApi.error('Error while getting all profiles')
        setShowProfilesTableLoading(false)
        setNewProfilesList([])
      }
    } catch (err) {
      messageApi.error('Error while getting all profiles')
      console.error('Error while getting all profiles' + err)
    }
  }
  const handleShowHistory = async () => {
    showDrawer()
    try {
      let history = await GetSearchHistory(userId)
      if (history.success && !history.error) {
        if (history.data) {
          setSessionArr(history.data)
        } else {
          message.error('Something went wrong')
        }
      } else {
        message.error('Error while getting the session history')
      }
    } catch (err) {
      console.error('Some thing went wrong while getting session history ', err)
      message.error('Some thing went wrong while getting session history')
    }
  }
  const popContent = (
    <div style={{width: '100%'}}>
      <Menu
        className='popover-menu'
        selectedKeys={selectedKey ? [selectedKey] : ['userSettings']}
        style={{width: '100%', opacity: 1}}
      >
        {/* <Menu.Item
          key={'userSettings'}
          icon={<UserCircleGear size={22} />}
          onClick={() => {
            handleUserSettings()
          }}
        >
          {!collapsed ? <span style={{fontSize: '16px'}}> User settings</span> : ''}
        </Menu.Item> */}

        <Menu.Item
          key='upgrade'
          onClick={(key) => {
            handleMenuClick(key)
            handleNavigatePricing()
          }}
          className='popover-menu-item'
          style={{fontSize: '16px', fontWeight: '400', color: 'black', opacity: 1}}
          icon={<CurrencyDollar size={22} />}
        >
          <span>Upgrade</span>
        </Menu.Item>
        <Menu.Item
          key='settings'
          className='popover-menu-item'
          onClick={(key) => {
            handleMenuClick(key)
            handleSettings()
          }}
          style={{fontSize: '16px', fontWeight: '400', color: 'black'}}
          icon={
            <Avatar
              style={{width: 'fit-content'}}
              ref={settingsRef}
              shape='square'
              src={<Gear size={22} />}
            />
          }
        >
          <span>Settings</span>
        </Menu.Item>
        <Menu.Item
          className='popover-menu-item'
          style={{fontSize: '16px', fontWeight: '400', color: 'black'}}
          icon={<SignOut size={22} />}
          onClick={() => {
            handleSignOut()
          }}
        >
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    </div>
  )

  useEffect(() => {
    const keyMap = {
      '/dashboard': 'chats',
      '/allProfiles': 'allProfiles',
      '/lists': 'lists',
      '/flows': 'flows',
      '/createFlow': 'flows',
      '/flowInterface': 'flows',
      '/settings': 'settings',
      '/profile': 'profile',
      '/upgrade': 'upgrade',
      '/analytics': 'analytics',
      '/usersDashboard': 'usersDashboard',
      '/dataenrichment': 'dataEnrich',
      '/search': 'search',
      '/automation': 'automation',
      '/verify-data': 'emailVerification',
    }

    const mappedKey = keyMap[pathname] || ''
    setSelectedKey(mappedKey)
  }, [pathname, selectedKey])

  const handleAutomation = () => {
    navigate('/automation')
  }
  const handleVerification = () => {
    navigate('/verify-data')
  }
  const handleFlowTableApi = async () => {
    try {
      setShowLoadingAtFlowTable(true)
      navigate('/flows')
      let data = await GetFlowListApi(userId)
      setShowLoadingAtFlowTable(false)
      if (data && data.success && !data.error) {
        setFlowsArr(data.data)
        setListNames([])
        setShowChatsCard(false)
        setShowFlow(false)
        setShowTableFlow(true)
        setEmail('')
        setLinVal('')
        setShowLinConnect(false)
        setLinConnect('')
        setLinkedinmessage('')
        setCall('')
        setEmailContact(false)
        setLinkedIn(false)
        setLinkedInmessage(false)
        setCallContact(false)
        setShowTask(false)
        setSelectedFlowId('')
        setStartingFlowName('')
        setTask('')
        setNewProfilesList([])
      }
    } catch (err) {
      console.error('Error in fetching flow table' + err)
      messageApi.error('Error in fetching flow table')
    }
  }
  const handledashBoardSelect = () => {
    navigate('/dashboard')
    setSearchId('')
    setSelectedSearchKey('')
    setNewSession(true)
    onClose()
    setNewProfilesList([])
    setSelectedSessionKeys([])
  }
  const handleActivity = async () => {
    navigate('/usersDashboard')
  }

  const handleDataEnrich = () => {
    navigate('/dataenrichment')
  }
  const handlegetSelectedProfiles = async () => {
    try {
      navigate('/lists')
      setShowChatsCard(false)
      setNewProfilesList([])
      let data = await GetListApi(userId)
      console.log(data)
      if (data.success) {
        if (data.data) {
          setListNames(data.data)
          setFlowsArr([])
          navigate('/lists')
        } else {
          setListNames([])
          setFlowsArr([])
          navigate('/lists')
        }
      } else {
        messageApi.warning('Something went wrong')
      }
    } catch (err) {
      messageApi.error('Error while setting the list names')
      console.log('Error while setting the list names ' + err)
    }
  }

  const hoverStyle = {
    backgroundColor: '#1890ff',
  }
  const handleSettings = () => {
    navigate('settings')
  }
  const handleNavigatePricing = () => {
    navigate('upgrade')
  }
  const handleSignOut = async () => {
    try {
      const logoutRes = await LogoutUser(userId)
      if (logoutRes) {
        if (logoutRes.success && logoutRes.data === 'logout success') {
          signOutFromGoogle()
          localStorage.clear()
          setToken('')
          logout()
          navigate('/')
        } else {
          messageApi.error('Error while Logging out ')
        }
      } else {
        messageApi.error('Error while Logging out ')
      }
    } catch (err) {
      messageApi.error('Error while setting the user login value to false ')
      console.error('Error while setting the user login value to false ' + err)
    }
  }
  const handleMenuClick = (key) => {
    setSelectedKey(key.key)
  }
  const handleSessionSelect = async (index: number) => {
    setSelectedSessionKeys((prevKeys) => {
      return [index.toString()]
    })
    try {
      setSearchId('')
      setShowMessages([])
      setProfilesData([])
      setProfileList([])
      onClose()
      setSelectedSearchKey(searchHistory[index].searchId)
      let data = await SessionSelectApi(searchHistory[index].searchId)
      if ((data || data.message) && !data.error) {
        setShowChatsCard(false)
        navigate('/dashboard')
        setShowchatUI(true)
        setNewSession(false)
        setShowMessages(data.chats)
        setFilterObject(data.filterObj)
        setSqlQuery(data.filterObj.searchQuery)
        if (data.profileData) {
          setSearchList(data.profileData)
          setTableUi(true)
        } else {
          setTableUi(false)
        }
      } else {
        message.error('Error while getting the session data')
      }
    } catch (err) {
      message.error('Error while getting the session data')
      console.error('Error while getting the session data' + err)
    }
  }

  const handleCancelEdit = () => {
    setEditingIndex(null)
  }

  const handleSetNameChange = async (index) => {
    try {
      let sId = sessionArr[index].searchId
      let data = await EditSearchNameApi(userId, sId, editedName)
      if (data.message && !data.error) {
        message.success('Successfully updated')
        let updatedHistory = await GetSearchHistory(userId)
        if (updatedHistory && !updatedHistory.error) {
          setSessionArr(updatedHistory)
          setEditingIndex(null)
          setShowOptions(null)
        } else {
          message.error('Error while updating search name')
        }
      }
    } catch (err) {
      console.error(err)
      message.error('Error while updating search name')
    }
  }
  const handleDelete = async (event, index: number) => {
    try {
      event.stopPropagation()
      let sId = sessionArr[index].searchId
      let data = await SessionDeleteApi(userId, sId)
      if (data.message && !data.error) {
        message.success('Successfully deleted')
        let updatedHistory = await GetSearchHistory(userId)
        if (updatedHistory && !updatedHistory.error) {
          setSessionArr(updatedHistory)
        } else {
          message.error('Error while updating history')
        }
      } else {
        message.error('Error while deleting the session')
      }
    } catch (err) {
      message.error('Error while deleting the session')
      console.error(err)
    }
  }
  const handleUserSettings = () => {
    navigate('/usersettings')
  }
  const handleSearchUi = () => {
    navigate('/search')
  }

  return (
    <div>
      <Tour
        placement='right'
        open={startTour}
        onClose={() => {
          localStorage.removeItem('startTour')
          setStartTour(false)
        }}
        steps={steps}
      />
      <Drawer
        placement={'right'}
        style={{background: '#f9f9f9', padding: '00px'}}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <Header
          style={{
            background: '#f9f9f9',
            color: 'black',
            fontFamily: "'Lato', sans-serif",
            fontWeight: '500',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <h1 className='text-black'>History</h1>
          <Button
            type='primary'
            onClick={() => handledashBoardSelect()}
            icon={<i style={{color: '#edfde3'}} className='bi bi-chat-right-text'></i>}
          >
            New chat
          </Button>
        </Header>
        <Divider style={{margin: '5px 0', background: '#dfdfdf'}} />
        <Content style={{width: '100%'}}>
          <Menu
            selectedKeys={selectedSessionKeys}
            style={{
              background: '#f9f9f9',
              color: 'black',
              fontFamily: "'Karla', sans-serif",
              fontWeight: '500',
              width: '100%',
            }}
          >
            {sessionArr.map((session, index) => (
              <>
                <Menu.Item
                  onClick={() => {
                    handleSessionSelect(index)
                  }}
                  key={index}
                  style={{width: '100%', paddingInline: 'calc(50% - 45%)', opacity: 1}}
                >
                  <div className='d-flex aling-items-center justify-content-between w-100'>
                    {editingIndex === index ? (
                      <div className='d-flex align-items-center justify-content-between w-100'>
                        <Input
                          onClick={(e) => e.stopPropagation()}
                          className='w-75'
                          value={editedName}
                          onChange={(e) => setEditedName(e.target.value)}
                          onPressEnter={(e) => {
                            e.stopPropagation()
                            handleSetNameChange(index)
                          }}
                          onBlur={handleCancelEdit}
                        />
                        <div>
                          <CloseOutlined
                            onClick={(e) => {
                              e.stopPropagation()
                              setEditingIndex(null)
                            }}
                            className='text-hover-danger'
                            rev={undefined}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <span onClick={() => handleSessionSelect(index)}>{session.searchName}</span>
                        <Flex align='center' gap={'small'}>
                          <EditOutlined
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelectedSessionKeys([])
                              setEditingIndex(index)
                              setEditedName(sessionArr[index].searchName)
                            }}
                            className='text-hover-warning'
                            rev={undefined}
                          />
                          <Popconfirm
                            key={session.searchId}
                            title={`Delete the session "${session.searchName}"`}
                            description='Are you sure to delete this session?'
                            okText='Yes'
                            cancelText='No'
                            placement='left'
                            onCancel={(e) => e?.stopPropagation()}
                            onConfirm={(e) => handleDelete(e, index)}
                          >
                            <DeleteIcon
                              onClick={(e) => e.stopPropagation()}
                              style={{opacity: 1, fontSize: '16px', marginBottom: '0px'}}
                            />
                          </Popconfirm>
                        </Flex>
                      </>
                    )}
                  </div>
                </Menu.Item>
                <Divider style={{margin: '5px 0', background: '#dfdfdf'}} />
              </>
            ))}
          </Menu>
        </Content>
      </Drawer>

      <Menu
        selectable={false}
        style={{
          background: '#f9f9f9',
          marginTop: '10px',
          marginBottom: '10px',
          marginRight: '10px',
          width: '100%',
        }}
      >
        <Menu.Item
          // onClick={() => {
          //   handledashBoardSelect()
          // }}
          key='logo'
          className='d-flex w-100 align-items-center'
        >
          {!collapsed ? (
            <Flex align='center' gap={'small'}>
              <img
                alt='Logo'
                src={BeanbagLogo}
                style={{height: '28px', width: '28px', objectFit: 'contain', marginRight: '10px'}}
              />
              <span style={{fontSize: '20px', fontWeight: '600'}} className='text-black '>
                Beanbag AI
              </span>
              <Flex align='center' justify='center' style={{marginTop: '5px'}}>
                <Divider type='vertical' style={{height: '20px', margin: '0px 2px'}} />
                <CaretDoubleLeft
                  size={22}
                  color='black'
                  style={{marginLeft: '4px'}}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    toggleSider()
                  }}
                />
              </Flex>
            </Flex>
          ) : (
            <img
              alt='Logo'
              src={BeanbagLogo}
              style={{height: '30px', width: '30px', objectFit: 'contain', marginLeft: '-5px'}}
            />
          )}
        </Menu.Item>
        {collapsed && (
          <Menu.Item
            icon={<CaretDoubleRight size={22} />}
            onClick={() => {
              toggleSider()
            }}
          >
            Expand
          </Menu.Item>
        )}
      </Menu>
      <Divider style={{margin: '0px 0', color: 'black'}} />
      <div
        style={{background: '#f9f9f9'}}
        className='d-flex flex-column justify-content-between h-100'
      >
        <Menu
          style={{
            background: '#f9f9f9',
            overflowX: 'hidden',
            overflowY: 'auto',
            height: '100%',
          }}
          mode='vertical'
          selectedKeys={[selectedKey]}
        >
          {/* <Menu.Item
            key='search'
            onClick={(key) => {
              handleMenuClick(key)
              handleSearchUi()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={<MagnifyingGlass size={22} />}
          >
            <span>Search</span>
          </Menu.Item> */}
          {/* <Menu.Item
            key='chats'
            onClick={(key) => {
              handleMenuClick(key)
              handleShowHistory()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar
                style={{width: 'fit-content'}}
                ref={chatsRef}
                shape='square'
                src={<Chats size={22} />}
              />
            }
          >
            <span
              style={{
                marginLeft: '-4px',
                fontSize: '16px',
                fontWeight: '500',
                fontStyle: 'normal',
                lineHeight: '22px',
              }}
            >
              {' '}
              Chats
            </span>
          </Menu.Item> */}

          <Menu.Item
            key='allProfiles'
            onClick={(key) => {
              handleMenuClick(key)
              handleAllProfilesApi()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar
                style={{width: 'fit-content'}}
                ref={allprofilesRef}
                size={'large'}
                shape='square'
                src={<Users size={22} />}
              />
            }
          >
            <span style={{marginLeft: '10px'}}> All Prospects</span>
          </Menu.Item>

          <Menu.Item
            key='lists'
            onClick={(key) => {
              handleMenuClick(key)
              handlegetSelectedProfiles()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar
                style={{width: 'fit-content'}}
                ref={listsRef}
                shape='square'
                src={<UserList size={22} />}
              />
            }
          >
            <span style={{marginLeft: '10px'}}>Lists</span>
          </Menu.Item>

          <Menu.Item
            key='analytics'
            onClick={(key) => {
              handleMenuClick(key)
              setShowMoreAnalyticsUI(false)
              handleGetAnalytics({
                setNewProfilesList,
                setFlowAnalyticsId,
                setAnalyticsData,
                navigate,
                setSelectedValue,
                setAnalyticsFlowName,
                setAccountsList,
              })
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar
                style={{width: 'fit-content'}}
                ref={analyticsRef}
                shape='square'
                src={<ChartPieSlice size={22} />}
              />
            }
          >
            <span style={{marginLeft: '10px'}}>Analytics</span>
          </Menu.Item>

          <Menu.Item
            key='flows'
            onClick={(key) => {
              handleMenuClick(key)
              handleFlowTableApi()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar
                style={{width: 'fit-content'}}
                ref={flowsRef}
                shape='square'
                src={<FlowArrow size={22} />}
              />
            }
          >
            <span style={{marginLeft: '10px'}}>Flows</span>
          </Menu.Item>
          <Menu.Item
            key='usersDashboard'
            onClick={(key) => {
              handleMenuClick(key)
              handleActivity()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar
                style={{width: 'fit-content'}}
                ref={activityRef}
                shape='square'
                src={<Pulse size={22} />}
              />
            }
          >
            <span style={{marginLeft: '10px'}}> Activity</span>
          </Menu.Item>

          <Menu.Item
            key='dataEnrich'
            onClick={(key) => {
              handleMenuClick(key)
              handleDataEnrich()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar
                style={{width: 'fit-content'}}
                ref={dataEnrichRef}
                shape='square'
                src={<Database size={22} />}
              />
            }
          >
            <span style={{marginLeft: '10px'}}>Data enrich</span>
          </Menu.Item>
          <Menu.Item
            key='automation'
            onClick={(key) => {
              handleMenuClick(key)
              handleAutomation()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar style={{width: 'fit-content'}} shape='square' src={<MagicWand size={22} />} />
            }
          >
            <span style={{marginLeft: '10px'}}>Automations</span>
          </Menu.Item>
          <Menu.Item
            key='emailVerification'
            onClick={(key) => {
              handleMenuClick(key)
              handleVerification()
            }}
            style={{fontSize: '1.2em', fontWeight: '400', color: 'black', width: '100%'}}
            icon={
              <Avatar style={{width: 'fit-content'}} shape='square' src={<SealCheck size={22} />} />
            }
          >
            <span style={{marginLeft: '10px'}}>Email Verification</span>
          </Menu.Item>
        </Menu>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'end',
            position: 'absolute',
            bottom: '0',
            background: '#f9f9f9',
          }}
        >
          <Divider style={{margin: '1px 0'}} />
          <Flex vertical align='center' style={{width: '100%', padding: '20px'}}>
            {collapsed ? (
              dashboardData?.userPic ? (
                <Popover style={{width: '70%'}} content={popContent} trigger={'click'}>
                  <img
                    src={dashboardData?.userPic}
                    style={{
                      width: '40px',
                      height: '40px',
                      objectFit: 'contain',
                      borderRadius: '50%',
                      zIndex: '50',
                      cursor: 'pointer',
                    }}
                    alt='profilepic'
                  />
                </Popover>
              ) : (
                <Popover style={{width: '100%'}} content={popContent} trigger={'click'}>
                  <UserCircle size={40} cursor={'pointer'} />
                </Popover>
              )
            ) : (
              <Flex gap={'middle'} justify='space-between' align='start' style={{width: '100%'}}>
                <Flex
                  align='center'
                  gap={'small'}
                  key='profile'
                  style={{
                    fontSize: '1.2em',
                    fontWeight: '400',
                    color: 'black',
                    minWidth: '140px',
                  }}
                >
                  {dashboardData?.userPic ? (
                    <img
                      src={dashboardData?.userPic}
                      style={{
                        width: '40px',
                        height: '40px',
                        objectFit: 'contain',
                        borderRadius: '50%',
                        zIndex: '50',
                        marginRight: '5px',
                      }}
                      alt='profilepic'
                    />
                  ) : (
                    <UserCircle size={40} />
                  )}
                  <Flex vertical>
                    <span style={{fontWeight: '600', fontSize: '16px'}}>
                      {(dashboardData?.userName
                        ? (dashboardData.userName as string).split(' ')[0]
                        : 'UserName') ?? 'UserName'}
                    </span>
                    <span style={{fontWeight: '500', fontSize: '13px'}}>
                      {dashboardData ? dashboardData['planName'] : 'Free User'}
                    </span>
                  </Flex>
                </Flex>
                <Popover content={popContent} trigger={'click'}>
                  <CaretDown style={{cursor: 'pointer'}} size={22} />
                </Popover>
              </Flex>
            )}
          </Flex>
        </div>
        <style>{`
          .ant-menu-dark .ant-menu-item:hover {
            ${hoverStyle}
          }
          .ant-menu-item-selected {
            background-color: #204f58 !important;
            color: white !important;
          }
        `}</style>
      </div>
    </div>
  )
}

export default Sidebar
