import {FC, createContext, useContext, useEffect, useState} from 'react'
import {useNodesState} from 'reactflow'
import {useEdgesState} from 'reactflow'
import {useApiContext} from './apicontext'
import {v4 as uuidV4} from 'uuid'
import {AccountType, LinkedinAccountType} from '../components/DataTypes/AccountsType'
interface FlowData {
  name: string
  ended: string
  created: string
  createdAt: string
  checked: boolean
}
interface FlowListData {
  flowId: string
  flowName: string
}
interface ContactingType {
  type: string
  message: string
  icon: string
}
interface ManualType {
  type: string
  message: string
  icon: string
}
interface InitialStyle {
  backgroundColor: string
  textColor: string
}
export interface EmailValue {
  Nodeid: string
  senderMail: UserAccountData | null
  subject: string
  content: string
  showsaved: boolean
  templateName: string
}
interface UserAccountData {
  id: string
  name: string
  type: string
}
export interface LinkedinValue {
  Nodeid: string
  linkedInProfile: string
  showsaved: boolean
}
export interface LinkedInMessageValue {
  Nodeid: string
  linkedInProfile: string
  message: string
  showsaved: boolean
}
export interface CallContactValue {
  Nodeid: string
  assignedTo: string
  details: string
  showsaved: boolean
}
interface DbEdge {
  id: string
  source: string
  target: string
  type: string
}

export interface FlowsArray {
  flowId: string
  flowName: string
  createdAt: string
  creator: string
  flowStarted: boolean
  nodes: DbNode[]
  edges: DbEdge[]
}
export interface TaskArray {
  Nodeid: string
  taskType: string
  taskTitle: string
  due: string
  time: string
  isReminderSet: boolean
}
interface NodeDelay {
  Nodeid: string
  delay: number
}

export interface FlowSettingsInterface {
  timeZone: string
  fromTime: string
  toTime: string
  DaysInWeek: string[]
  startDate: string
  endDate: string
  trackEmailOpen: boolean
  trackLinkClicks: boolean
  pauseCampaign: boolean
  account: AccountType
  linkedinAccount: LinkedinAccountType | null
}
interface PersonList {
  name: string[]
  id: string[]
  picUrl: string
  linkedinUrl: string[]
  git: boolean[]
  companyName: string[]
  companyUrl: string[]
  emailId: string[]
  role: string[]
  mobile: string[]
  listName: string
  date: string
}
interface Email {
  type: string | null
}
export interface DbNode {
  connectable: boolean
  data: {
    content
    label: string
    nodeId: string
    type: string
    delay: number
  }
  height: number
  id: string

  position: {
    x: number
    y: number
  }
  style: {
    width: string
    height: string
    fontSize: string
    cursor: string
    borderRadius: string
    backgroundColor: string
    color: string
  }
  type: string
  width: number
}
type FlowObjData = {
  TaskArr?: TaskArray[]
  EmailArr?: EmailValue[]
  LinkedinArr?: LinkedinValue[]
  LinkedInMessageValue?: LinkedInMessageValue[]
  CallContactValue?: CallContactValue[]
}
export interface FlowContent {
  [NodeId: string]: TaskArray | CallContactValue | LinkedInMessageValue | LinkedinValue | EmailValue
}

interface FlowsData {
  flowId: string
  sequence: {
    node: DbNode[]
    edges: DbEdge[]
  }
  data: FlowObjData
}
type FlowContextType = {
  isAnyContactTrue: boolean
  setIsAnyContactTrue: (isAnyContactTrue: boolean) => void
  showFlow: boolean
  setShowFlow: (showFlow: boolean) => void
  showTableFlow: boolean
  setShowTableFlow: (showTableFlow: boolean) => void
  index: number | null
  setIndex: (index: number | null) => void
  contacting: ContactingType[]
  setContacting: (contacting: ContactingType[]) => void
  emailContact: boolean
  setEmailContact: (emailContact: boolean) => void
  callContact: boolean
  setCallContact: (callContact: boolean) => void
  linkedIn: boolean
  setLinkedIn: (linkedIn: boolean) => void
  linkedInmessage: boolean
  setLinkedInmessage: (linkedInmessage: boolean) => void
  email: string
  setEmail: (email: string) => void
  linkedinmessage: string
  setLinkedinmessage: (linkedinmessage: string) => void
  call: string
  setCall: (call: string) => void
  linval: string
  setLinVal: (linkedinValue: string) => void
  initialStyle: InitialStyle
  setInitialStyle: (initialStyle: InitialStyle) => void
  nodes
  setNodes
  onNodesChange
  typeOfNode: string[]
  setTypeOfNode: (typeOfNode: string[]) => void
  initial: string
  setInitial: (initial: string) => void
  flowObject
  setFlowObject
  arr: string[]
  setArr: (arr: string[]) => void
  edges
  setEdges
  onEdgesChange
  emailObjArr: EmailValue[]
  setEmailObjArr: (emailObjArr: EmailValue[]) => void
  nodeUnqId: string
  setNodeUnqId: (nodeUnqId: string) => void
  linkedinObjArr: LinkedinValue[]
  setLinkedinObjArr: (linkedinObjArr: LinkedinValue[]) => void
  linkedinMessageObjArr: LinkedInMessageValue[]
  setLinkedinMessageObjArr: (linkedinMessageObjArr: LinkedInMessageValue[]) => void
  callContactObjArr: CallContactValue[]
  setCallContactObjArr: (callContactObjArr: CallContactValue[]) => void
  flowsArr: FlowsArray[]
  setFlowsArr: (flowsArr: FlowsArray[]) => void
  manual: ManualType[]
  setManual: (manual: ManualType[]) => void
  taskArr: TaskArray[]
  setTaskArr: (taskArr: TaskArray[]) => void
  showTask: boolean
  setShowTask: (showTask: boolean) => void
  task: string
  setTask: (task: string) => void
  dbNodes
  setDbNodes
  dbEdge
  setDbEdge
  flowDataObj: FlowsData
  setFlowDataObj: (flowDataObj: FlowsData) => void
  nodeCount: NodeDelay[]
  setNodeCount: (nodeCount: NodeDelay[]) => void
  startingFlowName: string
  setStartingFlowName: (startingFlowName: string) => void
  flowKeys: string[]
  setFlowKeys: (flowKeys: string[]) => void
  selectedFlowId: string
  setSelectedFlowId: (selectedFlowId: string) => void
  flowList: FlowListData[] | undefined
  setFlowList: (flowList: FlowListData[]) => void
  flowId: string
  setFlowId: (flowId: string) => void
  showSequenceOptions: boolean
  setShowSequenceOptions: (showSequenceOptions: boolean) => void
  templateImported: boolean
  setTemplateImported: (templateImported: boolean) => void
  dbContent: FlowContent | null
  setDbContent: (dbContent: FlowContent) => void
  flowName: string
  setFlowName: (flowName: string) => void
  flowDes: string
  setFlowDes: (flowDes: string) => void
  flowInfo
  setFlowInfo
  callContent: string
  setCallContent: (callContent: string) => void
  assigner: string
  setAssigner: (signer: string) => void
  taskContent: string
  setTaskContent: (taskContent: string) => void
  taskType: string
  setTaskType: (taskType: string) => void
  taskTitle: string
  setTaskTitle: (taskTitle: string) => void
  dueDate: string
  setDueDate: (dueDate: string) => void
  time: string
  setTime: (time: string) => void
  isReminderSet: boolean
  setIsReminderSet: (isReminderSet: boolean) => void
  showFlowSettings: boolean
  setShowFlowSettings: (showFlowSettings: boolean) => void
  flowSettingsObj: FlowSettingsInterface | null
  setFlowSettingsObj: (flowSettingsObj: FlowSettingsInterface) => void
  profiles
  setProfiles
  flowProfiles: PersonList[]
  setFlowProfiles: (flowProfiles: PersonList[]) => void
  showForms: boolean
  setShowForms: (showForms: boolean) => void
  flowTaskTemplateName: string
  setFlowTaskTemplateName: (flowTaskTemplateName: string) => void
  callTaskTemplateName: string
  setCallTaskTemplateName: (callTaskTemplateName: string) => void
  showLoadingAtFlowTable: boolean
  setShowLoadingAtFlowTable: (showLoadingAtFlowTable: boolean) => void
  linkedinToken: string
  setLinkedinToken: (linkedinToken: string) => void
  linConnect: string
  setLinConnect: (linConnect: string) => void
  showLinConnect: boolean
  setShowLinConnect: (showLinConnect: boolean) => void
  connectionMsg: string
  setConnectionMsg: (connectionMsg: string) => void
  mesg: string
  setMesg: (mesg: string) => void
  disableStartFlow: boolean
  setDisableStartFlow: (disableStartFlow: boolean) => void
  selectedNodeIndex: number
  setSelectedNodeIndex: (selectedNodeIndex: number) => void
  selectedLinkedinAccount: LinkedinAccountType | null
  disableStopFlow: boolean
  setDisableStopFlow: (disableStopFlow: boolean) => void
  setSelectedLinkedinAccount: (selectedLinkedinAccount: LinkedinAccountType | null) => void
}
const FlowContext = createContext<FlowContextType | undefined>(undefined)

const FlowContextProvider = ({children}) => {
  const [isAnyContactTrue, setIsAnyContactTrue] = useState<boolean>(false)
  const [flowProfiles, setFlowProfiles] = useState<PersonList[]>([])
  const [profiles, setProfiles] = useState()
  const [taskContent, setTaskContent] = useState<string>('')
  const [taskType, setTaskType] = useState<string>('')
  const [taskTitle, setTaskTitle] = useState<string>('')
  const [dueDate, setDueDate] = useState<string>('')
  const [time, setTime] = useState<string>('')
  const [isReminderSet, setIsReminderSet] = useState<boolean>(false)
  const [flowInfo, setFlowInfo] = useState()
  const [showSequenceOptions, setShowSequenceOptions] = useState<boolean>(false)
  const [flowTaskTemplateName, setFlowTaskTemplateName] = useState<string>('')
  const [callTaskTemplateName, setCallTaskTemplateName] = useState<string>('')
  const [showLoadingAtFlowTable, setShowLoadingAtFlowTable] = useState<boolean>(false)
  const [selectedLinkedinAccount, setSelectedLinkedinAccount] =
    useState<LinkedinAccountType | null>(null)
  const [manual, setManual] = useState([
    {
      type: 'Task',
      message: 'Assign the tasks',
      icon: 'bi bi-clipboard2-check-fill text-warning mx-2 mt-1 fs-1',
    },
    {
      type: 'CallContact',
      message: 'Contact the People with Call',
      icon: 'bi bi-telephone-outbound-fill text-success mx-2 mt-1 fs-1',
    },
  ])
  const [contacting, setContacting] = useState([
    {
      type: 'Email',
      message: 'Contact the People with Email',
      icon: 'bi bi-envelope-fill text-danger mx-2 mt-1 fs-1',
    },
    {
      type: 'LinkedIn',
      message: 'Vist LinkedIn Profile',
      icon: 'bi bi-linkedin text-primary mx-2 mt-1 fs-1',
    },
    {
      type: 'LinkedInMessage',
      message: 'Contact the People with LinkedIn Message',
      icon: 'bi bi-linkedin text-primary mx-2 mt-1 fs-1',
    },
  ])
  const [selectedFlowId, setSelectedFlowId] = useState<string>('')
  const [flowKeys, setFlowKeys] = useState<string[]>([])
  const [mesg, setMesg] = useState<string>('')
  const [callContent, setCallContent] = useState<string>('')
  const [assigner, setAssigner] = useState<string>('')
  const [startingFlowName, setStartingFlowName] = useState<string>('')
  const [flowsArr, setFlowsArr] = useState<FlowsArray[]>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [emailContact, setEmailContact] = useState<boolean>(false)
  const [linkedIn, setLinkedIn] = useState<boolean>(false)
  const [linkedInmessage, setLinkedInmessage] = useState<boolean>(false)
  const [callContact, setCallContact] = useState<boolean>(false)
  const [showTask, setShowTask] = useState<boolean>(false)
  const [showFlow, setShowFlow] = useState<boolean>(false)
  const [showTableFlow, setShowTableFlow] = useState<boolean>(true)
  const [index, setIndex] = useState<number | null>(null)
  const [email, setEmail] = useState<string>('')
  const [linval, setLinVal] = useState<string>('')
  const [linConnect, setLinConnect] = useState<string>('')
  const [showLinConnect, setShowLinConnect] = useState<boolean>(false)
  const [linkedinmessage, setLinkedinmessage] = useState<string>('')
  const [call, setCall] = useState<string>('')
  const [task, setTask] = useState<string>('')
  const [typeOfNode, setTypeOfNode] = useState<string[]>([''])
  const [flowList, setFlowList] = useState<FlowListData[]>([])
  const [flowName, setFlowName] = useState<string>('')
  const [flowDes, setFlowDes] = useState<string>('')
  const [showForms, setShowForms] = useState<boolean>(false)
  const [disableStartFlow, setDisableStartFlow] = useState<boolean>(false)
  const [initialStyle, setInitialStyle] = useState<InitialStyle>({
    backgroundColor: '#edfde3',
    textColor: 'black',
  })
  const [flowSettingsObj, setFlowSettingsObj] = useState<FlowSettingsInterface | null>(null)
  const [dbContent, setDbContent] = useState<FlowContent | null>(null)
  const [templateImported, setTemplateImported] = useState<boolean>(false)
  const {dashboardData} = useApiContext()
  const [connectionMsg, setConnectionMsg] = useState<string>('')
  const [nodeUnqId, setNodeUnqId] = useState<string>('')
  const [selectedNodeIndex, setSelectedNodeIndex] = useState<number>(0)
  const [flowObject, setFlowObject] = useState({
    addToReact: [],
    addToPython: [],
  })
  const initialNodes = [
    {
      id: '1',
      type: 'initial',
      data: {
        content: {},
        label: 'Email',
        nodeId: uuidV4(),
        type: 'initial',
      },
      position: {x: 0, y: -120},
      style: {
        width: '150px',
        height: 'fit-content',
        cursor: 'default',
        fontSize: '0.5em',
        borderRadius: '5px',
        backgroundColor: initialStyle.backgroundColor,
        color: initialStyle.textColor,
      },
      height: 30,
      connectable: true,
      width: 150,
    },
  ]

  const defaultFlowDataObj: FlowsData = {
    flowId: '',
    sequence: {
      node: [],
      edges: [],
    },
    data: {},
  }
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [flowDataObj, setFlowDataObj] = useState<FlowsData>(defaultFlowDataObj)
  const [nodeCount, setNodeCount] = useState<NodeDelay[]>([{Nodeid: '123', delay: 1}])
  const [dbEdge, setDbEdge] = useState<DbEdge[]>([])
  const [showFlowSettings, setShowFlowSettings] = useState<boolean>(false)
  const [dbNodes, setDbNodes] = useState<DbNode[]>([])
  const [arr, setArr] = useState<string[]>([initialNodes[0].id])
  const [initial, setInitial] = useState<string>('')
  const [emailObjArr, setEmailObjArr] = useState<EmailValue[]>([])
  const [linkedinObjArr, setLinkedinObjArr] = useState<LinkedinValue[]>([])
  const [linkedinMessageObjArr, setLinkedinMessageObjArr] = useState<LinkedInMessageValue[]>([])
  const [callContactObjArr, setCallContactObjArr] = useState<CallContactValue[]>([])
  const [taskArr, setTaskArr] = useState<TaskArray[]>([])
  const [linkedinToken, setLinkedinToken] = useState<string>('')
  const [flowId, setFlowId] = useState<string>('')
  const [disableStopFlow, setDisableStopFlow] = useState<boolean>(true)
  useEffect(() => {
    if (dashboardData?.flowList !== undefined) {
      setFlowList(dashboardData.flowList)
    }
  }, [dashboardData])

  const value = {
    isAnyContactTrue,
    setIsAnyContactTrue,
    showFlow,
    setShowFlow,
    showTableFlow,
    setShowTableFlow,
    index,
    setIndex,
    contacting,
    setContacting,
    emailContact,
    setEmailContact,
    linkedIn,
    linkedInmessage,
    setCallContact,
    setLinkedInmessage,
    setLinkedIn,
    callContact,
    email,
    setEmail,
    linval,
    setLinVal,
    disableStopFlow,
    setDisableStopFlow,
    linkedinmessage,
    call,
    setCall,
    setLinkedinmessage,
    initialStyle,
    setInitialStyle,
    nodes,
    setNodes,
    onNodesChange,
    typeOfNode,
    setTypeOfNode,
    initial,
    setInitial,
    flowObject,
    setFlowObject,
    arr,
    setArr,
    edges,
    setEdges,
    onEdgesChange,
    emailObjArr,
    setEmailObjArr,
    nodeUnqId,
    setNodeUnqId,
    linkedinObjArr,
    setLinkedinObjArr,
    linkedinMessageObjArr,
    setLinkedinMessageObjArr,
    callContactObjArr,
    setCallContactObjArr,
    flowsArr,
    setFlowsArr,
    manual,
    setManual,
    taskArr,
    setTaskArr,
    showTask,
    setShowTask,
    task,
    setTask,
    dbNodes,
    setDbNodes,
    dbEdge,
    setDbEdge,
    flowDataObj,
    setFlowDataObj,
    nodeCount,
    setNodeCount,
    startingFlowName,
    setStartingFlowName,
    flowKeys,
    setFlowKeys,
    selectedFlowId,
    setSelectedFlowId,
    flowList,
    setFlowList,
    flowId,
    setFlowId,
    showSequenceOptions,
    setShowSequenceOptions,
    templateImported,
    setTemplateImported,
    dbContent,
    setDbContent,
    flowName,
    setFlowName,
    flowDes,
    setFlowDes,
    flowInfo,
    setFlowInfo,
    callContent,
    assigner,
    setCallContent,
    setAssigner,
    taskContent,
    setTaskContent,
    taskType,
    setTaskType,
    taskTitle,
    setTaskTitle,
    dueDate,
    setDueDate,
    time,
    setTime,
    isReminderSet,
    setIsReminderSet,
    showFlowSettings,
    setShowFlowSettings,
    flowSettingsObj,
    setFlowSettingsObj,
    profiles,
    setProfiles,
    flowProfiles,
    setFlowProfiles,
    showForms,
    setShowForms,
    flowTaskTemplateName,
    setFlowTaskTemplateName,
    callTaskTemplateName,
    setCallTaskTemplateName,
    showLoadingAtFlowTable,
    setShowLoadingAtFlowTable,
    linkedinToken,
    setLinkedinToken,
    linConnect,
    setLinConnect,
    showLinConnect,
    setShowLinConnect,
    connectionMsg,
    setConnectionMsg,
    mesg,
    setMesg,
    disableStartFlow,
    setDisableStartFlow,
    selectedNodeIndex,
    setSelectedNodeIndex,
    selectedLinkedinAccount,
    setSelectedLinkedinAccount,
  }
  return <FlowContext.Provider value={value}>{children}</FlowContext.Provider>
}
const useFlowContext = () => {
  const useFlow = useContext(FlowContext)
  if (!useFlow) {
    throw new Error(' useSideBarContext must be used within a Sidebarprovider')
  }
  return useFlow
}
export {useFlowContext, FlowContextProvider}
