import React, {useState} from 'react'
import {Card, Flex, Modal, Button, Input, Spin, Tooltip} from 'antd'
import {ScrapeSaasBoomiData} from '../../apicalls/AutomationAPI'
import Papa from 'papaparse'
import {InfoCircleOutlined} from '@ant-design/icons'
import saasBoomiLogo from '../../assets/saasboomi.jpg'
const SaasBoomiScrape = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<'success' | 'failure' | null>(null)

  const handleScrapeData = async () => {
    setLoading(true)
    setModalVisible(false)
    setResult(null)
    try {
      const res = await ScrapeSaasBoomiData(username, password)
      if (res) {
        downloadCSV(res)
        setResult('success')
      } else {
        setResult('failure')
      }
    } catch (err) {
      console.error('Error scraping data:', err)
      setResult('failure')
    } finally {
      setLoading(false)
      setUsername('')
      setPassword('')
      setUrl('')
      setTimeout(() => {
        setResult(null)
      }, 5000)
    }
  }

  const downloadCSV = (data) => {
    const csv = Papa.unparse(data)
    const blob = new Blob([csv], {type: 'text/csv'})
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'saasBoomiData.csv')
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
  }

  return (
    <Flex vertical>
      <span style={{fontWeight: 'bold', fontSize: '1.5em'}}>SaasBoomi </span>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='linkedin'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={saasBoomiLogo}
            />
          </div>
        }
        onClick={() => (loading ? setModalVisible(false) : setModalVisible(true))}
        style={{
          width: '220px',
          height: '220px',
          background: '#232323',
          cursor: loading ? 'not-allowed' : 'pointer',
        }}
      >
        <Flex vertical gap={'middle'} justify='space-between' align='center'>
          <span
            style={{fontWeight: '500', fontSize: '1.5em', color: 'white', lineHeight: '1.2'}}
            className='heading'
          >
            Scrape SaasBoomi Members
          </span>
          <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
            Get the data of SaasBoomi members with one click
          </span>
          {loading && <Spin size='small' style={{marginLeft: '5px'}} />}
          {result === 'success' && <span style={{color: 'green', marginLeft: '5px'}}>✔</span>}
          {result === 'failure' && <span style={{color: 'red', marginLeft: '5px'}}>✘</span>}
        </Flex>
      </Card>
      <Modal
        title={
          <Flex gap={'small'}>
            <span style={{fontWeight: '600', fontSize: '1.1em'}}>Enter SaasBoomi credentials</span>
            <Tooltip title='Enter your SaaSBoomi login credentials'>
              <InfoCircleOutlined rev={undefined} />
            </Tooltip>
          </Flex>
        }
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='scrape'
            type='primary'
            disabled={!username || !password}
            onClick={handleScrapeData}
          >
            Scrape
          </Button>,
        ]}
      >
        <Flex vertical gap={'middle'}>
          <Input
            size='large'
            placeholder='Enter Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            size='large'
            placeholder='Enter Password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Flex>
      </Modal>
    </Flex>
  )
}

export default SaasBoomiScrape
