import React, {Suspense, useEffect, useState} from 'react'
import {Space, Table, Tag, Avatar, theme, Flex, Button, Spin, message} from 'antd'
import {v4 as uuidv4} from 'uuid'
import {useProfileContext} from '../../context/profilecontext'
import {CircleUserRound, ChevronRight} from 'lucide-react'
import type {MenuProps} from 'antd'
import {
  AddProfileToFlowApi,
  AddProfileToListApi,
  CreateNewListApi,
  GetCompanyWebsiteApi,
  GetExistingUserApi,
  GetFlowListApi,
  GetIndivdualProfileApi,
  GetListsApi,
  GetNextSetProfilesApi,
  UpdateFlowDetailsApi,
  getTimeLineApi,
} from '../../apicalls/ApiCall'
import {userId, userName} from '../DataTypes/UserDataTypes'
import {FlowsArray} from '../../context/FlowContext'
import FullProfileModal from './FullProfileModal'
import {useApiContext} from '../../context/apicontext'
import {useSessionContext} from '../../context/sessionContext'
import ContactIcons from '../Subcomponents/ContactIcons'
import CompanyModal from './CompanyModal'
import AddListFlowBtn from './AddListFlowBtn'

const LazyProfilePic = React.lazy(() => import('../CommonApiCalls/ProfilePic'))
const LazyCompanyLogo = React.lazy(() => import('../CommonApiCalls/CompanyLogo'))

const ResultsTable = () => {
  const [abortController, setAbortController] = useState(new AbortController())
  const signal = abortController.signal
  const {
    searchList,
    setIndex,
    totalResultProfiles,
    setProfile,
    setShowSpillBeans,
    setEmail,
    setPhone,
    profile,
    setEmailError,
    setPhoneError,
    setSearchList,
    showResultLoading,
    setCompanyData,
    setProfileTimeLine,
  } = useProfileContext()
  const {useToken} = theme
  const {scrollToken, setScrollToken, setSqlQuery, sqlQuery} = useApiContext()
  const {token} = useToken()
  const [createFlowLoading, setCreateFlowLoading] = useState<boolean>(false)
  const [showGettingData, setShowGettingData] = useState<boolean>(false)
  const [showCreateList, setShowCreateList] = useState<boolean>(false)
  const [showAddToListMsg, setShowAddToListMsg] = useState<boolean>(false)
  const [listName, setListName] = useState<string>('')
  const [selectedProfileRows, setSelectedProfileRows] = useState([])
  const [userLists, setUserLists] = useState<{listId: string; listName: string}[]>([])
  const [listItems, setListItems] = useState<MenuProps['items']>([])
  const [flowItems, setFlowItems] = useState<MenuProps['items']>([])
  const [disableLists, setDisableLists] = useState<boolean>(true)
  const [disableFlows, setDisableFlows] = useState<boolean>(true)
  const [userFlows, setUserFlows] = useState<FlowsArray[]>([])
  const [userFilteredLists, setUserFilteredLists] =
    useState<{listId: string; listName: string}[]>(userLists)
  const [flowName, setFlowName] = useState<string>('')
  const [showCreateFlow, setShowCreateFlow] = useState<boolean>(false)
  const [showAddToFlowMsg, setShowAddToFlowMsg] = useState<boolean>(false)
  const [createListLoading, setCreateListLoading] = useState<boolean>(false)
  const [userFilteredFlows, setUserFilteredFlows] = useState<FlowsArray[]>([])
  const [openProfileModal, setOpenProfileModal] = useState<boolean>(false)
  const {searchId, selectedSearchKey} = useSessionContext()
  const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false)

  useEffect(() => {
    if (userLists) {
      setUserFilteredLists(userLists)
    }
  }, [userLists])
  useEffect(() => {
    if (userFlows) {
      setUserFilteredFlows(userFlows)
    }
  }, [userFlows])

  const handleGetCompanyData = async (companylinkedin, record) => {
    try {
      setShowGettingData(true)
      console.log(companylinkedin)
      if (companylinkedin) {
        let companyRes = await GetCompanyWebsiteApi(
          companylinkedin,
          record.latestCompany.companyName,
          userId
        )
        setShowGettingData(false)
        if (companyRes && companyRes.success && companyRes.data) {
          setCompanyData(companyRes.data)
          setShowCompanyModal(true)
        } else {
          message.error('Something went wrong while getting the company data')
        }
      } else {
        message.error('Company data is unavailable')
      }
    } catch (err) {
      setShowGettingData(false)
      message.error('Something went wrong while getting the company data')
      console.error('Error while getting the company data ', err)
    }
  }
  const handleFullProfile = async (profileId, index, profile) => {
    try {
      setShowGettingData(true)
      let fullProfileData = await GetIndivdualProfileApi(userId, profileId, signal)

      if (fullProfileData && fullProfileData.success && fullProfileData.data) {
        setIndex(index)
        let resData = await GetExistingUserApi(fullProfileData.data['linkedin'], userId)
        setShowGettingData(false)
        if (resData === 'Not Requested' || resData === null) {
          setShowSpillBeans(true)
          setEmail([])
          setPhone([])
          setEmailError('')
          setPhoneError('')
        } else {
          if (resData) {
            setShowSpillBeans(false)
            let emailObjects = resData.work_email
            let mobilePhones = resData.mobile
            if (emailObjects) {
              if (emailObjects.every((obj) => obj.email === '' || obj.email === 'Not found')) {
                setEmailError("Sorry, Email doesn't exist")
              } else {
                let filteredEmails = emailObjects.filter(
                  (obj) => obj.email !== 'Not found' && obj.email !== ''
                )
                setEmail(filteredEmails)
              }
            } else {
              setEmailError('Sorry, Something went wrong')
            }
            if (mobilePhones) {
              if (
                mobilePhones.every((phone) => phone.number === '' || phone.number === 'Not found')
              ) {
                setPhoneError(
                  "Uh oh! Number is currently unavailable. If you need it on priority, let us know. You'll be notified via email soon"
                )
              } else {
                setPhone(
                  mobilePhones.filter(
                    (phone) =>
                      phone.number !== 'Not found' && phone.number !== '' && phone.number !== null
                  )
                )
              }
            } else {
              setPhoneError('Sorry, Something went wrong')
            }
          }
        }

        setOpenProfileModal(true)
        setProfile(fullProfileData.data)
      } else {
        setShowGettingData(false)
        message.error(`Something went wrong please try again`)
      }
      try {
        let timelineResponse = await getTimeLineApi(userId, profile['key'])
        if (timelineResponse && timelineResponse.success && timelineResponse.data) {
          setProfileTimeLine(timelineResponse.data)
        } else {
          console.error('No timelines found')
        }
      } catch (err) {
        console.error('error while getting timeline api ', err)
      }
      console.log(fullProfileData)
    } catch (err) {
      message.error(`Something went wrong please try again`)
      console.error('Error while getting the full profile')
    }
    console.log(profileId)
  }

  const getRowProps = (record) => {
    return {
      style: {padding: '8px'},
    }
  }
  const dataWithKeys = searchList.map((record) => ({
    ...record,
    key: record['key'],
  }))
  console.log('Selcted profiles ', selectedProfileRows)
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows) => {
      setSelectedProfileRows(selectedRows)
      if (selectedRows.length > 0) {
        setDisableLists(false)
        setDisableFlows(false)
      } else {
        setDisableLists(true)
        setDisableFlows(true)
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  const handleShowFlowDropDown = async (e) => {
    setShowGettingData(true)
    if (selectedProfileRows.length > 0) {
      try {
        let data = await GetFlowListApi(userId)

        if (data && !data.error) {
          setShowGettingData(false)
          setUserFlows(data)
        } else {
          message.error('Error while getting the flows ')
          setShowGettingData(false)
        }
      } catch (err) {
        message.error('Error while getting the flows ')
        setShowGettingData(false)
        console.log('Error in getting User Flow List')
      }
    }
  }
  useEffect(() => {
    const newMenu: MenuProps['items'] = []
    if (userLists.length > 0)
      userLists.forEach((list) => {
        newMenu.push({key: list.listId, label: list.listName})
      })
    else newMenu.push({key: '', label: ''})
    setListItems(newMenu)
  }, [userLists])

  useEffect(() => {
    const newMenu: MenuProps['items'] = []
    if (userFlows.length > 0)
      userFlows.forEach((flow) => {
        newMenu.push({key: flow.flowId, label: flow.flowName})
      })
    else newMenu.push({key: '', label: ''})
    setFlowItems(newMenu)
  }, [userFlows])

  const columns = [
    {
      title: 'Profile',
      dataIndex: 'linkedinUrl',
      key: 'imageAndName',
      render: (linkedinUrl, record, index) => (
        <Space
          size='middle'
          style={{cursor: 'pointer'}}
          onClick={(e) => {
            e.stopPropagation()
            handleFullProfile(record.pdlId, index, record)
          }}
        >
          <Suspense
            fallback={<Avatar src={<CircleUserRound />} style={{width: '30px', height: '30px'}} />}
          >
            {record.linkedin && (
              <Avatar
                src={
                  <LazyProfilePic linkedinUrl={record.linkedin} profile={record} /> ?? (
                    <CircleUserRound />
                  )
                }
              />
            )}
          </Suspense>
          <span style={{textTransform: 'capitalize'}} className='fs-7'>
            {record.profileName}
          </span>
        </Space>
      ),
    },

    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (companyName, record) => (
        <Space
          style={{cursor: 'pointer'}}
          onClick={() => handleGetCompanyData(record.companylinkedin, record)}
          size='middle'
          className='text-hover-primary'
        >
          <Suspense
            fallback={<Avatar src={<CircleUserRound />} style={{width: '30px', height: '30px'}} />}
          >
            <Avatar src={<LazyCompanyLogo companyName={record.latestCompany.companyName} />} />
          </Suspense>
          <Flex vertical>
            <span style={{textTransform: 'capitalize'}} className='fs-7'>
              {record.latestCompany.companyName}
            </span>
            <span style={{textTransform: 'capitalize'}} className='fs-7 text-grey'>
              {record.role}
            </span>
          </Flex>
        </Space>
      ),
    },
    {
      title: 'Contact info',
      dataIndex: 'contactAndLinks',
      key: 'contactAndLinks',
      render: (_, record) => (
        <Space size='small'>
          <ContactIcons
            linkedin={record.linkedin}
            github={record.git}
            userEmail={record['email']}
          />
        </Space>
      ),
    },
  ]

  const handleNextData = async () => {
    try {
      let searchKey = searchId ? userId + searchId : selectedSearchKey
      try {
        setShowGettingData(true)
        let data = await GetNextSetProfilesApi(searchKey, userId, scrollToken, sqlQuery)
        console.log(data)
        if (data.error || !data.success) {
          setShowGettingData(false)
          console.log(data)
        } else if (data && data.success && data.data) {
          setSearchList([...searchList, ...data.data.profiles])
          setScrollToken(data.data.token)
          setSqlQuery(data.data.query)
          setShowGettingData(false)
        }
      } catch (e) {
        setShowGettingData(false)
        console.log('error ', e)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Flex vertical gap='middle' style={{position: 'relative', height: '100%'}}>
      <div className='w-100 d-flex justify-content-between align-items-center px-1'>
        <span className='fs-3'>
          Profiles{' '}
          <Tag color={searchList.length && totalResultProfiles ? 'success' : 'error'}>
            {searchList.length && totalResultProfiles ? (
              <>
                {searchList.length}/{totalResultProfiles}
              </>
            ) : (
              'N/A'
            )}
          </Tag>
        </span>
        <Flex gap='middle' align='center'>
          {showGettingData && <Spin />}
          {disableLists && disableFlows ? (
            <>
              <Button
                disabled={disableLists}
                type='primary'
                icon={<i className='bi bi-clipboard2-data text-white'></i>}
              >
                Lists
              </Button>
              <Button
                disabled={disableFlows}
                onClick={(e) => {
                  e.preventDefault()
                  handleShowFlowDropDown(e)
                }}
                type='primary'
                icon={<i className='bi bi-send-fill text-white'></i>}
              >
                Flows
              </Button>{' '}
            </>
          ) : (
            <AddListFlowBtn profile={selectedProfileRows} />
          )}

          {scrollToken && (
            <Button
              onClick={(e) => {
                e.preventDefault()
                handleNextData()
              }}
              icon={<ChevronRight size={20} strokeWidth={1.5} />}
            ></Button>
          )}
        </Flex>
      </div>
      <FullProfileModal
        showModal={openProfileModal}
        profileData={profile}
        updateState={setOpenProfileModal}
      />
      <CompanyModal showDrawer={showCompanyModal} setShowdDrawer={setShowCompanyModal} />
      {showResultLoading ? (
        <Spin
          size='large'
          style={{position: 'absolute', zIndex: '1000', top: '50%', left: '47%'}}
        />
      ) : (
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          size='small'
          dataSource={dataWithKeys}
          columns={columns}
          onRow={getRowProps}
        />
      )}
    </Flex>
  )
}

export default ResultsTable
