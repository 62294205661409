import React from 'react'
import {useUserSettingContext} from '../context/UserSettings'
import {Row, Col, Divider, Flex, Progress, Tooltip} from 'antd'
import Tags from './Subcomponents/Tags'

const PlanDetailsCard: React.FC = () => {
  const {
    profileObj,
    emailCredits,
    phoneCredits,
    emailTotalCredits,
    phoneTotalCredits,
    expiryDate,
    rechargeDate,
  } = useUserSettingContext()

  return (
    <Flex vertical gap={'small'} className='w-100'>
      <div style={{marginBottom: '15px'}} className='card-title'>
        <span className='fw-bold heading fs-3 '>Plan usage</span>
      </div>
      <Row className='w-100 '>
        <Flex align='center ' className='w-100' style={{justifyContent: 'space-between'}}>
          <Col>
            <span className='fs-5 fw-semibold'>Email credits used</span>
          </Col>
          <Col span={6}>
            <span>
              {emailCredits !== undefined && emailTotalCredits !== undefined ? (
                <div>
                  <Tooltip title={emailCredits.toString() + '/' + emailTotalCredits.toString()}>
                    <Progress
                      percent={Math.ceil((emailCredits / emailTotalCredits) * 100)}
                      status='active'
                    />
                  </Tooltip>
                </div>
              ) : (
                <Tags color={'#204f58'} tagsContent={'N/A'} />
              )}
            </span>
          </Col>
        </Flex>
      </Row>
      <Divider style={{margin: '10px 0'}} />
      <Row className='w-100'>
        <Flex align='center ' className='w-100' style={{justifyContent: 'space-between'}}>
          <Col>
            <span className='fs-5 fw-semibold'>Mobile credits used</span>
          </Col>
          <Col span={6}>
            <span>
              {phoneCredits && phoneTotalCredits ? (
                <div>
                  <Tooltip title={phoneCredits.toString() + '/' + phoneTotalCredits.toString()}>
                    <Progress
                      percent={Math.ceil((phoneCredits / phoneTotalCredits) * 100)}
                      status='active'
                    />
                  </Tooltip>
                </div>
              ) : (
                <Tags color={'#204f58'} tagsContent={'N/A'} />
              )}
            </span>
          </Col>
        </Flex>
      </Row>
      <Divider style={{margin: '10px 0'}} />
      <Row className='w-100'>
        <Flex align='center ' className='w-100' style={{justifyContent: 'space-between'}}>
          <Col>
            <span className='fs-5 fw-semibold'>Plan name</span>
          </Col>
          <Col span={6}>
            <span>
              {profileObj?.planName ? (
                <Tags color={'#87255b'} tagsContent={profileObj?.planName} />
              ) : (
                <Tags color={'#204f58'} tagsContent={'N/A'} />
              )}
            </span>
          </Col>
        </Flex>
      </Row>
      <Divider style={{margin: '10px 0'}} />

      <Row className='w-100'>
        <Flex align='center ' className='w-100' style={{justifyContent: 'space-between'}}>
          <Col>
            <span className='fs-5 fw-semibold'>Recharge Date</span>
          </Col>
          <Col span={6}>
            <span>{rechargeDate}</span>
          </Col>
        </Flex>
      </Row>
      <Divider style={{margin: '10px 0'}} />
      <Row className='w-100'>
        <Flex align='center ' className='w-100' style={{justifyContent: 'space-between'}}>
          <Col>
            <span className='fs-5 fw-semibold'>Expiry Date</span>
          </Col>
          <Col span={6}>
            <span>{expiryDate}</span>
          </Col>
        </Flex>
      </Row>
    </Flex>
  )
}

export {PlanDetailsCard}
