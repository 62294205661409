import React from 'react'
import {Tag, Flex, Empty} from 'antd'
const Skills = ({userSkills}) => {
  return userSkills ? (
    <Flex vertical gap='small' className='p-2' style={{minHeight: '20%'}}>
      <span style={{fontFamily: "'Lato',sans-serif", fontSize: '1.2em'}}>Skills</span>
      <div>
        {userSkills && userSkills.length > 0
          ? userSkills.map((skill, index) => (
              <Tag style={{marginTop: '5px', marginRight: '5px'}} color='#204f58' key={index}>
                <span style={{fontFamily: "'Karla',sans-serif"}}>
                  {' '}
                  {skill.charAt(0).toUpperCase() + skill.slice(1)}
                </span>
              </Tag>
            ))
          : ''}
      </div>
    </Flex>
  ) : (
    <Empty />
  )
}

export default Skills
