import {useState, useEffect, createContext, useContext} from 'react'

interface FilterObject {
  Area: string[]
  Companies: string[]
  CompanySize: string
  ExcludedCompanies: string[]
  ExcludedGender: boolean
  ExcludedProfile: boolean
  Experience: {minExp: number; maxExp: number}[]
  FieldOfStudy: string[]
  Industries: string[]
  JobTitles: string[]
  Languages: string[]
  MinimumDegree: string
  PastJobTitles: string[]
  Profiles: number | number[]
  ReqInfo: string[]
  Skills: string[]
  TimeSpent: {years: number; months: number}
  University: string[]
  icon: string
  searchQuery: string
  title: string
}

type ValueContextType = {
  pastJobTitles: string[]
  setPastJobTitles: (pastJobTitles: string[]) => void
  jobTitles: string[]
  setJobTitles: (jobTitles: string[]) => void
  timespent: any
  setTimeSpent: (timespent: any) => void
  university: string[]
  setUniversity: (university: string[]) => void
  skills: string[]
  setSkills: (skills: string[]) => void
  languages: string[]
  setLanguages: (languages: string[]) => void
  excludedCompanies: string[]
  setExcludedCompanies: (excludedCompanies: string[]) => void
  minimumDegree: string
  setMinimumDegree: (minimumDegree: string) => void
  fieldOfStudy: string[]
  setFieldOfStudy: (fieldOfStudy: string[]) => void
  industries: string[]
  setIndustries: (industries: string[]) => void
  companySize: string
  setCompanySize: (companySize: string) => void
  workedTimeOCompanies: string
  setWorkedTimeofCompanies: (workedTimeOCompanies: string) => void
  companies: string[]
  setCompanies: (companies: string[]) => void
  area: string[]
  setArea: (area: string[]) => void
  profiles: number[] | number
  setProfiles: (profiles: number[] | number) => void
  experience: any
  setExperience: (experience: any) => void
  reqInfo: string[]
  setReqInfo: (reqInfo: string[]) => void
  excludedProfile: boolean
  setExcludedProfile: (excludedProfile: boolean) => void
  excludedGender: boolean
  setExcludedGender: (excludedGender: boolean) => void
  country: string[]
  setCountry: (country: string[]) => void
  historyOfCompany: string
  setHistoryOfCompany: (historyOfCompany: string) => void
  showCompanyCard: boolean
  setShowCompanyCard: (showCompanyCard: boolean) => void
  filterObject: FilterObject | undefined
  setFilterObject: (filterObject: FilterObject) => void
  showFieldsOfstudy: boolean
  setShowFieldsOfstudy: (showFieldsOfstudy: boolean) => void
  showPastJobTitles: boolean
  setShowPastJobTitles: (showPastJobTitles: boolean) => void
  showJobTitles: boolean
  setShowJobTitles: (showJobTitles: boolean) => void
  searchJobTitles: string[]
  setsearchJobTitles: (searchJobTitles: string[]) => void
  searchCompanies: string[]
  setsearchCompanies: (searchCompanies: string[]) => void
  searchLocation: string[]
  setSearchLocation: (searchLocation: string[]) => void
  searchIndustries: string[]
  setsearchIndustries: (searchIndustries: string[]) => void
  searchCountries: string[]
  setSearchCountries: (searchCountries: string[]) => void
  searchSkills: string[]
  setSearchSkills: (searchSkills: string[]) => void
  searchMajors: string[]
  setSearchMajors: (searchMajors: string[]) => void
  searchPastJobTitles: string[]
  setsearchPastJobTitles: (searchPastJobTitles: string[]) => void
  searchUniversities: string[]
  setSearchUniversities: (searchUniversities: string[]) => void
  searchLanguages: string[]
  setSearchLanguages: (searchLanguages: string[]) => void
  searchStates: string[]
  setSearchStates: (searchStates: string[]) => void
}

const ValuesContext = createContext<ValueContextType | undefined>(undefined)

const ValuesContextProvider = ({children}: {children: React.ReactNode}) => {
  const [pastJobTitles, setPastJobTitles] = useState<string[]>([])
  const [jobTitles, setJobTitles] = useState<string[]>([])
  const [searchJobTitles, setsearchJobTitles] = useState<string[]>([])
  const [searchPastJobTitles, setsearchPastJobTitles] = useState<string[]>([])
  const [searchCompanies, setsearchCompanies] = useState<string[]>([])
  const [searchLocation, setSearchLocation] = useState<string[]>([])
  const [searchIndustries, setsearchIndustries] = useState<string[]>([])
  const [searchCountries, setSearchCountries] = useState<string[]>([])
  const [searchLanguages, setSearchLanguages] = useState<string[]>([])
  const [searchUniversities, setSearchUniversities] = useState<string[]>([])
  const [searchSkills, setSearchSkills] = useState<string[]>([])
  const [searchMajors, setSearchMajors] = useState<string[]>([])
  const [searchStates, setSearchStates] = useState<string[]>([])
  const [timespent, setTimeSpent] = useState({years: 0, months: 0})
  const [university, setUniversity] = useState<string[]>([])
  const [skills, setSkills] = useState<string[]>([])
  const [languages, setLanguages] = useState<string[]>([])
  const [excludedCompanies, setExcludedCompanies] = useState<string[]>([])
  const [minimumDegree, setMinimumDegree] = useState<string>('')
  const [fieldOfStudy, setFieldOfStudy] = useState<string[]>([])
  const [industries, setIndustries] = useState<string[]>([])
  const [companySize, setCompanySize] = useState<string>('')
  const [workedTimeOCompanies, setWorkedTimeofCompanies] = useState<string>('')
  const [companies, setCompanies] = useState<string[]>([])
  const [area, setArea] = useState<string[]>([])
  const [profiles, setProfiles] = useState<number[] | number>(0)
  const [experience, setExperience] = useState([{minExp: 0}, {maxExp: 0}])
  const [reqInfo, setReqInfo] = useState<string[]>([])
  const [excludedProfile, setExcludedProfile] = useState<boolean>(false)
  const [excludedGender, setExcludedGender] = useState<boolean>(false)
  const [country, setCountry] = useState<string[]>([])
  const [historyOfCompany, setHistoryOfCompany] = useState<string>('')
  const [showCompanyCard, setShowCompanyCard] = useState<boolean>(false)
  const [filterObject, setFilterObject] = useState<FilterObject>()
  const [showFieldsOfstudy, setShowFieldsOfstudy] = useState<boolean>(false)
  const [showPastJobTitles, setShowPastJobTitles] = useState<boolean>(false)
  const [showJobTitles, setShowJobTitles] = useState<boolean>(false)

  const value: ValueContextType = {
    pastJobTitles,
    searchUniversities,
    setSearchUniversities,
    setPastJobTitles,
    jobTitles,
    setJobTitles,
    timespent,
    setTimeSpent,
    university,
    setUniversity,
    skills,
    setSkills,
    languages,
    setLanguages,
    excludedCompanies,
    setExcludedCompanies,
    minimumDegree,
    setMinimumDegree,
    fieldOfStudy,
    setFieldOfStudy,
    industries,
    setIndustries,
    companySize,
    setCompanySize,
    workedTimeOCompanies,
    setWorkedTimeofCompanies,
    companies,
    setCompanies,
    area,
    setArea,
    profiles,
    setProfiles,
    experience,
    setExperience,
    reqInfo,
    setReqInfo,
    excludedProfile,
    setExcludedProfile,
    excludedGender,
    setExcludedGender,
    country,
    setCountry,
    historyOfCompany,
    setHistoryOfCompany,
    showCompanyCard,
    setShowCompanyCard,
    filterObject,
    setFilterObject,
    showFieldsOfstudy,
    setShowFieldsOfstudy,
    showPastJobTitles,
    setShowPastJobTitles,
    showJobTitles,
    setShowJobTitles,
    searchJobTitles,
    setsearchJobTitles,
    searchCompanies,
    setsearchCompanies,
    searchLocation,
    setSearchLocation,
    searchIndustries,
    setsearchIndustries,
    searchCountries,
    setSearchCountries,
    searchSkills,
    setSearchSkills,
    searchMajors,
    setSearchMajors,
    searchPastJobTitles,
    setsearchPastJobTitles,
    searchLanguages,
    setSearchLanguages,
    searchStates,
    setSearchStates,
  }

  return <ValuesContext.Provider value={value}>{children}</ValuesContext.Provider>
}

const useValuesContext = () => {
  const valuesContext = useContext(ValuesContext)
  if (!valuesContext) {
    throw new Error('ValuesContext must be used within a valuesprovider')
  }
  return valuesContext
}

export {useValuesContext, ValuesContextProvider}
