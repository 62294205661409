import React from 'react'
import {Flex, Tooltip} from 'antd'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import PhoneIcon from '@mui/icons-material/Phone'
import GitHubIcon from '@mui/icons-material/GitHub'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
const ContactIcons = ({linkedin, github, userEmail}) => {
  const openLinkedInProfile = () => {
    if (linkedin) {
      if (linkedin.startsWith('https://www.')) {
        window.open(linkedin, '_blank')
      } else {
        window.open('https://www.' + linkedin, '_blank')
      }
    }
  }

  const openGitHubProfile = () => {
    if (github) {
      if (github.startsWith('https://www.')) {
        window.open(github, '_blank')
      } else {
        window.open('https://www.' + github, '_blank')
      }
    }
  }
  return (
    <Flex gap='6px' align='center'>
      {linkedin && (
        <LinkedInIcon
          style={{color: '#204f58', padding: 'none', fontSize: '20px', cursor: 'pointer'}}
          className='text-primary'
          onClick={openLinkedInProfile}
        />
      )}

      {github && (
        <GitHubIcon
          style={{color: '#204f58', padding: 'none', fontSize: '17px', cursor: 'pointer'}}
          className='bi bi-github text-dark'
          onClick={openGitHubProfile}
        />
      )}
      <Tooltip
        placement='topRight'
        title={userEmail ? userEmail : 'Open full profile to view email'}
      >
        {' '}
        <MailOutlineIcon
          style={{
            color: '#f5222d',
            padding: 'none',
            fontSize: '21px',
            cursor: 'pointer',
            marginBottom: '1px',
          }}
        />
      </Tooltip>
      <Tooltip placement='topRight' title={'Open full profile to view phone number'}>
        <PhoneIcon
          style={{color: '#204f58', padding: 'none', fontSize: '19px', cursor: 'pointer'}}
          className='text-success'
        />
      </Tooltip>
    </Flex>
  )
}

export default ContactIcons
