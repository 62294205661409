import React from 'react'
import {Tabs} from 'antd'
import type {TabsProps} from 'antd'
import teamStyle from '../../styles/teamsettings.module.css'
import TeamMembersTable from '../Reusablecomponents/TeamMembersTable'
import InviteTeamMem from '../Reusablecomponents/InviteTeamMem'
const TeamSettings = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span className='heading fs-4'>Team Members</span>,
      children: <TeamMembersTable />,
    },
  ]
  return (
    <div className={teamStyle.teamSettingsContainer}>
      <Tabs style={{height: '100%'}} defaultActiveKey='1' tabPosition='top' items={items} />
    </div>
  )
}

export default TeamSettings
