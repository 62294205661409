import {FC, useState, createContext, useContext, useEffect} from 'react'
import {useValuesContext} from './valuescontext'
import {MessageModel} from '../../_metronic/helpers'
import {useChatContext} from './chatcontext'
import {useSideBarContext} from './sidebarcontext'
import {useProfileContext} from './profilecontext'
import {useApiContext} from './apicontext'
type Profile = {
  profileName: string
  profilePic: string
  phone: number
  email: string
  github: string
  linkedin: string
  latestCompany: {
    companyName: string
    companyLogo: string
  }
  role: string
  skills: string[]
  experience: {
    companyName: string
    companyLogo: string
    duration: string
    jobdescription: string
  }[]
  education: {
    name: string
    type: string
    duration: string
  }[]
  conversations?: {
    recruiterMail: {
      subject: string
      content: string
      sendAt: string
    }
    userMail: {
      subject: string
      content: string
      sendAt: string
    }
  }[]
}

interface SessionProps {
  id: string
  sessionName: string
  chats: MessageModel[]
  profilesData: Profile[]
}

type SessionContextProps = {
  sessionArray: SessionProps[]
  setSessionArray: (sessionArray: SessionProps[]) => void
  interaction: boolean
  setInteraction: (interaction: boolean) => void
  searchHistory: SearchHistoryProps[]
  setSearchHistory: (searchHistory: SearchHistoryProps[]) => void
  sessionInteracted: boolean
  setSessionInteracted: (sessionInteracted: boolean) => void
  selectedSearchKey: string
  setSelectedSearchKey: (selectedSearchKey: string) => void
  searchId: string
  setSearchId: (searchId: string) => void
  showChatsCard: boolean
  setShowChatsCard: (showChatsCard: boolean) => void
}
interface SearchHistoryProps {
  searchId: string
  searchName: string
}
const SessionContext = createContext<SessionContextProps | undefined>(undefined)

const SessionContextProvider = ({children}) => {
  const [showChatsCard, setShowChatsCard] = useState<boolean>(false)
  const [searchHistory, setSearchHistory] = useState<SearchHistoryProps[]>([])
  const [sessionArray, setSessionArray] = useState<SessionProps[]>([])
  const [interaction, setInteraction] = useState<boolean>(false)
  const {newSession} = useSideBarContext()
  const {setFilterObject} = useValuesContext()
  const {setProfilesData} = useProfileContext()
  const {messages, setShowMessages} = useChatContext()
  const [sessionInteracted, setSessionInteracted] = useState<boolean>(false)
  const [selectedSearchKey, setSelectedSearchKey] = useState<string>('')
  const [searchId, setSearchId] = useState<string>('')
  const {dashboardData, setApiSearchId, setSqlQuery, setSearchQuery} = useApiContext()

  useEffect(() => {
    if (dashboardData) {
      setSearchHistory(dashboardData.searchHistory)
    }
  }, [dashboardData])

  useEffect(() => {
    if (newSession) {
      setShowMessages([])
      setSearchId('')
      setApiSearchId('')
      setProfilesData([])
      setSessionInteracted(false)
      setFilterObject({
        title: '',
        icon: '',
        searchQuery: '',
        JobTitles: [],
        PastJobTitles: [],
        Profiles: 0,
        Area: [],
        ReqInfo: [],
        ExcludedGender: false,
        ExcludedProfile: false,
        Experience: [{minExp: 0, maxExp: 0}],
        TimeSpent: {years: 0, months: 0},
        University: [],
        Skills: [],
        Languages: [],
        Companies: [],
        Industries: [],
        CompanySize: '',
        ExcludedCompanies: [],
        MinimumDegree: '',
        FieldOfStudy: [],
      })
    }
  }, [newSession])

  let value = {
    sessionArray,
    setSessionArray,
    interaction,
    setInteraction,
    searchHistory,
    setSearchHistory,
    setSessionInteracted,
    sessionInteracted,
    selectedSearchKey,
    setSelectedSearchKey,
    searchId,
    setSearchId,
    showChatsCard,
    setShowChatsCard,
  }

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
}

const useSessionContext = () => {
  const sessionContext = useContext(SessionContext)
  if (!sessionContext) throw new Error('There is an error with Session Context')
  return sessionContext
}

export {useSessionContext, SessionContextProvider}
