/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {SigninUserWithLinkedinAuthApi} from '../../../apicalls/ApiCall'
import {
  SendUTMParams,
  SignInUserWithGoogle,
  SignUpFormUserData,
  SignUpUserData,
} from '../../../apicalls/Authentication'
import {LinkedIn} from 'react-linkedin-login-oauth2'
import {Button, notification, Input} from 'antd'
import {useNavigate} from 'react-router-dom'
import {signInWithPopup} from 'firebase/auth'
import {auth, provider} from '../../../../firebase'
import {EyeFilled} from '@ant-design/icons'
import PageLoader from '../../../components/Subcomponents/PageLoader'
import {getUTMParameters} from '../../../components/UTM/utm'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number'
    ),
  changepassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const {saveAuth, setCurrentUser, setToken} = useAuth()
  const navigate = useNavigate()
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const [screenLoading, setScreenLoading] = useState(false)
  const [text, setText] = useState<string>('')
  const channel = new BroadcastChannel('linkedinValues')
  channel.onmessage = (event) => {
    const msg: string = event.data.data
    setText(msg)
  }
  const sendUTM = async (params, userId) => {
    try {
      await SendUTMParams(params, userId)
    } catch (err) {
      console.error('Error while sending the utm ', err)
    }
  }
  useEffect(() => {
    if (window.location.href.includes('code')) {
      const channel = new BroadcastChannel('linkedinValues')
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      if (code) {
        channel.postMessage(code)
        window.close()
      }
      const state = urlParams.get('state')
    }
  }, [window.location.href])

  useEffect(() => {
    ;(async () => {
      try {
        if (text) {
          const currentUrl = window.location.href
          const urlParts = currentUrl.split('?')
          const utmParams = {}
          if (urlParts.length > 1) {
            const urlParams = new URLSearchParams(urlParts[1])
            urlParams.forEach((value, param) => {
              utmParams[param] = value
            })
          }
          setScreenLoading(true)
          let obj = {
            code: text,
            utmParams: utmParams,
          }
          let response = await SigninUserWithLinkedinAuthApi(obj)
          setScreenLoading(false)
          if (response && response.success && response.data) {
            localStorage.setItem('loginToken', response.data.token)
            localStorage.setItem('startTour', JSON.stringify(true))
            localStorage.setItem('userId', response.data.userId)
            localStorage.setItem('getUserdetails', JSON.stringify(true))
            setToken(response.data.userId)
            localStorage.setItem('kt_theme_mode_value', 'light')
            navigate('/flows')
            window.location.reload()
          } else {
            openNotificationWithIcon('error', response.error.message, 'Signin Error')
            setLoading(false)
          }
        }
      } catch (err) {
        setScreenLoading(false)
        console.log(err)
      }
    })()
  }, [text])

  const handleSuccess = (data) => {
    console.log(data)
  }
  const handleFailure = (error) => {
    console.error(error)
  }
  const toggleConfirmPassVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const [api, contextHolder] = notification.useNotification()

  const openNotificationWithIcon = (type, description, title) => {
    api[type]({
      message: title,
      description: description,
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      console.log(values)
      try {
        if (values.password === values.changepassword) {
          const currentUrl = window.location.href
          const urlParts = currentUrl.split('?')
          const utmParams = {}
          if (urlParts.length > 1) {
            const urlParams = new URLSearchParams(urlParts[1])
            urlParams.forEach((value, param) => {
              utmParams[param] = value
            })
          }
          const payLoad = {
            email: values.email,
            password: values.password,
            userName: values.firstname,
            lastname: values.lastname,
            role: 'Normal',
            photoUrl: '',
            planName: 'Free Plan',
            utmParams: utmParams,
          }
          const signUpRes = await SignUpFormUserData(payLoad)
          if (signUpRes.success) {
            localStorage.setItem('userId', JSON.stringify(values.email))
            navigate('/emailVerify')
            openNotificationWithIcon('success', signUpRes.data, 'Signup Error')
          } else {
            setLoading(false)
            openNotificationWithIcon('error', signUpRes.data, 'Signup Error')
          }
        }
      } catch (error) {
        console.error(error)
        openNotificationWithIcon('error', 'Something went wrong', 'Signup Error')
        saveAuth(undefined)

        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  const handleGoogleSignIn = async () => {
    try {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const currentUser = result.user
          if (currentUser) {
            const utmParams = getUTMParameters()
            let obj = {
              userId: currentUser.uid,
              email: currentUser.email,
              photoUrl: currentUser.photoURL,
              userName: currentUser.displayName,
              password: '',
              planName: 'Free Plan',
              utmParams: utmParams,
            }

            try {
              let data = await SignInUserWithGoogle(currentUser.email)
              if (data.success) {
                if (data.data === null) {
                  let newUser = await SignUpUserData(obj)
                  if (newUser && newUser.success && newUser.data) {
                    localStorage.setItem('userDefaultData', JSON.stringify(obj))
                    localStorage.setItem('loginToken', newUser.data.token)
                    localStorage.setItem('userId', newUser.data['userId'])
                    localStorage.setItem('startTour', JSON.stringify(true))
                    localStorage.setItem('getUserdetails', JSON.stringify(true))
                    localStorage.setItem('kt_theme_mode_value', 'light')
                    console.log('New User', newUser.data.userId)
                    setToken(data.data['userId'])
                    localStorage.setItem('kt_theme_mode_value', 'light')
                    navigate('/flows')
                    window.location.reload()
                  } else {
                    setLoading(false)
                    openNotificationWithIcon('error', newUser.data, 'Signup Error')
                  }
                } else {
                  if (data.data) {
                    localStorage.setItem('userDefaultData', JSON.stringify(obj))
                    localStorage.setItem('loginToken', data.data.token)
                    localStorage.setItem('userId', data.data['userId'])
                    setToken(data.data['userId'])
                    localStorage.setItem('kt_theme_mode_value', 'light')
                    navigate('/flows')
                    window.location.reload()
                  }
                }
              } else {
                openNotificationWithIcon('error', data.error.message, 'Signin Error')
                setLoading(false)
              }
            } catch (err) {
              console.log('Error at Google sign in with db', err)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      console.log('Auth err' + err)
    }
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {contextHolder}
      {screenLoading && <PageLoader />}
      {/* begin::Heading */}
      <div className='text-center mb-8'>
        {/* begin::Title */}
        <h1 style={{fontSize: '2.7em'}} className='text-dark fw-bolder'>
          Create a new account
        </h1>
        <span className='fs-4 fw-semibold'>Find Contact Info of Prospects for Free</span>
      </div>
      <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a
            onClick={() => handleGoogleSignIn()}
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign up with Google
          </a>
        </div>
        <div className='col-md-6'>
          <LinkedIn
            closePopupMessage='Custom close message'
            scope={['openid', 'email', 'profile'].join(' ')}
            clientId='86jl57vdhipvvt'
            redirectUri={'https://app.beanbag.ai/'}
            onSuccess={handleSuccess}
            onError={handleFailure}
          >
            {({linkedInLogin}) => (
              <a
                onClick={linkedInLogin}
                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')}
                  className='theme-light-show h-15px me-2'
                />
                Sign up with Linkedin
              </a>
            )}
          </LinkedIn>
        </div>
      </div>
      <div className='separator separator-content my-12'>
        <span className='w-150px text-gray-500 fw-semibold fs-7'>Or with your data</span>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      <div className='fv-row mb-4'>
        <Input
          size='large'
          placeholder='First name'
          type='text'
          status={formik.errors.firstname ? 'error' : ''}
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-4'>
        <Input
          size='large'
          placeholder='Last name'
          type='text'
          status={formik.errors.lastname ? 'error' : ''}
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-4'>
        <Input
          size='large'
          placeholder='Email'
          type='email'
          status={formik.errors.email ? 'error' : ''}
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-4' data-kt-password-meter='true'>
        <div className=' position-relative'>
          <div className='position-relative mb-3'>
            <Input
              status={formik.errors.password ? 'error' : ''}
              size='large'
              suffix={
                <EyeFilled
                  style={{cursor: 'pointer'}}
                  onClick={togglePasswordVisibility}
                  rev={undefined}
                />
              }
              style={{backgroundImage: 'none'}}
              type={showPassword ? 'text' : 'password'}
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                {
                  'is-invalid no-val-bg': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />

            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          ></div>
        </div>
      </div>
      <div className='fv-row mb-4'>
        <div className='position-relative'>
          <Input
            status={formik.errors.changepassword ? 'error' : ''}
            size='large'
            suffix={
              <EyeFilled
                style={{cursor: 'pointer'}}
                onClick={toggleConfirmPassVisibility}
                rev={undefined}
              />
            }
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder='Confirm password'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />

          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='text-gray-500 text-center fw-semibold fs-6 mb-3'>
        Already have account?{' '}
        <Link to='/' className='link-primary'>
          Sign in
        </Link>
      </div>
      <div className='text-center'>
        <Button
          type='primary'
          size='large'
          className='w-100'
          id='kt_sign_up_submit'
          htmlType='submit'
          loading={loading}
          onClick={() => formik.handleSubmit()}
          disabled={
            formik.isSubmitting ||
            !formik.isValid ||
            Object.values(formik.values).some((value) => value === '')
          }
        >
          <span className='indicator-label'>Submit</span>
        </Button>
      </div>
      <div className='fv-row mt-4 '>
        <span className='fs-8 fw-semibold text-muted'>
          By clicking ‘Create Account’ or signing up, you agree to the
          <a
            href='https://www.beanbag.ai/legal/terms-of-use'
            target='_blank'
            className='ms-1 link-primary'
          >
            Terms of Use {` `}
          </a>
          and
          <a
            href='https://www.beanbag.ai/privacy-policy'
            target='_blank'
            className='ms-1 link-primary'
          >
            Privacy policy
          </a>
          . You also agree to receive information and offers relevant to our services
          via email and phone .
        </span>

        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}
