import {Route, Routes,Navigate} from 'react-router-dom'
import {Registration} from './components/Registration'
import Login from './components/Login'
import {AuthLayout} from './AuthLayout'
import {auth} from '../../../firebase'
import {provider} from '../../../firebase'
import {ForgotPassword} from './components/ForgotPassword'
import {ForgotPasswordReset} from './components/ForgotPasswordNew'
import {ConfirmPasswordReset} from './components/ConfirmPassword'
import {EmailVerification} from './components/VerifyEmail'

const AuthPage = () => (
  <Routes>
    <Route path='/' element={<AuthLayout />}>
      <Route path='/' element={<Login auth={auth} provider={provider} />} />
      <Route path='/registration' element={<Registration />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      {/* <Route path='/reset-password' element={<ForgotPasswordReset />} /> */}
      <Route index element={<Login auth={auth} provider={provider} />} />
      <Route path='/emailVerify' element={<EmailVerification />} />
      <Route path='/confirmforgotpassword' element={<ConfirmPasswordReset />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Route>
  </Routes>
)

export {AuthPage}
