import {createContext, useContext, useState, useEffect} from 'react'
import {useProfileContext} from './profilecontext'
import {useSideBarContext} from './sidebarcontext'
import QueryMessage from '../components/QueryMessage'

import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../_metronic/helpers'
import {ChatAiApi} from '../apicalls/ApiCall'
import {useValuesContext} from './valuescontext'
import {UserObject} from '../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuContent'
import {FilterObject} from '../components/FilterPopUp'
interface DefaultQuery {
  text: string
  searchQuery: string
  JobTitles: string[]
  PastJobTitles: string[]
  Profiles: number
  Area: string[]
  ReqInfo: string[]
  ExcludedGender: boolean
  ExcludedProfile: boolean
  Experience: {minExp: number; maxExp: number}[]
  TimeSpent: {years: number; months: number}
  University: string[]
  Skills: string[]
  Languages: string[]
  Companies: string[]
  Industries: string[]
  CompanySize: string
  ExcludedCompanies: string[]
  MinimumDegree: string
  FieldOfStudy: string[]
}

type ChatContextType = {
  showchatUI: boolean
  setShowchatUI: (value: boolean) => void
  setMessages: (messages: MessageModel[]) => void
  sendMessage: (message: string) => void
  messages: MessageModel[]
  showQuery: boolean
  setShowQuery: (value: boolean) => void
  componentInput: string
  setComponentInput: (componentInput: string) => void
  queryOutput: string
  setQueryOutput: (queryOutput: string) => void
  setMessage: (message: string) => void
  localChat: MessageModel[]
  setLocalChat: (localChat: MessageModel[]) => void
  isListen: boolean
  setIsListen: (value: boolean) => void
  showMessages: MessageModel[]
  setShowMessages: (showMessages: MessageModel[]) => void
  defaultQuery: DefaultQuery | undefined
  setDefaultQuery: (defaultQuery: DefaultQuery | undefined) => void
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
}

const ChatContext = createContext<ChatContextType | undefined>(undefined)
let userObj: UserObject
let userId = ''
const userObjString = localStorage.getItem('userDefaultData')
if (userObjString !== null) {
  userObj = JSON.parse(userObjString)
  userId = userObj.userId
}
const ChatContextProvider = ({children}) => {
  const {
    setArea,
    setExcludedCompanies,
    setExcludedGender,
    setExcludedProfile,
    setCompanies,
    setExperience,
    setFieldOfStudy,
    setIndustries,
    setJobTitles,
    setPastJobTitles,
    setLanguages,
    setFilterObject,
    filterObject,
  } = useValuesContext()
  const [showQuery, setShowQuery] = useState<boolean>(false)
  const [showchatUI, setShowchatUI] = useState(false)
  const [messages, setMessages] = useState<MessageModel[]>([])
  const [showMessages, setShowMessages] = useState<MessageModel[]>([])
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const {newSession} = useSideBarContext()
  const [componentInput, setComponentInput] = useState<string>('')
  const [queryOutput, setQueryOutput] = useState<string>('')
  const [replyMsg, setReplyMsg] = useState<MessageModel>()
  const {profilesData} = useProfileContext()
  const [localChat, setLocalChat] = useState<MessageModel[]>([])
  const [defaultQuery, setDefaultQuery] = useState<DefaultQuery>()
  const [isListen, setIsListen] = useState<boolean>(false)
  const [updatedFilterObject, setUpdatedFilterObject] = useState<FilterObject>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  useEffect(() => {
    if (filterObject) {
      setUpdatedFilterObject({...filterObject})
    }
  }, [filterObject])

  const sendMessage = async (value: string) => {
    const newMessage: MessageModel = {
      user: userId,
      type: 'out',
      text: value,
      time: 'Just now',
    }
    if (showMessages.length > 0) {
      setShowMessages([...showMessages, newMessage])
    } else {
      setShowMessages([newMessage])
    }
    let data = await ChatAiApi(newMessage, showMessages, filterObject)
    if (data.success) {
      setFilterObject(data.data['resultJson'])
      const newClient: MessageModel = {
        user: userId,
        text: '',
        time: 'Just now',
        type: 'in',
        content: <QueryMessage componentInput={data.data['message']} />,
      }
      setShowMessages((prevMessages) => [...prevMessages, newClient])
      toggleChatUpdateFlat((flag) => !flag)
    }
    toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')
  }

  // useEffect(() => {
  //   if (componentInput) {
  //     setTimeout(() => {
  //       const newClient: MessageModel = {
  //         user: userId,
  //         text: '',
  //         time: 'Just now',
  //         type: 'in',
  //         content: <QueryMessage componentInput={componentInput} />,
  //       }

  //       setShowMessages((prevMessages) => [...prevMessages, newClient])
  //       toggleChatUpdateFlat((flag) => !flag)
  //     }, 1000)
  //   }
  // }, [componentInput])

  // useEffect(() => {
  //   if (profilesData.length > 0) {
  //     setComponentInput('Here are Your Results')
  //   } else if (err === 'failed') {
  //     setComponentInput('Something went Wrong please try again')
  //   }
  // }, [profilesData,showMessages])

  useEffect(() => {
    if (newSession) {
      setMessages([])
      setMessage('')
      setShowchatUI(false)
      setShowMessages([])
      // bufferMessages.length = 0
      setComponentInput('')
      setLocalChat([])
    }
  }, [newSession])

  const chatValue = {
    showchatUI,
    setShowchatUI,
    setMessages,
    messages,
    sendMessage,
    showQuery,
    setShowQuery,
    componentInput,
    setComponentInput,
    queryOutput,
    setQueryOutput,
    setMessage,
    setLocalChat,
    localChat,
    setIsListen,
    isListen,
    showMessages,
    setShowMessages,
    defaultQuery,
    setDefaultQuery,
    isModalOpen,
    setIsModalOpen,
  }

  return <ChatContext.Provider value={chatValue}>{children}</ChatContext.Provider>
}

const useChatContext = () => {
  const chatcontext = useContext(ChatContext)

  if (!chatcontext) {
    throw new Error('useChatContext must be used within a ChatContextProvider')
  }

  return chatcontext
}

export {useChatContext, ChatContextProvider}
