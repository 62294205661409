import React from 'react'
import {Spin} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
const PageLoader = () => {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 50,
            color: '#204f58',
            position: 'fixed',
            left: '50%',
            top: '50%',
            zIndex: '1000',
          }}
          spin
          rev={undefined}
        />
      }
    />
  )
}

export default PageLoader
