import React, {useState} from 'react'
import {Tabs} from 'antd'
import AutomationPage from '../Pages/AutomationPage'
import DashboardPage from '../Pages/DashboardPage'
import PreviousAutomations from '../Subcomponents/PreviousAutomations'
import CurrentAutomations from '../Subcomponents/CurrentAutomations'
import emptyImg from '../../assets/empty.png'
const {TabPane} = Tabs

const AutomationNavigation = () => {
  const [activeTab, setActiveTab] = useState('1')

  const handleTabChange = (key) => {
    setActiveTab(key)
  }

  const navigateToAutomationPage = () => {
    setActiveTab('1')
  }

  const onCreateAutomationClick = () => {
    navigateToAutomationPage()
  }

  return (
    <div style={{height: '100%'}}>
      <AutomationPage />
      {/* <Tabs
        defaultActiveKey='1'
        tabPosition='left'
        activeKey={activeTab}
        onChange={handleTabChange}
        tabBarStyle={{width: '200px'}}
      >
        <TabPane tab='Automations' key='1'>
          <AutomationPage />
        </TabPane>
        <TabPane tab='Dashboard' key='2'>
          <Tabs
            defaultActiveKey='current'
            tabPosition='left'
            style={{height: '100%', background: '#FFFFFF'}}
            tabBarStyle={{width: '200px'}}
          >
            <TabPane tab='Current' key='current'>
              <div>
                <DashboardPage onCreateAutomationClick={onCreateAutomationClick} />
                <CurrentAutomations />
              </div>
            </TabPane>
            <TabPane tab='Previous' key='previous'>
              <div>
                <DashboardPage onCreateAutomationClick={onCreateAutomationClick} />
                <PreviousAutomations />
              </div>
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs> */}
    </div>
  )
}

export default AutomationNavigation
