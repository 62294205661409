import React from 'react'
import {Card, Flex, Spin} from 'antd'
import twitterLogo from '../../assets/Twitterlogo.jpeg'
const TwitterCard = ({loadingData, setModal, title, description}) => {
  return (
    <div>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='twitter'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={twitterLogo}
            />
          </div>
        }
        style={{
          width: '220px',
          height: '220px',
          background: '#000000',
          cursor: loadingData ? 'not-allowed' : 'pointer',
        }}
        onClick={() => {
          loadingData ? setModal(false) : setModal(true)
        }}
      >
        <Flex vertical>
          <Flex gap={'small'} align='center' justify='space-between'>
            <Flex align='start' vertical gap={'middle'}>
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '1.5em',
                  color: 'white',
                  lineHeight: '1.2',
                  textAlign: 'start',
                }}
                className='heading'
              >
                {title}
              </span>{' '}
              <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
                {description}
              </span>
            </Flex>
            {loadingData && <Spin size='small' style={{marginLeft: '5px'}} />}
          </Flex>
        </Flex>
      </Card>{' '}
    </div>
  )
}

export default TwitterCard
