import React from 'react'
import {Modal, Flex, Menu, Avatar, Input, Button, Tabs} from 'antd'
import EmptyLogo from './Empty'
import emailStyle from '../../styles/flowstyle.module.css'
import messageIcon from '../../assets/message.png'
import socialIcon from '../../assets/social-media.png'
import timeIcon from '../../assets/time.png'
import pieChartIcon from '../../assets/pie-chart.png'
import groupIcon from '../../assets/group.png'
import network from '../../assets/network.png'

const {TextArea} = Input
const categoryIconMap = {
  'Initial Outreach': messageIcon,
  'Follow-Up': timeIcon,
  'Industry-Specific Outreach': pieChartIcon,
  'Role-Specific Outreach': groupIcon,
  Networking: network,
  'Social Media Outreach': socialIcon,
}
const Mrbean = ({
  showMrBean,
  setPromptValue,
  setShowMrBean,
  promptData,
  handlePromptSelect,
  selectedPrompt,
  promptValue,
  handleTabUpdate,
  handleUsePrompt,
  loading,
}) => {
  return (
    <Modal
      title='AI Generated Templates'
      centered
      closable={false}
      style={{paddingLeft: '20px', paddingRight: '20px'}}
      width={1200}
      open={showMrBean}
      onCancel={() => {
        setPromptValue('')
        setShowMrBean(false)
      }}
      footer={[]}
    >
      <div style={{border: '1px solid #f3f3f3'}}>
        <Flex gap={'middle'}>
          <Menu
            defaultActiveFirst={true}
            defaultSelectedKeys={['0']}
            theme='dark'
            style={{
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              height: '400px',
              paddingTop: '10px',
            }}
          >
            {promptData && promptData.length > 0 ? (
              promptData.map((prompt, index) => (
                <Menu.Item onClick={() => handlePromptSelect(index)} key={index}>
                  <Flex align='center' gap={'small'}>
                    <Avatar
                      shape='square'
                      size={'small'}
                      src={
                        <img
                          style={{width: 'fit-content', objectFit: 'contain'}}
                          src={categoryIconMap[prompt.category]}
                          alt='category icon'
                        />
                      }
                    />
                    <span>{prompt.category}</span>
                  </Flex>
                </Menu.Item>
              ))
            ) : (
              <EmptyLogo description={'No prompts available'} />
            )}
          </Menu>
          {selectedPrompt && selectedPrompt.prompts ? (
            <Tabs
              style={{paddingTop: '10px', width: '90%'}}
              activeKey={
                Object.keys(selectedPrompt.prompts).length === 1
                  ? Object.keys(selectedPrompt.prompts)[0]
                  : ''
              }
              onChange={(activeKey) => handleTabUpdate(activeKey)}
              tabPosition={'right'}
              items={
                selectedPrompt.prompts
                  ? (Object.values(selectedPrompt.prompts) as string[]).map((value, index) => ({
                      label: (
                        <div
                          id={promptValue === value ? emailStyle.tabItem : ''}
                          style={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            wordWrap: 'break-word',
                            maxWidth: '550px',
                            textAlign: 'left',
                            lineHeight: '1.2em',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <span style={{whiteSpace: 'pre-line', fontSize: '14px'}}>{value}</span>
                        </div>
                      ),
                      key: String(index + 1),
                    }))
                  : [{label: '', key: ''}]
              }
            />
          ) : (
            <div style={{width: '550px'}}>
              <EmptyLogo description='There are no values' />
            </div>
          )}
          <div
            style={{
              flexDirection: 'column',
              width: '400px',
            }}
            className='d-flex align-items-center justify-content-around'
          >
            <TextArea
              onChange={(e) => setPromptValue(e.target.value)}
              value={promptValue}
              placeholder='Type your prompt to search'
              autoSize={{minRows: 15, maxRows: 15}}
            />
            <Button
              style={{width: '100%'}}
              size='large'
              disabled={promptValue === '' && selectedPrompt?.prompts[0] === ''}
              type='primary'
              loading={loading}
              onClick={handleUsePrompt}
            >
              Use prompt
            </Button>
          </div>
        </Flex>
      </div>
    </Modal>
  )
}

export default Mrbean
