import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import {registerLicense} from '@syncfusion/ej2-base'
import {notification} from 'antd'
import axios from 'axios'
import {useAuthContext} from './context/AuthContext'
import {useNavigate} from 'react-router-dom'
import {userId} from './components/DataTypes/UserDataTypes'
import Beanbag from './assets/smallLogo.png'
registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NHaF5cWWJCf1JpRGpGfV5yd0VHal9UTnVZUj0eQnxTdEZiWX1ecXdRQWBaV0V0Xg=='
)
const App = () => {
  const {logout, setToken} = useAuth()
  const {signOutFromGoogle} = useAuthContext()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  axios.interceptors.response.use((response) => {
    if (response.data.success) {
      if (response.data.data === 'Invalid Session') {
        signOutFromGoogle()
        localStorage.clear()
        setToken('')
        logout()
        navigate('auth')
      }
    }
    return response
  })
  localStorage.setItem('kt_theme_mode_value', 'light')
  const showNotification = (data) => {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification')
    } else if (Notification.permission === 'granted') {
      const options = {
        body: data.value,
        icon: Beanbag,
      }
      const notification = new Notification(data.Type, options)
      console.log(data.link)
      notification.addEventListener('click', () => {
        window.open(data.link, '_blank')
      })
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          const options = {
            body: data.value,
            icon: Beanbag,
          }
          const notification = new Notification(data.Type, options)
          notification.addEventListener('click', () => {
            window.open(data.link, '_blank')
          })
        }
      })
    }
  }

  useEffect(() => {
    const ws = new WebSocket(`wss://api.beanbag.ai/ws/${userId}`)

    ws.onopen = () => {
      console.log('Connected to WebSocket')
    }

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data)
      console.log('Received data from WebSocket:', data)
      showNotification(data)
    }

    ws.onerror = (error) => {
      console.error('WebSocket error:', error)
    }

    return () => {
      ws.close()
    }
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <AuthInit>
          {contextHolder}
          <Outlet />
        </AuthInit>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
