import React, {useEffect, useState} from 'react'
import {Card, Flex, Modal, Button, Input, message, Tooltip, Switch, Select} from 'antd'
import {ScrapePeopleData} from '../../apicalls/AutomationAPI'
import {InfoCircleOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons'
import linkedinLogo from '../../assets/linkedin logg.png'
import {GetFlowListApi, GetListApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'

export const GetPeople = () => { 
  const [modalVisible, setModalVisible] = useState(false)
  const [liAt, setLiAt] = useState('')
  const [peopleSearch, setPeoplesearch] = useState('')
  const [linkedinMessage, setLinkedinMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<'success' | 'failure' | null>(null)
  const [automationTitle, setAutomationTitle] = useState<string>('')
  const [flows, setFlows] = useState([])
  const [lists, setLists] = useState([])
  const [selectedList, setSelectedList] = useState<string | null>(null)
  const [selectedFlow, setSelectedFlow] = useState<string | null>(null)
  const [peopleData, setPeopleData] = useState<
    {
      searchUrl: string
      flowId: string
      listId?: string
    }[]
  >([
    {
      searchUrl: '',
      flowId: '',
      listId: '',
    },
  ])
  const [mode, setMode] = useState<'lists' | 'flows'>('lists')
  const listOptions = lists.map((list) => ({label: list['listName'], value: list['listId']}))
  const flowOptions = flows.map((flow) => ({label: flow['flowName'], value: flow['flowId']}))
  useEffect(() => {
    getFlows()
    getLists()
  }, [])
  const handleScrapeJobsData = async () => {
    setLoading(true)
    setModalVisible(false)
    setResult(null)
    try {
      message.info('Started automation,you will receive an Email shortly')
      const data = {
        liAt: liAt,
        searchData: peopleData,
        userId: userId,
        flowTitle: automationTitle,
      }
      const res = await ScrapePeopleData(data)
      if (res) {
      } else {
        message.error('Error while getting the employees')
        setResult('failure')
      }
    } catch (err) {
      message.error('Error while getting the employees')
      console.error('Error visiting profile:', err)
      setResult('failure')
    } finally {
      setLoading(false)
      setLiAt('')
      setPeoplesearch('')
      setLinkedinMessage('')
      setTimeout(() => {
        setResult(null)
      }, 5000)
    }
  }

  const getFlows = async () => {
    try {
      let data = await GetFlowListApi(userId)
      if (data.success) {
        setFlows(data.data)
      }
    } catch (err) {
      message.error('error while getting the accounts data')
      console.error('error while getting the accounts data')
    }
  }
  async function getLists() {
    try {
      const response = await GetListApi(userId)
      if (response && response.success && response.data) {
        setLists(response.data)
      }
    } catch (err) {
      console.error('Error while getting the lists of user ' + err)
    }
  }
  const handleJobsPostingUrlChange = (value, index) => {
    const updatedJobsPostingData = [...peopleData]
    updatedJobsPostingData[index].searchUrl = value
    setPeopleData(updatedJobsPostingData)
  }
  const removeJobPostingData = (index) => {
    const updatedJobsPostingData = [...peopleData]
    updatedJobsPostingData.splice(index, 1)
    setPeopleData(updatedJobsPostingData)
  }
  const onChange = (checked: boolean) => {
    setMode(checked ? 'flows' : 'lists')
  }
  const handleSelectionChange = (value: string, index) => {
    if (mode === 'lists') {
      setSelectedList(value)
      const updatedJobsPostingData = [...peopleData]
      updatedJobsPostingData[index].flowId = ''
      updatedJobsPostingData[index].listId = value
    } else {
      setSelectedFlow(value)
      const updatedJobsPostingData = [...peopleData]
      updatedJobsPostingData[index].flowId = value
      updatedJobsPostingData[index].listId = ''
    }
  }
  const addJobPostingUrl = () => {
    setPeopleData([...peopleData, {searchUrl: '', flowId: ''}])
  }
  return (
    <Flex vertical>
      <Card
        hoverable
        cover={
          <div
            style={{
              width: '40px',
              height: '40px',
              background: 'white',
              borderBottomRightRadius: '25px',
            }}
          >
            <img
              alt='linkedin'
              style={{width: '30px', height: '30px', objectFit: 'contain', textAlign: 'center'}}
              src={linkedinLogo}
            />
          </div>
        }
        onClick={() => setModalVisible(true)}
        style={{width: '220px', height: '220px', background: '#0077b5', cursor: 'pointer'}}
      >
        <Flex vertical gap={'middle'}>
          <span style={{color: 'white', fontWeight: '500', fontSize: '1.5em', lineHeight: '1.2'}}>
            People Search Export
          </span>
          <span style={{fontWeight: '500', fontSize: '1em', color: 'white'}}>
            Fetch all the Prospects data in few simple steps
          </span>
        </Flex>
      </Card>
      <Modal
        centered
        title='Enter details to get the Prospects information'
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='visit-profile'
            type='primary'
            disabled={
              !liAt ||
              !peopleData[0].searchUrl ||
              (!peopleData[0].flowId && !peopleData[0].listId) ||
              !automationTitle
            }
            onClick={handleScrapeJobsData}
          >
            Launch
          </Button>,
        ]}
      >
        <Flex vertical gap={10}>
          <div>
            <Flex gap={'4px'}>
              <span>Automation title</span>
            </Flex>
            <Input
              id='JobpostingUrl'
              placeholder='Enter Automation title'
              value={automationTitle}
              onChange={(e) => setAutomationTitle(e.target.value)}
            />
          </div>
          <div>
            <Flex gap={'4px'}>
              <span>LinkedIn Cookie</span>
              <Tooltip title='Your LinkedIn authentication token'>
                <InfoCircleOutlined rev={undefined} style={{color: '#848484', fontSize: '12px'}} />
              </Tooltip>
            </Flex>
            <Input
              id='liAtInput'
              placeholder='Enter li_at'
              value={liAt}
              onChange={(e) => setLiAt(e.target.value)}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Flex justify='space-between'>
              <Flex gap={'4px'}>
                <span>LinkedIn Search URL</span>
                <Tooltip title='Search URL of people EX: https://www.linkedin.com/search/results/PEOPLE/?keywords=python%20developer&origin=SWITCH_SEARCH_VERTICAL&sid=Z2r'>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{color: '#848484', fontSize: '12px'}}
                  />
                </Tooltip>
              </Flex>
              <Flex align='center' gap={'5px'}>
                <Switch onChange={onChange} />
                <Button
                  type='primary'
                  onClick={addJobPostingUrl}
                  icon={<PlusOutlined rev={undefined} />}
                  size={'small'}
                ></Button>
              </Flex>
            </Flex>
            {peopleData.map((jobData, index) => (
              <div
                key={index}
                style={{display: 'flex', gap: '10px', justifyContent: 'space-between'}}
              >
                <Flex gap={'middle'} style={{width: '100%'}} align='center'>
                  <Input
                    id={`JobpostingUrl${index}`}
                    placeholder='Enter Job search URL'
                    value={jobData.searchUrl}
                    onChange={(e) => handleJobsPostingUrlChange(e.target.value, index)}
                  />
                  <Select
                    allowClear
                    style={{width: '100%'}}
                    placeholder={mode === 'lists' ? 'Please select a list' : 'Please select a flow'}
                    value={mode === 'lists' ? selectedList : selectedFlow}
                    onChange={(value) => handleSelectionChange(value, index)}
                    options={mode === 'lists' ? listOptions : flowOptions}
                  />
                </Flex>
                {index > 0 && (
                  <Button
                    onClick={() => removeJobPostingData(index)}
                    icon={<MinusOutlined rev={undefined} />}
                  />
                )}
              </div>
            ))}
          </div>
        </Flex>
      </Modal>
    </Flex>
  )
}
