import {FC, useState, useEffect} from 'react'
import {Card} from 'react-bootstrap'
import tilestyle from '../styles/tiles.module.css'
import {useChatContext} from '../context/chatcontext'
import {useValuesContext} from '../context/valuescontext'
import {useApiContext} from '../context/apicontext'
import {useSessionContext} from '../context/sessionContext'
import {v4 as uuid} from 'uuid'
import {SetFilterObjectApi} from '../apicalls/ApiCall'
import {Building2, Landmark, Code} from 'lucide-react'
import {UserObject} from '../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuContent'
interface FilterObject {
  Area: string[]
  Companies: string[]
  CompanySize: string
  ExcludedCompanies: string[]
  ExcludedGender: boolean
  ExcludedProfile: boolean
  Experience: {minExp: number; maxExp: number}[]
  FieldOfStudy: string[]
  Industries: string[]
  JobTitles: string[]
  Languages: string[]
  MinimumDegree: string
  PastJobTitles: string[]
  Profiles: number
  ReqInfo: string[]
  Skills: string[]
  TimeSpent: {years: number; months: number}
  University: string[]
  icon: string
  searchQuery: string
  title: string
}

//  These are the functions which are rendered in the Ui for easy query Tile1,til2,tile3 for 3 different type of querying
const Tile1: FC = () => {
  const {setFilterObject} = useValuesContext()
  const {setSessionInteracted, setSearchId, searchId, selectedSearchKey} = useSessionContext()
  const {sendMessage, setShowchatUI, setComponentInput} = useChatContext()
  const {dashboardData, setApiSearchId, setSqlQuery, setQueryObj, setSearchQuery} = useApiContext()
  const [thumnail1Data, setThumbnail1Data] = useState<FilterObject>()
  let userObj: UserObject
  let userId = ''
  const userObjString = localStorage.getItem('userDefaultData')
  if (userObjString !== null) {
    userObj = JSON.parse(userObjString)
    userId = userObj.userId
  }
  useEffect(() => {
    setThumbnail1Data(dashboardData?.defaultThumbnails.thumbnail1)

    if (dashboardData?.defaultThumbnails.thumbnail1) {
      setFilterObject(dashboardData.defaultThumbnails.thumbnail1)
      setSearchQuery(
        dashboardData?.defaultThumbnails?.thumbnail1?.searchQuery
          ? dashboardData?.defaultThumbnails?.thumbnail1?.searchQuery
          : ''
      )
      setSqlQuery(dashboardData?.defaultThumbnails?.thumbnail1?.searchQuery)
      setQueryObj(dashboardData.defaultThumbnails.thumbnail1)
    }
  }, [dashboardData])

  const handleTile1Query = () => {
    if (thumnail1Data) {
      setSearchQuery(thumnail1Data?.searchQuery)
      setFilterObject(thumnail1Data)
      setSqlQuery(thumnail1Data.searchQuery)
      setQueryObj(thumnail1Data)
    }

    // setSessionInteracted(true)
    // setComponentInput(
    //   'Seeking a Web Devloper Manager with a minimum of 5 years of experience, located in India'
    // )
    // let Id = uuid()
    // setSearchId(Id)
    // setApiSearchId(Id)
    // if (thumnail1Data) {
    //   setFilterObject(thumnail1Data)
    //   setSqlQuery(thumnail1Data.searchQuery)
    //   setQueryObj(thumnail1Data)
    // }

    // sendMessage('Edit the below query to fetch refined results')
    // setShowchatUI(true)
    // setTimeout(() => {
    //   ;(async () => {
    //     try {
    //       if (Id) {
    //         let data = await SetFilterObjectApi(userId + Id, thumnail1Data)
    //         console.log(data)
    //       }
    //     } catch (err) {
    //       throw new Error('Error while sending the filter Request')
    //     }
    //   })()
    // }, 9000)
  }
  return (
    <Card
      onClick={() => handleTile1Query()}
      id={tilestyle.tile1Card}
      className={`${tilestyle.hovered}`}
    >
      <Building2 className={tilestyle.tileIcons} style={{width: '30px', height: '30px'}} />
      <h2 className='mt-2 fw-bold'>{thumnail1Data?.title}</h2>
    </Card>
  )
}
const Tile2: FC = () => {
  let userObj: UserObject
  let userId = ''
  const userObjString = localStorage.getItem('userDefaultData')
  if (userObjString !== null) {
    userObj = JSON.parse(userObjString)
    userId = userObj.userId
  }
  const [thumnail2Data, setThumbnail2Data] = useState<FilterObject>()
  const {setSessionInteracted, setSearchId} = useSessionContext()
  const {sendMessage, setShowchatUI, setComponentInput} = useChatContext()
  const {dashboardData, setApiSearchId, setSqlQuery, setQueryObj, setSearchQuery} = useApiContext()
  const {setFilterObject} = useValuesContext()
  useEffect(() => {
    setThumbnail2Data(dashboardData?.defaultThumbnails.thumbnail2)
  }, [dashboardData])
  const handleTile2Query = async () => {
    if (thumnail2Data) {
      setSearchQuery(thumnail2Data?.searchQuery)
      setFilterObject(thumnail2Data)
      setSqlQuery(thumnail2Data.searchQuery)
      setQueryObj(thumnail2Data)
    }
    // setSessionInteracted(true)
    // let Id = uuid()
    // setSearchId(Id)
    // setApiSearchId(Id)

    // setComponentInput(
    //   'Seeking a Project Manager with a minimum of 5 years of experience, located in India'
    // )
    // if (thumnail2Data) {
    //   setFilterObject(thumnail2Data)
    //   setSqlQuery(thumnail2Data.searchQuery)
    //   setQueryObj(thumnail2Data)
    // }
    // sendMessage('Edit the below query to fetch refined results')
    // setShowchatUI(true)
    // setTimeout(() => {
    //   ;(async () => {
    //     try {
    //       if (Id) {
    //         let data = await SetFilterObjectApi(userId + Id, thumnail2Data)
    //         console.log(data)
    //       }
    //     } catch (err) {
    //       throw new Error('Error while sending the filter Request')
    //     }
    //   })()
    // }, 9000)
  }

  return (
    <Card onClick={() => handleTile2Query()} id={tilestyle.tile2Card} className={tilestyle.hovered}>
      <Code className={tilestyle.tileIcons} style={{width: '30px', height: '30px'}} />
      <h2 className='mt-2 fw-bold'>{thumnail2Data?.title}</h2>
    </Card>
  )
}

const Tile3: FC = () => {
  let userObj: UserObject
  let userId = ''
  const userObjString = localStorage.getItem('userDefaultData')
  if (userObjString !== null) {
    userObj = JSON.parse(userObjString)
    userId = userObj.userId
  }
  const [thumnail3Data, setThumbnail3Data] = useState<FilterObject>()
  const {setSessionInteracted, setSearchId} = useSessionContext()
  const {sendMessage, setShowchatUI, setComponentInput} = useChatContext()
  const {dashboardData, setApiSearchId, setSqlQuery, setQueryObj, setSearchQuery} = useApiContext()
  const {setFilterObject} = useValuesContext()
  useEffect(() => {
    setThumbnail3Data(dashboardData?.defaultThumbnails.thumbnail3)
  }, [dashboardData])
  const handleTile3Query = async () => {
    if (thumnail3Data) {
      setSearchQuery(thumnail3Data?.searchQuery)
      setFilterObject(thumnail3Data)
      setSqlQuery(thumnail3Data.searchQuery)
      setQueryObj(thumnail3Data)
    }
    // setSessionInteracted(true)
    // setComponentInput(
    //   'Seeking a Quality Analyst with a minimum of 5 years of experience, located in India'
    // )
    // let Id = uuid()
    // setSearchId(Id)
    // setApiSearchId(Id)
    // if (thumnail3Data) {
    //   setFilterObject(thumnail3Data)
    //   setSqlQuery(thumnail3Data.searchQuery)
    //   setQueryObj(thumnail3Data)
    // }
    // sendMessage('Edit the below query to fetch refined results')
    // setShowchatUI(true)
    // setTimeout(() => {
    //   ;(async () => {
    //     try {
    //       if (Id) {
    //         let data = await SetFilterObjectApi(userId + Id, thumnail3Data)
    //         console.log(data)
    //       }
    //     } catch (err) {
    //       throw new Error('Error while sending the filter Request')
    //     }
    //   })()
    // }, 9000)
  }
  return (
    <Card onClick={() => handleTile3Query()} id={tilestyle.tile3Card} className={tilestyle.hovered}>
      <Landmark className={tilestyle.tileIcons} style={{width: '30px', height: '30px'}} />
      <h2 className='mt-2 fw-bold'>{thumnail3Data?.title}</h2>
    </Card>
  )
}

export {Tile1, Tile2, Tile3}
