/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useAuth} from '../core/Auth'
import {Button, notification, Flex, Input} from 'antd'
import {VeryifyEmailApi} from '../../../apicalls/Authentication'
import {useNavigate} from 'react-router-dom'
import {getUTMParameters} from '../../../components/UTM/utm'

const initialValues = {
  verificationToken: '',
}

const registrationSchema = Yup.object().shape({
  verificationToken: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Verification token is required'),
})

export function EmailVerification() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setToken} = useAuth()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()


  const openNotificationWithIcon = (type, description, title) => {
    api[type]({
      message: title,
      description: description,
    })
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let userId: string | null = localStorage.getItem('userId')
        let id: string | null = userId ? JSON.parse(userId) : null
        if (id) {
          let verifyRes = await VeryifyEmailApi(id, values.verificationToken)
          if (verifyRes && verifyRes.success && verifyRes.data) {
            setLoading(false)
            localStorage.setItem('startTour', JSON.stringify(true))
            localStorage.setItem('loginToken', verifyRes.data.token)
            localStorage.setItem('userId', verifyRes.data.userId)
            setToken(verifyRes.data.userId)
            localStorage.setItem('kt_theme_mode_value', 'light')
            localStorage.setItem('getUserdetails', JSON.stringify(true))
            navigate('/flows')
            window.location.reload()
          } else {
            openNotificationWithIcon('error', verifyRes.error.message, 'Signin Error')
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {contextHolder}
      <div className='text-center mb-6'>
        <h1 style={{fontSize: '1.7em'}} className='text-dark fw-bolder heading'>
          Verify your Email
        </h1>
        <span className='fs-5 fw-semibold text-grey'>
          Please enter the verification code to continue
        </span>
      </div>
      <div className='fv-row mb-6'>
        <Input
          status={formik.errors.verificationToken ? 'error' : ''}
          size='large'
          placeholder='Enter the verification code'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('verificationToken')}
          className={clsx({
            'is-invalid': formik.touched.verificationToken && formik.errors.verificationToken,
            'is-valid': formik.touched.verificationToken && !formik.errors.verificationToken,
          })}
        />
        {formik.touched.verificationToken && formik.errors.verificationToken && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.verificationToken}</span>
            </div>
          </div>
        )}
      </div>
      <div className='text-center w-100'>
        <Button
          size='large'
          htmlType='submit'
          type='primary'
          disabled={!formik.isValid || !formik.dirty}
          className='w-100'
          loading={loading}
        >
          {loading ? 'Verifying' : 'Verify'}
        </Button>
      </div>
      <div className='fv-row mt-4'>
        <span className='fs-8 fw-semibold text-muted'>
          By clicking ‘Create Account’ or signing up, you agree to the
          <a
            href='https://www.beanbag.ai/legal/terms-of-use'
            target='_blank'
            className='ms-1 link-primary'
          >
            Terms of Use {` `}
          </a>
          and
          <a
            href='https://www.beanbag.ai/privacy-policy'
            target='_blank'
            className='ms-1 link-primary'
          >
            Privacy policy
          </a>
          . You also agree to receive information and offers relevant to our services
          via email and phone .
        </span>
      </div>
    </form>
  )
}
