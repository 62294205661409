import {createContext, useContext, useState, ReactNode} from 'react'
import {TeamDataProps} from '../components/DataTypes/TeamSettings'

type TeamSettingsContextProps = {
  teamData: TeamDataProps[]
  setTeamData: (teamData: TeamDataProps[]) => void
}

const TeamSettingsContext = createContext<TeamSettingsContextProps | undefined>(undefined)

const TeamSettingsProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [teamData, setTeamData] = useState<TeamDataProps[]>([
    // {
    //   userId: 'nikhil@crewscale.com',
    //   status: 'Accepted',
    //   emailCredits: 30,
    //   emailTotalCredits: 20,
    //   phoneCredits: 30,
    //   phoneTotalCredits: 20,
    //   teamMemRole: 'Admin',
    // },
    // {
    //   userId: 'abhiram@crewscale.com',
    //   status: 'Pending',
    //   emailCredits: 10,
    //   emailTotalCredits: 20,
    //   phoneCredits: 10,
    //   phoneTotalCredits: 20,
    //   teamMemRole: 'User',
    // },
    // {
    //   userId: 'raghu@crewscale.com',
    //   status: 'Rejected',
    //   emailCredits: 10,
    //   emailTotalCredits: 20,
    //   phoneCredits: 10,
    //   phoneTotalCredits: 20,
    //   teamMemRole: 'Admin',
    // },
  ])

  const value: TeamSettingsContextProps = {
    teamData,
    setTeamData,
  }

  return <TeamSettingsContext.Provider value={value}>{children}</TeamSettingsContext.Provider>
}

const useTeamSettingContext = (): TeamSettingsContextProps => {
  const teamSettings = useContext(TeamSettingsContext)
  if (!teamSettings) {
    throw new Error('Error at team settings context')
  }
  return teamSettings
}

export {TeamSettingsProvider, useTeamSettingContext}
