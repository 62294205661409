import {createContext, useContext, useState} from 'react'

const EditQueryContext = createContext()

const EditQueryProvider = ({children}) => {
  const [showPopup, setShowPopup] = useState(false)

  const contextValue = {
    showPopup,
    setShowPopup,
  }
  return <EditQueryContext.Provider value={contextValue}>{children}</EditQueryContext.Provider>
}

const useEditQueryContext = () => {
  const context = useContext(EditQueryContext)
  if (!context) {
    throw new Error('useEditQueryContext must be used within an EditQueryProvider')
  }
  return context
}

export {EditQueryProvider, useEditQueryContext}
