import React, {useEffect, useState} from 'react'
import {Flex, Row, Button, Spin, message} from 'antd'
import {EyeOutlined} from '@ant-design/icons'
import {useProfileContext} from '../../context/profilecontext'
import {GetUserInfoApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import copy from 'clipboard-copy'
import {ProfileEmails, ProfilePhoneNumbers} from '../DataTypes/ProfileTypes'
import ContactIcons from './ContactIcons'
import EmailsAndPhoneComponent from './EmailsAndPhoneComponent'

const ContactInfo = ({linkedin, github, profileName, profile}) => {
  const {
    showSpillBeans,
    setShowSpillBeans,
    setEmailError,
    setEmail,
    setPhone,
    email,
    emailError,
    phone,
    phoneError,
    setPhoneError,
  } = useProfileContext()

  const [emails, setEmails] = useState<ProfileEmails[]>([])
  const [copiedMessage, setCopiedMessage] = useState(false)
  const [phones, setPhones] = useState<ProfilePhoneNumbers[]>([])
  const [showAll, setShowAll] = useState(false)
  const [displayedNumbers, setDisplayedNumbers] = useState<ProfilePhoneNumbers[]>([])
  const [displayedEmail, setDisplayedEmail] = useState<ProfileEmails[]>([])
  const [getSPillBeansData, setGetSpillBeansData] = useState<Boolean>(false)

  useEffect(() => {
    if (phone && phone.length > 0) {
      setDisplayedNumbers(phone)
    } else {
      setDisplayedNumbers([])
    }
  }, [phone, showAll])
  console.log('Email ' + email)
  useEffect(() => {
    if (email && email.length > 0) {
      setDisplayedEmail(email)
    } else {
      setDisplayedEmail([])
    }
  }, [email, showAll])

  useEffect(() => {
    if (email.length > 0) {
      setEmails(email)
    } else if (emailError) {
      setEmails([{type: '', email: emailError, verified: '', score: ''}])
    } else {
      setEmails([])
    }
  }, [email, emailError])

  useEffect(() => {
    if (phone.length > 0) {
      setPhones(phone)
    } else if (emailError) {
      setPhones([{name: '', network: emailError, location: '', number: phoneError}])
    } else {
      setPhones([])
    }
  }, [phone, phoneError, emailError])

  const handleEmailCopy = (emailToCopy) => {
    setCopiedMessage(true)
    setTimeout(() => {
      setCopiedMessage(false)
    }, 2000)
    copy(emailToCopy)
  }
  const toggleShowAll = () => {
    setDisplayedNumbers(phone)
    setDisplayedEmail(email)
    setShowAll(true)
  }

  const handleShowLess = () => {
    setDisplayedNumbers(phone.slice(0, 2))
    setDisplayedEmail(email.slice(0, 2))
    setShowAll(false)
  }
  const handleSpillTheBeans = async () => {
    try {
      setGetSpillBeansData(true)
      let resData = await GetUserInfoApi(linkedin, userId, '', profileName, profileName)
      console.log(resData)
      if (resData[0] === 'No Credits') {
        setGetSpillBeansData(false)
        setEmail([{type: 'Personal', email: 'No credits left', verified: '', score: ''}])
        setPhone([
          {
            number: 'No credits left',
            location: '',
            network: '',
            name: '',
          },
        ])
      } else if (resData[0]) {
        setGetSpillBeansData(false)
        setShowSpillBeans(false)
        let emailObjects = resData[0].work_email
        let mobilePhones = resData[0].mobile
        if (emailObjects) {
          if (emailObjects.every((obj) => obj.email === '' || obj.email === 'Not found')) {
            setEmailError("Sorry, Email doesn't exist")
          } else {
            let filteredEmails = emailObjects.filter(
              (obj) => obj.email !== 'Not found' && obj.email !== ''
            )
            setEmail(filteredEmails)
          }
        } else {
          setEmailError('Sorry, Something went wrong')
        }
        if (mobilePhones) {
          if (mobilePhones.every((phone) => phone.number === '' || phone.number === 'Not found')) {
            setPhoneError(
              "Uh oh! Number is currently unavailable. If you need it on priority, let us know. You'll be notified via email soon"
            )
          } else {
            setPhone(
              mobilePhones.filter(
                (phone) =>
                  phone.number !== 'Not found' && phone.number !== '' && phone.number !== null
              )
            )
          }
        } else {
          setPhoneError('Sorry, Something went wrong')
        }
      }
    } catch (err) {
      message.error('Error occured please try again')
      console.error(err)
    }
  }

  return (
    <Row className='w-100 p-2' style={{minHeight: '30%'}}>
      <Flex vertical gap='small' className='w-100'>
        <span style={{fontFamily: "'Lato',sans-serif", fontSize: '1.2em'}}>Contact</span>
        <Row>
          <Flex gap='small'>
            <ContactIcons linkedin={linkedin} github={github} userEmail={profile['email']} />
            {getSPillBeansData && <Spin size='small' />}
          </Flex>
        </Row>
        <Row>
          {showSpillBeans ? (
            <Button
              type='primary'
              onClick={(e) => {
                e.preventDefault()
                handleSpillTheBeans()
              }}
              icon={<EyeOutlined rev={undefined} />}
            >
              Spill the Beans
            </Button>
          ) : (
            <div style={{width: '100%', overflowX: 'scroll'}}>
              <EmailsAndPhoneComponent
                displayedEmail={displayedEmail}
                displayedNumbers={displayedNumbers}
                handleEmailCopy={handleEmailCopy}
                copiedMessage={copiedMessage}
                showAll={showAll}
                handleShowLess={handleShowLess}
                toggleShowAll={toggleShowAll}
              />
            </div>
          )}
        </Row>
      </Flex>
    </Row>
  )
}

export default ContactInfo
