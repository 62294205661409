import  {useState} from 'react'
import TwitterCard from '../Subcomponents/TwitterCard'
import { Flex, Modal, Button, Input, Select, message, Tooltip} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'
import {GetTwitterFollowersApi} from '../../apicalls/AutomationAPI'
import {userId} from '../DataTypes/UserDataTypes'


const TwitterFollowers = () => {
  const [messagemodal, setMessageModal] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [cookie, setCookie] = useState<string>('')
  const [spreadsheetLink, setSpreadsheetLink] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [types, setTypes] = useState<string[]>([])
  
  const handleTwitterSendMessages = async () => {
    try {
      setMessageModal(false)
      // console.log({
      //   userId,
      //   auth: cookie,
      //   spreadsheet: spreadsheetLink,
      //   title,
      //   message: twitterMessage,
      //   replaceValues: selectedOptions,
      //   minTime: minTime,
      //   maxTime: maxTime,
      //   rephrase: rephraseData,
      // })
      const res = await GetTwitterFollowersApi({
        userId,
        auth: cookie,
        spreadsheet: spreadsheetLink,
        title,
        followers: types,
      })
      if (res && res.data && res.success) {
        message.success('Automation has started, please check your Email')
      } else {
        message.error("Couldn't start the Automation, please try again")
      }
    } catch (err) {
      console.error('Error while getting the followers from twitter' + err)
      message.error("Couldn't start the Automation, please try again later")
    }
  }

  const handleChange = (value: string[]) => {
    setTypes(value)
  }
  return (
    <div>
      {' '}
      <TwitterCard
        loadingData={loadingData}
        setModal={setMessageModal}
        title={'Collect Twitter Followers'}
        description={'Extract the followers of a Twitter account in one place'}
      />
      <Modal
        width={600}
        centered
        title='Connect Twitter Automation with required details to start getting followers'
        footer={[
          <Button key='cancel' onClick={() => setMessageModal(false)}>
            Cancel
          </Button>,
          <Button
            key='get-data'
            type='primary'
            disabled={!cookie || !spreadsheetLink || !title || types.length === 0}
            onClick={handleTwitterSendMessages}
          >
            Get followers
          </Button>,
        ]}
        closable={false}
        maskClosable={false}
        open={messagemodal}
        onCancel={() => setMessageModal(false)}
      >
        <Flex vertical gap={'middle'}>
          <Flex vertical>
            <span>Automation title</span>
            <Input
              placeholder='Automation title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Flex>
          <Flex vertical>
            <Flex gap={'small'}>
              <span>User cookie</span>
              <Tooltip title='In Twitter you will find a cookie name "auth_token"'>
                <InfoCircleOutlined
                  rev={undefined}
                  style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            </Flex>
            <Input
              placeholder='auth_token'
              value={cookie}
              onChange={(e) => setCookie(e.target.value)}
            />
          </Flex>
          <Flex vertical>
            <Flex gap={'small'}>
              <span>Spreadsheet Link</span>
              <Tooltip title="Provide the Spreadsheet which has all the Twitter userId's in the first column and should allow editor access Ex: https://docs.google.com/spreadsheets/d/1Xf-PrSeYPdRopeKngzvfL07Y3JR0FGJA--J2L7UkR2Y/edit#gid=0">
                <InfoCircleOutlined
                  rev={undefined}
                  style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            </Flex>
            <Input
              placeholder='Group URL'
              value={spreadsheetLink}
              onChange={(e) => setSpreadsheetLink(e.target.value)}
            />
          </Flex>
          <Flex vertical>
            <Flex gap={'small'}>
              <span>Followers Type</span>
              <Tooltip title="Provide the type of followers i.e Verified/Normal or both">
                <InfoCircleOutlined
                  rev={undefined}
                  style={{color: 'grey', fontSize: '12px', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            </Flex>
            <Select
              mode='multiple'
              onChange={handleChange}
              value={types}
              placeholder='Select the type/types of followers to get'
              options={[
                {label: 'Verified', value: 'verified'},
                {label: 'Normal', value: 'normal'},
              ]}
            ></Select>
          </Flex>
        </Flex>
      </Modal>
    </div>
  )
}

export default TwitterFollowers
