import axios from 'axios'
const API = 'https://puppetdashboard.beanbag.ai'
// const API = "http://localhost:80"

export const GetPeopleFromApolloApi = async (cookie, searchUrl) => {
  try {
    const response = await axios.post(`${API}/getApolloData`, {cookie: cookie, url: searchUrl})
    return response.data
  } catch (err) {
    console.error('Error while getting the people  data : ', err)
  }
}
export const StopAutomationApi = async (id) => {
  try {
    const response = await axios.delete(`${API}/linkedin/stopPeopleautomation/${id}`)
    return response.data
  } catch (error) {
    console.error('Error while stopping the automation ' + error)
  }
}
export const GetCompanyFromApolloApi = async (cookie, searchUrl) => {
  try {
    const response = await axios.post(`${API}/getApolloData/company`, {
      cookie: cookie,
      url: searchUrl,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the people  data : ', err)
  }
}
export const SendTwitterMessages = async (obj) => {
  try {
    const response = await axios.post(`${API}/twitter/sendmessages`, obj)
    return response.data
  } catch (error) {
    console.error('Error while starting twitter automation ' + error)
  }
}
export const GetTwitterFollowersApi = async (obj) => {
  try {
    const response = await axios.post(`${API}/twitter/getFollowers`, obj)
    return response.data
  } catch (error) {
    console.error('Error while starting twitter automation ' + error)
  }
}
export const ScrapeUpworkData = async (searchUrl) => {
  try {
    const response = await axios.post(`${API}/upwork/getSearchResults`, {
      website: searchUrl,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the upwork  data : ', err)
  }
}
export const ScrapeSaasBoomiData = async (username, password) => {
  try {
    const response = await axios.post(`${API}/getSaasboomiData`, {
      email: username,
      password: password,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}
export const GetContactInfo = async (li_at, profileUrl) => {
  try {
    const response = await axios.post(`${API}/linkedin/getContact`, {
      li_at: li_at,
      profileUrl: profileUrl,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}
export const VisitProfileApi = async (li_at, profileUrl) => {
  try {
    const response = await axios.post(`${API}/linkedin/visitProfile`, {
      li_at: li_at,
      spreadSheet: profileUrl,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}
export const SendMessageApi = async (li_at, profileUrl, content) => {
  try {
    const response = await axios.post(`${API}/linkedin/sendMessage`, {
      li_at: li_at,
      spreadSheet: profileUrl,
      content: content,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}
export const SendConnectionApi = async (li_at, profileUrl, content) => {
  try {
    const response = await axios.post(`${API}/linkedin/sendConnection`, {
      li_at: li_at,
      spreadSheet: profileUrl,
      content: content,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}

export const ScrapeJobsPostingApi = async (li_at, jobData) => {
  try {
    const response = await axios.post(`${API}/linkedin/getJobData/nonEnterprise`, {
      li_at: li_at,
      jobData: jobData,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}
export const SalesPeopleSearchExportApi = async (obj) => {
  try {
    const response = await axios.post(`${API}/linkedin/sales/prospects`, obj)
    return response.data
  } catch (err) {
    console.error('Error while linkedin scrapping')
  }
}
export const SalesAccountSearchExportApi = async (obj) => {
  try {
    const response = await axios.post(`${API}/linkedin/sales/companies`, obj)
    return response.data
  } catch (err) {
    console.error('Error while linkedin scrapping')
  }
}

export const SendFacebookGroupMessagesApi = async (obj) => {
  try {
    const response = await axios.post(`${API}/groups/facebook`, obj)
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}

export const SendWhatsAppMessagesApi = async (userId, title, sheetUrl) => {
  try {
    const response = await axios.post(`${API}/whatsapp/sendMessage`, {userId, title, sheetUrl})
    return response.data
  } catch (err) {
    console.error('Error while sending the whats app messages ' + err)
  }
}

export const ScrapeEmployeesData = async (li_at, companyUrl) => {
  try {
    const response = await axios.post(`${API}/linkedin/company/getAllEmployees`, {
      li_at: li_at,
      companyUrl: companyUrl,
    })
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}
export const ScrapePeopleData = async (obj) => {
  try {
    const response = await axios.post(`${API}/linkedin/peopledata`, obj)
    return response.data
  } catch (err) {
    console.error('Error while getting the saasboomi members  data : ', err)
  }
}

export const CheckLinkedinLoginApi = async (liAt) => {
  try {
    const response = await axios.post(`${API}/linkedin/status`, {liAt})
    return response.data
  } catch (err) {
    console.error('Error while checking the linkedin login ' + err)
  }
}
export const GetUserInformation = async (liAt) => {
  try {
    const response = await axios.post(`${API}/linkedin/userdetails`, {liAt})
    return response.data
  } catch (err) {
    console.error('Error while checking the linkedin login ' + err)
  }
}
