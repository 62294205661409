import React, {FC, useEffect, useState, useCallback} from 'react'
import { GetUserSettingsApi} from '../apicalls/ApiCall'
import {useUserSettingContext} from '../context/UserSettings'
import {Row, Col, message, Card, Flex, Avatar, Tabs} from 'antd'
import {NodeIndexOutlined} from '@ant-design/icons'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import GroupsIcon from '@mui/icons-material/Groups'
import EmailIcon from '@mui/icons-material/Email'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import {userId, userName} from './DataTypes/UserDataTypes'
import EmailSetting from './emailsettings'
import UserSettings from './UserSettings'
import Upgrade from './Upgrade'
import TeamSettings from './Pages/TeamSettings'
import EmptyLogo from './Subcomponents/Empty'
import ApiIcon from '@mui/icons-material/Api';
import APIkeyGeneration from './Reusablecomponents/APIkeyGeneration'
// This is the settings page of whole website where user can update the settings for the flow and can also manage subscriptions
const Settings: FC = () => {

  const {setProfileObj} = useUserSettingContext()

  const [settingsData, setSettingsData] = useState([
    {
      icon: <EmailIcon style={{color: '#f9856e ', fontSize: '22px'}} />,
      name: 'Connected Accounts',
      color: 'danger',
      content: <EmailSetting />,
    },
    {
      icon: <ManageAccountsIcon style={{color: '#73ba9b', fontSize: '22px'}} />,
      name: 'User Settings',
      color: 'success',
      content: <UserSettings />,
    },
    {
      icon: <MonetizationOnIcon style={{color: '#8d86c9', fontSize: '22px'}} />,
      name: 'Plan and Billing',
      color: 'warning',
      content: <Upgrade />,
    },
    {
      icon: <GroupsIcon style={{color: '#87255b', fontSize: '22px'}} />,
      name: 'Team Settings',
      color: 'primary',
      content: <TeamSettings />,
    },
    {
      icon: <NodeIndexOutlined rev={undefined} style={{color: '#f5222d', fontSize: '22px'}} />,
      name: 'Integrations',
      color: 'info',
      content: <EmptyLogo description={'Coming Soon'} />,
    },
    {
      icon: <ApiIcon style={{color: '#8d86c9', fontSize: '22px'}} />,
      name: 'API',
      color: 'primary',
      content: <APIkeyGeneration />,
    }
    // {
    //   icon: <InsertDriveFileIcon style={{color: '#204f58', fontSize: '22px'}} />,
    //   name: 'Documents',
    //   color: 'primary',
    // },
  ])

  useEffect(() => {
    handleGetUserSettings()
  }, [])

  const handleGetUserSettings = async () => {
    try {
      let data = await GetUserSettingsApi(userId)
      if (data && data.success && data.data) {
        let profile = data.data
        if (profile) setProfileObj(profile)
      } else {
        message.error('Something went wrong ')
      }
    } catch (err) {
      message.error('Something went wrong ')
      console.error('Error while making the request ' + err)
    }
  }

  return (
    <div style={{paddingLeft: '10px', height: '100%'}}>
      <Tabs
        tabPosition='left'
        style={{height: '100%', background: '#FFFFFF', padding: '15px 15px 0px 0px'}}
      >
        {settingsData.map((setting, index) => (
          <Tabs.TabPane
            style={{height: '100%'}}
            tab={
              <Flex align='center' gap={'small'}>
                {setting.icon}
                {setting.name}
              </Flex>
            }
            key={index}
          >
            {setting.content}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default Settings
