import React, {lazy} from 'react'
import {Row, Col, Flex, Avatar, Tooltip} from 'antd'
import BusinessIcon from '@mui/icons-material/Business'
import CompanyIcons from './CompanyIcons'
import EmailIcons from './EmailIcons'
import PhoneIcons from './PhoneIcon'

const CompanyInfoBlock = ({
  companyLogo,
  companyAddress,
  companyName,
  linkedin,
  twitter,
  facebook,
  phone,
  email,
  website,
}) => {
  console.log(companyLogo)
  return (
    <div>
      <Row>
        <Col span={6}>
          <Avatar
            style={{height: '70px', width: '70px'}}
            src={
              companyLogo ? (
                <img src={companyLogo} alt='company logo' />
              ) : (
                <BusinessIcon sx={{color: 'grey', width: '50px', height: '60px'}} />
              )
            }
          />
        </Col>
        <Col span={17} style={{whiteSpace: 'normal'}}>
          <Flex vertical gap={'small'}>
            <Tooltip
              color={'rgb(32, 79, 88)'}
              placement='right'
              title={companyName.charAt(0).toUpperCase() + companyName.slice(1)}
            >
              <span
                style={{
                  fontSize: '1.5em',
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
                className='heading  fw-bold'
              >
                {companyName.charAt(0).toUpperCase() + companyName.slice(1) + ''}
              </span>
            </Tooltip>
            <Tooltip color={'rgb(32, 79, 88)'} placement='right' title={companyAddress}>
              {' '}
              <span
                style={{
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
                className='fs-7 text-muted'
              >
                {' '}
                {companyAddress}
              </span>
            </Tooltip>

            <CompanyIcons linkedin={linkedin} twitter={twitter} facebook={facebook} />
            <Flex>
              {email && (
                <Tooltip color={'rgb(32, 79, 88)'} placement='right' title={email}>
                  <div>
                    <EmailIcons width={'1em'} height={'1em'} />
                  </div>
                </Tooltip>
              )}
              {phone && (
                <Tooltip color={'rgb(32, 79, 88)'} placement='right' title={`Phone: ${phone}`}>
                  <div>
                    <PhoneIcons />
                  </div>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Col>
      </Row>
    </div>
  )
}

export default CompanyInfoBlock
