import {FC, useState} from 'react'
import dashstyle from '../styles/newdash.module.css'
import {Card, Flex, Tooltip} from 'antd'
import {KTIcon} from '../../_metronic/helpers'
import {useValuesContext} from '../context/valuescontext'
import {Filterjobtitles} from '../apicalls/ApiCall'
import Search from './Reusablecomponents/Select'
import FilterTags from './Reusablecomponents/FilterTags'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import FineTuneModal from './Subcomponents/FineTuneModal'
// This function renders the past job titles card on the filter pop up
const PastJobTitles: FC = () => {
  const {pastJobTitles, setPastJobTitles, setShowPastJobTitles, showPastJobTitles} =
    useValuesContext()
  // const [jobTitlesChose, setJobTitlesChose] = useState<string[]>(pastJobTitles)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [jobTitles, setJobTitles] = useState<string[]>([])
  const [showFineTuneJobTitles, setShowFineTuneJobTitles] = useState<boolean>(false)
  const [fineTuneObject, setFineTuneObject] = useState<{type: string; prompt: string}>({
    type: '',
    prompt: '',
  })
  const handleJobTitleChoose = (value: string) => {
    console.log(value)
    console.log(value !== jobTitles[0])
    if (!(pastJobTitles.includes(value) && value !== jobTitles[0])) {
      setPastJobTitles([...pastJobTitles, value])
    }
  }
  const handleInputChange = async (e) => {
    try {
      setSearchTerm(e.target.value)
      if (e.target.value.length === 0) setJobTitles([])
      let jobs = await Filterjobtitles(e.target.value)
      if (jobs) setJobTitles(jobs)
    } catch (err) {
      console.log('Error while getting the job titles ', err)
    }
  }
  const handleRemoveJobTitlesChose = (value: string) => {
    const updatedJobTitles = [...pastJobTitles]
    const indexToRemove = updatedJobTitles.indexOf(value)
    if (indexToRemove !== -1) {
      updatedJobTitles.splice(indexToRemove, 1)
      setPastJobTitles(updatedJobTitles)
    }
  }
  const handleSaveCompanyFineTune = () => {
    console.log(fineTuneObject)
  }

  const handlePromptInputChange = (e) => {
    setFineTuneObject({type: 'pastjobtitles', prompt: e.target.value})
  }
  return (
    <div>
      <Card>
        <div>
          <div className='d-flex flex-column justify-content-evenly h-75px mt-0'>
            <div className='d-flex flex-column w-100 justify-content-between'>
              <Flex align='center ' style={{justifyContent: 'space-between'}}>
                <span style={{fontFamily: "'Lato',sans-serif"}} className=' fs-6'>
                  Past job titles
                </span>
                <Tooltip title='Fine tune past job titles'>
                  <Flex vertical align='center' onClick={() => setShowFineTuneJobTitles(true)}>
                    <AutoAwesomeIcon style={{color: '#87255b', cursor: 'pointer'}} />
                  </Flex>
                </Tooltip>
              </Flex>
              <Search
                apiCallFunction={Filterjobtitles}
                statevariable={jobTitles}
                updateFunction={setJobTitles}
                updateUiFunction={setPastJobTitles}
              />
              <FineTuneModal
                showModal={showFineTuneJobTitles}
                setShowModal={setShowFineTuneJobTitles}
                handleFineTune={handleSaveCompanyFineTune}
                handleInputChange={handlePromptInputChange}
              />
            </div>
          </div>

          <div className={dashstyle.jobtitlecontainer}>
            {pastJobTitles?.map((jobs, index) => (
              <FilterTags removeFunction={handleRemoveJobTitlesChose} value={jobs} />
            ))}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default PastJobTitles
