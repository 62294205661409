import React, {FC, useState} from 'react'
import dashstyle from '../styles/newdash.module.css'
import {Card, Flex, Tooltip} from 'antd'
import {useValuesContext} from '../context/valuescontext'
import {FilterUniversities} from '../apicalls/ApiCall'
import Search from './Reusablecomponents/Select'
import FilterTags from './Reusablecomponents/FilterTags'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import FineTuneModal from './Subcomponents/FineTuneModal'
// This function renders the card component for selecting the universities
const UniversityCard: FC = () => {
  const [universities, setUniversities] = useState<string[]>([])
  const {university, setUniversity} = useValuesContext()
  const [UniversityChose, setUniversityChose] = useState<string[]>([])
  const [showCard, setShowCard] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showFineTuneUniversity, setFineTuneUniversity] = useState<boolean>(false)
  const [fineTuneObject, setFineTuneObject] = useState<{type: string; prompt: string}>({
    type: '',
    prompt: '',
  })
  const handleUniversityChose = (value: string) => {
    if (!university.includes(value)) {
      setUniversity([...university, value])
      setShowCard(false)
    }
  }

  const handleRemoveUniversity = (value: string) => {
    const updatedUniversities = [...university]
    const indexToRemove = updatedUniversities.indexOf(value)

    if (indexToRemove !== -1) {
      updatedUniversities.splice(indexToRemove, 1)
      setUniversity(updatedUniversities)
    }
  }
  const handleInputChange = async (e) => {
    try {
      setSearchTerm(e.target.value)
      let list = await FilterUniversities(e.target.value)
      if (list) setUniversities(list)
    } catch (err) {
      console.log('Error while Getting the university ', err)
    }
  }
  const handleSaveCompanyFineTune = () => {
    console.log(fineTuneObject)
  }

  const handlePromptInputChange = (e) => {
    setFineTuneObject({type: 'university', prompt: e.target.value})
  }
  return (
    <Card>
      <Flex align='center ' style={{justifyContent: 'space-between'}}>
        <span className=' fs-6 heading'>Universities</span>
        <Tooltip title='Fine tune universities'>
          <Flex vertical align='center' onClick={() => setFineTuneUniversity(true)}>
            <AutoAwesomeIcon style={{color: '#87255b', cursor: 'pointer'}} />
          </Flex>
        </Tooltip>
      </Flex>
      <FineTuneModal
        showModal={showFineTuneUniversity}
        setShowModal={setFineTuneUniversity}
        handleFineTune={handleSaveCompanyFineTune}
        handleInputChange={handlePromptInputChange}
      />
      <div>
        <Search
          apiCallFunction={FilterUniversities}
          statevariable={universities}
          updateFunction={setUniversities}
          updateUiFunction={setUniversity}
        />
      </div>
      <div className='d-flex flex-wrap'>
        {university?.map((chosenUniversity, index) => (
          <FilterTags removeFunction={handleRemoveUniversity} value={chosenUniversity} />
        ))}
      </div>
    </Card>
  )
}

export default UniversityCard
