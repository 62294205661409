import React, {Suspense, useEffect, useState} from 'react'
import {Row, Col, Flex, Avatar, Tooltip} from 'antd'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {CircleUserRound} from 'lucide-react'
import AddListFlowBtn from '../Reusablecomponents/AddListFlowBtn'
import {useProfileContext} from '../../context/profilecontext'
import CompanyBlock from './CompanyBlock'
const LazyProfilePic = React.lazy(() => import('../CommonApiCalls/FullProfilePic'))

const ProfileInfoBlock = ({name, userRole, linkedinUrl, profile}) => {
  const {showDefaultImage} = useProfileContext()
  const [newLinkedinUrl, setNewLinkedinUrl] = useState<string | null>(null)
  const [imageElement, setImageElement] = useState<React.ReactNode | null>(null)
  const [key, setKey] = useState()
  useEffect(() => {
    setNewLinkedinUrl(linkedinUrl)
  }, [linkedinUrl])
  useEffect(() => {
    setKey(profile)
  }, [profile])
  useEffect(() => {
    if (linkedinUrl) {
      setImageElement(
        <Suspense
          fallback={<Avatar src={<CircleUserRound />} style={{width: '90px', height: '90px'}} />}
        >
          {showDefaultImage ? (
            <AccountCircleIcon style={{width: '90px', height: '90px', color: 'grey'}} />
          ) : (
            <Avatar
              style={{width: '60px', height: '60px'}}
              src={
                <LazyProfilePic linkedinUrl={linkedinUrl} profile={key} /> ?? (
                  <CircleUserRound style={{width: '90px', height: '90px'}} />
                )
              }
            />
          )}
        </Suspense>
      )
    }
  }, [showDefaultImage, linkedinUrl])

  return (
    <div style={{gap: '5px'}} className='w-100 d-flex flex-column mt-2 p-2'>
      <Row gutter={1} style={{height: 'fit-content'}}>
        <Col span={5}>
          {profile['profilePic'] ? (
            <Avatar
              style={{width: '60px', height: '60px'}}
              src={
                profile['profilePic'] ? (
                  <img src={profile['profilePic']} alt='profile pic' />
                ) : (
                  <CircleUserRound />
                )
              }
            />
          ) : (
            imageElement
          )}
        </Col>
        <Col span={18}>
          <Flex vertical justify='center' gap={'2px'} className='h-100'>
            <span style={{fontFamily: "'Lato',sans-serif", fontSize: '1.4em'}}>
              {name ? name.charAt(0).toUpperCase() + name.slice(1) : ''}
            </span>
            <Tooltip
              color={'rgb(32, 79, 88)'}
              placement='right'
              title={userRole ? userRole.charAt(0).toUpperCase() + userRole.slice(1) : ''}
            >
              <span
                className='text-grey'
                style={{
                  width: '100%',
                  fontSize: '1em',
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {userRole ? userRole.charAt(0).toUpperCase() + userRole.slice(1) : ''}
              </span>
            </Tooltip>
            <Tooltip
              color={'rgb(32, 79, 88)'}
              placement='right'
              title={
                profile['latestCompany']['companyName']
                  ? profile['latestCompany']['companyName'].charAt(0).toUpperCase() +
                    profile['latestCompany']['companyName'].slice(1)
                  : ''
              }
            >
              {' '}
              <span
                className='text-grey'
                style={{
                  width: '100%',

                  fontSize: '0.9em',
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {profile['latestCompany']['companyName']
                  ? profile['latestCompany']['companyName'].charAt(0).toUpperCase() +
                    profile['latestCompany']['companyName'].slice(1)
                  : ''}
              </span>
            </Tooltip>
            <AddListFlowBtn profile={profile} />
          </Flex>
        </Col>
      </Row>
    </div>
  )
}

export default ProfileInfoBlock
