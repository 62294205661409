import React from 'react'
import {LinkedinFilled, FacebookFilled, TwitterSquareFilled} from '@ant-design/icons'
import {Flex} from 'antd'

const CompanyIcons = ({linkedin, twitter, facebook}) => {
  const openUrl = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://www.' + url
    }
    window.open(url, '_blank')
  }

  return (
    <Flex align='center' gap='small'>
      {linkedin && (
        <LinkedinFilled
          rev={undefined}
          style={{color: '#204f58', padding: 'none', fontSize: '20px', cursor: 'pointer'}}
          onClick={() => openUrl(linkedin)}
        />
      )}
      {twitter && (
        <TwitterSquareFilled
          rev={undefined}
          style={{color: '#27a3e0', padding: 'none', fontSize: '20px', cursor: 'pointer'}}
          onClick={() => openUrl(twitter)}
        />
      )}
      {facebook && (
        <FacebookFilled
          rev={undefined}
          style={{color: '#204f58', padding: 'none', fontSize: '20px', cursor: 'pointer'}}
          onClick={() => openUrl(facebook)}
        />
      )}
    </Flex>
  )
}

export default CompanyIcons
