import React, {useEffect, useState} from 'react'
import {Drawer, Row, Col, Flex, Divider, Card, Tag} from 'antd'
import ListComponent from '../Subcomponents/ListComponent'
import CompanyInfoBlock from '../Subcomponents/CompanyInfoBlock'
import CompanyDescriptionBlock from '../Subcomponents/CompanyDescriptionBlock'
import OtherCompanyInfo from './OtherCompanyInfo'
import {useProfileContext} from '../../context/profilecontext'
import {FundingType} from '../DataTypes/CompanyDatatype'
const CompanyModal = ({showDrawer, setShowdDrawer}) => {
  const {companyData} = useProfileContext()
  const [companyLogo, setCompanyLogo] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [linkedinLink, setLinkedinLink] = useState('https://www.linkedin.com/')
  const [twitterLink, setTwitterLink] = useState('https://twitter.com/')
  const [facebookLink, setFacebookLink] = useState('https://www.facebook.com/')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyDes, setCompanyDes] = useState('')
  const [keyWords, setKeyWords] = useState([])
  const [employeeCount, setEmployeeCount] = useState(1000)
  const [industry, setIndustry] = useState('')
  const [revenue, setRevenue] = useState('')
  const [funding, setFunding] = useState<FundingType[]>([])
  const [alternateDomains, setAlternateDomains] = useState([])
  const [alternateName, setAlternateName] = useState([])
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [geoLocation, setGeoLocation] = useState('')
  const [categories, setCategories] = useState<{[key: string]: number}>({})
  const [traffic, setTraffic] = useState('')
  const [tech, setTech] = useState([])
  const [type, setType] = useState('')
  const [source, setSource] = useState('')
  const [year, setYear] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState<string>('')
  useEffect(() => {
    if (companyData) {
      const {
        name,
        address,
        description,
        linkedinurl,
        twitterurl,
        facebookurl,
        keywords,
        employee,
        industry,
        revenue,
        funding,
        alternateDomain,
        alternateName,
        phone,
        email,
        geoLocation,
        categories,
        traffic,
        tech,
        type,
        source,
        logo,
        year,
        website,
      } = companyData
      setCompanyLogo(logo || '')
      setCompanyName(name || '')
      setCompanyAddress(address || '')
      setCompanyDes(description || '')
      setLinkedinLink(linkedinurl || '')
      setTwitterLink(twitterurl || '')
      setFacebookLink(facebookurl || '')
      setKeyWords(keywords || [])
      setEmployeeCount(employee || 0)
      setIndustry(industry || '')
      setRevenue(revenue || '')
      setFunding(funding || [])
      setAlternateDomains(alternateDomain || [])
      setCompanyWebsite(website || '')
      setYear(year || '')
      setAlternateName(alternateName || [])
      setPhone(phone || '')
      setEmail(email || '')
      setGeoLocation(geoLocation || '')
      setCategories(categories || {})
      setTraffic(traffic || '')
      setTech(tech || [])
      setType(type || '')
      setSource(source || '')
    } else {
      console.error('No company data available')
    }
  }, [companyData])

  return (
    <div>
      <Drawer
        placement='right'
        width={1200}
        closable={false}
        onClose={() => setShowdDrawer(false)}
        open={showDrawer}
      >
        <Flex vertical gap='small' className='h-100'>
          <Row style={{minHeight: '20%', maxHeight: 'fit-content'}}>
            <Col span={7}>
              <CompanyInfoBlock
                companyLogo={companyLogo}
                companyAddress={companyAddress}
                companyName={companyName}
                linkedin={linkedinLink}
                facebook={facebookLink}
                twitter={twitterLink}
                phone={phone}
                email={email}
                website={companyWebsite}
              />
            </Col>
            <Divider
              type='vertical'
              style={{height: '100%', width: '1px', color: '#f3f3f3', margin: '0 25px'}}
            />
            <Col span={15} style={{overflowY: 'scroll', height: '20vh'}}>
              <CompanyDescriptionBlock companyAddress={companyAddress} companyDes={companyDes} />
            </Col>
          </Row>
          <Divider style={{margin: '10px 0'}} />
          <Row style={{height: '78%'}}>
            <Col span={14}>
              <Flex vertical gap='small'>
                <Row>
                  <Card>
                    <Flex vertical gap={'small'}>
                      <span className='heading fw-bold fs-4'>Keywords</span>
                      <div>
                        {keyWords.map((keyWord, index) => (
                          <Tag
                            color={'#204f58'}
                            style={{
                              fontFamily: "'Lato',sans-serif",
                              whiteSpace: 'break-spaces',
                              marginRight: '5px',
                            }}
                          >
                            <span style={{textTransform: 'capitalize'}}>{keyWord}</span>
                          </Tag>
                        ))}
                      </div>
                    </Flex>
                  </Card>
                </Row>
                <Row>
                  <Card className='w-100'>
                    {' '}
                    <OtherCompanyInfo
                      employeeCount={employeeCount}
                      industry={industry}
                      revenue={revenue}
                      alternateDomains={alternateDomains}
                      alternateName={alternateName}
                      year={year}
                    />
                  </Card>
                </Row>
              </Flex>
            </Col>
            <Divider type='vertical' style={{height: '100%', width: '1px', color: '#f3f3f3'}} />
            <Col span={9} className='w-100' style={{height: '100%'}}>
              <ListComponent listElements={tech} categories={categories} funding={funding} />
            </Col>
          </Row>
        </Flex>
      </Drawer>
    </div>
  )
}

export default CompanyModal
