import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './app/components/Antstyles/Buttonstyle.css'
import posthog from 'posthog-js'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider} from './app/modules/auth'
import {EditQueryProvider} from './app/context/editquery'
import {ChatContextProvider} from './app/context/chatcontext'
import {ProfileProvider} from './app/context/profilecontext'
import {ApiContextProvider} from './app/context/apicontext'
import {SideBarContextProvider} from './app/context/sidebarcontext'
import {ValuesContextProvider} from './app/context/valuescontext'
import {DataContextProvider} from './app/context/reportscontext'
import {SessionContextProvider} from './app/context/sessionContext'
import {FlowContextProvider} from './app/context/FlowContext'
import {UpgradeContextProvider} from './app/context/UpgradeContext'
import {EmailProvider} from './app/context/emailContext'
import {SettingProvider} from './app/context/settingscontext'
import {TeamSettingsProvider} from './app/context/TeamSettings'
import {TaskContextProvider} from './app/context/taskscontext'
import {UserSettingProvider} from './app/context/UserSettings'
import {AuthContextProvider} from './app/context/AuthContext'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {DataSearchContextProvider} from './app/context/DataSearchContext'
import {ActivityDashContextProvider} from './app/context/activityContext'
import {EnrichContextProvider} from './app/context/EnrichContext'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

axios.interceptors.request.use((request) => {
  if (
    request.url &&
    (request.url.includes('/user/signUpNew') ||
      request.url.includes('/user/loginNew') ||
      request.url.includes('/user/googlelogin'))
  ) {
    console.log(request)
    return request
  }
  if (localStorage.getItem('loginToken')) {
    request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('loginToken')
  }
  return request
})

Chart.register(...registerables)
if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init('phc_xb1vRRALhj6pag3mGRo79eBVoyDTjT6d7KLbZpE4mnG', { api_host: "https://us.posthog.com" })
}
const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AuthContextProvider>
            <ProfileProvider>
              <SettingProvider>
                <TaskContextProvider>
                  <SideBarContextProvider>
                    <TeamSettingsProvider>
                      <UserSettingProvider>
                        <ValuesContextProvider>
                          <ChatContextProvider>
                            <ApiContextProvider>
                              <SessionContextProvider>
                                <FlowContextProvider>
                                  <EmailProvider>
                                    <EditQueryProvider>
                                      <UpgradeContextProvider>
                                        <DataContextProvider>
                                          <ActivityDashContextProvider>
                                            <EnrichContextProvider>
                                              <DataSearchContextProvider>
                                                <GoogleOAuthProvider clientId='1047464535753-5r62b1js5hltuou0dan3lnatmte9hr98.apps.googleusercontent.com'>
                                                  <AppRoutes />
                                                </GoogleOAuthProvider>
                                              </DataSearchContextProvider>
                                            </EnrichContextProvider>
                                          </ActivityDashContextProvider>
                                        </DataContextProvider>
                                      </UpgradeContextProvider>
                                    </EditQueryProvider>
                                  </EmailProvider>
                                </FlowContextProvider>
                              </SessionContextProvider>
                            </ApiContextProvider>
                          </ChatContextProvider>
                        </ValuesContextProvider>
                      </UserSettingProvider>
                    </TeamSettingsProvider>
                  </SideBarContextProvider>
                </TaskContextProvider>
              </SettingProvider>
            </ProfileProvider>
          </AuthContextProvider>
        </AuthProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
