import axios from 'axios'
const APILINK = 'https://api.beanbag.ai'

export const SignUpUserData = async (userObj) => {
  try {
    const response = await axios.post(`${APILINK}/user/signUpNew`, userObj)
    return response.data
  } catch (err) {
    console.log('SignUpUserData', err)
  }
}
export const SignUpFormUserData = async (userObj) => {
  try {
    const response = await axios.post(`${APILINK}/user/regularSignUp`, userObj)
    return response.data
  } catch (err) {
    console.log('SignUpUserData', err)
  }
}

export const LoginUserData = async (userObj) => {
  try {
    const response = await axios.post(`${APILINK}/user/loginNew`, userObj)
    return response.data
  } catch (err) {
    console.log('LoginUser error', err)
  }
}

export const SignInUserWithGoogle = async (userId) => {
  try {
    const response = await axios.post(`${APILINK}/user/googleloginnew/${userId}`)
    return response.data
  } catch (err) {
    console.log('signinwith google error', err)
  }
}

export const ForgotPasswordApi = async (email) => {
  try {
    const response = await axios.post(`${APILINK}/user/sendForgotPasswordToken`, {email: email})
    return response.data
  } catch (err) {
    console.error('Error while sending reset code ')
  }
}

export const ConfirmForgotPassword = async (passToken, newPassword, email) => {
  try {
    const response = await axios.post(`${APILINK}/user/forgotPassword`, {
      email: email,
      token: passToken,
      newPassword: newPassword,
    })
    return response.data
  } catch (err) {
    console.error('Error while checking and setting the new password')
  }
}

export const VeryifyEmailApi = async (userId, verifyToken) => {
  try {
    const response = await axios.post(`${APILINK}/user/verifyEmail/${userId}`, {
      verifyToken: verifyToken,
    })
    return response.data
  } catch (err) {
    console.error('Error while verifying the email ', err)
  }
}

export const ResendForgotPasswordApi = async (email) => {
  try {
    const response = await axios.post(`${APILINK}/user/sendForgotPasswordToken`, {email: email})
    return response.data
  } catch (err) {
    console.error('Error while sending reset code ')
  }
}

// Send utm params
export const SendUTMParams = async (params, userId) => {
  try {
    const response = await axios.post(`${APILINK}/utm/params/${userId}`, params)
    return response.data
  } catch (err) {
    console.error('Error while sending the utm params ', err)
  }
}
