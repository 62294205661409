import {Modal, Button, Input, Flex} from 'antd'

const FineTuneModal = ({showModal, setShowModal, handleFineTune, handleInputChange}) => {
  return (
    <Modal
      centered
      footer={[
        <Button key='cancel' onClick={() => setShowModal(false)}>
          Cancel
        </Button>,
        <Button key='use' type='primary' onClick={handleFineTune}>
          Use prompt
        </Button>,
      ]}
      open={showModal}
      onOk={handleFineTune}
      onCancel={() => setShowModal(false)}
    >
      <Flex vertical gap={'small'} className='px-4 py-4'>
        <span className='heading'>Fine tune your search by entering a custom query:</span>
        <Input
          size='large'
          placeholder='Enter the prompt to get the fine tuning for search'
          onChange={handleInputChange}
        />
      </Flex>
    </Modal>
  )
}

export default FineTuneModal
