import React, {useEffect, useState} from 'react'
import {Drawer} from 'antd'
import FullProfile from './FullProfile'
const FullProfileModal = ({showModal, updateState, profileData}) => {
  const onClose = () => {
    updateState(false)
  }
  useEffect(() => {})
  console.log(showModal)
  return (
    <Drawer
      style={{position: 'relative'}}
      width={1200}
      placement='right'
      closable={false}
      onClose={onClose}
      open={showModal}
    >
      <FullProfile profileData={profileData} />
    </Drawer>
  )
}

export default FullProfileModal
