import React, {FC} from 'react'
import {Flex, Col, List, Avatar, Tooltip, Popover, Divider} from 'antd'
import {Briefcase, ChevronsDown, ChevronsUp, SquareUserRound} from 'lucide-react'
import PhoneIcons from './PhoneIcon'
import EmailIcons from './EmailIcons'
import {ProfileEmails} from '../DataTypes/ProfileTypes'
import {useLocation} from 'react-router-dom'
import Tags from './Tags'

type ComponentProps = {
  displayedEmail: ProfileEmails[]
  displayedNumbers: PhoneNumber[]
  handleEmailCopy
  copiedMessage: boolean
  showAll: boolean
  handleShowLess
  toggleShowAll
}
interface PhoneNumber {
  number: string
  location: string
  network: string
  name: string
}

const EmailsAndPhoneComponent: FC<ComponentProps> = ({
  displayedNumbers,
  displayedEmail,
  handleEmailCopy,
  copiedMessage,
  showAll,
  handleShowLess,
  toggleShowAll,
}) => {
  const location = useLocation()
  return (
    <div className='w-100'>
      <Flex gap='small' align='center' justify='center ' style={{width: '100%'}}>
        <Col style={{width: '100%'}}>
          <List
            className='w-100'
            size='small'
            dataSource={displayedEmail}
            renderItem={(item) => (
              <List.Item style={{padding: '3px'}}>
                <Flex align='center' className='w-100'>
                  <List.Item.Meta
                    avatar={<Avatar src={<EmailIcons width={'19px'} height={'19px'} />} />}
                  />
                  <Flex align='center' justify='space-between' className='w-100'>
                    <Flex align='center' gap={'4px'} className='w-75'>
                      <Tooltip placement='top' title={copiedMessage ? 'Copied' : 'Copy'}>
                        <span
                          style={{cursor: 'pointer'}}
                          className='fs-5'
                          onClick={(e) => {
                            e.preventDefault()
                            handleEmailCopy(item.email)
                          }}
                        >
                          {item.email}
                        </span>{' '}
                      </Tooltip>
                      {item.verified === 'Yes' ? (
                        <Tooltip placement='right' title={'Verified'}>
                          <span style={{fontSize: '0.6em'}}>🟢</span>
                        </Tooltip>
                      ) : (
                        <Tooltip placement='right' title={'Unverified'}>
                          <span style={{fontSize: '0.6em'}}> 🟡</span>{' '}
                        </Tooltip>
                      )}
                    </Flex>

                    {item.type === 'Professional' ? (
                      <Briefcase size={20} strokeWidth={1.5} color='#7b8081' />
                    ) : (
                      <SquareUserRound size={20} strokeWidth={1.5} color='#7b8081' />
                    )}
                  </Flex>
                </Flex>
              </List.Item>
            )}
          />
        </Col>
      </Flex>
      <Divider style={{margin: '1px 0'}} />
      <Flex gap='small' align='start' className='w-100'>
        <Col className='w-100'>
          <List
            size='small'
            dataSource={displayedNumbers}
            renderItem={(item) => (
              <List.Item style={{padding: '3px'}}>
                <Flex align='center' style={{width: '100%'}}>
                  <List.Item.Meta avatar={<Avatar style={{padding: '0'}} src={<PhoneIcons />} />} />

                  <Popover
                    placement='right'
                    content={() => (
                      <Flex vertical>
                        <span>Name: {item.name}</span>
                        <span>Network: {item.network}</span>
                      </Flex>
                    )}
                  >
                    <Tooltip placement='top' title={copiedMessage ? 'Copied' : 'Copy'}>
                      <Flex
                        className='w-100'
                        align='start'
                        justify='space-between'
                        onClick={(e) => {
                          e.preventDefault()
                          handleEmailCopy(item.number)
                        }}
                        style={{cursor: 'pointer'}}
                      >
                        <span> {item.number}</span>
                        {item.location && (
                          <Tags color={''} tagsContent={item.location.toUpperCase()} />
                        )}
                      </Flex>
                    </Tooltip>
                  </Popover>
                </Flex>
              </List.Item>
            )}
          />
        </Col>
      </Flex>
    </div>
  )
}

export default EmailsAndPhoneComponent
