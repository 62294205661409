import React, {useEffect, useRef, useState} from 'react'
import {Table, Flex, Avatar, Button, Input, Select, Tooltip, Space, message, Popconfirm} from 'antd'
import {CheckCircleFilled, CloseOutlined, EditFilled, SearchOutlined} from '@ant-design/icons'
import DeleteIcon from '@mui/icons-material/Delete'
import Highlighter from 'react-highlight-words'
import teamStyle from '../../styles/teamsettings.module.css'
import InviteTeamMem from './InviteTeamMem'
import {DeleteTeamMemberApi, GetTeamMembersApi, UpdateTeamMateRoleApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import PageLoader from '../Subcomponents/PageLoader'
import Tags from '../Subcomponents/Tags'
import {useTeamSettingContext} from '../../context/TeamSettings'
import {getTeamMembers} from '../CommonApiCalls/TeamsettingsApi'
import CreditsEdit from '../Subcomponents/CreditsEdit'
import {handleGetUserSettings} from '../CommonApiCalls/UserSettings'
import {useUserSettingContext} from '../../context/UserSettings'
const {Option} = Select

const TeamMembersTable = () => {
  const {teamData, setTeamData} = useTeamSettingContext()
  const {setProfileObj} = useUserSettingContext()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [edited, setEdited] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [editRoleIndex, setEditRoleIndex] = useState<number | null>(null)
  const [editCreditIndex, setEditCreditIndex] = useState<number | null>(null)
  const [editedRole, setEditedRole] = useState<string>('')
  const [editedCredit, setEditedCredit] = useState<number>(0)
  const [member, setMember] = useState({})
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  useEffect(() => {
    console.log('test')
  }, [])
  useEffect(() => {
    getTeamMembers({setShowLoader, setTeamData})
  }, [])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined rev={undefined} />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
        rev={undefined}
      />
    ),
    onFilter: (value, record) =>
      record['userId'].toString().toLowerCase().includes(value.toLowerCase()),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const handleRoleChange = (value, index) => {
    setEditedRole(value)
    setEditRoleIndex(index)
    setEdited(true)
  }

  const handleDeleteMember = async (id) => {
    try {
      let res = await DeleteTeamMemberApi(userId, id)
      if (res && res.success && res.data) {
        message.success('Team member removed successfully')
        await getTeamMembers({setShowLoader, setTeamData})
        await handleGetUserSettings({message, setProfileObj})
      } else {
        message.error('Unsuccessfull in removing the team member')
      }
    } catch (err) {
      message.error('Something went wrong please try again')
      console.error('error while deleting the member', err)
    }
  }

  const handleEditCredits = (record, index) => {
    setMember(record)
    setShowEditModal(true)
  }

  console.log(editRoleIndex)
  const handleRoleSave = async (recordId: string) => {
    const newData = teamData.map((item) => {
      if (recordId === item.userId) {
        return {...item, teamMemRole: editedRole}
      }
      return item
    })
    console.log(newData)
    try {
      let res = await UpdateTeamMateRoleApi(userId, newData)
      if (res && res.success && res.data) {
        message.success('Updated the role successfully')
        setTeamData(newData)
        setEditRoleIndex(null)
      } else {
        message.error('Something went wrong please try again')
      }
    } catch (err) {
      message.error("Error while  updating the user's role")
      console.error("Error while  updating the user's role", err)
    }
  }

  const handleCancelEdit = (type: string) => {
    if (type === 'role') {
      setEditRoleIndex(null)
    }
  }

  const columns = [
    {
      width: 280,
      title: 'User',
      dataIndex: 'userId',
      ...getColumnSearchProps('name'),
      render: (name, record, index) => {
        const colors = ['f9856e', '204f58', '87255b']
        const colorIndex = index % colors.length
        const backgroundColor = `#${colors[colorIndex]}`

        return (
          <Flex align='center'>
            <Avatar
              shape='square'
              style={{
                marginRight: '8px',
                backgroundColor: backgroundColor,
              }}
            >
              {name[0].toUpperCase()}
            </Avatar>
            <span className='fs-5'> {name}</span>
          </Flex>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, record, index) => (
        <Tags
          color={status === 'Accepted' ? '#73ba9b' : status === 'Rejected' ? '#f5222d' : '#8d86c9'}
          tagsContent={status}
        />
      ),
    },
    {
      align: 'center',
      width: 150,
      title: 'Role',
      dataIndex: 'teamMemRole',
      render: (role, record, index) => (
        <div className={teamStyle.creditCol} style={{position: 'relative'}}>
          <Space>
            <Select
              value={editRoleIndex === index ? editedRole : role}
              onChange={(value) => handleRoleChange(value, index)}
              style={{width: 80}}
            >
              <Option value='Admin'>Admin</Option>
              <Option value='User'>User</Option>
            </Select>
          </Space>
        </div>
      ),
    },
    {
      width: 150,
      align: 'center',
      title: 'Email credits',
      dataIndex: 'emailCredits',
      render: (credit, record, index) => (
        <div className={teamStyle.creditCol}>
          <span>{credit}</span>
        </div>
      ),
    },
    {
      width: 200,
      align: 'center',
      title: 'Email Total credits',
      dataIndex: 'emailTotalCredits',
      render: (credit, record, index) => (
        <div className={teamStyle.creditCol}>
          <span>{credit}</span>
        </div>
      ),
    },
    {
      width: 150,
      align: 'center',
      title: 'Phone credits',
      dataIndex: 'mobileCredits',
      render: (credit, record, index) => (
        <div className={teamStyle.creditCol}>
          <span>{credit}</span>
        </div>
      ),
    },
    {
      width: 220,
      align: 'center',
      title: 'Phone Total credits',
      dataIndex: 'mobileTotalCredits',
      render: (credit, record, index) => (
        <div className={teamStyle.creditCol}>
          <span>{credit}</span>
        </div>
      ),
    },
    {
      width: 40,
      align: 'center',
      title: 'Action',
      dataIndex: '',
      render: (action, record, index) => (
        <div style={{textAlign: 'center'}}>
          {editRoleIndex === index ? (
            <>
              <Tooltip title='Save'>
                <CheckCircleFilled
                  onClick={() => handleRoleSave(record.userId)}
                  style={{cursor: 'pointer'}}
                  rev={undefined}
                />
              </Tooltip>
              <Tooltip title='Cancel'>
                <CloseOutlined
                  onClick={() => handleCancelEdit('role')}
                  style={{cursor: 'pointer', marginLeft: '8px'}}
                  rev={undefined}
                />
              </Tooltip>
            </>
          ) : (
            <Flex gap={'small'} align='end'>
              <Tooltip title='Edit credits'>
                <EditFilled
                  rev={undefined}
                  onClick={() => handleEditCredits(record, index)}
                  style={{cursor: 'pointer', fontSize: '18px'}}
                />
              </Tooltip>
              <Popconfirm
                placement='topLeft'
                title='Are you sure you want to remove this member?'
                onConfirm={() => handleDeleteMember(record['userId'])}
              >
                <div onClick={(e) => e.preventDefault()}>
                  <DeleteIcon style={{cursor: 'pointer', fontSize: '18px'}} />
                </div>
              </Popconfirm>
            </Flex>
          )}
        </div>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <div className={teamStyle.teamTable}>
      {showLoader && <PageLoader />}
      <CreditsEdit
        listOfMembers={teamData}
        showModal={showEditModal}
        member={member}
        setShowModal={setShowEditModal}
      />
      <Flex vertical gap={'middle'}>
        <InviteTeamMem />
        <Table rowSelection={rowSelection} columns={columns as any} dataSource={teamData} />
      </Flex>
    </div>
  )
}

export default TeamMembersTable
