import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TimeLine from './TimeLine'
import Conversation from './Conversations'
import EduTable from './Reusablecomponents/EduTable'
import {useProfileContext} from '../context/profilecontext'

// In profile these components are rendered as tabs timeline and conversations
function TimeLineComponent() {
  return (
    <div style={{}}>
      <TimeLine />
    </div>
  )
}

function ConversationsComponent() {
  return (
    <div>
      <Conversation />
    </div>
  )
}

export default function ProfileTabs() {
  const [value, setValue] = React.useState(0)
  const {profile} = useProfileContext()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          TabIndicatorProps={{
            style: {
              backgroundColor: 'teal',
            },
          }}
        >
          <Tab
            style={{
              width: '50%',
              fontSize: '1.2em',
              fontWeight: '400',
              textTransform: 'capitalize',
              color: '#333',
              fontFamily: "'Lato', sans-serif",
            }}
            label='Timeline'
          />
          <Tab
            style={{
              width: '50%',
              fontSize: '1.1em',
              fontWeight: '400',
              textTransform: 'capitalize',
              color: '#333',
              fontFamily: "'Lato', sans-serif",
            }}
            label='Conversations'
          />
          {/* <Tab
            style={{
              width: '25%',
              fontSize: '1.1em',
              fontWeight: '400',
              textTransform: 'capitalize',
              color: '#333',
              fontFamily: "'Lato', sans-serif",
            }}
            label='Education'
          />
          <Tab
            style={{
              width: '25%',
              fontSize: '1.1em',
              fontWeight: '400',
              textTransform: 'capitalize',
              color: '#333',
              fontFamily: "'Lato', sans-serif",
            }}
            label='Experience'
          /> */}
        </Tabs>
      </Box>
      {value === 0 && <TimeLineComponent />}
      {value === 1 && <ConversationsComponent />}
      {/* {value === 2 && (
        <EduTable
          educationDetails={profile && profile['education'].length > 0 ? profile['education'] : []}
        />
      )}
      {value === 3 && (
        <EduTable
          educationDetails={
            profile && profile['experience'].length > 0 ? profile['experience'] : []
          }
        />
      )} */}
    </Box>
  )
}
