import { Flex} from 'antd'
import TwitterMessage from './TwitterMessage'
import TwitterFollowers from './TwitterFollowers'
const TwitterCards = () => {

  return (
    <Flex style={{width: '100%'}} vertical gap={'4px'}>
      <span style={{fontWeight: 'bold', fontSize: '1.5em'}}>Twitter</span>
      <Flex gap={'middle'}>
        <TwitterMessage />
        <TwitterFollowers />
      </Flex>
    </Flex>
  )
}

export default TwitterCards
