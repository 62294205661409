import React, {FC, useEffect, useMemo, useState, Suspense} from 'react'
import dashstyle from '../styles/newdash.module.css'
import {Card, Tag, Flex, Tooltip, Modal, Input} from 'antd'
import {KTIcon} from '../../_metronic/helpers'
import {useValuesContext} from '../context/valuescontext'
import {FilterCompanies, GetCompanyLogoApi} from '../apicalls/ApiCall'
import BusinessIcon from '@mui/icons-material/Business'
import Search from './Reusablecomponents/Select'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import FineTuneModal from './Subcomponents/FineTuneModal'

const CompanyLogo = ({companyName}: {companyName: string}) => {
  const [companyUrl, setCompanyUrl] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  const fetchImage = async () => {
    try {
      let data = await GetCompanyLogoApi(companyName)
      if (data) {
        setCompanyUrl(data)
      }
    } catch (err) {
      setError('Error while getting the profile picture: ' + err)
    }
  }

  useMemo(() => {
    fetchImage()
  }, [companyName])

  if (error) {
    console.error(error)
  }

  return companyUrl ? (
    <img
      src={companyUrl}
      style={{width: '15px', height: '14px', borderRadius: '5px', marginRight: '10px'}}
      alt='Company Logo'
    />
  ) : (
    <BusinessIcon
      style={{width: '15px', height: '14px', borderRadius: '5px', marginRight: '10px'}}
    />
  )
}
let initialWorked = ['Past', 'Present', 'Past+Present']

// This function consists of all the company choices that the user wants to make, and when searching for companies, it fetches the list of companies.
const CompaniesListing: FC = () => {
  const {
    companies,
    setCompanies,
    setHistoryOfCompany,
    historyOfCompany,
    showCompanyCard,
    setShowCompanyCard,
  } = useValuesContext()
  const [companiesList, setCompaniesList] = useState<string[]>([])

  const [searchTerm, setSearchTerm] = useState('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [showFineTuneCompany, setShowFineTuneCompany] = useState<boolean>(false)
  const [fineTuneObject, setFineTuneObject] = useState<{type: string; prompt: string}>({
    type: '',
    prompt: '',
  })
  const handleCompanyChose = (value) => {
    if (!companies.includes(value)) {
      setCompanies([...companies, value])
      setShowCompanyCard(false)
    }
  }

  const handleRemoveCompanies = (company) => {
    const updatedCompanyChose = [...companies]
    const indexToRemove = updatedCompanyChose.indexOf(company)

    if (indexToRemove !== -1) {
      updatedCompanyChose.splice(indexToRemove, 1)
      setCompanies(updatedCompanyChose)
    }
  }

  const handleCompanyNameChange = async (event) => {
    setShowSpinner(true)
    const name = event.target.value
    setSearchTerm(name)
    try {
      const response = await FilterCompanies(name)
      console.log(response)
      if (response)
        if (response.success && response.data) {
          setCompaniesList(response.data)
        }
    } catch (error) {
      console.error(error)
    } finally {
      setShowSpinner(false)
    }
    if (event.target.value.length === 0) setCompaniesList([])
  }
  const handleInputClick = (e) => {
    e.stopPropagation()
    setShowCompanyCard(!showCompanyCard)
  }
  const handleSaveCompanyFineTune = () => {
    console.log(fineTuneObject)
  }

  const handleInputChange = (e) => {
    setFineTuneObject({type: 'company', prompt: e.target.value})
  }
  return (
    <Card onClick={() => setShowCompanyCard(false)}>
      <div>
        <div className='align-items-center justify-content-between'>
          <Flex align='center ' style={{justifyContent: 'space-between'}}>
            <span style={{fontFamily: "'Lato',sans-serif"}} className=' fs-6'>
              Companies
            </span>
            <Tooltip title='Fine tune companies'>
              <Flex vertical align='center' onClick={() => setShowFineTuneCompany(true)}>
                <AutoAwesomeIcon style={{color: '#87255b', cursor: 'pointer'}} />
              </Flex>
            </Tooltip>
          </Flex>
          <FineTuneModal
            showModal={showFineTuneCompany}
            setShowModal={setShowFineTuneCompany}
            handleFineTune={handleSaveCompanyFineTune}
            handleInputChange={handleInputChange}
          />
        </div>
        <div>
          <Search
            apiCallFunction={FilterCompanies}
            statevariable={companiesList}
            updateFunction={setCompaniesList}
            updateUiFunction={setCompanies}
          />
        </div>
        <div className={dashstyle.companiesContainer}>
          {companies?.map((company, index) => (
            <Tag
              style={{
                margin: '3px',
                borderRadius: '15px',
                padding: '5px',
                paddingRight: '15px',
                paddingLeft: '15px',
                fontFamily: "'Karla', sans-serif",
              }}
              icon={
                <Suspense>
                  <CompanyLogo companyName={company} />
                </Suspense>
              }
              color='teal'
              closable
              onClose={(e) => {
                e.preventDefault()
                handleRemoveCompanies(company)
              }}
            >
              {company.toString().charAt(0).toUpperCase() + company.toString().slice(1)}
            </Tag>
          ))}
        </div>
      </div>
      <div className='d-flex w-100 justify-content-between mt-6'>
        <span
          style={{fontFamily: "'Lato',sans-serif"}}
          onClick={() => setCompanies([])}
          className={`btn-label ${dashstyle.clear}`}
        >
          Clear All
        </span>
      </div>
    </Card>
  )
}

export default CompaniesListing
