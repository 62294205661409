import {useState, useEffect} from 'react'
import {Settings} from '../modules/accounts/components/settings/Settings'
import {AccountHeader} from '../modules/accounts/AccountHeader'
import {useNavigate} from 'react-router-dom'
import {GetUserSettingsApi, GetTotalUserCreditsApi, GetCreditsApi} from '../apicalls/ApiCall'
import {UserObject} from '../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuContent'
import {useUserSettingContext} from '../context/UserSettings'
import {userId, userName} from './DataTypes/UserDataTypes'
import {Card, Divider} from 'antd'
import UserForm from './Reusablecomponents/UserForm'
import {PlanDetailsCard} from './PlanDetailsCard'
import SpillBeansSettings from './Subcomponents/SpillBeansSettings'

// This is the component which shows the user settings of the UI

interface CreditDetail {
  creditType: string
  amount: number
}

interface Communication {
  Email: string
  Phone: string
}

interface SignInObject {
  userName: string
  password: string
}

interface PlanDetails {
  planName: string
  creditDetails: CreditDetail[]
  expiry: string
}

interface UserProfile {
  profilePic: string
  fullName: string
  company: string
  contactPhone: string
  companySite: string
  country: string
  Language: string
  TimeZone: string
  Currency: string
  Communication: Communication
  SignInObject: SignInObject
  PlanDetails: PlanDetails
}

const UserSettings = () => {
  const {
    setProfileObj,
    setUserCredits,
    setTotalCredits,
    setEmailCredits,
    setEmailTotalCredits,
    setPhoneTotalCredits,
    setPhoenCredits,
    setExpiryDate,
    setRechargeDate,
  } = useUserSettingContext()
  const navigate = useNavigate()
  useEffect(() => {
    ;(async () => {
      try {
        let data = await GetUserSettingsApi(userId)
        let creditsres = await GetCreditsApi(userId)
        if (creditsres && creditsres.success && creditsres.data) {
          setEmailCredits(creditsres.data.usedEmailCredit)
          setPhoenCredits(creditsres.data.usedMobileCredit)
          setEmailTotalCredits(creditsres.data.emailTotalCredit)
          setPhoneTotalCredits(creditsres.data.mobileTotalCredit)
          setExpiryDate(creditsres.data.ExpiryDate)
          setRechargeDate(creditsres.data.rechargeDate)
        }
        if (data && data.success && data.data) {
          let profile = data.data
          if (profile) setProfileObj(profile)
        }
      } catch (err) {
        console.error('Error while making the request ' + err)
      }
    })()
  }, [])

  return (
    <div style={{display: 'flex', background: 'white', justifyContent: 'center', height: '100%'}}>
      <div
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          padding: '10px 10px 10px 10px',
        }}
      >
        <AccountHeader />
        <Divider />
        <UserForm />
        <Divider style={{margin: '10px 0'}} />
        <SpillBeansSettings />
        <Divider style={{margin: '10px 0'}} />
        <PlanDetailsCard />
        {/* <Settings /> */}
      </div>
    </div>
  )
}

export default UserSettings
