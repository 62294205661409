import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone'
const PhoneIcons = () => {
  return (
    <PhoneIcon
      style={{color: '#204f58', padding: 'none', width: '1em', height: '1em', cursor: 'pointer'}}
      className='text-success'
    />
  )
}

export default PhoneIcons
