import React, {createContext, useContext, ReactNode, useState} from 'react'
import {AnalyticsList} from '../components/DataTypes/AccountsType'

type DataContextType = {
  analyticsData: any
  setAnalyticsData: (analyticsData: any) => void
  flowAnalyticsId: string
  setFlowAnalyticsId: (flowAnalyticsId: string) => void
  selectedvalue: string
  setSelectedValue: (selectedvalue: string) => void
  analyticsFlowName: string
  setAnalyticsFlowName: (analyticsFlowName: string) => void
  accountsList: AnalyticsList[]
  setAccountsList: (accountsList: AnalyticsList[]) => void
  showMoreAnalyticsUI: boolean
  setShowMoreAnalyticsUI: (showMoreAnalyticsUI: boolean) => void
}

const DataContext = createContext<DataContextType | undefined>(undefined)

const useReportContext = () => {
  const context = useContext(DataContext)
  if (!context) {
    console.error('useReportContext must be used within a DataContextProvider')
  }
  return context!
}

const DataContextProvider = ({children}: {children: ReactNode}) => {
  const [analyticsData, setAnalyticsData] = useState<any>()
  const [flowAnalyticsId, setFlowAnalyticsId] = useState<string>('')
  const [selectedvalue, setSelectedValue] = useState<string>('All')
  const [analyticsFlowName, setAnalyticsFlowName] = useState<string>('')
  const [accountsList, setAccountsList] = useState<AnalyticsList[]>([])
  const [showMoreAnalyticsUI, setShowMoreAnalyticsUI] = useState<boolean>(false)
  const value = {
    setFlowAnalyticsId,
    flowAnalyticsId,
    analyticsData,
    setAnalyticsData,
    selectedvalue,
    setSelectedValue,
    analyticsFlowName,
    setAnalyticsFlowName,
    accountsList,
    setAccountsList,
    showMoreAnalyticsUI,
    setShowMoreAnalyticsUI,
  }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

export {useReportContext, DataContextProvider}
