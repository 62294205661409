import React, {useState} from 'react'
import {Row, Col, Flex, Spin, Divider, Empty} from 'antd'
import ProfileInfoBlock from '../Subcomponents/ProfileInfoBlock'
import Skills from '../Subcomponents/Skills'
import ContactInfo from '../Subcomponents/ContactInfo'
import EduTable from './EduTable'
import ProfileTabs from '../Tabs'
import {NextBtn, PrevBtn} from '../NextandPrevBtn'
import CompanyInfoBlock from '../Subcomponents/CompanyInfoBlock'
import CompanyBlock from '../Subcomponents/CompanyBlock'
const FullProfile = ({profileData}) => {
  const [showNextLoading, setShowNextLoading] = useState<boolean>(false)

  return (
    <div style={{height: '100%', position: 'relative'}}>
      {showNextLoading && <Spin fullscreen />}
      {/* <Row>
        <Flex justify='space-between' style={{width: '100%'}}>
          <Col style={{width: '5%'}}>
            <PrevBtn showLoading={setShowNextLoading} />
          </Col>
          <Col style={{width: '5%', display: 'flex', justifyContent: 'flex-end'}}>
            <NextBtn showLoading={setShowNextLoading} />
          </Col>
        </Flex>
      </Row> */}
      {/* <Row style={{height: '20%'}}>
        <Col style={{width: '90%'}}></Col>
      </Row> */}
      {/* <Divider style={{margin: '1px 0', background: '#f3f3f3'}} />
      <Row style={{height: 'fit-content'}}>
        <Col style={{width: '30%'}}>
          <Flex vertical align='start' style={{padding: '10px'}}>
            {profileData['profileName'] ? (
              <div>
                <ProfileInfoBlock
                  profile={profileData}
                  userRole={profileData['role'] ? profileData['role'] : ''}
                  name={profileData['profileName'] ? profileData['profileName'] : ''}
                  linkedinUrl={profileData['linkedin'] ? profileData['linkedin'] : ''}
                />
              </div>
            ) : (
              <Empty />
            )}
            <Divider type='vertical' style={{height: '100%', background: '#f3f3f3'}} />
          </Flex>
          <Divider style={{margin: '10px 0', background: '#f3f3f3'}} />
          <ContactInfo
            linkedin={profileData['linkedin'] ? profileData['linkedin'] : ''}
            github={profileData['github'] ? profileData['github'] : ''}
            profileName={profileData['profileName']}
            profile={profileData}
          />
          <Divider style={{margin: '1px 0', background: '#f3f3f3'}} />
          <Flex vertical>
            <Skills userSkills={profileData['skills']?.length > 0 ? profileData['skills'] : []} />
          </Flex>
        </Col>
        <Divider
          type='vertical'
          style={{height: '100vh', maxHeight: 'auto', background: '#f3f3f3'}}
        />

        <Col
          style={{
            width: '65%',
          }}
        >
          <ProfileTabs />
        </Col>
      </Row> */}
      <Row style={{height: '100%'}}>
        <Col span={7} style={{height: '100%'}}>
          <Row style={{paddingTop: '10px', paddingBottom: '13px'}}>
            <div className='d-flex align-items-center justify-content-between w-100'>
              <PrevBtn showLoading={setShowNextLoading} />
              {showNextLoading && <Spin />}
              <NextBtn showLoading={setShowNextLoading} />
            </div>
          </Row>
          <Divider style={{margin: '1px 0', background: '#f3f3f3'}} />
          <Row style={{height: '20%'}}>
            {profileData['profileName'] ? (
              <ProfileInfoBlock
                profile={profileData}
                userRole={profileData['role'] ? profileData['role'] : ''}
                name={profileData['profileName'] ? profileData['profileName'] : ''}
                linkedinUrl={profileData['linkedin'] ? profileData['linkedin'] : ''}
              />
            ) : (
              <Empty />
            )}
          </Row>
          <Divider style={{margin: '1px 0', background: '#f3f3f3'}} />

          <Row>
            <ContactInfo
              linkedin={profileData['linkedin'] ? profileData['linkedin'] : ''}
              github={profileData['github'] ? profileData['github'] : ''}
              profileName={profileData['profileName']}
              profile={profileData}
            />
          </Row>

          <Divider style={{margin: '10px 0', background: '#f3f3f3'}} />
          <Row style={{minHeight: 'fit-content', maxHeight: '40%', overflowY: 'scroll'}}>
            <Skills userSkills={profileData['skills']?.length > 0 ? profileData['skills'] : []} />
          </Row>
        </Col>
        <Divider type='vertical' style={{height: '100%'}} />
        <Col span={10} style={{height: '100%'}}>
          <ProfileTabs />
        </Col>
        <Divider type='vertical' style={{height: '100%'}} />
        <Col span={6} style={{height: '100%'}}>
          <Row
            style={{
              paddingTop: '13px',
              paddingBottom: '12px',
            }}
          >
            <Flex vertical gap='small' className='w-100'>
              <span style={{fontFamily: "'Lato',sans-serif", fontSize: '1.2em'}}>Experience</span>
              <Divider style={{margin: '1px 0', background: '#f3f3f3'}} />
              <EduTable
                educationDetails={
                  profileData['experience'].length > 0 ? profileData['experience'] : []
                }
              />
            </Flex>
          </Row>
          <Row style={{height: '50%', maxHeight: '50%', overflowY: 'scroll'}}>
            <Flex vertical gap='small' className='w-100'>
              <span style={{fontFamily: "'Lato',sans-serif", fontSize: '1.2em'}}>Education</span>
              <Divider style={{margin: '1px 0', background: '#f3f3f3'}} />
              <EduTable
                educationDetails={
                  profileData['education'].length > 0 ? profileData['education'] : []
                }
              />
            </Flex>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default FullProfile
