import {FC, useEffect, useState} from 'react'
import {Chat} from '../../../_metronic/partials/chat/Chat'
import dashstyle from '../../styles/newdash.module.css'
import {useEditQueryContext} from '../../context/editquery'
import {useProfileContext} from '../../context/profilecontext'
import {useApiContext} from '../../context/apicontext'
import {useSideBarContext} from '../../context/sidebarcontext'
import {useChatContext} from '../../context/chatcontext'
import {SaveUserInformationApi} from '../../apicalls/ApiCall'
import {useSessionContext} from '../../context/sessionContext'
import {Modal, Button, Steps, Flex, message} from 'antd'
import ResultsTable from '../../components/Reusablecomponents/ResultsTable'
import TeamInfo from '../../components/Reusablecomponents/TeamInfo'
import PersonalInfo from '../../components/Reusablecomponents/PersonalInfo'
import {userId} from '../../components/DataTypes/UserDataTypes'
import PageLoader from '../../components/Subcomponents/PageLoader'

// DashBoardPage function is the main function which renders the UI for the route of dashboard
// It contains the Chat UI and TableWidget UI. It also consist of the filter popup and the backdrop
const DashboardPage: FC = () => {
  const {setShowPopup} = useEditQueryContext()
  const {setShowProfile, setLocalProfiles} = useProfileContext()
  const {tableUi, setTableUi} = useApiContext()
  const {setShowChatsCard} = useSessionContext()
  const {newSession, setNewSession, setUpdateChatUiSize, updateChatUiSize} = useSideBarContext()
  const {setShowQuery, setMessage} = useChatContext()
  const [open, setOpen] = useState<boolean>(false)
  const [showGetInfo, setShowGetInfo] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [typeOfTeam, setTypeOfTeam] = useState<{type: string}>({type: ''})
  const [personalInfo, setPersonalInfo] = useState<{
    designation: string
    mobile: string
    tool: string
    companyName: string
  }>({designation: '', mobile: '', tool: '', companyName: ''})
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  

  const handleFinish = async () => {
    try {
      setShowLoader(true)
      let newObj = {
        team: typeOfTeam.type,
        designation: personalInfo.designation,
        mobile: personalInfo.mobile,
        tool: personalInfo.tool,
        companyName: personalInfo.companyName,
      }
      const res = await SaveUserInformationApi(userId, newObj)
      setShowLoader(false)
      if (res && res.success && res.data) {
        message.success('Welcome to Beanbag AI')
        localStorage.removeItem('getUserdetails')

        setShowGetInfo(false)
      } else {
        message.error('Error while saving the information')
      }
    } catch (err) {
      message.error('Error while saving the information ' + err)
      console.error('Error while saving the information ', err)
    }
  }
  const handleNext = () => {
    setCurrentStep(currentStep + 1)
  }

  const checkFormValidity = () => {
    return (
      personalInfo.designation.trim() !== '' &&
      personalInfo.mobile.trim() !== '' &&
      personalInfo.tool.trim() !== '' &&
      personalInfo.companyName.trim() !== ''
    )
  }

  useEffect(() => {
    setIsFormValid(checkFormValidity())
  }, [personalInfo])

  const handlePrev = () => {
    setCurrentStep(currentStep - 1)
  }

  useEffect(() => {
    if (newSession) {
      setTableUi(false)
      setShowPopup(false)
      setShowProfile(false)
      setUpdateChatUiSize(false)
      setNewSession(false)
      setShowQuery(false)
      setMessage('')
      setLocalProfiles([])
    }
  }, [newSession])

  useEffect(() => {
    if (tableUi) {
      setUpdateChatUiSize(true)
    }
  }, [tableUi])

  let val = false

  useEffect(() => {
    const storedValue = localStorage.getItem('startTour')
    const getInfo = localStorage.getItem('getUserdetails')
    if (getInfo) {
      let showModal = JSON.parse(getInfo)
      setShowGetInfo(showModal)
    }
    val = storedValue !== null ? JSON.parse(storedValue) : false
    if (val) {
      setOpen(true)
    }
  }, [])

  const steps = [
    {
      key: 'teamInfo',
      title: 'Team information',
      content: <TeamInfo typeOfTeam={setTypeOfTeam} teamType={typeOfTeam} />,
    },
    {
      title: 'Personal Information',
      content: <PersonalInfo setPersonalInfo={setPersonalInfo} personalInfo={personalInfo} />,
    },
  ]

  const items = steps.map((item) => ({key: item.title, title: item.title}))
  return (
    <>
      {/* <Modal
        centered
        width={800}
        closable={false}
        footer={[
          currentStep === steps.length - 1 ? (
            <>
              <Button key='prev' onClick={handlePrev}>
                Previous
              </Button>
              <Button key='submit' type='primary' onClick={handleFinish} disabled={!isFormValid}>
                Save Information
              </Button>
            </>
          ) : (
            <>
              <Button key='prev' onClick={handlePrev} disabled={currentStep === 0}>
                Previous
              </Button>
              <Button
                type='primary'
                key='next'
                onClick={handleNext}
                disabled={typeOfTeam.type === ''}
              >
                Next
              </Button>
            </>
          ),
        ]}
        open={showGetInfo}
      >
        <Flex gap={'middle'} vertical className='p-4'>
          {showLoader && <PageLoader />}
          {steps[currentStep].content}
          <Steps size='small' current={currentStep} items={items} />
        </Flex>
      </Modal> */}
      <div
        style={
          updateChatUiSize ? {gridTemplateColumns: '60% 40%'} : {gridTemplateColumns: '100% 0%'}
        }
        className={`${dashstyle.mainContainer}`}
      >
        <div
          onClick={(e) => {
            e.stopPropagation()
            setShowChatsCard(false)
          }}
          className={dashstyle.chatContainer}
        >
          <Chat />
        </div>
        {tableUi && (
          <div
            onClick={(e) => {
              e.stopPropagation()
              setShowChatsCard(false)
            }}
            className={dashstyle.resultContainer}
          >
            <ResultsTable />
          </div>
        )}
      </div>
    </>
  )
}

const NewDashboardWrapper: FC = () => {
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {NewDashboardWrapper}
