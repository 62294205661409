import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {notification} from 'antd'
import {ConfirmForgotPassword} from '../../../apicalls/Authentication'
import {Button, Flex} from 'antd'
import {useNavigate} from 'react-router-dom'

const initialValues = {
  token: '',
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  token: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Reset code is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match') // Updated to compare with null as well
    .required('Confirm Password is required'),
})

export function ConfirmPasswordReset() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()

  const openNotification = (placement, type, message, description) => {
    api[type]({
      message,
      description,
      placement,
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        let userId = localStorage.getItem('userId')
        let id = userId ? JSON.parse(userId) : null
        let sendForgotToken = await ConfirmForgotPassword(values.token, values.confirmPassword, id)
        if (sendForgotToken && sendForgotToken.data && sendForgotToken.success) {
          openNotification(
            'topRight',
            'success',
            'Password reset success',
            'Successfully reset the password'
          )
          navigate('/')
        } else {
          openNotification(
            'topRight',
            'error',
            'Error',
            'An error occurred while setting the new password. Please try again later.'
          )
        }
      } catch (err) {
        openNotification(
          'topRight',
          'error',
          'Error',
          'An error occurred while setting the new password. Please try again later.'
        )
        console.error('Error while sending the code for forgot password ', err)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      {contextHolder}
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter the reset code along with new password.
        </div>
      </div>

      <div className='fv-row mb-6'>
        <input
          style={{height: '40px'}}
          placeholder='Enter new password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.password && formik.errors.password,
            'is-valid': formik.touched.password && !formik.errors.password,
          })}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-6'>
        <input
          style={{height: '40px'}}
          placeholder='Confirm new password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
          })}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <input
          type='text'
          style={{height: '40px'}}
          placeholder='Enter the reset code'
          autoComplete='off'
          {...formik.getFieldProps('token')}
          className={clsx('form-control bg-transparent ', {
            'is-invalid': formik.touched.token && formik.errors.token,
            'is-valid': formik.touched.token && !formik.errors.token,
          })}
        />
        {formik.touched.token && formik.errors.token && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.token}</span>
            </div>
          </div>
        )}
      </div>
      <Flex gap={'middle'} justify='center'>
        <Button
          style={{height: '35px'}}
          type='primary'
          htmlType='submit'
          disabled={!formik.isValid || Object.values(formik.values).some((value) => value === '')}
          loading={loading}
        >
          <span className='indicator-label'>Confirm new password</span>
        </Button>
        <Link to='/'>
          <Button style={{height: '35px'}} disabled={loading}>
            Cancel
          </Button>
        </Link>
      </Flex>
    </form>
  )
}
