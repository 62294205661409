import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {NewDashboardWrapper} from '../pages/dashboard/NewDashBoardWrapper'
import EmailSetting from '../components/emailsettings'
import Settings from '../components/Settings'
import {FlowReportTable} from '../../_metronic/partials/widgets/tables/FlowReportTable'
import AutomationNavigation from '../components/Subcomponents/AutomationNavigation'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const Upgrade = lazy(() => import('../components/Upgrade'))
  const ListPage = lazy(() => import('../components/Pages/ListPage'))
  const AllProfiles = lazy(() => import('../components/AllProfiles'))
  const Analytcis = lazy(() => import('../components/Pages/AnalyticsPage'))
  const UserSettings = lazy(() => import('../components/UserSettings'))
  const Integrations = lazy(() => import('../components/Integrations'))
  const TasksPage = lazy(() => import('../components/Tasks'))
  const CreateFlow = lazy(() => import('../components/CreateFlow'))
  const FlowInterface = lazy(() => import('../components/FlowInterface'))
  const FlowTemplate = lazy(() => import('../components/FlowTemplate'))
  const DataSearch = lazy(() => import('../components/DataSearch'))
  const CampaginPage = lazy(() => import('../components/Pages/CampaginsPage'))
  const DataDashboard = lazy(() => import('../components/DataDashboard'))
  const UsersDashboard = lazy(() => import('../components/Reusablecomponents/SpilledActivity'))
  const EnrichMent = lazy(() => import('../components/Pages/DataEnrichment'))
  const TeamSettings = lazy(() => import('../components/Pages/TeamSettings'))
  const SignUpDetails = lazy(() => import('../components/Pages/SignUpDetails'))
  const SearchUi = lazy(() => import('../components/Pages/SearchUI'))
  const Automations = lazy(() => import('../components/Pages/AutomationPage'))
  const EmailVerify = lazy(() => import('../components/Pages/VerifyEmail'))
  let localData = localStorage.getItem('userDefaultData')
  let email = ''
  if (localData) {
    let parsedData = JSON.parse(localData)
    email = parsedData.email
  }
  const userId = localStorage.getItem('userId')
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/flows' />} />
        {/* Pages */}
        <Route path='dashboard' element={<NewDashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {email && email === 'marketing@crewscale.com' && (
          <Route
            path='/keywordSearch'
            element={
              <SuspensedView>
                <DataSearch />
              </SuspensedView>
            }
          />
        )}
        <Route
          path='analytics'
          element={
            <SuspensedView>
              <Analytcis />
            </SuspensedView>
          }
        />
        <Route
          path='/upgrade*'
          element={
            <SuspensedView>
              <Upgrade />
            </SuspensedView>
          }
        />
        {(userId === 'raghu@beanbag.ai' ||
          userId === 'akash@beanbag.ai' ||
          userId === 'manasvi.d@crewscale.com' ||
          userId === 'pandiri.nikhila@crewscale.com' ||
          userId === 'nishanth.bhat@crewscale.com' ||
          userId === 'nirmaanagr@gmail.com' ||
          userId === 'renuka@crewscale.com' ||
          userId === 'tech@beanbag.ai' ||
          userId === 'ashmita.gupta@crewscale.com' ||
          userId === 'shiva@beanbag.ai' ||
          userId === 'nikhil@crewscale.com' ||
          userId === '19211a0369@bvrit.ac.in' ||
          userId === 'nikhilkavuri1503@gmail.com' ||
          userId === 'abhiram@crewscale.com') && (
          <Route
            path='/admin'
            element={
              <SuspensedView>
                <DataDashboard />
              </SuspensedView>
            }
          />
        )}
        <Route
          path='/usersettings*'
          element={
            <SuspensedView>
              <UserSettings />
            </SuspensedView>
          }
        />
        <Route
          path='/usersDashboard'
          element={
            <Suspense>
              <UsersDashboard />
            </Suspense>
          }
        />
        <Route
          path='/verify-data'
          element={
            <Suspense>
              <EmailVerify />
            </Suspense>
          }
        />

        <Route
          path='/automation'
          element={
            <Suspense>
              <AutomationNavigation />
            </Suspense>
          }
        />
        <Route
          path='/search'
          element={
            <Suspense>
              <SearchUi />
            </Suspense>
          }
        />

        <Route
          path='additonalInformation'
          element={
            <Suspense>
              <SignUpDetails />
            </Suspense>
          }
        />
        <Route
          path='/autoflowtemplate'
          element={
            <SuspensedView>
              <FlowTemplate />
            </SuspensedView>
          }
        />
        <Route
          path='/flowInterface'
          element={
            <SuspensedView>
              <FlowInterface />
            </SuspensedView>
          }
        />
        <Route
          path='/teamsettings*'
          element={
            <SuspensedView>
              <TeamSettings />
            </SuspensedView>
          }
        />
        <Route
          path='/createFlow'
          element={
            <SuspensedView>
              <CreateFlow />
            </SuspensedView>
          }
        />
        <Route
          path='/dataenrichment'
          element={
            <Suspense>
              <EnrichMent />
            </Suspense>
          }
        />
        <Route
          path='/flowreports'
          element={
            <SuspensedView>
              <FlowReportTable className={''} flowProfiles={[]} />
            </SuspensedView>
          }
        />
        <Route
          path='/integration*'
          element={
            <SuspensedView>
              <Integrations />
            </SuspensedView>
          }
        />
        <Route
          path='/emailsettings'
          element={
            <SuspensedView>
              <EmailSetting />
            </SuspensedView>
          }
        />
        <Route
          path='/taskspage'
          element={
            <SuspensedView>
              <TasksPage />
            </SuspensedView>
          }
        />
        <Route
          path='/settings'
          element={
            <SuspensedView>
              <Settings />
            </SuspensedView>
          }
        />
        <Route
          path='/flows'
          element={
            <SuspensedView>
              <CampaginPage />
            </SuspensedView>
          }
        />
        <Route
          path='/lists*'
          element={
            <SuspensedView>
              <ListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/allprofiles*'
          element={
            <SuspensedView>
              <AllProfiles />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
