/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
import {Auth, GoogleAuthProvider} from 'firebase/auth'
import {signInWithPopup} from 'firebase/auth'
import {SigninUserWithLinkedinAuthApi} from '../../../apicalls/ApiCall'
import {
  LoginUserData,
  SendUTMParams,
  SignInUserWithGoogle,
  SignUpUserData,
} from '../../../apicalls/Authentication'
import {useAuthContext} from '../../../context/AuthContext'
import {BroadcastChannel} from 'broadcast-channel'
import {Button, notification, Input, Spin} from 'antd'
import {LinkedIn} from 'react-linkedin-login-oauth2'
import {EyeFilled} from '@ant-design/icons'
import {getUTMParameters} from '../../../components/UTM/utm'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

interface LoginProps {
  auth: Auth
  provider: GoogleAuthProvider
}

const Login: React.FC<LoginProps> = ({auth, provider}) => {
  const [loading, setLoading] = useState(false)
  const [screenLoading, setScreenLoading] = useState(false)
  const {saveAuth, setToken} = useAuth()
  const {error, toast} = useAuthContext()
  const [text, setText] = useState<string | null>('')
  const [showPassWord, setShowPassword] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()
  const channel = new BroadcastChannel('linkedinValues')

  channel.onmessage = (msg) => setText(msg)
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.href.includes('code')) {
      const channel = new BroadcastChannel('linkedinValues')
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      if (code) {
        channel.postMessage(code)
        window.close()
      }
    }
  }, [window.location.href])

  const sendUTM = async (params, userId) => {
    try {
      await SendUTMParams(params, userId)
    } catch (err) {
      console.error('Error while sending the utm ', err)
    }
  }
  useEffect(() => {
    ;(() => {
      const currentUrl = window.location.href
      const urlParts = currentUrl.split('?')
      if (urlParts.length > 1) {
        const urlParams = new URLSearchParams(urlParts[1])
        const utmParams = {}
        urlParams.forEach((value, param) => {
          utmParams[param] = value
        })
        if (utmParams) {
          sessionStorage.setItem('utmParams', JSON.stringify(utmParams))
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (toast) {
      setLoading(false)
    }
  }, [toast])

  useEffect(() => {
    ;(async () => {
      try {
        if (text) {
          const utmParams = getUTMParameters()
          setScreenLoading(true)
          let obj = {
            code: text,
            utmParams: utmParams,
          }
          let response = await SigninUserWithLinkedinAuthApi(obj)
          if (response && response.success && response.data) {
            setScreenLoading(false)
            localStorage.setItem('loginToken', response.data.token)
            localStorage.setItem('userId', response.data.userId)
            setToken(response.data.userId)
            localStorage.setItem('kt_theme_mode_value', 'light')
            navigate('/flows')
            window.location.reload()
          } else {
            openNotificationWithIcon('error', response.error.message, 'Signin Error')
            setLoading(false)
          }
        }
      } catch (err) {
        setScreenLoading(false)
        console.log(err)
      }
    })()
  }, [text])

  const openNotificationWithIcon = (type, description, title) => {
    console.log(title)
    api[type]({
      message: title,
      description: description,
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const payLoad = {
          email: values.email,
          password: values.password,
        }
        const loginRes = await LoginUserData(payLoad)
        if (loginRes.success) {
          localStorage.setItem('loginToken', loginRes.data.token)
          localStorage.setItem('userId', loginRes.data.userId)
          setToken(loginRes.data.userId)
          localStorage.setItem('kt_theme_mode_value', 'light')
          setLoading(false)
          navigate('/flows')
          window.location.reload()
        } else {
          openNotificationWithIcon('error', loginRes.error.message, 'Signin Error')
          setLoading(false)
        }
      } catch (error) {
        openNotificationWithIcon('error', 'Something went wrong', 'Signin Error')
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleSuccess = (data) => {
    console.log(data)
  }
  const handleFailure = (error) => {
    console.error(error)
  }

  const handleGoogleSignIn = async () => {
    try {
      setScreenLoading(true)
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const currentUser = result.user
          console.log(result)
          if (currentUser) {
            const utmParams = getUTMParameters()
            let obj = {
              userId: currentUser.uid,
              email: currentUser.email,
              photoUrl: currentUser.photoURL,
              userName: currentUser.displayName,
              password: '',
              planName: 'Free Plan',
              utmParams: utmParams,
            }
            try {
              let data = await SignInUserWithGoogle(currentUser.email)
              if (data.success) {
                if (data.data === null) {
                  let newUser = await SignUpUserData(obj)
                  if (newUser.success && newUser.data) {
                    localStorage.setItem('startTour', JSON.stringify(true))
                    localStorage.setItem('loginToken', newUser.data.token)
                    localStorage.setItem('userId', newUser.data.userId)
                    setToken(newUser.data.userId)
                    localStorage.setItem('kt_theme_mode_value', 'light')
                    localStorage.setItem('getUserdetails', JSON.stringify(true))
                    navigate('/flows')
                    window.location.reload()
                  } else {
                    openNotificationWithIcon('error', newUser.error.message, 'Signin Error')
                    setLoading(false)
                  }
                } else {
                  if (data.data) {
                    localStorage.setItem('loginToken', data.data.token)
                    localStorage.setItem('userId', data.data.userId)
                    setToken(data.data.userId)
                    localStorage.setItem('kt_theme_mode_value', 'light')
                    navigate('/flows')
                    window.location.reload()
                  }
                }
              } else {
                openNotificationWithIcon('error', data.error.message, 'Signin Error')
                setLoading(false)
              }
            } catch (err) {
              console.log('Error at Google sign in with db', err)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
      setScreenLoading(false)
    } catch (err) {
      console.log('Auth err' + err)
    }
  }

  return (
    <div className='container mt-8'>
      {contextHolder}
      <div className='row justify-content-center'>
        {screenLoading && (
          <div style={{position: 'fixed', top: '50%', left: '50%'}}>
            <Spin size='large' />
          </div>
        )}
        <div className='col-md-10'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='text-center mb-9'>
              <h1 style={{fontSize: '3em'}} className='text-dark fw-bolder  mb-3'>
                Welcome back!
              </h1>
            </div>
            {toast && (
              <div className='fv-plugins-message-container '>
                <span role='alert' className='text-danger'>
                  {error}
                </span>
              </div>
            )}
            <div className='fv-row mb-6'>
              <Input
                status={formik.errors.email ? 'error' : ''}
                size='large'
                placeholder='Work email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container '>
                  <span role='alert' className='text-danger'>
                    {formik.errors.email}
                  </span>
                </div>
              )}
            </div>
            <div className='fv-row mb-6'>
              <Input
                size='large'
                status={formik.errors.password ? 'error' : ''}
                placeholder='Password'
                type={showPassWord ? 'text' : 'password'}
                suffix={
                  <EyeFilled
                    style={{cursor: 'pointer'}}
                    onClick={() => setShowPassword(!showPassWord)}
                    rev={undefined}
                  />
                }
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-6'>
              <div />
              <Link to='/forgot-password' className='link-primary'>
                Forgot Password ?
              </Link>
            </div>
            <div className='d-grid mb-8'>
              <Button
                size='large'
                type='primary'
                loading={loading}
                htmlType='submit'
                onClick={() => formik.handleSubmit()}
                id='kt_sign_in_submit'
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  Object.values(formik.values).some((value) => value === '')
                }
              >
                <span className='indicator-label'>Login</span>
              </Button>
            </div>
            <div className='text-gray-500 text-center fw-semibold fs-6'>
              Not a Member yet?{' '}
              <Link to='/registration' className='link-primary'>
                Sign up
              </Link>
            </div>
            <div className='separator separator-content my-12'>
              <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with</span>
            </div>
            <div className='row g-3 mb-9'>
              <div className='col-md-6'>
                <a
                  onClick={() => handleGoogleSignIn()}
                  href='#'
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className='h-15px me-2'
                  />
                  Sign in with Google
                </a>
              </div>
              <div className='col-md-6'>
                <LinkedIn
                  closePopupMessage='Custom close message'
                  scope={['openid', 'email', 'profile'].join(' ')}
                  clientId='86jl57vdhipvvt'
                  redirectUri={'https://app.beanbag.ai/'}
                  onSuccess={handleSuccess}
                  onError={handleFailure}
                >
                  {({linkedInLogin}) => (
                    <a
                      onClick={linkedInLogin}
                      className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                    >
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')}
                        className='theme-light-show h-15px me-2'
                      />
                      Sign in with Linkedin
                    </a>
                  )}
                </LinkedIn>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default Login

export const GrowSpinner = () => {
  return (
    <div
      style={{width: '50px', height: '50px'}}
      className='spinner-grow text-primary'
      role='status'
    >
      <span className='sr-only'>Loading...</span>
    </div>
  )
}
