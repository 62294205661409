import React from 'react'
import {Modal, Flex, Row, Col} from 'antd'
import PersonalChoice from '../PersonalChoiceCard'
import JobTitlesCard from '../JobTitlesCard'
import PastJobTitles from '../PastJobTitlesCard'
import CompaniesListing from '../CompanyListCard'
import UniversityCard from '../UnviversitiesCard'
import CompanyIndustryCard from '../CompanyIndustryCard'
import LanguageCard from '../LanguageCard'
const FilterModal = ({modalOpen, onOk, onCancel}) => {
  return (
    <div style={{padding: '10px'}}>
      <Modal width={1000} centered open={modalOpen} onOk={onOk} onCancel={onCancel}>
        <Row gutter={[10, 16]} style={{padding: '5px'}}>
          <Col span={12}>
            <Flex gap={'small'} vertical>
              <PersonalChoice />
              <JobTitlesCard />
              <PastJobTitles />
            </Flex>
          </Col>
          <Col span={12}>
            <Flex gap={'small'} vertical>
              <CompaniesListing />
              <UniversityCard />
              <CompanyIndustryCard />
              <LanguageCard />
            </Flex>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default FilterModal
