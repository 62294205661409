// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getAnalytics, logEvent} from 'firebase/analytics'
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBtViP3NEYUnbU_A1Ke45hoSprCPj0G634',
  authDomain: 'beanbag-auth.firebaseapp.com',
  projectId: 'beanbag-auth',
  storageBucket: 'beanbag-auth.appspot.com',
  messagingSenderId: '346448495660',
  appId: '1:346448495660:web:fab412edd4bda78089fd89',
  measurementId: 'G-XX4YC5P0D8',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
logEvent(analytics, 'init')

export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()

export default app
