import React, {useState, useEffect} from 'react'
import {Flex, Input, Select, Divider} from 'antd'

const PersonalInfo = ({setPersonalInfo, personalInfo}) => {
  const [showOthersInput, setShowOthersInput] = useState(false)
  const [designation, setDesignation] = useState('')
  const [mobile, setMobile] = useState('')
  const [showFindoutOthers, setShowFindoutOthers] = useState(false)
  const [tool, setTool] = useState('')
  const [findout, setFindout] = useState('')
  const [companyName, setCompanyName] = useState('')

  useEffect(() => {
    setDesignation(personalInfo.designation)
    setMobile(personalInfo.mobile)
    setTool(personalInfo.tool)
  }, [])

  const options = [
    {value: 'Lusha', label: 'Lusha'},
    {value: 'Apollo', label: 'Apollo'},
    {value: 'ContactOut', label: 'ContactOut'},
    {value: 'EasyLeadz', label: 'EasyLeadz'},
    {value: 'Others', label: 'Others'},
  ]

  const findOutOptions = [
    {value: 'Google search', label: 'Google search'},
    {value: 'Social media', label: 'Social media'},
    {value: 'Blog article', label: 'Blog article'},
    {value: 'Customer Review sites', label: 'Customer Review sites'},
    {value: 'Peer refferal ', label: 'Peer refferal '},
    {value: 'Employer/co-worker', label: 'Employer/co-worker'},
    {value: 'Networking event', label: 'Networking event'},
    {value: 'Word of mouth', label: 'Word of mouth'},
    {value: 'Others', label: 'Others'},
  ]

  const handleDesignationChange = (value) => {
    setDesignation(value)
    setPersonalInfo({...personalInfo, designation: value})
  }

  const handleCompanyName = (value) => {
    setCompanyName(value)
    setPersonalInfo({...personalInfo, companyName: value})
  }

  const handlePhoneNumberChange = (value) => {
    setMobile(value)
    setPersonalInfo({...personalInfo, mobile: value})
  }

  const handleSelectChange = (value) => {
    if (value === 'Others') {
      setTool('')
      setShowOthersInput(true)
    } else {
      setTool(value)
      setShowOthersInput(false)
      setPersonalInfo({...personalInfo, tool: value})
    }
  }
  const handleSelectFindoutChange = (value) => {
    if (value === 'Others') {
      setFindout('')
      setShowFindoutOthers(true)
    } else {
      setFindout(value)
      setShowFindoutOthers(false)
      setPersonalInfo({...personalInfo, findout: value})
    }
  }

  const handleOthers = (value) => {
    setTool(value)
    setPersonalInfo({...personalInfo, tool: value})
  }
  const handleFindoutOthers = (value) => {
    setFindout(value)
    setPersonalInfo({...personalInfo, findout: value})
  }

  return (
    <Flex vertical gap={'small'} style={{paddingBottom:'20px'}} >
      <Divider>
        <span style={{fontSize:'20px',fontWeight:500}}>Tell us a bit more about yourself</span>
      </Divider>
      <Flex vertical align='center' gap={'middle'} justify='space-between' wrap='wrap'>
        <Flex vertical style={{width: '100%'}}>
          <span className='fs-5 text-grey'>Enter your Company name</span>
          <Input
            style={{fontSize: '14px', fontFamily: "'Karla',sans-serif",height:'40px'}}
            value={companyName}
            onChange={(e) => handleCompanyName(e.target.value)}
            required
            placeholder='Your company name'
            size='large'
          />
        </Flex>
        <Flex vertical style={{width: '100%'}}>
          <span className='fs-5 text-grey'>What's your designation</span>
          <Input
            style={{fontSize: '14px', fontFamily: "'Karla',sans-serif",height:'40px'}}
            value={designation}
            onChange={(e) => handleDesignationChange(e.target.value)}
            required
            placeholder='Your designation'
            size='large'
          />
        </Flex>
        <Flex vertical style={{width: '100%'}}>
          <span className='fs-5 text-grey'>What's your mobile number?</span>
          <Input
            style={{fontSize: '14px', fontFamily: "'Karla',sans-serif",height:'40px'}}
            value={mobile}
            onChange={(e) => handlePhoneNumberChange(e.target.value)}
            required
            placeholder='Enter your mobile number'
            size='large'
          />
        </Flex>
        <Flex vertical style={{width: '100%'}}>
          <span className='fs-5 text-grey'>Have you used any similar tool before?</span>
          <Select
            value={tool}
            size='large'
            style={{width: '100%', fontSize: '14px', fontFamily: "'Karla',sans-serif",height:'40px'}}
            placeholder='Select your tool'
            options={options}
            onChange={handleSelectChange}
          />
          {showOthersInput && (
            <Input
              value={tool}
              onChange={(e) => handleOthers(e.target.value)}
              size='large'
              
              style={{fontSize: '14px',marginTop:"10px", fontFamily: "'Karla',sans-serif",height:'40px'}}
              required
              placeholder='Enter your tool'
            />
          )}
        </Flex>
        <Flex vertical style={{width: '100%',position:'relative'}}>
          <span className='fs-5 text-grey'>How did you hear about us?</span>
          <Select
            value={findout}
            size='large'
            style={{fontSize: '14px', fontFamily: "'Karla',sans-serif",height:'40px'}}
            placeholder='Select your tool'
            options={findOutOptions}
            onChange={handleSelectFindoutChange}
          />
          {showFindoutOthers && (
            <Input
              value={findout}
              style={{fontSize: '14px', fontFamily: "'Karla',sans-serif",height:'40px',marginTop:'10px'}}
              onChange={(e) => handleFindoutOthers(e.target.value)}
              size='large'
              required
              placeholder='Type your own'
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PersonalInfo
