import {UserObject} from '../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuContent'

let userObj: UserObject
export let userId = ''
export let userName = ''
const name = localStorage.getItem('userName')
const Id = localStorage.getItem('userId')
if (name) {
  userName = name as string
}
if (Id) {
  userId = Id as string
}
