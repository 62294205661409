import {Tag} from 'antd'
const FilterTags = ({removeFunction, value}) => {
  return (
    <Tag
      style={{
        margin: '3px',
        fontFamily: "'Karla', sans-serif",
        borderRadius: '15px',
        padding: '5px',
        paddingRight: '15px',
        paddingLeft: '15px',
      }}
      color='teal'
      closable
      onClose={(e) => {
        e.preventDefault()
        removeFunction(value)
      }}
    >
      {value.charAt(0).toUpperCase() + value.slice(1)}
    </Tag>
  )
}

export default FilterTags
