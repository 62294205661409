import {FC, useState, useEffect, useRef} from 'react'
import emailStyle from '../styles/flowstyle.module.css'
import {useFlowContext} from '../context/FlowContext'
import {useEmailContext} from '../context/emailContext'
import {Editor} from '@tinymce/tinymce-react'
import {GetEmailTemplatesApi, GetAiPromptApi, PromptApi, GetPreviewApi} from '../apicalls/ApiCall'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {SaveUserTemplateApi, UpdateFlowContentApi} from '../apicalls/ApiCall'
import {Card} from 'react-bootstrap'
import {SaveOutlined, SearchOutlined} from '@ant-design/icons'
import BeenhereIcon from '@mui/icons-material/Beenhere'
import type {MenuProps} from 'antd'
import messageIcon from '../assets/message.png'
import socialIcon from '../assets/social-media.png'
import timeIcon from '../assets/time.png'
import pieChartIcon from '../assets/pie-chart.png'
import groupIcon from '../assets/group.png'
import network from '../assets/network.png'
import DashboardIcon from '@mui/icons-material/Dashboard'
import {
  Input,
  Button,
  Tooltip,
  Dropdown,
  message,
  Spin,
  Tabs,
  Modal,
  Divider,
  Flex,
  Menu,
  List,
  Empty,
  Avatar,
} from 'antd'
import EmailTemplate from './EmailTemplates'
import {userId} from './DataTypes/UserDataTypes'
import EmailIcons from './Subcomponents/EmailIcons'
import EmptyLogo from './Subcomponents/Empty'
import EditorComponent from './Subcomponents/Editor'
import Mrbean from './Subcomponents/Mrbean'
import {BracketsCurly, Robot} from '@phosphor-icons/react'

interface EmailValue {
  Nodeid: string
  senderMail: UserAccountData | null
  subject: string
  message: string
  showsaved: boolean
  templateName: string
}
interface UserAccountData {
  id: string
  name: string
  type: string
}
interface OutreachEmail {
  category: string
  prompts: {
    prompt1: string
    prompt2: string
    prompt3: string
  }
}
const braceValueItems: MenuProps['items'] = [
  {
    label: <span>{`{{profileName}}`}</span>,
    key: '{{profileName}}',
  },
  {
    label: <span>{`{{picUrl}}`}</span>,
    key: '{{picUrl}}',
  },
  {
    label: <span>{`{{mobile}}`}</span>,
    key: '{{mobile}}',
  },
  {
    label: <span>{`{{email}}`}</span>,
    key: '{{email}}',
  },
  {
    label: <span>{`{{github}}`}</span>,
    key: '{{github}}',
  },
  {
    label: <span>{`{{linkedin}}`}</span>,
    key: '{{linkedin}}',
  },

  {
    label: <span>{`{{companyName}}`}</span>,
    key: '{{companyName}}',
  },

  {
    label: <span>{`{{role}}`}</span>,
    key: '{{role}}',
  },
  {
    label: <span>{`{{skills}}`}</span>,
    key: '{{skills}}',
  },
]

const categoryIconMap = {
  'Initial Outreach': messageIcon,
  'Follow-Up': timeIcon,
  'Industry-Specific Outreach': pieChartIcon,
  'Role-Specific Outreach': groupIcon,
  Networking: network,
  'Social Media Outreach': socialIcon,
}
// This component provides an interface for composing and saving email messages, including subjects, content, and templates.
const EmailForm: FC = () => {
  const {Search, TextArea} = Input
  const {nodeUnqId, flowDataObj, setEmailObjArr, selectedFlowId, setNodes, startingFlowName} =
    useFlowContext()
  const {
    setShowEmailTemplate,
    sub,
    setSub,
    content,
    setContent,
    templateValue,
    setTemplateValue,
    setAllTemplates,
  } = useEmailContext()

  const [messageApi, contextHolder] = message.useMessage()
  const [templateKeys, setTemplateKeys] = useState<string[]>([])
  const [showSaved, setShowSaved] = useState<boolean>(false)
  const [mrBeanLoading, setMrBeanLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [account, setAccount] = useState<UserAccountData | null>(null)
  const [showAiTemplate, setShowAiTemplate] = useState<boolean>(false)
  const [promptData, setPromptData] = useState<OutreachEmail[]>([])
  const [selectedPrompt, setSelectedPrompt] = useState<OutreachEmail | null>(null)
  const [hoveredPrompt, setHoveredPrompt] = useState<string | null>(null)
  const [promptValue, setPromptValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [disableSave, setDisableSave] = useState<boolean>(true)
  const [disableTemplateBtn, setDisableTemplateBtn] = useState<boolean>(true)
  const [showTemplates, setShowTemplates] = useState<boolean>(false)
  const [showMrBean, setShowMrBean] = useState<boolean>(false)
  const [loadingAi, setLoadingAi] = useState<boolean>(false)
  const [braceValue, setBraceValue] = useState<MenuProps['items']>(braceValueItems)
  const [isAutoSaving, setIsAutoSaving] = useState(false)
  const [lastModifiedTime, setLastModifiedTime] = useState(Date.now())
  const editorRef = useRef<any>(null)

  useEffect(() => {
    if (flowDataObj.data.EmailArr) {
      setEmailObjArr(flowDataObj.data.EmailArr)
    }
  }, [flowDataObj])

  useEffect(() => {
    if (sub && content) {
      setDisableSave(false)
      setDisableTemplateBtn(false)
    } else {
      setDisableTemplateBtn(true)
      setDisableSave(true)
    }
  }, [sub, content])

  const handleSubChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const subject = event.target.value
    setSub(subject)
    setShowSaved(false)
    setLastModifiedTime(Date.now())
  }
  const handleTemplateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const templateName = event.target.value
    setTemplateValue(templateName)
    setShowSaved(false)
    setLastModifiedTime(Date.now())
  }
  const handleEditorChange = (content, editor) => {
    setContent(content)
    setShowSaved(false)
    setShowAiTemplate(false)
    setLastModifiedTime(Date.now())
  }
  const handleAiTemplate = async () => {
    try {
      setMrBeanLoading(true)
      setShowMrBean(true)
      let data = await GetAiPromptApi(userId)
      setMrBeanLoading(false)
      if (data && data.data && data.success) {
        setPromptData(data.data)
        setSelectedPrompt(data.data[0])
        setShowAiTemplate(true)
      }
    } catch (err) {
      messageApi.error('Error while getting the data of prompts ')
      console.error('Error while getting the data of prompts' + err)
    }
  }

  const handleEmailObjSave = async () => {
    const updatedEmailObj: EmailValue = {
      Nodeid: nodeUnqId,
      senderMail: account ? account : null,
      subject: sub,
      message: content,
      showsaved: true,
      templateName: templateValue,
    }

    try {
      if (selectedFlowId) {
        let data = await UpdateFlowContentApi(userId, nodeUnqId, updatedEmailObj, selectedFlowId)
        if (data.success) {
          messageApi.success('Email data successfully saved')
          setNodes(data.data['node'])
        }
      } else {
        let data = await UpdateFlowContentApi(userId, nodeUnqId, updatedEmailObj, startingFlowName)
        if (data.success) {
          messageApi.success('Email data successfully saved')

          setNodes(data.data['node'])
        }
      }
    } catch (err) {
      messageApi.error('Failed to save email data ')
      console.error('Error while sending the request' + err)
    }
    setShowSaved(true)
  }
  const handleSavetemplate = async () => {
    if (!templateValue) {
      return message.warning('Template name is empty')
    }
    let templateId = nodeUnqId
    let tempData = {
      templateName: templateValue,
      content: content,
      subject: sub,
    }
    try {
      let data = await SaveUserTemplateApi(userId, templateId, tempData)
      if (data.success) {
        messageApi.success('Template saved successfully')
        setTemplateKeys(data.data)
      }
    } catch (err) {
      messageApi.error('Error while saving the template')
      console.error('error while saving the template' + err)
    }
  }

  const handleSelectTemplate = async () => {
    try {
      setShowTemplates(true)
      let data = await GetEmailTemplatesApi(userId)
      console.log(data)
      if (data.success) {
        setAllTemplates(data.data)
        setShowSaved(false)
        setShowEmailTemplate(true)
      }
    } catch (err) {
      messageApi.error('Error while  Setting the template Data')
      console.error('Error while Setting the template Data')
    }
  }
  const handleSelectValue = (item) => {
    if (!editorRef.current) return
    const editor = editorRef.current.editor
    const selection = editor.selection
    if (!selection.isCollapsed()) {
      editor.selection.setContent(String(item))
    } else {
      editor.execCommand('mceInsertContent', false, String(item))
    }
    setSearchText('')
    setBraceValue(braceValueItems)
  }

  const handleSubjectReplace = (item) => {
    const input = document.getElementById('Subject') as HTMLInputElement
    const startPos = input.selectionStart
    const endPos = input.selectionEnd

    if (startPos !== null && endPos !== null) {
      const newValue =
        input.value.substring(0, startPos) +
        item +
        input.value.substring(endPos, input.value.length)
      input.value = newValue
      const newCursorPosition = startPos + item.length
      input.setSelectionRange(newCursorPosition, newCursorPosition)
      const event = new Event('input', {bubbles: true})
      input.dispatchEvent(event)
      setSub(input.value)
      setLastModifiedTime(Date.now())
    }
    setSearchText('')
    setBraceValue(braceValueItems)
  }

  const handleUsePrompt = async () => {
    try {
      setShowMrBean(false)
      setLoadingAi(true)
      if (promptValue || selectedPrompt?.prompts[0]) {
        let data = await PromptApi(userId, promptValue || selectedPrompt?.prompts[0])
        setLoadingAi(false)
        if (data && data.success && data.data) {
          setPromptValue('')
          let subject = ''
          const lines = data.data.split('<p>')
          if (lines[0].trim().startsWith('Subject:')) {
            subject = lines[0].trim().substring(8).trim()
          }
          const content = lines.slice(2).join('<p>')
          setContent(content)
          setSub(subject)
        } else {
          messageApi.error('Error while getting the data from AI')
        }
      }
    } catch (err) {
      setLoadingAi(false)
      messageApi.error('Error while getting the data from AI')
      console.error('Error while getting the prompt data' + err)
    }
  }

  const handlePromptSelect = (index: number) => {
    let newPrompt = promptData[index]
    console.log(newPrompt)
    setSelectedPrompt(newPrompt)
  }

  const handleTabUpdate = (activeKey) => {
    if (selectedPrompt && selectedPrompt.prompts) {
      const selectedLabel = Object.values(selectedPrompt.prompts)[parseInt(activeKey, 10) - 1]
      setPromptValue(selectedLabel)
    }
  }
  const handleSearch = (e) => {
    const searchTerm = e.target.value
    setSearchText(searchTerm)
    if (!searchTerm) {
      setBraceValue(braceValueItems)
    } else {
      let filtered = braceValueItems?.filter((option) =>
        (option?.key?.toString() ?? '').toLowerCase().includes(searchTerm.toLowerCase())
      )
      setBraceValue(filtered)
    }
  }
  useEffect(() => {
    const autoSaveTimer = setInterval(() => {
      if (Date.now() - lastModifiedTime >= 2000) {
        clearInterval(autoSaveTimer)
        setIsAutoSaving(false)
      } else {
        if (window.location.pathname !== '/lists') handleEmailObjSave()
      }
    }, 1000)
    return () => clearInterval(autoSaveTimer)
  }, [sub, content, templateValue, lastModifiedTime])

  return (
    <div
      style={{padding: '10px 20px 10px 20px'}}
      className='d-flex flex-column justify-content-between h-100'
    >
      {contextHolder}
      <div>
        <div>
          <div className='d-flex w-100 justify-content-between py-3'>
            <div className='d-flex justify-content-center align-items-center'>
              <div className='d-flex'>
                <div
                  style={{gap: '10px'}}
                  className='symbol symbol-35px me-2 d-flex align-items-center'
                >
                  <span>
                    <EmailIcons width={'1.23em'} height={'1.23em'} />
                  </span>
                  <Input
                    value={templateValue}
                    placeholder='Template Name'
                    type='text'
                    onChange={handleTemplateChange}
                  />
                </div>
              </div>
            </div>

            <div style={{gap: '10px'}} className='d-flex align-items-center'>
              <Tooltip placement='top' title={'Save to templates'}>
                <Button
                  disabled={disableTemplateBtn}
                  onClick={handleSavetemplate}
                  type='primary'
                  icon={<SaveOutlined rev={undefined} />}
                ></Button>
              </Tooltip>

              <Button
                style={{color: '#f9856e', border: '1px solid #f9856e', borderRadius: '6px'}}
                onClick={() => handleSelectTemplate()}
                icon={<DashboardIcon style={{fontSize: '1.5em'}} className='mb-1' />}
              >
                Templates
              </Button>
            </div>
            <Modal
              centered
              width={900}
              open={showTemplates}
              onCancel={() => setShowTemplates(false)}
              footer={null}
            >
              <EmailTemplate setShowTemplates={setShowTemplates} />
            </Modal>
          </div>
          <div className='d-flex w-100 align-items-start mb-3 flex-column'>
            <Flex
              gap={'middle'}
              style={{width: '100%', justifyContent: 'space-between'}}
              align='center'
            >
              <label className='fs-6 col-form-label fw-semibold'>Subject :</label>

              <div style={{position: 'relative'}}>
                <Dropdown
                  menu={{items: braceValue}}
                  dropdownRender={(overlay) => (
                    <div
                      style={{
                        minHeight: '20vh',
                        maxHeight: '30vh',
                        overflowY: 'scroll',
                        padding: '10px',
                      }}
                      className='bg-white'
                    >
                      <Input
                        value={searchText}
                        onChange={(e) => handleSearch(e)}
                        placeholder='Search/Create list'
                        suffix={<SearchOutlined rev={undefined} />}
                      />
                      <List
                        dataSource={braceValue}
                        renderItem={(item, index) => (
                          <List.Item
                            onClick={() => handleSubjectReplace(item?.key)}
                            className='bg-hover-light px-3'
                          >
                            <span>{item?.key}</span>
                          </List.Item>
                        )}
                      ></List>
                    </div>
                  )}
                >
                  <BracketsCurly
                    size={22}
                    color='#000333'
                    id={emailStyle.braces}
                    onClick={(e) => e.preventDefault()}
                  />
                </Dropdown>
              </div>
            </Flex>
            <Input
              value={sub}
              id='Subject'
              placeholder='Enter Your subject here'
              type='text'
              onChange={handleSubChange}
            />
          </div>
          <div
            style={{position: 'relative'}}
            className='d-flex w-100 align-items-start mb-7 flex-column'
          >
            <div className='w-100 d-flex flex-column '>
              <div className='d-flex justify-content-between align-items-center'>
                <label className=' fs-6 col-form-label'>Content :</label>
                <div style={{gap: '10px'}} className='d-flex align-items-center '>
                  {loadingAi && <Spin tip='Loading...' />}
                  <Button
                    style={{gap: '5px'}}
                    onClick={() => handleAiTemplate()}
                    icon={<Robot size={20} style={{marginBottom: '3px'}} color='#fcfcfc' />}
                    type='primary'
                  >
                    Mr Bean
                  </Button>
                  {mrBeanLoading ? (
                    <Spin fullscreen />
                  ) : (
                    <Mrbean
                      showMrBean={showMrBean}
                      setPromptValue={setPromptValue}
                      setShowMrBean={setShowMrBean}
                      promptData={promptData}
                      handlePromptSelect={handlePromptSelect}
                      selectedPrompt={selectedPrompt}
                      promptValue={promptValue}
                      handleTabUpdate={handleTabUpdate}
                      handleUsePrompt={handleUsePrompt}
                      loading={loading}
                    />
                  )}

                  <div style={{position: 'relative'}}>
                    <Dropdown
                      menu={{items: braceValue}}
                      dropdownRender={(overlay) => (
                        <div
                          style={{
                            minHeight: '20vh',
                            maxHeight: '30vh',
                            overflowY: 'scroll',
                            padding: '10px',
                          }}
                          className='bg-white'
                        >
                          <Input
                            value={searchText}
                            onChange={(e) => handleSearch(e)}
                            placeholder='Search/Create list'
                            suffix={<SearchOutlined rev={undefined} />}
                          />
                          <List
                            dataSource={braceValue}
                            renderItem={(item, index) => (
                              <List.Item
                                onClick={() => handleSelectValue(item?.key)}
                                className='bg-hover-light px-3'
                              >
                                <span>{item?.key}</span>
                              </List.Item>
                            )}
                          ></List>
                        </div>
                      )}
                    >
                      <BracketsCurly
                        size={22}
                        color='#000333'
                        id={emailStyle.braces}
                        onClick={(e) => e.preventDefault()}
                      />
                    </Dropdown>
                  </div>
                </div>
              </div>
              <EditorComponent
                editorRef={editorRef}
                content={content}
                handleEditorChange={handleEditorChange}
              />
            </div>
          </div>
        </div>
        <div className={` ${emailStyle.emailcardfooter}`}>
          <div
            className={`d-flex justify-content-between w-100
              `}
          >
            <div style={{gap: '10px'}} className='w-50 d-flex  '></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailForm
