import {FC, useState, createContext, useContext} from 'react'

type settingsDataType = {
  icon: string
  name: string
  color: string
}
interface CreditDetail {
  creditType: string
  amount: number
}

interface Communication {
  Email: string
  Phone: string
}

interface SignInObject {
  userName: string
  password: string
}

interface PlanDetails {
  planName: string
  creditDetails: CreditDetail[]
  expiry: string
}

interface UserProfile {
  profilePic: string
  fullName: string
  company: string
  contactPhone: string
  companySite: string
  country: string
  Language: string
  TimeZone: string
  Currency: string
  Communication: Communication
  SignInObject: SignInObject
  PlanDetails: PlanDetails
}

type UserSettingContextProp = {
  profileObj
  setProfileObj
  userCredits: number
  setUserCredits: (userCredits: number) => void
  totalCredits: number
  setTotalCredits: (totalCredits: number) => void
  emailCredits: number
  setEmailCredits: (emailCredits: number) => void
  phoneCredits: number
  setPhoenCredits: (phoneCredits: number) => void
  emailTotalCredits: number
  setEmailTotalCredits: (emailTotalCredits: number) => void
  phoneTotalCredits: number
  setPhoneTotalCredits: (phoneTotalCredits: number) => void
  expiryDate: string
  setExpiryDate: (expiryDate: string) => void
  rechargeDate: string
  setRechargeDate: (rechargeDate: string) => void
}

const UserSettingContext = createContext<UserSettingContextProp | undefined>(undefined)

const UserSettingProvider = ({children}) => {
  const [profileObj, setProfileObj] = useState()
  const [userCredits, setUserCredits] = useState<number>(0)
  const [totalCredits, setTotalCredits] = useState<number>(0)
  const [emailCredits, setEmailCredits] = useState<number>(0)
  const [phoneCredits, setPhoenCredits] = useState<number>(0)
  const [emailTotalCredits, setEmailTotalCredits] = useState<number>(0)
  const [phoneTotalCredits, setPhoneTotalCredits] = useState<number>(0)
  const [expiryDate, setExpiryDate] = useState<string>('')
  const [rechargeDate, setRechargeDate] = useState<string>('')
  let value = {
    profileObj,
    setProfileObj,
    userCredits,
    setUserCredits,
    totalCredits,
    setTotalCredits,
    emailCredits,
    setEmailCredits,
    emailTotalCredits,
    phoneCredits,
    setPhoenCredits,
    setEmailTotalCredits,
    phoneTotalCredits,
    setPhoneTotalCredits,
    expiryDate,
    setExpiryDate,
    rechargeDate,
    setRechargeDate,
  }

  return <UserSettingContext.Provider value={value}>{children}</UserSettingContext.Provider>
}

const useUserSettingContext = () => {
  const upgrade = useContext(UserSettingContext)
  if (!upgrade) throw new Error('error in UpgradeContext')
  return upgrade
}

export {useUserSettingContext, UserSettingProvider}
