import React from 'react'
import {Flex, Divider} from 'antd'

const OtherCompanyInfo = ({
  employeeCount,
  industry,
  revenue,
  alternateDomains,
  alternateName,
  year,
}) => {
  return (
    <Flex vertical>
      <span className='heading fw-bold fs-4'>Company information</span>
      <Divider />
      {employeeCount && (
        <>
          <Flex align='center' gap='middle'>
            <span className='heading fs-5'>Employee count:</span>
            <span className=' fs-5'>{employeeCount}</span>
          </Flex>
          <Divider style={{margin: '10px 0'}} />
        </>
      )}
      {year && (
        <>
          <Flex align='center' gap='middle'>
            <span className='heading fs-5'>Founded year:</span>
            <span className=' fs-5'>{year}</span>
          </Flex>
          <Divider style={{margin: '10px 0'}} />
        </>
      )}
      {revenue && (
        <>
          <Flex align='center' gap='middle'>
            <span className='heading fs-5'>Revenue :</span>
            <span className=' fs-5'>{revenue}</span>
          </Flex>
          <Divider style={{margin: '10px 0'}} />
        </>
      )}
      {industry && (
        <>
          <Flex align='center' gap='middle'>
            <span className='heading fs-5'>Company industry:</span>
            <span className=' fs-5'>{industry}</span>
          </Flex>
          <Divider style={{margin: '10px 0'}} />
        </>
      )}
      {alternateDomains && alternateDomains.length > 0 && (
        <>
          <Flex vertical align='start' gap='small' justify='start'>
            <span className='heading fs-5 '>Company domains:</span>
            <span className=' fs-6 text-grey'>{alternateDomains.join(', ')}</span>
          </Flex>
          <Divider style={{margin: '10px 0'}} />
        </>
      )}
      {alternateName && alternateName.length > 0 && (
        <>
          <Flex vertical align='start' gap='small' justify='start'>
            <span className='heading fs-5 '>Company Alternate names:</span>
            <span className=' fs-6 text-grey'>{alternateName.join(', ')}</span>
          </Flex>
          <Divider style={{margin: '10px 0'}} />
        </>
      )}
    </Flex>
  )
}

export default OtherCompanyInfo
