import {GetUserAnalyticsDataApi} from '../../apicalls/ApiCall'
import {userId} from '../DataTypes/UserDataTypes'
import {message} from 'antd'

export const handleGetAnalytics = async ({
  setNewProfilesList,
  setFlowAnalyticsId,
  setAnalyticsData,
  navigate,
  setSelectedValue,
  setAnalyticsFlowName,
  setAccountsList,
}) => {
  try {
    let dict = {
      flowId: null,
      AccId: null,
    }
    let res = await GetUserAnalyticsDataApi(userId, dict)
    setNewProfilesList([])
    console.log(res.data)
    if (res.success) {
      if (res.data) {
        setAnalyticsData(res.data)
        setFlowAnalyticsId('')
        setSelectedValue('All')
        navigate('/analytics')
        setAnalyticsFlowName('')
        setAccountsList([])
      } else {
        message.error('Something went wrong')
      }
    } else {
      message.error('Error while getting the analytics data')
    }
  } catch (err) {
    message.error('Error while getting the analytics data')
    console.error('Error while getting the analytics data')
  }
}

export const handleGetFlowAnalytics = async ({
  setNewProfilesList,
  setAnalyticsData,
  navigate,
  flowId,
}) => {
  try {
    let dict = {
      flowId: flowId,
      AccId: null,
    }
    let res = await GetUserAnalyticsDataApi(userId, dict)
    setNewProfilesList([])
    if (res.success) {
      if (res.data) {
        setAnalyticsData(res.data)
        navigate('/analytics')
      } else {
        message.error('Something went wrong')
      }
    } else {
      message.error('Error while getting the analytics data')
    }
  } catch (err) {
    message.error('Error while getting the analytics data')
    console.error('Error while getting the analytics data')
  }
}
